import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import removalsActions from "../../../redux/actions/removalsGreenPoint";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { ViewList, ViewModule } from "@material-ui/icons";

export default function Search() {
  const [search, setSearch] = useState("");
  const removals = useSelector((state) => state.removalsGreenPoint);
  const dispatch = useDispatch();

  useEffect(() => setSearch(removals.search), [removals.search]);

  const handleSubmit = () => {
    dispatch(removalsActions.setSearch({ search }));
  };
  return (
    <Grid
      container
      alignItems="center"
      style={{ marginBottom: 10 }}
      justify="space-between"
      direct="row"
    >
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              placeholder="buscar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ height: 40, marginTop: 3 }}
              onClick={() => handleSubmit()}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ToggleButtonGroup
          value={removals.dataView}
          exclusive
          onChange={(e, dataView) =>
            dispatch(removalsActions.setDataView(dataView))
          }
          size="small"
        >
          <ToggleButton value="LIST" aria-label="LIST">
            <ViewList />
          </ToggleButton>
          <ToggleButton value="CARDS" aria-label="CARDS">
            <ViewModule />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
}
