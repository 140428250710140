import axios from "axios";
import authActions from "./auth";

const fetchGreenPoints = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/greenPoints");
      dispatch({ type: "UTILS_FETCH_GREENPOINTS", payload: response.data });
    } catch (err) {
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/users");
      dispatch({ type: "UTILS_FETCH_USERS", payload: response.data });
    } catch (err) {
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchGroups = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/groups/groups");
      dispatch({ type: "UTILS_FETCH_GROUPS", payload: response.data });
    } catch (err) {
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchMaterials = () => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      let URL = "";
      if (user.type === "ADMIN" || user.type === "GREENPOINT") {
        URL = "/utils/materials";
      }
      if (user.type === "ADMIN_EXTERN" || user.type === "GREENPOINT_EXTERN") {
        URL = `/utils/externals-materials?group=${user.groupID._id}`;
      }

      const response = await axios.get(URL);
      const { oldMaterials, allMaterials, allMaterialsWithDeleded } =
        response.data;
      dispatch({ type: "UTILS_FETCH_MATERIALS", payload: oldMaterials });
      dispatch({ type: "UTILS_FETCH_ALL_MATERIALS", payload: allMaterials });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED",
        payload: allMaterialsWithDeleded,
      });
    } catch (err) {
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchAllGroupExternalsMaterials = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/utils/externals-materials-by-all-groups`
      );
      const { oldMaterials, allMaterials, allMaterialsWithDeleded } =
        response.data;
      dispatch({
        type: "UTILS_FETCH_MATERIALS_EXTERNALS",
        payload: oldMaterials,
      });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_EXTERNALS",
        payload: allMaterials,
      });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED_EXTERNALS",
        payload: allMaterialsWithDeleded,
      });
    } catch (err) {
      dispatch({ type: "UTILS_FETCH_MATERIALS_EXTERNALS", payload: {} });
      dispatch({ type: "UTILS_FETCH_ALL_MATERIALS_EXTERNALS", payload: {} });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED_EXTERNALS",
        payload: {},
      });
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchExternalsMaterials = () => {
  return async (dispatch, getState) => {
    try {
      const { filters } = getState().externalsIndicators;

      const response = await axios.get(
        `/utils/externals-materials?group=${filters.groupID}`
      );
      const { oldMaterials, allMaterials, allMaterialsWithDeleded } =
        response.data;
      dispatch({
        type: "UTILS_FETCH_MATERIALS_EXTERNALS",
        payload: oldMaterials,
      });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_EXTERNALS",
        payload: allMaterials,
      });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED_EXTERNALS",
        payload: allMaterialsWithDeleded,
      });
    } catch (err) {
      dispatch({ type: "UTILS_FETCH_MATERIALS_EXTERNALS", payload: {} });
      dispatch({ type: "UTILS_FETCH_ALL_MATERIALS_EXTERNALS", payload: {} });
      dispatch({
        type: "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED_EXTERNALS",
        payload: {},
      });
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

export default {
  fetchGreenPoints,
  fetchUsers,
  fetchMaterials,
  fetchExternalsMaterials,
  fetchGroups,
  fetchAllGroupExternalsMaterials,
};
