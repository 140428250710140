const defaultState = {
  removals: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  cards: {
    completes: 0,
    news: 0,
  },
  search: "",
  groupID: "",
  selector: "COMPLETES",
  createModal: false,
  deleteModal: false,
  viewModal: false,
  editModal: false,
  aprobeModal: false,
  createMassiveRemovalsModal: false,
  dataModal: null,
  responseMassiveCreateRemovals: {
    isValidData: true,
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "EXTERNAL_REMOVALS_FETCH": {
      return { ...state, removals: payload };
    }
    case "EXTERNAL_REMOVALS_SET_SELECTOR": {
      return { ...state, selector: payload };
    }
    case "EXTERNAL_REMOVALS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "EXTERNAL_REMOVALS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "EXTERNAL_REMOVALS_SET_VIEW_MODAL": {
      return { ...state, viewModal: payload };
    }
    case "EXTERNAL_REMOVALS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "EXTERNAL_REMOVALS_SET_APROBE_MODAL": {
      return { ...state, aprobeModal: payload };
    }
    case "EXTERNAL_REMOVALS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "EXTERNAL_REMOVALS_SET_CARDS": {
      return { ...state, cards: payload };
    }
    case "EXTERNAL_REMOVALS_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "EXTERNAL_REMOVALS_SET_SEARCH": {
      return { ...state, ...payload };
    }
    case "EXTERNAL_REMOVALS_SET_GROUPID": {
      return { ...state, ...payload };
    }
    case "EXTERNAL_REMOVALS_SET_CREATE_MASSIVE_REMOVALS_MODAL": {
      return { ...state, createMassiveRemovalsModal: payload };
    }
    case "EXTERNAL_SET_MASSIVE_CREATE_REMOVALS_RESPONSE": {
      return { ...state, responseMassiveCreateRemovals: payload };
    }
    default:
      return state;
  }
}

export default reducer;
