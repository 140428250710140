import React, { useEffect, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import StateCard from "./Card";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";

export default function Cards() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);

  const [completes, setCompletes] = useState("...");
  const [news, setNews] = useState("...");
  const [notifyToggle, setNotifyToggle] = useState(false);
  const [cont, setCont] = useState(0);

  useEffect(() => {
    if (removals.cards) {
      setCompletes(removals.cards.completes);
      setNews(removals.cards.news);
      setCont(0);
    }
  }, [removals.cards]);

  useEffect(() => {
    let interval = null;
    if (cont < 8) {
      interval = setInterval(() => {
        setNotifyToggle(!notifyToggle);
        setCont(cont + 1);
      }, 300);
      return () => clearInterval(interval);
    } else {
      setNotifyToggle(true);
    }
  }, [notifyToggle, cont]);

  const setSelector = (value) => {
    dispatch(removalsActions.setSelector(value));
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6} onClick={() => setSelector("COMPLETES")}>
        <StateCard
          title="COMPLETOS"
          value={completes ? completes : "..."}
          background={
            removals.selector === "COMPLETES"
              ? theme.palette.successGradient.main
              : theme.palette.primaryGradient.main
          }
        />
      </Grid>
      <Grid item sm={3} xs={6} onClick={() => setSelector("NEWS")}>
        <StateCard
          title="NUEVOS"
          value={news ? news : "..."}
          background={
            removals.selector === "NEWS"
              ? theme.palette.success.main
              : news && notifyToggle
              ? theme.palette.warningGradient.main
              : theme.palette.primaryGradient.main
          }
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
