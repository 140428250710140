const defaultState = {
  loading: false,
  loginStatus: "READY",
  theme: "LIGHT",
  isMobile: false,
  isOpenMenu: false,
  menuAnchorEl: null,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "APP_SET_IS_MOBILE": {
      return { ...state, ...payload };
    }
    case "APP_SWITCH_MENU": {
      return { ...state, isOpenMenu: payload };
    }
    case "APP_SET_THEME": {
      return { ...state, theme: payload };
    }
    case "APP_SET_LOADING": {
      return { ...state, loading: payload };
    }
    case "APP_SET_MENUANCHOREL": {
      return { ...state, menuAnchorEl: payload };
    }
    default:
      return state;
  }
}

export default reducer;
