import React from "react";
import {
  Card,
  Typography,
  Grid,
  ButtonGroup,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { useSelector } from "react-redux";

export default function Materials({ formData, setFormData, error }) {
  const utils = useSelector((state) => state.utils);

  const handleChangeMaterialsButtons = ({ type, material }) => {
    let materials = formData.materials;
    if (type === "PLUS") {
      materials[material] += 1;
    }
    if (type === "MINUS" && materials[material]) {
      materials[material] -= 1;
    }
    setFormData({ ...formData, materials });
  };

  const handleChangeMaterialsField = ({ e, material }) => {
    let materials = formData.materials;
    let value = parseFloat(e.target.value);
    if (value >= 0) {
      materials[material] = value;
      setFormData({ ...formData, materials });
    }
  };

  return (
    <Grid container spacing={3}>
      {formData.materials &&
        Object.keys(formData.materials).map((material) => (
          <Grid item xs={6} sm={4} md={3} key={material + "card"}>
            <Card>
              <Grid
                container
                alignItems="center"
                justify="center"
                spacing={1}
                style={{ padding: 10 }}
              >
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    {utils.all_materials_deleted[material].name}
                  </Typography>
                  {utils.all_materials_deleted[material].name == "Vidrio" ? (
                    <hr
                      style={{
                        backgroundColor:
                          utils.all_materials_deleted[material]?.color,
                        height: 4,
                        border: "2px solid black",
                      }}
                    />
                  ) : utils.all_materials_deleted[material].name == "Latas" ? (
                    <hr
                      style={{
                        backgroundColor:
                          utils.all_materials_deleted[material]?.color,
                        height: 4,
                        border: "2px solid gray",
                      }}
                    />
                  ) : (
                    <hr
                      style={{
                        backgroundColor:
                          utils.all_materials_deleted[material]?.color,
                        height: 4,
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    type="number"
                    value={String(formData.materials[material])}
                    onChange={(e) =>
                      handleChangeMaterialsField({ e, material })
                    }
                    error={error}
                    InputProps={{
                      endAdornment: <InputAdornment>KG</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup fullWidth size="small">
                    <Button
                      variant="contained"
                      color={"secondary"}
                      disabled={!Boolean(formData.materials[material])}
                      onClick={() =>
                        handleChangeMaterialsButtons({
                          type: "MINUS",
                          material,
                        })
                      }
                    >
                      <Remove />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleChangeMaterialsButtons({
                          type: "PLUS",
                          material,
                        })
                      }
                    >
                      <Add />
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}
