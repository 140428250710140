import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  makeStyles,
  Zoom,
} from "@material-ui/core";
import logo from "../../resources/logos/logo.png";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  welcomeLogo: {
    width: "80%",
  },
}));

export default function DialogLogin() {
  const styles = useStyles();
  const auth = useSelector((state) => state.auth);
  return (
    <Dialog
      open={true}
      onClose={null}
      maxWidth="lg"
      fullWidth
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent>
        <Zoom in={true}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={8} md={4} align="center">
              <img
                className={styles.welcomeLogo}
                src={logo}
                alt="GIRA"
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} sm={8}>
              <Typography
                variant="h4"
                align="center"
                style={{ color: "white" }}
              >
                Bienvenid@ {auth.user.name}
              </Typography>
            </Grid>
          </Grid>
        </Zoom>
      </DialogContent>
    </Dialog>
  );
}
