import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Card, CardMedia } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import PropTypes from "prop-types";
import Jimp from "jimp";

import Map from "./Map";
import PlacesAutocomplete from "./PlacesAutocomplete";
import formats from "../../../../resources/formats.json";
import zones from "../../../../resources/zones.json";
import Cities from "../../../../resources/cities.json";
export default function Content({
  formData,
  setFormData,
  error,
  imageSrc,
  setImageSrc,
  setIsUploadingImage,
}) {
  const [provincias] = useState([]);
  const [cities] = useState([]);

  const handleSetImages = (event) => {
    setIsUploadingImage(true);
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    Jimp.read(URL.createObjectURL(event.target.files[0]))
      .then((photo) => {
        photo
          .resize(1062, 1280)
          .contain(800, 800)
          .quality(100)
          .getBase64Async(Jimp.MIME_JPEG)
          .then((res) => {
            setFormData({ ...formData, image: res });
            setIsUploadingImage(false);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    Object.keys(Cities).map((keys, i) => {
      return provincias.push({
        city: Cities[keys].cantones,
      });
    });
    Object.keys(provincias).map((keys, j) => {
      Object.keys(provincias[keys].city).map((ckey, y) => {
        return cities.push({
          name: provincias[keys].city[ckey].canton,
          code: ckey,
        });
      });
    });
    cities.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={2}>
            <TextField
              label="Nombre"
              variant="outlined"
              margin="dense"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              error={error && !formData.name}
            />
          </Grid>
          <Grid item md={6} sm={2}>
            <TextField
              label="Formato"
              variant="outlined"
              margin="dense"
              value={formData.format}
              select
              SelectProps={{ native: true }}
              error={error && !formData.format}
              onChange={(e) =>
                setFormData({ ...formData, format: e.target.value })
              }
              fullWidth
            >
              <option value=""></option>

              {formats.map((format, i) => (
                <option key={`op${i}-${format.code}`} value={format.code}>
                  {format.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <PlacesAutocomplete setFormData={setFormData} formData={formData} />
        <Grid container spacing={2}>
          <Grid item md={6} sm={2}>
            <TextField
              label="Ciudad"
              variant="outlined"
              margin="dense"
              value={formData.city}
              select
              SelectProps={{ native: true }}
              error={error && !formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              fullWidth
            >
              <option value=""></option>
              {cities.map((city, i) => (
                <option key={`op${i}-${city.code}`} value={city.code}>
                  {city.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={2}>
            <TextField
              label="Zona"
              variant="outlined"
              margin="dense"
              value={formData.zone}
              select
              SelectProps={{ native: true }}
              error={error && !formData.zone}
              onChange={(e) =>
                setFormData({ ...formData, zone: e.target.value })
              }
              fullWidth
            >
              <option value=""></option>

              {zones.map((zone, i) => (
                <option key={`op${i}-${zone.code}`} value={zone.code}>
                  {zone.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Card>
          <CardMedia
            style={{ height: 0, paddingTop: "60%", backgroundColor: "#d1d1cf" }} // 16:9
            image={imageSrc}
            title="Imagen punto Gira"
          />
        </Card>
        <TextField
          label="Descripción"
          variant="outlined"
          margin="dense"
          value={formData.description}
          rows={6}
          multiline
          error={error && !formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <Map formData={formData} style={{ width: "100%" }} />
        <Grid item sm={12} xs={12}>
          <input
            id="raised-button-file-2"
            name="image"
            type="file"
            accept="image/jpeg,image/jpg"
            style={{ display: "none", width: "100%" }}
            error={error}
            onChange={handleSetImages}
          />
          <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginTop: 8,
                height: 40,
              }}
              fullWidth
              startIcon={<CloudUploadOutlinedIcon />}
              component="span"
            >
              Foto Punto GIRA
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  error: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
