import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Buttons({ handleClose, handleSuccess, isUploadingImage }) {
  return (
    <ButtonGroup size="small" variant="contained">
      <Button
        onClick={() => handleClose()}
        color="secondary"
        startIcon={<Close />}
      >
        Cancelar
      </Button>
      <Button
        onClick={() => handleSuccess()}
        color="primary"
        disabled={isUploadingImage}
        startIcon={<Check />}
      >
        Crear usuario
      </Button>
    </ButtonGroup>
  );
}

Buttons.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};
