import React, { useEffect } from "react";
import { Paper, Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import Header from "./Header";
import Cards from "./Cards";
import Ecoeq from "./Ecoeq";
import Charts from "./Charts";
import CreateModal from "../CreateModal";
import externalReportsActions from "../../../../redux/actions/externalReports";
import { useDispatch } from "react-redux";
import ExportToExcelModal from "./ExportToExcelModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const start = () => dispatch(externalReportsActions.fetchDataDashboard());
    start();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Cards />
            </Grid>
            <Grid item xs={12}>
              <Ecoeq />
            </Grid>
            <Grid item xs={12}>
              <Charts />
            </Grid>
          </Grid>
        </CardContent>
        <CreateModal />
        <ExportToExcelModal />
      </Card>
    </Paper>
  );
}
