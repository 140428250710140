import React from "react";
import { Typography, Grid, Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export default function Content({ formData, imageSrc }) {
  const auth = useSelector((state) => state.auth);

  const translateType = (value) => {
    if (value.type === "GREENPOINT") {
      return "Monitor Punto GIRA";
    }
    if (value.type === "ADMIN") {
      if (value._id === auth.user._id) {
        return "Administrador (Tú)";
      } else {
        return "Administrador";
      }
    }
    return "unk";
  };

  return (
    <Grid container>
      <Grid item container xs={12} sm={6} justify="center" alignItems="center" direction="column">
        <Avatar
          alt="User photo"
          src={formData.urlPhoto ? `${formData.urlPhoto}?20130910043254` : imageSrc}
          style={{ height: '100px', width: '100px' }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">Nombre:</Typography>
        <Typography variant="body2">
          {formData.name + " " + formData.lastName}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">Email:</Typography>
        <Typography variant="body2">{formData.email}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">Teléfono:</Typography>
        <Typography variant="body2">{formData.phone}</Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">Tipo de usuario:</Typography>
        <Typography variant="body2">{translateType(formData)}</Typography>
      </Grid>
      {formData.type === "GREENPOINT" &&
        (<React.Fragment>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">Ruc:</Typography>
            <Typography variant="body2">{formData.ruc}</Typography>
          </Grid>
        </React.Fragment>)
      }
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">Fecha de creación:</Typography>
        <Typography variant="body2">
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(formData.createdAt ? formData.createdAt : null))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">Fecha última actualización:</Typography>
        <Typography variant="body2">
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(formData.updatedAt ? formData.updatedAt : null))}
        </Typography>
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  formData: PropTypes.object.isRequired,
};
