import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Geocode from "react-geocode";

import greenPointsActions from "../../../../redux/actions/greenPoints";
import Content from "./Content";
//import Header from "./Header";
import Buttons from "./Buttons";
import Header from "../../../Shared/Modals/Header";

import cloudupload from "../../../../resources/images/cloudupload.png";

export default function CreateModal() {
  const dispatch = useDispatch();
  const greenPoints = useSelector((state) => state.greenPoints);
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState(cloudupload);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  Geocode.setApiKey(process.env.REACT_APP_PLACES_GOOGLE); // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setLanguage("en"); // set response language. Defaults to english.
  Geocode.setRegion("es"); // set response region. Its optional. // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.enableDebug(); // Enable or disable logs. Its optional.

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    description: "",
    format: "",
    zone: "",
    city: "",
    coordinates: {
      latitude: -0.232887,
      longitude: -78.478673,
    },
    lat: 0,
    long: 0,
    image: "",
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  useEffect(() => {
    const fetchGeocode = async () => {
      await Geocode.fromAddress(formData.address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setFormData({
            ...formData,
            coordinates: {
              latitude: lat,
              longitude: lng,
            },
          });
        },
        (error) => {
          console.error(error);
        }
      );
    };

    if (formData.address) {
      fetchGeocode();
    }
  }, [formData.address]);

  const handleClose = () => {
    setImageSrc(cloudupload);
    dispatch(greenPointsActions.setCreateModal(false));
  };

  const handleSuccess = () => {
    if (
      (formData.coordinates.latitude === 0 &&
        formData.coordinates.latitude.longitude === 0) ||
      !formData.name ||
      !formData.description ||
      !formData.format ||
      !formData.zone ||
      !formData.city
    ) {
      setError(true);
    } else {
      dispatch(greenPointsActions.postGreenPoint({ formData }));
      setImageSrc(cloudupload);
      handleClose();
    }
  };

  return (
    <Dialog open={greenPoints.createModal} maxWidth="md" fullWidth>
      <DialogContent>
        <Header
          handleClose={handleClose}
          title={"Agregar Punto GIRA"}
          type="CREATE"
        />
        <Content
          formData={formData}
          setFormData={setFormData}
          error={error}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          setIsUploadingImage={setIsUploadingImage}
        />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleSuccess={handleSuccess}
          handleClose={handleClose}
          isUploadingImage={isUploadingImage}
        />
      </DialogActions>
    </Dialog>
  );
}
