import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  startOfMonth,
  differenceInMonths,
  endOfDay,
  endOfMonth,
} from "date-fns";
import {
  Grid,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Box,
  TextField,
  Menu,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import formats from "../../../../../resources/formats.json";
import citiesJson from "../../../../../resources/cities.json";
import zones from "../../../../../resources/zones.json";
import useMenu from "../../../../../hooks/useMenu";
import externalsIndicatorsAction from "../../../../../redux/actions/externalsIndicators";
import groupsAction from "../../../../../redux/actions/groups";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
}));

export default function Filters() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { greenPoints: greenPointList } = useSelector(
    (state) => state.externalsIndicators
  );
  const { filters } = useSelector((state) => state.externalsIndicators);
  const { groupsSelect } = useSelector((state) => state.groups);
  const { open, anchorEl, handleOpen, handleClose } = useMenu();
  const [provincias, setProvincias] = useState([]);
  const [cities, setCities] = useState([]);
  const [greenPoints, setGreenPoints] = useState([]);

  const getCheckedGreenPoints = (list) => {
    return list.filter((item) => item.checked).map((item) => item._id);
  };

  const checkGreenPoint = (id) => {
    const newGreenPoints = greenPoints.map((item) => {
      return item._id !== id ? item : { ...item, checked: !item.checked };
    });
    setGreenPoints(newGreenPoints);
    dispatch(
      externalsIndicatorsAction.updateFiltersGreenPoint(
        newGreenPoints.filter((item) => item.checked).map((item) => item._id)
      )
    );
  };
  const checkAllGreenPoints = () => {
    const allSelected =
      greenPoints.filter((item) => !item.checked).length === 0;

    const newGreenPoints = greenPoints.map((item) => ({
      ...item,
      checked: !allSelected,
    }));
    setGreenPoints(newGreenPoints);

    dispatch(
      externalsIndicatorsAction.updateFiltersGreenPoint(
        getCheckedGreenPoints(newGreenPoints)
      )
    );
  };

  useEffect(() => {
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(startDate);

    dispatch(groupsAction.fetchSelectGroups());

    dispatch(
      externalsIndicatorsAction.updateFilters({
        ...filters,
        differenceMonths: 1,
        initialDate: startDate,
        endDate: endOfDay(endDate),
        zone: "ALL",
        format: "ALL",
        city: "ALL",
        groupID: user.type === "ADMIN_EXTERN" ? user.groupID._id : "",
      })
    );
    Object.keys(citiesJson).map((keys, i) => {
      return provincias.push({
        city: citiesJson[keys].cantones,
      });
    });
    Object.keys(provincias).map((keys, j) => {
      Object.keys(provincias[keys].city).map((ckey, y) => {
        return cities.push({
          name: provincias[keys].city[ckey].canton,
          code: ckey,
        });
      });
    });
    cities.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    });
  }, []);

  useEffect(() => {
    setGreenPoints(greenPointList.map((item) => ({ ...item, checked: true })));
    dispatch(
      externalsIndicatorsAction.updateFiltersGreenPoint(
        greenPointList.map((item) => item._id)
      )
    );
  }, [greenPointList]);

  return (
    <div>
      <Grid container spacing={2}>
        {user.type === "ADMIN" ? (
          <Grid item xs={12}>
            <TextField
              label="Grupo"
              variant="outlined"
              fullWidth
              margin="dense"
              select
              SelectProps={{ native: true }}
              onChange={(e) => {
                dispatch(
                  externalsIndicatorsAction.updateFilters({
                    ...filters,
                    groupID: e.target.value,
                  })
                );
              }}
            >
              <option value=""></option>

              {groupsSelect.map((group, i) => (
                <option key={`op${i}-${group._id}`} value={group._id}>
                  {group.name}
                </option>
              ))}
            </TextField>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <TextField
              label="Grupo"
              variant="outlined"
              fullWidth
              margin="dense"
              value={user.groupID.name}
              disabled={true}
              SelectProps={{ native: true }}
            ></TextField>
          </Grid>
        )}
        <Grid item xs={6} md={4}>
          <DatePicker
            inputVariant="outlined"
            margin="dense"
            fullWidth
            label="Fecha inicial"
            value={filters.initialDate}
            disableFuture
            onChange={(date) => {
              dispatch(
                externalsIndicatorsAction.updateFilters({
                  ...filters,
                  differenceMonths: differenceInMonths(
                    filters.endDate,
                    new Date(date)
                  ),
                  initialDate: new Date(date),
                })
              );
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <DatePicker
            inputVariant="outlined"
            margin="dense"
            fullWidth
            label="Fecha final"
            value={filters.endDate}
            onChange={(date) => {
              const endDate = endOfDay(new Date(date));
              dispatch(
                externalsIndicatorsAction.updateFilters({
                  ...filters,
                  differenceMonths: differenceInMonths(
                    new Date(date),
                    filters.initialDate
                  ),
                  endDate: endOfDay(endDate),
                })
              );
            }}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            label="Formato"
            variant="outlined"
            fullWidth
            margin="dense"
            select
            SelectProps={{ native: true }}
            onChange={(e) => {
              dispatch(
                externalsIndicatorsAction.updateFilters({
                  ...filters,
                  format: e.target.value,
                })
              );
            }}
          >
            <option value="ALL">TODOS</option>

            {formats.map((format, i) => (
              <option key={`op${i}-${format.code}`} value={format.code}>
                {format.name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label="Cuidad"
            variant="outlined"
            fullWidth
            margin="dense"
            select
            SelectProps={{ native: true }}
            onChange={(e) => {
              dispatch(
                externalsIndicatorsAction.updateFilters({
                  ...filters,
                  city: e.target.value,
                })
              );
            }}
          >
            <option value="ALL">TODOS</option>
            {cities.map((city, i) => (
              <option key={`op${i}-${city.code}`} value={city.code}>
                {city.name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label="Zona"
            variant="outlined"
            fullWidth
            margin="dense"
            select
            SelectProps={{ native: true }}
            onChange={(e) => {
              dispatch(
                externalsIndicatorsAction.updateFilters({
                  ...filters,
                  zone: e.target.value,
                })
              );
            }}
          >
            <option value="ALL">TODOS</option>
            {zones.map((zone, i) => (
              <option key={`op${i}-${zone.code}`} value={zone.code}>
                {zone.name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            label={`${greenPoints.length} Puntos Gira`}
            variant="outlined"
            fullWidth
            margin="dense"
            onClick={handleOpen}
            value={`${
              greenPoints.filter((item) => item.checked).length
            } seleccionados`}
          ></TextField>

          <Menu
            open={open}
            anchorEl={anchorEl}
            keepMounted
            onClose={handleClose}
            classes={{ paper: classes.paper }}
          >
            <Box p={2}>
              <Box padding="5px 10px">
                <Typography style={{ fontWeight: "bold" }}>
                  PUNTOS GIRA
                </Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={6} md={4}>
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            greenPoints.filter((item) => !item.checked)
                              .length === 0
                          }
                          name="checkedB"
                          color="primary"
                          onChange={checkAllGreenPoints}
                        />
                      }
                      label={"Todos"}
                    />
                  </MenuItem>
                </Grid>
                {greenPoints.map((greenPoint) => (
                  <Grid item xs={6} md={4} key={greenPoint._id}>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={greenPoint.checked}
                            color="primary"
                            onChange={() => checkGreenPoint(greenPoint._id)}
                          />
                        }
                        label={greenPoint.name}
                      />
                    </MenuItem>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Menu>
        </Grid>
      </Grid>
    </div>
  );
}
