import React, { useState, useEffect } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Card, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export default function Map({ formData }) {
  const app = useSelector((state) => state.app);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: -0.232887,
    longitude: -78.478673,
    zoom: 14,
  });

  useEffect(
    () =>
      setViewport({
        ...viewport,
        latitude: parseFloat(formData.coordinates.latitude)
          ? parseFloat(formData.coordinates.latitude)
          : 0,
        longitude: parseFloat(formData.coordinates.longitude)
          ? parseFloat(formData.coordinates.longitude)
          : 0,
      }),
    [formData.coordinates.longitude, formData.coordinates.latitude]
  );
  const token = process.env.REACT_APP_MAPGL_API_KEY;

  return (
    <ReactMapGL
      mapboxApiAccessToken={token}
      {...viewport}
      width="100%"
      height={400}
      mapStyle={
        app.theme === "LIGHT"
          ? "mapbox://styles/mapbox/light-v10"
          : "mapbox://styles/mapbox/dark-v10"
      }
      onViewportChange={(viewport) => setViewport(viewport)}
      scrollZoom={true}
    >
      <Marker
        key={0}
        longitude={formData.coordinates.longitude}
        latitude={formData.coordinates.latitude}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LocationOnIcon
              color="primary"
              style={{
                fontSize: 44,
              }}
            />
          </Grid>
          <Grid item align="center">
            <Card>
              <Typography style={{ margin: 10 }} variant="body1">
                {formData.name ? formData.name : <em>punto gira</em>}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Marker>
    </ReactMapGL>
  );
}

Map.propTypes = {
  formData: PropTypes.object.isRequired,
};
