import React, { useEffect, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import StateCard from "./Card";
import { useSelector, useDispatch } from "react-redux";
import checkListActions from "../../../../redux/actions/checkList";

export default function Cards() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { cards, selector } = useSelector((state) => state.checkLists);

  const [notifyToggle, setNotifyToggle] = useState(false);
  const [cont, setCont] = useState(0);

  useEffect(() => {
    let interval = null;
    if (cont < 8) {
      interval = setInterval(() => {
        setNotifyToggle(!notifyToggle);
        setCont(cont + 1);
      }, 300);
      return () => clearInterval(interval);
    } else {
      setNotifyToggle(true);
    }
  }, [notifyToggle, cont]);

  const setSelector = (value) => {
    dispatch(checkListActions.setSelector(value));
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6} onClick={() => setSelector("CHECKLIST")}>
        <StateCard
          title="EVALUACIONES GIRA"
          value={cards.evaluations ? cards.evaluations : "..."}
          background={
            selector === "CHECKLIST"
              ? theme.palette.successGradient.main
              : theme.palette.primaryGradient.main
          }
        />
      </Grid>
      <Grid item sm={3} xs={6} onClick={() => setSelector("REPORTS")}>
        <StateCard
          title="REPORTES"
          value={cards.reports ? cards.reports : "..."}
          background={
            selector === "REPORTS"
              ? theme.palette.success.main
              : cards.reports && notifyToggle
              ? theme.palette.warningGradient.main
              : theme.palette.primaryGradient.main
          }
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
