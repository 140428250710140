import axios from "axios";
import appActions from "./app";

const loginStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: "AUTH_SET_LOGIN_STATUS",
      payload: status,
    });
  };
};

const login = ({ email, password }) => {
  return async (dispatch) => {
    try {
      dispatch(loginStatus("LOADING"));
      let response = await axios.post("/auth/login", { email, password });
      if (
        !response.data.token ||
        !response.data.user ||
        (response.data.user?.type !== "ADMIN" &&
          response.data.user?.type !== "GREENPOINT" &&
          response.data.user?.type !== "GREENPOINT_EXTERN" &&
          response.data.user?.type !== "ADMIN_EXTERN")
      ) {
        dispatch({ type: "AUTH_LOGIN_FAIL" });
        dispatch(loginStatus("ERROR"));
        return;
      }
      const payload = {
        userType: response.data.user.type,
        user: response.data.user,
        loged: "LOGED",
      };

      window.localStorage.setItem("token", response.data.token);
      axios.defaults.headers.common.authorization = `${response.data.token}`;

      dispatch({ type: "AUTH_LOGIN_SUCCESS", payload });
      dispatch(loginStatus("WELCOME"));
    } catch (err) {
      if (err?.response?.status === 418) {
        dispatch(loginStatus("NOT_ACTIVED"));
      } else if (err?.response?.status === 417) {
        dispatch(loginStatus("NOT_ENTITY"));
      } else if (err?.response?.status === 404) {
        dispatch(loginStatus("AUTH_FAILED"));
      } else {
        dispatch(loginStatus("ERROR"));
      }
    }
  };
};
const recoveryPassword = (email, password) => {
  return async (dispatch) => {
    try {
      await axios.post("/auth/requestPassword", email);
      dispatch({ type: "AUTH_LOGIN_SUCCESS" });
    } catch (e) {
      dispatch(logout());
    }
  };
};
const verifyToken = () => {
  return async (dispatch) => {
    try {
      await axios.post("/auth/verifyToken");
      dispatch({ type: "AUTH_LOGIN_SUCCESS" });
    } catch (e) {
      dispatch(logout());
    }
  };
};

const logout = () => {
  return async (dispatch) => {
    axios.defaults.headers.common.authorization = null;
    dispatch(appActions.setMenuAnchorEl(null));
    dispatch({ type: "AUTH_LOGOUT" });
  };
};

const logoutIfNotAuth = (err) => {
  return (dispatch) => {
    if (
      err?.response?.status === 403 ||
      err?.response?.status === 401 ||
      err?.response?.status === 418
    ) {
      dispatch(logout);
    }
  };
};

const me = () => {
  return async (dispatch) => {
    try {
      let response = await axios.get("/auth/me");
      if (response.data.user) {
        const payload = { user: response.data.user };
        dispatch({ type: "AUTH_LOGIN_SUCCESS", payload });
        return;
      }
    } catch (err) {
      if (err?.response?.status === 418) {
        dispatch(loginStatus("NOT_ACTIVED"));
      } else if (err?.response?.status === 417) {
        dispatch(loginStatus("NOT_ENTITY"));
      } else if (err?.response?.status === 404) {
        dispatch(loginStatus("AUTH_FAILED"));
      } else {
        dispatch(loginStatus("ERROR"));
      }
    }
  };
};

export default {
  loginStatus,
  login,
  logout,
  verifyToken,
  logoutIfNotAuth,
  recoveryPassword,
  me,
};
