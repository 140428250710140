import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import {
  AddCircleOutline as PlusIcon,
  LocalShipping as TruckIcon,
  Publish as PublishIcon,
} from "@material-ui/icons/";
import removalsActions from "../../../redux/actions/removals";
import { useDispatch } from "react-redux";

export default function Removals() {
  const dispatch = useDispatch();
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <TruckIcon style={{ fontSize: 34, marginBottom: 6 }} /> Registros
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<PlusIcon />}
              onClick={() => dispatch(removalsActions.setCreateModal(true))}
            >
              Ingresar registro manual
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<PublishIcon />}
              onClick={() =>
                dispatch(removalsActions.setCreateMassiveRemovalsModal(true))
              }
            >
              Ingresar carga masiva
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
