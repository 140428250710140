import React from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import SecurityIcon from "@material-ui/icons/Security";
import functions from "../../../../resources/functions";

export default function Content({
  formData,
  setFormData,
  error,
  helperTextInfo,
  helperTextPass,
  validateEmail,
}) {
  const updateData = (e) => {
    if (e.target.name === "email") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim().toLowerCase(),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const passwordGen = () => {
    const password = Math.round(Math.random() * 10000000 + 10000000);
    setFormData({ ...formData, password });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Agregar administrador externo</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="type"
          value={formData.type}
          onChange={(e) => updateData(e)}
          select
          margin="dense"
          label="Tipo de usuario"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          disabled
          error={error && !formData.type}
        >
          <MenuItem value="ADMIN_EXTERN">Administrador Externo</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="email"
          value={formData.email}
          onChange={(e) => updateData(e)}
          margin="dense"
          label="Email"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && (!formData.email || !validateEmail(formData.email))}
          helperText={
            (!formData.email || !validateEmail(formData.email)) &&
            helperTextInfo
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="phone"
          value={formData.phone}
          onChange={(e) =>
            setFormData({
              ...formData,
              phone: functions.formatPhone(e.target.value),
            })
          }
          margin="dense"
          label="Teléfono"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && formData.phone.length < 16}
          helperText={formData.phone.length < 16 && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="name"
          value={formData.name}
          onChange={(e) =>
            setFormData({
              ...formData,
              name: functions.formatOnlyLetters(e.target.value),
            })
          }
          margin="dense"
          label="Nombre"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && !formData.name}
          helperText={!formData.name && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="lastName"
          value={formData.lastName}
          onChange={(e) =>
            setFormData({
              ...formData,
              lastName: functions.formatOnlyLetters(e.target.value),
            })
          }
          margin="dense"
          label="Apellido"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && !formData.lastName}
          helperText={!formData.lastName && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="ruc"
          value={formData.ruc}
          onChange={(e) =>
            setFormData({
              ...formData,
              ruc: functions.formatRuc(e.target.value),
            })
          }
          margin="dense"
          label="Ruc"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && formData.ruc.length < 10}
          helperText={formData.ruc.length < 10 && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="password"
          value={formData.password}
          onChange={(e) => updateData(e)}
          margin="dense"
          label="Contraseña"
          type={"text"}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && formData.password.length < 8}
          helperText={formData.password.length < 8 && helperTextPass}
        />
        <Button
          variant="text"
          color="primary"
          size="small"
          startIcon={<SecurityIcon />}
          onClick={() => passwordGen()}
        >
          generar contraseña
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.actived === "ACTIVED"}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  actived: e.target.checked ? "ACTIVED" : "DEACTIVED",
                })
              }
              color="primary"
            />
          }
          label="Crear usuario activado"
        />
      </Grid>
    </Grid>
  );
}
