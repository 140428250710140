import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { BarChart as IndicatorIcon } from "@material-ui/icons";

export default function Header() {
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <IndicatorIcon style={{ fontSize: 34, marginBottom: 6 }} />{" "}
          Indicadores de gestión
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
