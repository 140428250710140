import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import removalsActions from "../../../redux/actions/externalRemovals";

export default function Search() {
  const [search, setSearch] = useState("");
  const removals = useSelector((state) => state.externalRemovals);
  const dispatch = useDispatch();

  useEffect(() => setSearch(removals.search), [removals.search]);

  const handleSubmit = () => {
    dispatch(removalsActions.setSearch({ search }));
  };
  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      style={{ marginBottom: 10 }}
    >
      <Grid item>
        <TextField
          variant="outlined"
          margin="dense"
          placeholder="nombre, apellido, email, teléfono"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ height: 40, marginTop: 3 }}
          onClick={() => handleSubmit()}
          startIcon={<SearchIcon />}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}
