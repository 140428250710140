import React, { useState } from "react";
import {
  Grid,
  Chip,
  Typography,
  Collapse,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Button,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import checkListActions from "../../../../redux/actions/checkList";

export default function ViewModal() {
  const dispatch = useDispatch();
  const { dataModal } = useSelector((state) => state.checkLists);

  const [expanded, setExpanded] = useState({});
  const [titleTotals, setTitlesTotal] = useState({});

  const handleExpandClick = (id, questionData) => {
    let total = 0;
    if (!expanded[`${id}`]) {
      total = questionData.items.reduce(
        (itemAccum, item) => itemAccum + item.score,
        0
      );
    }

    const expandHandler = {
      ...expanded,
      [`${id}`]: !expanded[`${id}`],
    };

    const totalHandler = {
      ...titleTotals,
      [`${id}`]: total,
    };

    setTitlesTotal(totalHandler);
    setExpanded(expandHandler);
  };

  return dataModal ? (
    <Grid container spacing={2}>
      <Grid item container xs={12} style={{ justifyContent: "flex-end" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(checkListActions.setEditMode(true))}
        >
          Editar evaluación
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Punto GIRA: {dataModal.greenPointID?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Monitor GIRA: {dataModal.userId?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Registrado por: {dataModal.adminId?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Fecha de creación:{" "}
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(dataModal.updatedAt))}
        </Typography>
      </Grid>
      {dataModal.questions.map((question) => (
        <Card key={question.title} style={{ width: "100%" }}>
          <CardActionArea
            onClick={() => handleExpandClick(question.title, question)}
          >
            <CardHeader
              action={
                !expanded[`${question.title}`] ? <ExpandLess /> : <ExpandMore />
              }
              title={question.title}
              subheader={
                !expanded[`${question.title}`]
                  ? `Valor total ${question.totalPoints}`
                  : `Puntuación obtenida: ${
                      titleTotals[`${question.title}`]
                    } / ${question.totalPoints}`
              }
            />
          </CardActionArea>
          <Collapse
            in={!expanded[`${question.title}`]}
            timeout="auto"
            unmountOnExit
          >
            {question.items.map((item) => (
              <CardContent key={item.title}>
                <Grid container>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    sm={9}
                    lg={10}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {`${item.item}) ${item.question}`}
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    xs={12}
                    sm={3}
                    lg={2}
                  >
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Chip
                        color="primary"
                        label={`${item.score} / ${item.maxValue}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            ))}
          </Collapse>
        </Card>
      ))}

      <Grid item xs={12}>
        <Typography align="center" style={{ fontWeight: "bold" }}>
          TOTAL {dataModal.total} / 100
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Observaciones:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{dataModal.observations}</Typography>
      </Grid>
    </Grid>
  ) : null;
}
