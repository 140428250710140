import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import externalsIndicatorsAction from "../../../../redux/actions/externalsIndicators";
import ModalUpdateGoal from "./ModalUpdateGoal";

const useStyles = makeStyles({
  button_update: {
    cursor: "pointer",
  },
});

export default function MissingAlert() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { goalMissingList: list } = useSelector(
    (state) => state.externalsIndicators
  );
  const onClickUpdate = () => {
    dispatch(externalsIndicatorsAction.setUpdateModal(true));
  };

  useEffect(() => {
    dispatch(externalsIndicatorsAction.fetchGreenPointWithoutGoal());
  }, []);
  return (
    <>
      {list.length > 0 && (
        <Alert severity="warning" style={{ marginBottom: "15px" }}>
          Tienes <strong>{list.length}</strong>{" "}
          {`Punto${list.length === 1 ? "" : "s"}`} Gira sin metas.{" "}
          <strong className={classes.button_update} onClick={onClickUpdate}>
            Actualizar
          </strong>
          <ModalUpdateGoal />
        </Alert>
      )}
    </>
  );
}
