import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import {
  Edit as EditIcon,
  LocalShipping as TruckIcon,
} from "@material-ui/icons/";
import { useDispatch, useSelector } from "react-redux";
import profileActions from "../../../redux/actions/profile";

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <TruckIcon style={{ fontSize: 34, marginBottom: 6 }} /> Perfil
        </Typography>
      </Grid>
      <Grid item>
        <Button
          startIcon={<EditIcon />}
          size="small"
          color="primary"
          variant="contained"
          onClick={() => dispatch(profileActions.setEditProfile(!profile.edit))}
        >
          {profile.edit ? "Descartar cambios" : "Editar información"}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
