import React, { useEffect } from "react";
import { Paper, makeStyles, Card, CardContent } from "@material-ui/core";
import GreenPointsList from "./GreenPointsList";
import GroupList from "./GroupList";
import Cards from "./Cards";
import CreateModal from "./CreateModal";
import CreateGroupModal from "./CreateGroupModal";
import EditGroupModal from "./EditGroupModal";
import ViewGroupModal from "./ViewGroupModal";
import ViewModal from "./ViewModal";
import DeleteModal from "./DeleteModal";
import DeleteGroupModal from "./DeleteGroupModal";
import EditGoalModal from "./EditGoalModal";
import EditMassiveGoals from "./EditMassiveGoals";
import Search from "./Search";
import Header from "./Header";
import externalsGreenPointActions from "../../../redux/actions/externalsGreenPoint";
import groupsActions from "../../../redux/actions/groups";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Removals() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const externalGreenPoint = useSelector((state) => state.externalGreenPoint);

  useEffect(() => {
    const exec = () => {
      dispatch(externalsGreenPointActions.fetchGreenPoints());
      dispatch(externalsGreenPointActions.fetchCards());
      dispatch(groupsActions.fetchGroups());
    };
    exec();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Cards />
          <Search />
          <CreateModal />
          <ViewModal />
          <DeleteModal />
          {externalGreenPoint.selector === "GREENPOINTS" ? (
            <GreenPointsList />
          ) : (
            <GroupList />
          )}
          <CreateGroupModal />
          <DeleteGroupModal />
          <EditGoalModal />
          <EditMassiveGoals />
          <EditGroupModal />
          <ViewGroupModal />
        </CardContent>
      </Card>
    </Paper>
  );
}
