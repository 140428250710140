import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import externalUsersActions from "../../../../redux/actions/externalUsers";

import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

export default function ModalDelete() {
  const externalUsers = useSelector((state) => state.externalUsers);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (externalUsers.dataModal) {
      setData({
        name:
          externalUsers.dataModal.name + " " + externalUsers.dataModal.lastName,
        email: externalUsers.dataModal.email,
        type: externalUsers.dataModal.type,
        entityID: externalUsers.dataModal.entityID,
        groups: externalUsers.dataModal.groups,
      });
    }
  }, [externalUsers.dataModal]);

  const handleClose = () => {
    dispatch(externalUsersActions.setDataModal(null));
    dispatch(externalUsersActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    if (check) {
      dispatch(externalUsersActions.deleteUser(externalUsers.dataModal._id));
      handleClose();
      setCheck(false);
    }
  };

  return (
    <Dialog
      open={externalUsers.deleteModal}
      onClose={handleClose}
      scroll={"body"}
    >
      <DialogContent>
        <Header
          handleClose={handleClose}
          title="Eliminar usuario"
          type="DELETE"
        />
        <Content data={data} check={check} setCheck={setCheck} />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleDelete={handleDelete}
          check={check}
        />
      </DialogActions>
    </Dialog>
  );
}
