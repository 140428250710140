import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
} from "@material-ui/core";
import Chart from "./Chart";
import Card from "./Card";
import { useSelector } from "react-redux";

export default function Content() {
  const removals = useSelector((state) => state.externalRemovals);
  const utils = useSelector((state) => state.utils);
  const theme = useTheme();
  const [data, setData] = useState({ materials: [] });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (removals.dataModal) {
      setData(removals.dataModal);
      setTotal(
        removals.dataModal.materials.reduce(
          (ac, material) => ac + material.quantity * 1,
          0
        )
      );
    }
  }, [removals.dataModal]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="body1">Fecha de registro:</Typography>
        <Typography variant="body2">
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(
            new Date(data.datetimeRemoval ? data.datetimeRemoval : null)
          )}
        </Typography>
        <hr />
        <Typography variant="body1">Registrado por:</Typography>
        <Typography variant="body2">
          {data.userID?.name + " " + data.userID?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Card
          background={theme.palette.primary.main}
          title="Material reciclado"
          value={Math.round(total * 100) / 100 + " Kg"}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography align="center" variant="h6" style={{ marginBottom: 6 }}>
          Materiales
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Material</strong>
              </TableCell>
              <TableCell>
                <strong>Cantidad</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.materials.map((material) => (
              <TableRow key={material.material + "row"}>
                <TableCell>
                  {utils.all_materials_deleted[material.material]?.name}
                </TableCell>
                <TableCell>{material.quantity} kg</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6} style={{ backgroundColor: "#ebebed" }}>
        <Typography align="center" variant="h6" style={{ marginBottom: 6 }}>
          Relación porcentual
        </Typography>
        <Chart total={total} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Punto Gira:</Typography>
        <Typography variant="body2">{data.greenPointID?.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <hr />
        <Typography variant="body1">Descripción:</Typography>
        <Typography variant="body2">
          {data.description ? data.description : null}
        </Typography>
      </Grid>
    </Grid>
  );
}
