import React, { useEffect } from "react";
import Header from "./Header";
import CreateModal from "./CreateModal";
import ViewModal from "./ViewModal";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import RemovalsList from "./RemovalsList";
import CardList from "./CardList";
import Search from "./Search";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../redux/actions/externalRemovelsGreenpoints";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Removals() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const removals = useSelector((state) => state.externalRemovalsGreenpoints);
  const app = useSelector((state) => state.app);

  useEffect(() => {
    const start = () => {
      dispatch(removalsActions.fetchRemovals());
      if (app.isMobile) {
        dispatch(removalsActions.setDataView("CARDS"));
        dispatch(removalsActions.setPagination({ rows: 4, page: 0 }));
      }
    };
    start();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Search />
          <CreateModal />
          <ViewModal />
          <EditModal />
          <DeleteModal />
          {removals.dataView === "LIST" && <RemovalsList />}
          {removals.dataView === "CARDS" && <CardList />}
        </CardContent>
      </Card>
    </Paper>
  );
}
