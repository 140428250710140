const now = new Date();

const defaultState = {
  createModal: false,
  exportToExcelModal: false,
  dataModal: null,
  createFilters: {
    city: "ALL",
    format: "ALL",
    zone: "ALL",
    groupId: "",
  },
  exportFilters: {
    zones: [],
    formats: [],
    greenPoints: [],
    greenPointList: [],
    groupId: "",
  },
  externalGreenpoints: [],
  createGreenPoints: [],
  dataCreate: null,
  data: null,
  dates: {
    dateInit: new Date(now.getFullYear(), now.getMonth(), 1),
    dateFinish: new Date(now.getFullYear(), now.getMonth() + 1, 1),
    groupId: "ALL",
  },
  dateRange: "CURRENT",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "EXTERNAL_REPORTS_ADD": {
      return { ...state, reports: [...state.reports, payload] };
    }
    case "EXTERNAL_REPORTS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "EXTERNAL_REPORTS_EXCEL_SET_CREATE_MODAL": {
      return { ...state, exportToExcelModal: payload };
    }
    case "EXTERNAL_REPORTS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "EXTERNAL_REPORTS_SET_DATA_CREATE": {
      return { ...state, dataCreate: payload };
    }
    case "EXTERNAL_REPORTS_SET_FILTERS_CREATE_MODAL": {
      return { ...state, createFilters: payload };
    }
    case "EXTERNAL_REPORTS_SET_FILTERS_GREENPOINTS_MODAL": {
      return {
        ...state,
        createGreenPoints: payload,
      };
    }
    case "EXTERNAL_REPORTS_DASHBOARD_FETCH": {
      return { ...state, data: payload };
    }
    case "EXTERNAL_REPORTS_DASHBOARD_SET_DATES": {
      return { ...state, dates: payload };
    }
    case "EXTERNAL_REPORTS_DASHBOARD_SET_DATERANGE": {
      return { ...state, dateRange: payload };
    }
    case "EXTERNAL_UPDATE_EXPORT_FILTERS": {
      return { ...state, exportFilters: payload };
    }
    case "EXTERNAL_UPDATE_EXPORT_GREENPOINTS": {
      return {
        ...state,
        exportFilters: { ...state.exportFilters, greenPointList: payload },
      };
    }
    default:
      return state;
  }
}

export default reducer;
