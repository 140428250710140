import React from "react";
import { Box, makeStyles, Popper } from "@material-ui/core";

const useStyles = makeStyles({
  popper: {
    WebkitTransition: "transform 400ms ease 0s",
    position: "absolute",
  },
  root: {
    backgroundColor: "rgb(255, 255, 255)",
    border: " 1px solid rgb(204, 204, 204)",
    "& span": {
      display: "block",
    },
  },
  kg: {
    marginTop: "5px",
    color: "rgb(31, 97, 141)",
  },
});

const BarTooltip = ({ open, material, total, anchor }) => {
  const classes = useStyles();
  return (
    <Popper
      open={open}
      anchorEl={anchor}
      placement="right"
      className={classes.popper}
      container={document.getElementById("root-scroll-bar")}
    >
      <Box padding={2} className={classes.root}>
        <span>{material}</span>
        <span className={classes.kg}>{`${total.toFixed(2)} kg`}</span>
      </Box>
    </Popper>
  );
};

BarTooltip.propTypes = {};

export default BarTooltip;
