import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import useMenu from "../../../../hooks/useMenu";
import greenPointsActions from "../../../../redux/actions/greenPoints";

const useStyles = makeStyles(() => ({
  main: {},
}));

const Filters = ({ formik }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { greenPointsForFilter } = useSelector((state) => state.greenPoints);
  const { createMassiveRemovalsModal } = useSelector((state) => state.removals);
  const { loading } = useSelector((state) => state.app);
  const { open, anchorEl, handleOpen, handleClose } = useMenu();

  const addAllGreenPoints = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      formik.setValues({
        greenPoints:
          greenPointsForFilter?.map((greenPoint) => greenPoint._id) || [],
      });
      return;
    }

    formik.setValues({
      greenPoints: [],
    });
  };

  const addCurrentGreenPoint = (e) => {
    const isChecked = e.target.checked;
    const greenPointId = e.target.value;

    if (isChecked) {
      formik.setValues({
        greenPoints: [...formik.values.greenPoints, greenPointId],
      });
      return;
    }
    formik.setValues({
      greenPoints: formik.values.greenPoints.filter(
        (id) => id !== greenPointId
      ),
    });
  };

  useEffect(() => {
    dispatch(greenPointsActions.fetchGreenPointsForFilter());
  }, []);

  useEffect(() => {
    if (createMassiveRemovalsModal && greenPointsForFilter?.length > 0) {
      formik.setValues({
        greenPoints:
          greenPointsForFilter?.map((greenPoint) => greenPoint._id) || [],
      });
    }
  }, [createMassiveRemovalsModal, greenPointsForFilter]);

  return (
    <>
      <Grid container spacing={2}>
        {!loading ? (
          <Grid item xs={12}>
            <TextField
              label={`Puntos Gira`}
              variant="outlined"
              fullWidth
              margin="dense"
              onClick={handleOpen}
              value={`${formik.values.greenPoints?.length} Seleccionados`}
            ></TextField>

            <Menu
              open={open}
              anchorEl={anchorEl}
              keepMounted
              onClose={handleClose}
              classes={{ paper: classes.paper }}
            >
              <Box p={2}>
                <Box padding="5px 10px">
                  <Typography style={{ fontWeight: "bold" }}>
                    PUNTOS GIRA
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              formik.values.greenPoints.length ===
                              greenPointsForFilter?.length
                            }
                            name="checkedB"
                            color="primary"
                            onChange={addAllGreenPoints}
                          />
                        }
                        label={"Todos"}
                      />
                    </MenuItem>
                    {/* --- */}
                    {greenPointsForFilter?.map((item) => (
                      <Grid item xs={6} md={12} key={item._id}>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.greenPoints?.includes(
                                  item?._id
                                )}
                                value={item?._id}
                                name="checkedB"
                                color="primary"
                                onChange={addCurrentGreenPoint}
                              />
                            }
                            label={item?.name}
                          />
                        </MenuItem>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            </Menu>
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography>Cargando...</Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Filters;
