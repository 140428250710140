import { createMuiTheme } from "@material-ui/core/styles";
// https://cssgradient.io/
// gradiente a 20°
const primary = "#1F618D";
const secondary = "#AEB6BF";
const success = "#00C851";
const warning = "#FF8000";
const neutral = "#ebebed";
const neutral_text = "#333333";
const yellow_gira = "#fbd43c";
const green_gira = "#6cbd4c";
const primaryGradient =
  "linear-gradient(20deg, rgba(2,0,36,1) 0%, rgba(0,168,202,1) 35%, rgba(110,214,235,1) 100%)";
const successGradient =
  "linear-gradient(20deg, rgba(2,0,36,1) 0%, rgba(0,200,81,1) 35%, rgba(108,208,148,1) 100%)";
const warningGradient =
  "linear-gradient(20deg, rgba(2,0,36,1) 0%, rgba(255,128,0,1) 35%, rgba(245,183,121,1) 100%)";

export default createMuiTheme({
  palette: {
    type: "light",

    primaryGradient: {
      main: primaryGradient,
    },
    successGradient: {
      main: successGradient,
    },
    warningGradient: {
      main: warningGradient,
    },
    primary: {
      main: primary,
    },
    yellow_gira: {
      main: yellow_gira,
    },

    green_gira: {
      main: green_gira,
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
    neutral: {
      main: neutral,
      text: neutral_text,
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 450,
    fontWeightBold: 500,
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: primary,
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: secondary,
          color: "#000",
        },
        "&$selected": {
          background: primary,
          color: "#fff",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
    },
    MuiPaper: {
      root: {
        marginBottom: "4%",
      },
    },
  },
});
