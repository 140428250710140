import React, { Fragment } from "react";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useSelector } from "react-redux";
import { DateTimePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";

export default function Content({
  error,
  formData,
  setFormData,
  checkMaterials,
  dataSelectEntity,
}) {
  const utils = useSelector((state) => state.utils);
  const validNumber = (num) => {
    if (num * 1 < 0) {
      return 0;
    } else {
      return parseFloat(num);
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">General</Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            onChange={(_e, k) =>
              setFormData({
                ...formData,
                greenPointID: k._id,
              })
            }
            options={dataSelectEntity}
            value={dataSelectEntity.find(
              (gp) => gp._id === formData.greenPointID
            )}
            disableClearable
            getOptionLabel={(option) =>
              option ? option.name : "Selecciona un Punto GIRA"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Punto GIRA"
                margin="dense"
                variant="outlined"
                error={error && formData.greenPointID === "EMPTY"}
              />
            )}
          />
          <Typography variant="body2">
            {
              utils.greenPoints.find((gp) => gp._id === formData.greenPointID)
                ?.address
            }
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <DateTimePicker
            label="Fecha del registro"
            inputVariant="outlined"
            margin="dense"
            fullWidth
            value={formData.datetimeRemoval}
            onChange={(date) =>
              setFormData({ ...formData, datetimeRemoval: date })
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Materiales</Typography>
        </Grid>
        {formData.materials &&
          Object.keys(formData.materials).map((material) => (
            <Grid item xs={2} key={material}>
              <TextField
                variant="outlined"
                margin="dense"
                type="number"
                label={utils.all_materials_deleted[material].name}
                error={error && !checkMaterials()}
                value={formData.materials[material].toString()}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    materials: {
                      ...formData.materials,
                      [material]: validNumber(e.target.value),
                    },
                  })
                }
                InputProps={{
                  endAdornment: <InputAdornment>Kg</InputAdornment>,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Nota descriptiva</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={5}
            error={error && !formData.description}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

Content.propTypes = {
  error: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  checkMaterials: PropTypes.func.isRequired,
};
