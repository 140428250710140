import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  Typography,
  makeStyles,
  ButtonGroup,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { EmojiEvents as GoalIcon } from "@material-ui/icons";
import indicatorsActions from "../../../../redux/actions/indicators";
import GoalByMaterial from "./Tabs/GoalByMaterial";
import GoalByMonth from "./Tabs/GoalByMonth";
import GoalByGreenPoint from "./Tabs/GoalByGreenPoint";
import GoalByGreenPointMaterial from "./Tabs/GoalByGreenPointMaterial";

const useStyles = makeStyles(() => ({
  tabs: {
    paddingBottom: "20px",
  },
}));

export default function Content() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const onChangeTab = (index, type) => {
    dispatch(indicatorsActions.fetchGoals(type));
    setCurrentTab(index);
  };

  useEffect(() => {
    dispatch(indicatorsActions.fetchGoals("BY_MATERIAL"));
    setCurrentTab(0);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">
            <GoalIcon style={{ fontSize: 34, marginBottom: 6 }} />
            Metas
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              dispatch(indicatorsActions.setViewModal(false));
            }}
          >
            <Close />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.tabs}>
        <ButtonGroup size="small" variant="contained">
          <Button
            onClick={() => onChangeTab(0, "BY_MATERIAL")}
            color={currentTab === 0 ? "primary" : "secondary"}
          >
            Meta por material
          </Button>
          <Button
            onClick={() => onChangeTab(1, "BY_GREEN_POINT")}
            color={currentTab === 1 ? "primary" : "secondary"}
          >
            Meta mensual por Punto Gira
          </Button>
          <Button
            onClick={() => onChangeTab(2, "TOTAL_MONTH")}
            color={currentTab === 2 ? "primary" : "secondary"}
          >
            Meta mensual por material total
          </Button>
          <Button
            onClick={() => onChangeTab(3, "BY_MATERIAL_AND_GREEN_POINT")}
            color={currentTab === 3 ? "primary" : "secondary"}
          >
            Meta por material y Puntos Gira
          </Button>
        </ButtonGroup>
      </Grid>
      <Typography variant="button">
        Nota: las metas mostradas corresponden al mes actual y año actual.
      </Typography>
      <Grid item xs={12}>
        <Box minHeight="400px">
          {currentTab === 0 && <GoalByMaterial />}
          {currentTab === 1 && <GoalByGreenPoint />}
          {currentTab === 2 && <GoalByMonth />}
          {currentTab === 3 && <GoalByGreenPointMaterial />}
        </Box>
      </Grid>
    </Grid>
  );
}
