import axios from "axios";
import app from "./app";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";
import checklistActions from "./checkList";

const fetchReports = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().checklistReports;
      const response = await axios.get(
        "/evaluations-reports/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({ type: "REPORTS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patch = () => {};

const createReport = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post("/evaluations-reports/admin", formData);
      dispatch(fetchReports());
      dispatch(checklistActions.fetchCards());
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_REPORT_CREATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_REPORT_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const deleteReport = (reportID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/evaluations-reports/admin/" + reportID);
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_REPORT_DELETE_SUCCESS"));
      dispatch(fetchReports());
      dispatch(checklistActions.fetchCards());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_REPORT_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const getData = (value) => {
  return async (dispatch) => {
    try {
      dispatch(app.setLoading(true));
      const response = await axios.post("/evaluations-reports/admin/getData", {
        ...value,
      });
      dispatch({ type: "REPORTS_SET_DATA_CREATE", payload: response.data });
      dispatch(app.setLoading(false));
    } catch (err) {
      dispatch(app.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchGreenPoints = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { createFilters: filters } = getState().checklistReports;
      const response = await axios.get(`/indicators/greenpoints`, {
        params: {
          city: filters.city === "ALL" ? "" : filters.city,
          format: filters.format === "ALL" ? "" : filters.format,
          zone: filters.zone === "ALL" ? "" : filters.zone,
        },
      });
      dispatch({
        type: "REPORTS_SET_FILTERS_GREENPOINTS_MODAL",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const updateFilters = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "REPORTS_SET_FILTERS_CREATE_MODAL",
      payload: value,
    });
    dispatch(fetchGreenPoints());
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_DELETE_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REPORTS_SET_DATA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "REPORTS_SET_SEARCH", payload: value });
    dispatch(fetchReports());
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "REPORTS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchReports());
  };
};

export default {
  fetchReports,
  patch,
  deleteReport,
  createReport,
  getData,
  setCreateModal,
  setDeleteModal,
  setDataModal,
  setSearch,
  setPagination,
  updateFilters,
};
