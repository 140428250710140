import axios from "axios";
import notifiesActions from "./notifies";
// import utilsActions from "./utils";
import appActions from "./app";
import authActions from "./auth";

const fetchUsers = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalUsers;
      const response = await axios.get(
        "/users/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search +
          "&userTypes=" +
          state.userTypes
      );
      dispatch({ type: "EXTERNAL_USERS_FETCH", payload: response.data });
      // dispatch(utilsActions.fetchUsers);
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const deleteUser = (userID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/users/admin/" + userID);
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const createUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/users/admin", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const patchUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/users/admin", data);
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const assignUserToEntity = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/users/admin/assignUserToEntity", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNAL_USERS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNAL_USERS_SET_DELETE_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNAL_USERS_SET_EDIT_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNAL_USERS_SET_DATA_MODAL", payload: value });
};

const setUsertypes = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "EXTERNAL_USERS_SET_USERTYPES", payload: value });
    dispatch(fetchUsers());
  };
};

const fetchCards = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/users/admin-extern/cards");
      dispatch({
        type: "USERS_FETCH_CARDS",
        payload: response.data,
      });
    } catch (e) {}
  };
};

const fetchUsersAdminExtern = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalUsers;
      const response = await axios.get(
        "/users/admin-extern/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search +
          "&userTypes=" +
          state.userTypes
      );
      dispatch({ type: "EXTERNAL_USERS_FETCH", payload: response.data });
      // dispatch(utilsActions.fetchUsers);
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const deleteUserAdminExtern = (userID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/users/admin-extern/" + userID);
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_SUCCESS"));
      dispatch(fetchUsersAdminExtern());
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const createUserAdminExtern = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/users/admin-extern", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsersAdminExtern());
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const patchUserAdminExtern = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/users/admin-extern", data);
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_SUCCESS"));
      dispatch(fetchUsersAdminExtern());
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setSearch = (value) => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    await dispatch({ type: "EXTERNAL_USERS_SET_SEARCH", payload: value });
    if (auth.userType === "ADMIN") {
      dispatch(fetchUsers());
    } else {
      dispatch(fetchUsersAdminExtern());
    }
  };
};

const setPagination = (value) => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    await dispatch({
      type: "EXTERNAL_USERS_SET_PAGINATION",
      payload: value,
    });
    if (auth.userType === "ADMIN") {
      dispatch(fetchUsers());
    } else {
      dispatch(fetchUsersAdminExtern());
    }
  };
};

export default {
  fetchUsers,
  deleteUser,
  createUser,
  patchUser,
  fetchUsersAdminExtern,
  deleteUserAdminExtern,
  createUserAdminExtern,
  patchUserAdminExtern,
  assignUserToEntity,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setDataModal,
  setSearch,
  setPagination,
  setUsertypes,
  fetchCards,
};
