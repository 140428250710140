import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import groupActions from "../../../redux/actions/groups";

export default function GroupSelect({ formData, setFormData, error }) {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(groupActions.fetchSelectGroups());
  }, []);

  return (
    <TextField
      label="Grupo"
      variant="outlined"
      margin="dense"
      value={
        typeof formData.groupId === "object" && formData.groupId
          ? formData.groupId._id
          : formData.groupId
      }
      select
      SelectProps={{ native: true }}
      error={error && !formData.groupId}
      onChange={(e) => setFormData({ ...formData, groupId: e.target.value })}
      fullWidth
    >
      <option value=""></option>
      {groups.groupsSelect.length ? (
        groups.groupsSelect.map((group, i) => (
          <option key={`op${i}-${group._id}`} value={group._id}>
            {group.name}
          </option>
        ))
      ) : (
        <option value="" disabled>
          Cargando...
        </option>
      )}
    </TextField>
  );
}

GroupSelect.propTypes = {
  error: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
