import { Grid, Button } from "@material-ui/core";
import { AddCircleOutline as PlusIcon } from "@material-ui/icons/";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Search from "../Search";
import ExternalsMaterialsModal from "../ExternalsMaterialsModal";
import ApprovedList from "./ApprovedList";
import PendingList from "./PendingList";

const ListsMaterials = () => {
  const { selector } = useSelector((state) => state.externalsMaterials);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      {selector === "APPROVED" ? (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Search />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                startIcon={<PlusIcon />}
                size="small"
                onClick={() => setOpenModal(true)}
              >
                Agregar Nuevo Material
              </Button>
            </Grid>
          </Grid>
          <ApprovedList />
        </>
      ) : (
        <>
          <Grid container justify="space-between">
            <Grid item>
              <Search />
            </Grid>
          </Grid>
          <PendingList />
        </>
      )}
      {openModal && (
        <ExternalsMaterialsModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          type={"CREATE"}
        />
      )}
    </>
  );
};

export default ListsMaterials;
