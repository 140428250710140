import React, { useState } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";

import Map from "./Map";

export default function CreateModal({ data }) {
  const [greenPoint, setGreenPoint] = useState(data[0]);

  const handleChange = (e) => {
    const selectedGreenPoint = data.find(
      (group) => group._id === e.target.value
    );
    setGreenPoint(selectedGreenPoint);
  };

  return data.length ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="PG Externo"
              variant="outlined"
              margin="dense"
              value={greenPoint._id}
              select
              SelectProps={{ native: true }}
              onChange={handleChange}
              fullWidth
            >
              {data.map((item, i) => (
                <option key={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Dirección:</Typography>
            <Typography variant="body2">{greenPoint.address}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Ciudad:</Typography>
            <Typography variant="body2">{greenPoint.city}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {greenPoint.users && (
              <>
                <Typography variant="body1">Monitores: </Typography>
                {greenPoint.users.length
                  ? greenPoint.users.map((user) => (
                      <Typography variant="body2">
                        -{" "}
                        {user.name +
                          " " +
                          user.lastName +
                          " (" +
                          user.email +
                          ")"}
                      </Typography>
                    ))
                  : "No registra monitores"}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} sm={3}>
            <Typography variant="body1">KG Reciclados en el mes:</Typography>
            <Typography variant="body2">0</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Map data={greenPoint} style={{ width: "100%" }} />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align="center">
          Este grupo no tiene puntos GIRA asociados.
        </Typography>
      </Grid>
    </Grid>
  );
}
