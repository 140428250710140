import React from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
  Box,
  makeStyles,
} from "@material-ui/core";
import { EmojiEvents as GoalIcon } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  quantity: {
    fontSize: "20px",
    fontWeight: "bold",
    opacity: 0.4,
  },
  progress: {
    height: "10px",
    borderRadius: "5px",
  },
  percentage: {
    fontSize: "14px",
    fontWeight: "bold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  root_progress: {
    borderRadius: "50%",
    boxShadow: "inset 0px 0px 0px 4px #E4E6EE",
  },
  circle_green: {
    "&.MuiCircularProgress-colorPrimary": {
      color: "#21AD57",
    },
  },
  circle_yellow: {
    "&.MuiCircularProgress-colorPrimary": {
      color: "#FDE498",
    },
  },
  circle_red: {
    "&.MuiCircularProgress-colorPrimary": {
      color: "#DD405A",
    },
  },
}));

export default function ProgressCard({ type, percentage, goal }) {
  const classes = useStyles();

  const setProgressColor = (value) => {
    if (value > 99) return classes.circle_green;
    if (value <= 99 && value >= 51) return classes.circle_yellow;
    return classes.circle_red;
  };
  return (
    <>
      {type === "lineal" && (
        <Grid item xs={12}>
          <Box>
            <Box>
              <Typography className={classes.quantity}>50%</Typography>
            </Box>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <LinearProgress
                  variant="determinate"
                  value={percentage > 100 ? 100 : percentage}
                  classes={{ root: classes.progress }}
                />
              </Grid>
              <Grid item xs={2}>
                <GoalIcon style={{ fontSize: 28, marginBottom: 6 }} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
      {type === "circular" && (
        <Grid item xs={12}>
          <Box>
            <Grid container alignItems="center">
              <Grid
                item
                xs={4}
                style={{
                  position: "relative",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={percentage > 100 ? 100 : percentage}
                  classes={{ root: classes.root_progress }}
                  size={50}
                  className={setProgressColor(percentage)}
                />
                <span className={classes.percentage}>{`${percentage}%`}</span>
              </Grid>
              <Grid item xs={8}>
                <Box paddingY="10px">
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      opacity: "0.8",
                    }}
                  >
                    {`${parseFloat(goal).toFixed(2)} kg`}
                  </Typography>
                  <Typography style={{ fontSize: "13px", opacity: "0.7" }}>
                    Material a recuperar
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
}
