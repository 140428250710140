import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import app from "./reducers/app";
import utils from "./reducers/utils";
import users from "./reducers/users";
import auth from "./reducers/auth";
import removals from "./reducers/removals";
import notifies from "./reducers/notifies";
import greenPoints from "./reducers/greenPoints";
import materials from "./reducers/materials";
import reports from "./reducers/reports";
import checkLists from "./reducers/checkList";
import dashboard from "./reducers/dashboard";
import removalsGreenPoint from "./reducers/removalsGreenPoint";
import reportsGreenPoint from "./reducers/reportsGreenPoint";
import profile from "./reducers/profile";
import indicators from "./reducers/indicators";
import goalsGreenPoint from "./reducers/goalsGreenPoint";
import externalGreenPoint from "./reducers/externalsGreenPoint";
import groups from "./reducers/groups";
import externalUsers from "./reducers/externalUsers";
import externalRemovals from "./reducers/externalsRemovals";
import externalRemovalsGreenpoints from "./reducers/externalsRemovelsGreenpoints";
import externalReports from "./reducers/externalReports";
import externalsIndicators from "./reducers/externalsIndicators";
import externalsMaterials from "./reducers/externalsMaterials";
import checklistReports from "./reducers/checklistReports";
import { createBrowserHistory } from "history";

const createRootReducer = (history) =>
  combineReducers({
    app,
    utils,
    users,
    auth,
    removals,
    notifies,
    greenPoints,
    materials,
    indicators,
    reports,
    checkLists,
    dashboard,
    removalsGreenPoint,
    profile,
    reportsGreenPoint,
    goalsGreenPoint,
    externalGreenPoint,
    groups,
    externalUsers,
    externalRemovals,
    externalRemovalsGreenpoints,
    externalReports,
    externalsIndicators,
    externalsMaterials,
    checklistReports,
  });

// * -------- Estado inicial de la aplicación
const initialState = {};

// * -------- Middlewares
export const history = createBrowserHistory();
const middlewares = [ReduxThunk, routerMiddleware(history)];

// * -------- Enhancers del store --------
const enhancers = [];

// * -------- Componer Middlewares y Enhancers --------
let composedEnhancers = null;
if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  const composeEnhancers = composeWithDevTools({ trace: true });
  composedEnhancers = composeEnhancers(
    applyMiddleware(...middlewares),
    ...enhancers
  );
} else {
  composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);
}

// * -------- Configuración del Persist --------
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["router"],
  whitelist: ["app", "auth"],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export default function configureStore() {
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  return { store, persistor };
}
