import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import materialsActions from "../../../redux/actions/materials";
import { AddCircleOutline as PlusIcon, Queue } from "@material-ui/icons/";
import MaterialsList from "./MaterialsList";
import Search from "./Search";
import MaterialsModal from "./MaterialsModal";

const useStyles = makeStyles(() => ({
  main: {},
}));

const Materials = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(materialsActions.fetchMaterials());
  }, []);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h4">
                <Queue style={{ fontSize: 34, marginBottom: 6 }} /> Tipos de
                Residuos
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                startIcon={<PlusIcon />}
                size="small"
                onClick={() => setOpenModal(true)}
              >
                Agregar Nuevo Material
              </Button>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Search />
          <MaterialsList />
        </CardContent>
      </Card>
      <MaterialsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        type={"CREATE"}
      />
    </Paper>
  );
};

export default Materials;
