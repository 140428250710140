const enqueueNotify = (type) => {
  return (dispatch) => {
    switch (type) {
      case "EXTERNAL_REMOVAL_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro externo creado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "EXTERNAL_REMOVAL_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el retiro externo",
            severity: "error",
          },
        });
        return true;
      }
      case "EXTERNAL_REMOVAL_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro externo actualizado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "EXTERNAL_REMOVAL_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY",
          payload: {
            duration: 3000,
            text: "Error en actualizar el retiro externo",
            severity: "error",
          },
        });
        return true;
      }
      case "EXTERNAL_REMOVAL_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro externo eliminado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "EXTERNAL_REMOVAL_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error en eliminar el retiro externo",
            severity: "error",
          },
        });
        return true;
      }

      case "REMOVAL_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el retiro",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el retiro",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Retiro actualizado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVAL_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo actualizar el retiro",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVAL_CREATE_GREENPOINT": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 2000,
            text: "Nuevo retiro registrado por punto verde",
            severity: "info",
          },
        });
        return true;
      }
      case "USER_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Usuario eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "USER_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el usuario",
            severity: "error",
          },
        });
        return true;
      }
      case "USER_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Usuario creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "USER_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el usuario",
            severity: "error",
          },
        });
        return true;
      }
      case "USER_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Usuario actualizado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "USER_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo actualizar el usuario",
            severity: "error",
          },
        });
        return true;
      }
      case "REPORT_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Reporte creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REPORT_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el reporte",
            severity: "error",
          },
        });
        return true;
      }
      case "REPORT_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Reporte creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "REPORT_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el reporte",
            severity: "error",
          },
        });
        return true;
      }
      case "CHECKLIST_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Evaluación creada con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "CHECKLIST_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear la evaluación",
            severity: "error",
          },
        });
        return true;
      }
      case "GREENPOINT_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Punto verde eliminado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "GREENPOINT_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo eliminar el punto verde",
            severity: "error",
          },
        });
        return true;
      }
      case "GREENPOINT_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Punto verde creado con éxito",
            severity: "success",
          },
        });
        return true;
      }
      case "GREENPOINT_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el punto verde",
            severity: "error",
          },
        });
        return true;
      }
      case "GREENPOINT_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo actualizar el punto verde",
            severity: "error",
          },
        });
        return true;
      }
      case "GREENPOINT_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Punto verde actualizado",
            severity: "success",
          },
        });
        return true;
      }
      case "GOAL_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Meta actualizada",
            severity: "success",
          },
        });
        return true;
      }
      case "GOAL_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al actualizar la meta",
            severity: "success",
          },
        });
        return true;
      }
      case "GROUP_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Grupo creado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "GROUP_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el Grupo",
            severity: "error",
          },
        });
        return true;
      }
      case "GROUP_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Grupo actualizado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "GROUP_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY",
          payload: {
            duration: 3000,
            text: "Error en actualizar el Grupo",
            severity: "error",
          },
        });
        return true;
      }
      case "GROUP_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Grupo eliminado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "GROUP_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error en eliminar el Grupo",
            severity: "error",
          },
        });
        return true;
      }
      case "CHECKLIST_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Evaluación Gira creado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "CHECKLIST_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear la Evaluación Gira",
            severity: "error",
          },
        });
        return true;
      }
      case "CHECKLIST_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Evaluación Gira actualizado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "CHECKLIST_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY",
          payload: {
            duration: 3000,
            text: "Error en actualizar la Evaluación Gira",
            severity: "error",
          },
        });
        return true;
      }
      case "CHECKLIST_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Evaluación Gira eliminado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "CHECKLIST_REMOVAL_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error en eliminar la Evaluación Gira",
            severity: "error",
          },
        });
        return true;
      }
      case "CHECKLIST_REPORT_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Reporte de Evaluación Gira creado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "CHECKLIST_REPORT_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "No se pudo crear el Reporte de Evaluación Gira",
            severity: "error",
          },
        });
        return true;
      }

      case "CHECKLIST_REPORT_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Reporte de Evaluación Gira eliminado correctamente",
            severity: "success",
          },
        });
        return true;
      }
      case "CHECKLIST_REPORT_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error en eliminar el Reporte de Evaluación Gira",
            severity: "error",
          },
        });
        return true;
      }
      case "MATERIAL_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Marial Creado Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "MATERIAL_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Crear Material",
            severity: "error",
          },
        });
        return true;
      }
      case "MATERIAL_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Marial Actualizado Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "MATERIAL_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Actualizar Material",
            severity: "error",
          },
        });
        return true;
      }
      case "MATERIAL_DELETE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Marial Eliminado Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "MATERIAL_DELETE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Eliminar Material",
            severity: "error",
          },
        });
        return true;
      }
      case "MATERIAL_APPROVE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Marial Aprobado Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "MATERIAL_APPROVE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Aprobado Material",
            severity: "error",
          },
        });
        return true;
      }
      case "TEMPLATE_GOALS_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Plantilla de Metas Creada Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "TEMPLATE_GOALS_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Crear Plantilla de Metas",
            severity: "error",
          },
        });
        return true;
      }
      case "GOALS_MASSIVE_UPDATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Metas Actualizadas Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "GOALS_MASSIVE_UPDATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Actualizar Metas",
            severity: "error",
          },
        });
        return true;
      }
      case "TEMPLATE_REMOVALS_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Plantilla de Residuos Creada Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "TEMPLATE_REMOVALS_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Crear Plantilla de Residuos",
            severity: "error",
          },
        });
        return true;
      }
      case "REMOVALS_MASSIVE_CREATE_SUCCESS": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Residuos Creados Exitosamente",
            severity: "success",
          },
        });
        return true;
      }
      case "REMOVALS_MASSIVE_CREATE_FAIL": {
        dispatch({
          type: "NOTIFY_ENQUEUE",
          payload: {
            duration: 3000,
            text: "Error al Crear Residuos",
            severity: "error",
          },
        });
        return true;
      }
      default: {
        return false;
      }
    }
  };
};

const dequeueNotify = () => {
  return (dispatch) => dispatch({ type: "NOTIFY_DEQUEUE" });
};

export default { enqueueNotify, dequeueNotify };
