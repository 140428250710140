import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import Header from "./Header";
import ViewGoalModal from "./ViewGoalModal";
import indicatorsActions from "../../../redux/actions/indicators";
import utilsActions from "../../../redux/actions/utils";
import MissingAlert from "./MissinAlert";
import MissinAlertByMaterials from "./MissinAlertByMaterials";
import Charts from "./Charts";
import ExportDataModal from "./ExportDataModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(indicatorsActions.fetchGoals());
    dispatch(indicatorsActions.getMonthlyProgress());
    dispatch(utilsActions.fetchMaterials());
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <>
            <Header />
            <MissingAlert />
            <MissinAlertByMaterials />
            <Charts />
            <ViewGoalModal />
            <ExportDataModal />
          </>
        </CardContent>
      </Card>
    </Paper>
  );
}
