import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";

import Header from "../../../Shared/Modals/Header";
import Buttons from "./Buttons";
import Content from "./Contents";

const initialState = {
  description: "",
  greenPointID: "EMPTY",
  datetimeRemoval: new Date(),
};
export default function ModalCreate() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const utils = useSelector((state) => state.utils);
  const removals = useSelector((state) => state.removals);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 1500);
    }
  }, [error]);

  useEffect(() => {
    if (removals.editModal && removals.dataModal) {
      let temp = {};
      let filtered = {};
      removals.dataModal.materials.forEach((material) => {
        temp[material.material] = material.quantity;
      });

      Object.keys(utils.all_materials).map((key) => {
        if (temp[key]) {
          filtered[key] = temp[key];
        } else {
          filtered[key] = 0;
        }
      });

      setFormData({
        ...removals.dataModal,
        greenPointID: removals.dataModal.greenPointID._id,
        materials: filtered,
      });
    }
  }, [removals.editModal, removals.dataModal]);

  const handleClose = () => {
    dispatch(removalsActions.setEditModal(false));
  };

  const checkMaterials = () =>
    Object.keys(formData.materials).reduce(
      (sum, current) => (sum += parseFloat(formData.materials[current])),
      0
    );

  const handleSuccess = () => {
    if (
      formData.greenPointID !== "EMPTY" &&
      formData.description !== "" &&
      checkMaterials()
    ) {
      const materials = Object.keys(formData.materials).map((material) => {
        return {
          material,
          quantity: formData.materials[material],
        };
      });
      dispatch(
        removalsActions.patchRemovals({
          formData: {
            ...formData,
            materials,
            userID: auth.user._id,
          },
        })
      );
      setFormData(initialState);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={removals.editModal}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <DialogContent>
        <Header handleClose={handleClose} title="Editar registro" type="VIEW" />
        <Content
          error={error}
          formData={formData}
          setFormData={setFormData}
          checkMaterials={checkMaterials}
        />
      </DialogContent>
      <DialogActions>
        <Buttons
          edit={true}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
        />
      </DialogActions>
    </Dialog>
  );
}
