import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  DialogActions,
  ButtonGroup,
  Button,
  useMediaQuery,
  Box,
  InputAdornment,
  IconButton,
  Collapse,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import _ from "lodash";
import { Add, Remove, ExpandLess, ExpandMore } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Check, Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import checkListActions from "../../../../redux/actions/checkList";
import usersActions from "../../../../redux/actions/users";
import Header from "../../../Shared/Modals/Header";

const initState = {
  greenPointID: "",
  date: new Date(),
  userId: "",
  total: 0,
  observations: "",
  questions: [
    {
      totalPoints: 20,
      title: "Presencia / Imagen de Monitor",
      items: [
        {
          item: "a",
          question: "Monitor se encuentra en el Punto de Reciclaje",
          maxValue: 7,
          score: 0,
        },
        {
          item: "b",
          question: "Monitor bien uniformado",
          maxValue: 7,
          score: 0,
        },
        {
          item: "c",
          question: "Monitor usa Equipo de Protección Personal",
          maxValue: 6,
          score: 0,
        },
      ],
    },
    {
      totalPoints: 60,
      title: "Operatividad del Punto Gira",
      items: [
        {
          item: "a",
          question: `Indicadores de Gestión ( Debe mostrar 2 indicadores: 1 Porcentaje de Recuperación 2. Número de clientes gestionados por semana.)`,
          maxValue: 20,
          score: 0,
        },
        {
          item: "b",
          question: `Tiene iniciativa de mostrar resultados y acciones`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "c",
          question: `SOLES (Internamente el Punto de Reciclaje cuenta con: Señalización, orden, informativos, presencia de indicadores actualizados visibles, afiches informativos).`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "d",
          question: `Gestión Monitor (Verificar el procedimiento operativo del monitor, envío de materiales, no material acumulado sin justificación)`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "d",
          question: `Limpieza / Organización dentro del Punto/ Imagen/ Mantenimiento`,
          maxValue: 10,
          score: 0,
        },
      ],
    },
    {
      totalPoints: 20,
      title: "Iniciativa recuperación de materiales",
      items: [
        {
          item: "a",
          question: `Buenas Prácticas`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "b",
          question: `Plan de Acción (Identificar oportunidades mejora)`,
          maxValue: 10,
          score: 0,
        },
      ],
    },
  ],
};

export default function CreateModal() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const checkList = useSelector((state) => state.checkLists);
  const utils = useSelector((state) => state.utils);
  const users = useSelector((state) => state.users);

  const [formData, setFormData] = useState(_.cloneDeep(initState));
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [titleTotals, setTitlesTotal] = useState({});
  const [helperTextInfo, setHelperTextInfo] = useState("");

  const handleExpandClick = (id, questionData) => {
    let total = 0;
    if (!expanded[`${id}`]) {
      total = questionData.items.reduce(
        (itemAccum, item) => itemAccum + item.score,
        0
      );
    }

    const expandHandler = {
      ...expanded,
      [`${id}`]: !expanded[`${id}`],
    };

    const totalHandler = {
      ...titleTotals,
      [`${id}`]: total,
    };

    setTitlesTotal(totalHandler);
    setExpanded(expandHandler);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setHelperTextInfo("");
      }, 1500);
    }
  }, [error]);

  useEffect(() => {
    dispatch(usersActions.fetchUsersGreenpoints());
  }, []);

  const handleClose = () => {
    if (!loading) {
      dispatch(checkListActions.setCreateModal(false));
      dispatch(checkListActions.setDataModal(null));
      setFormData(_.cloneDeep(initState));
    }
  };
  const handleSuccess = () => {
    setLoading(true);
    if (!formData.greenPointID || !formData.userId) {
      setHelperTextInfo("Este campo es requerido");
      setError(true);
      setLoading(false);
    } else {
      setFormData(_.cloneDeep(initState));
      dispatch(checkListActions.createEvaluation(formData));
      setLoading(false);
      handleClose();
    }
  };

  const calculateTotal = (questionArray) =>
    questionArray.reduce((questionAccum, question) => {
      const itemsTotalScore = question.items.reduce(
        (itemAccum, item) => itemAccum + item.score,
        0
      );
      return questionAccum + itemsTotalScore;
    }, 0);

  const handleInputChange = (event, questionIndex, itemIndex) => {
    const questionArray = formData.questions;
    questionArray[questionIndex].items[itemIndex].score = +event.target.value;
    const total = calculateTotal(questionArray);

    setFormData({
      ...formData,
      total,
      questions: questionArray,
    });
  };

  const handleButton = (questionIndex, itemIndex, type) => {
    const questionArray = formData.questions;

    if (type === "DECREASE") {
      questionArray[questionIndex].items[itemIndex].score--;
    }

    if (type === "INCREASE") {
      questionArray[questionIndex].items[itemIndex].score++;
    }

    const total = calculateTotal(questionArray);

    setFormData({
      ...formData,
      total,
      questions: questionArray,
    });
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={checkList.createModal}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <DialogContent>
        <Box>
          <Header
            title="Crear Evaluación GIRA"
            type="CREATE"
            handleClose={handleClose}
            loading={loading}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                onChange={(e, k) =>
                  setFormData({
                    ...formData,
                    greenPointID: k._id,
                  })
                }
                options={utils.greenPoints}
                value={utils.greenPoints.find(
                  (gp) => gp._id === formData.greenPointID
                )}
                disableClearable
                getOptionLabel={(option) =>
                  option ? option.name : "Selecciona un Punto GIRA"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto GIRA"
                    margin="dense"
                    variant="outlined"
                    error={error && !formData.greenPointID}
                    helperText={!formData.greenPointID && helperTextInfo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                inputVariant="outlined"
                margin="dense"
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha"
                value={formData.date}
                disableFuture={(date) => {
                  setFormData({
                    ...formData,
                    date: new Date(date),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                onChange={(e, k) =>
                  setFormData({
                    ...formData,
                    userId: k._id,
                  })
                }
                options={users.users}
                value={users.users.find((gp) => gp._id === formData.userId)}
                disableClearable
                getOptionLabel={(option) =>
                  option
                    ? `${option.name} ${option.lastName}`
                    : "Selecciona un Monitor"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Monitor"
                    margin="dense"
                    variant="outlined"
                    error={error && !formData.userId}
                    helperText={!formData.userId && helperTextInfo}
                  />
                )}
              />
            </Grid>

            {formData.questions.map((question, questionIndex) => (
              <Card key={question.title} style={{ width: "100%" }}>
                <CardActionArea
                  onClick={() => handleExpandClick(question.title, question)}
                >
                  <CardHeader
                    action={
                      !expanded[`${question.title}`] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    }
                    title={question.title}
                    subheader={
                      !expanded[`${question.title}`]
                        ? `Valor total ${question.totalPoints}`
                        : `Puntuación obtenida: ${
                            titleTotals[`${question.title}`]
                          } / ${question.totalPoints}`
                    }
                  />
                </CardActionArea>
                <Collapse
                  in={!expanded[`${question.title}`]}
                  timeout="auto"
                  unmountOnExit
                >
                  {question.items.map((item, itemIndex) => (
                    <CardContent key={item.title}>
                      <Grid container>
                        <Grid
                          item
                          container
                          alignItems="center"
                          xs={12}
                          sm={9}
                          lg={10}
                        >
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              {`${item.item}) ${item.question}`}
                            </Typography>{" "}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          alignItems="center"
                          xs={12}
                          sm={3}
                          lg={2}
                        >
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="number"
                              size="small"
                              margin="dense"
                              error={error}
                              value={item.score}
                              onChange={(e) =>
                                handleInputChange(e, questionIndex, itemIndex)
                              }
                              inputProps={{
                                max: item.maxValue,
                                readonly: "true",
                                style: { textAlign: "center" },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      disabled={item.score === 0}
                                      onClick={(e) =>
                                        handleButton(
                                          questionIndex,
                                          itemIndex,
                                          "DECREASE"
                                        )
                                      }
                                    >
                                      <Remove />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      disabled={item.score >= item.maxValue}
                                      onClick={(e) =>
                                        handleButton(
                                          questionIndex,
                                          itemIndex,
                                          "INCREASE"
                                        )
                                      }
                                    >
                                      <Add />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography variant="caption">
                              Puntaje maximo a asignar {item.maxValue}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  ))}
                </Collapse>
              </Card>
            ))}

            <Grid item xs={12}>
              <Typography align="center" style={{ fontWeight: "bold" }}>
                TOTAL {formData.total} / 100
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                placeholder=""
                label="Observaciones"
                value={formData.observations}
                onChange={(e) =>
                  setFormData({ ...formData, observations: e.target.value })
                }
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button
            color="secondary"
            startIcon={<Close />}
            disabled={loading}
            onClick={() => handleClose()}
          >
            Salir
          </Button>
          <Button
            color="primary"
            startIcon={<Check />}
            disabled={loading}
            onClick={() => handleSuccess()}
          >
            Crear
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
