import React from "react";

export default function Glass({ size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Layer_1"
      x={0}
      y={0}
      width={size}
      height={size}
      viewBox="0 0 555.2 731.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st0_glass{fill:#ede9e6}.prefix__st1_glass{fill:#4d4129}.prefix__st3_glass{fill:#231f20}"
        }
      </style>
      <path
        className="prefix__st0_glass"
        d="M336 448.4V288c0-18.5-20.2-44.4-28.1-48.8-4.1-2.3-4-8.3-4-8.9 3.7-39.8-2-57.5-6.1-70.5l-.7-2.2c-1.8-5.8-2.1-17.3-2.1-25.1h2.7c1.6 0 2.9-1.5 2.6-3.1l-1.3-7.6-.3-.9c1.2-.3 2.1-1.5 2.1-2.8 0-.2 0-.5-.1-.7-.5.3-1.2.5-2 .5-1.7 0-2.5-.8-3.2-1.4-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4s-2.5-.8-3.2-1.4c-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4-1.7 0-2.5-.8-3.2-1.4-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4-1.7 0-2.5-.8-3.2-1.4-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4-.9 0-1.5-.2-2-.5-.1.2-.1.5-.1.7 0 1.3.9 2.5 2.1 2.8-.2.3-.3.6-.3.9l-1.3 7.6c-.3 1.6 1 3.1 2.6 3.1h2c0 8.4-.4 19.6-2.1 25.1l-.7 2.2c-4.1 12.9-9.8 30.7-6.1 70.5 0 .6.1 6.7-4 8.9-7.9 4.4-28.1 30.2-28.1 48.8v160.4c-.1 1.6-.7 12.3 5.9 19.5 3.9 4.2 9.3 6.4 16.1 6.4H314c6.8 0 12.2-2.1 16.1-6.4 6.6-7.2 6-17.9 5.9-19.5z"
      />
      <path
        className="prefix__st0_glass"
        d="M256.2 115.9c.1.1.2.1.4.1h.1c.2 0 .3.1.5.1s.4 0 .6-.1c.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1.1 0 .1-.1.2-.1s.1-.1.2-.1c.1-.1.2-.1.3-.2l.1-.1.1-.1c.7-.6 1.6-1.4 3.2-1.4s2.5.8 3.2 1.4l.1.1.1.1c.1.1.2.2.3.2.1.1.1.1.2.1s.1.1.2.1.2.1.3.1c.1 0 .1.1.2.1.2 0 .4.1.6.1.2 0 .4 0 .6-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1.1 0 .1-.1.2-.1s.1-.1.2-.1c.1-.1.2-.1.3-.2l.1-.1.1-.1c.7-.6 1.6-1.4 3.2-1.4 1.7 0 2.5.8 3.2 1.4l.1.1.1.1c.1.1.2.1.3.2.1.1.1.1.2.1s.1.1.2.1.2.1.3.1c.1 0 .1.1.2.1.2 0 .4.1.6.1.2 0 .4 0 .6-.1.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1.1 0 .1-.1.2-.1s.1-.1.2-.1c.1-.1.2-.1.3-.2l.1-.1.1-.1c.7-.6 1.6-1.4 3.2-1.4s2.5.8 3.2 1.4l.1.1.1.1c.1.1.2.1.3.2.1.1.1.1.2.1s.1.1.2.1.2.1.3.1c.1 0 .1.1.2.1.2 0 .4.1.6.1s.4 0 .6-.1c.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1.1 0 .1-.1.2-.1s.1-.1.2-.1c.1-.1.2-.1.3-.2l.1-.1.1-.1c.7-.6 1.6-1.4 3.2-1.4 1.7 0 2.5.8 3.2 1.4l.1.1.1.1c.1.1.2.1.3.2.1.1.1.1.2.1s.1.1.2.1.2.1.3.1c.1 0 .1.1.2.1.2 0 .4.1.6.1.2 0 .4 0 .5-.1h.1c.1 0 .2-.1.4-.1.2-.1.4-.2.6-.4 0-.1 0-.3-.1-.4l-1.6-4.8c-.2-.6-.7-1-1.2-1h-39c-.5 0-1 .4-1.2 1l-1.6 4.8c0 .1-.1.3-.1.4.2.2.4.3.6.4z"
      />
      <g>
        <path
          d="M222.5 331.6v117s-2.2 22.4 18.7 22.4h72.9c20.8 0 18.7-22.4 18.7-22.4v-117l-2.2.1c0 8.6-23.7 15.6-53 15.6s-53-7-53-15.6l-2.1-.1z"
          fill="#40b3c0"
        />
        <path
          className="prefix__st1_glass"
          d="M277.6 350.6c-28 0-56.3-6.5-56.3-18.9 0-12.4 28.3-18.9 56.3-18.9s56.3 6.5 56.3 18.9c0 12.4-28.4 18.9-56.3 18.9zm0-31.2c-13.8 0-26.8 1.6-36.5 4.4-10.4 3.1-13.1 6.5-13.1 7.8 0 4.2 17.5 12.3 49.7 12.3 13.8 0 26.8-1.6 36.5-4.4 10.4-3.1 13.1-6.5 13.1-7.8-.1-4.2-17.6-12.3-49.7-12.3z"
        />
        <path
          className="prefix__st3_glass"
          d="M279.8 115.3H276c.5.5 1 .8 1.9.8s1.3-.3 1.9-.8zM298.6 116.1c.4 0 .7-.1 1-.2-.5-.4-1.1-.6-1.8-.6h-1.1c.6.5 1 .8 1.9.8zM290.1 115.3h-3.7c.5.5 1 .8 1.9.8.8 0 1.3-.3 1.8-.8zM269.4 115.3h-3.7c.5.5 1 .8 1.9.8s1.3-.3 1.8-.8z"
        />
        <path
          className="prefix__st1_glass"
          d="M298.6 118c-1.7 0-2.5-.8-3.2-1.4-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4-1.7 0-2.5-.8-3.2-1.4-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4-1.7 0-2.5-.8-3.2-1.4-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4s-2.5-.8-3.2-1.4c-.6-.5-1-.9-2-.9-.9 0-1.4.4-2 .9-.7.6-1.6 1.4-3.2 1.4-.9 0-1.5-.2-2-.5-.1.2-.1.5-.1.7 0 1.3.9 2.5 2.1 2.8-.2.3-.3.6-.3.9l-1.3 7.6c-.3 1.6 1 3.1 2.6 3.1h39.5c1.6 0 2.9-1.5 2.6-3.1l-1.3-7.6-.3-.9c1.2-.3 2.1-1.5 2.1-2.8 0-.2 0-.5-.1-.7-.4.3-1 .5-1.9.5z"
        />
        <path
          className="prefix__st3_glass"
          d="M257.2 116.1c.9 0 1.3-.3 1.9-.8H258c-.7 0-1.3.2-1.8.6.3.2.6.2 1 .2z"
        />
        <g>
          <path
            className="prefix__st1_glass"
            d="M257.2 116.1c.9 0 1.4-.4 2-.9.7-.6 1.6-1.4 3.2-1.4 1.7 0 2.5.8 3.2 1.4.6.5 1 .9 2 .9s1.4-.4 2-.9c.7-.6 1.6-1.4 3.2-1.4 1.7 0 2.5.8 3.2 1.4.6.5 1 .9 2 .9s1.4-.4 2-.9c.7-.6 1.6-1.4 3.2-1.4 1.7 0 2.5.8 3.2 1.4.6.5 1 .9 2 .9.9 0 1.4-.4 2-.9.7-.6 1.6-1.4 3.2-1.4 1.7 0 2.5.8 3.2 1.4.6.5 1 .9 2 .9.8 0 1.2-.3 1.6-.6 0-.1 0-.3-.1-.4l-1.6-4.8c-.2-.6-.7-1-1.2-1h-39c-.5 0-1 .4-1.2 1l-1.6 4.8c0 .1-.1.3-.1.4.4.4.8.6 1.6.6z"
          />
        </g>
        <path
          className="prefix__st1_glass"
          d="M314 474.3h-72.9c-6.8 0-12.2-2.1-16.1-6.4-6.6-7.2-6-17.9-5.9-19.5V288c0-18.5 20.2-44.4 28.1-48.8 4.1-2.3 4-8.3 4-8.9-3.7-39.8 2-57.5 6.1-70.5l.7-2.2c2.4-7.6 2.2-26.5 1.9-33.1 0-.9.3-1.8.9-2.4.6-.7 1.5-1 2.4-1h28.4c.9 0 1.8.4 2.4 1 .6.7 1 1.5.9 2.4 0 .2-1 23.9 1.9 33.1l.7 2.2c4.1 12.9 9.8 30.7 6.1 70.5 0 .6-.1 6.7 4 8.9 7.9 4.4 28.1 30.2 28.1 48.8v160.4c.1 1.6.7 12.3-5.9 19.5-3.5 4.2-8.9 6.4-15.8 6.4zm-47.2-346.6c.2 7.2.2 23.8-2.3 31.9l-.7 2.2c-4.2 13.1-9.4 29.4-5.8 68 0 .5.5 10.9-7.4 15.2-5.9 3.2-24.7 27.1-24.7 43v160.9c0 .1-.8 9.2 4.2 14.6 2.5 2.8 6.3 4.2 11.2 4.2H314c4.9 0 8.6-1.4 11.2-4.2 4.9-5.4 4.2-14.5 4.2-14.6V288c0-15.8-18.8-39.7-24.7-43-8-4.4-7.5-14.7-7.4-15.1 3.6-38.7-1.6-55-5.8-68.1l-.7-2.2c-2.5-8.1-2.5-24.7-2.3-31.9h-21.7z"
        />
      </g>
      <g>
        <path
          className="prefix__st1_glass"
          d="M217.4 556.4c1.5-4.1 3-5.4 5.8-5.4 2.9 0 5.5 2.6 5.5 5.5 0 1-.2 2.1-.7 3.2l-.5 1.1-15.5 37c-1.3 3-3 4.4-5.4 4.4-2.5 0-4.4-1.4-5.6-4.4l-15.3-36.9-.5-1.1c-.5-1.1-.7-2.3-.7-3.3 0-3 2.6-5.5 5.6-5.5 2.8 0 4.3 1.4 5.8 5.5l10.7 27.8 10.8-27.9zM244 557.4c0 3.3-2.6 6.1-5.9 6.1-3.1 0-5.8-2.7-5.8-5.9 0-3.5 2.6-6.1 6-6.1 3.1 0 5.7 2.6 5.7 5.9zm-.9 38.4v1.1c-.1 3.4-2 5.6-4.8 5.6-2.9 0-4.9-2.3-4.9-5.6v-25.6c.1-3.4 2-5.6 4.8-5.6 2.9 0 4.9 2.3 4.9 5.6v24.5zM274.8 558.3v-1.1c.1-3.4 2-5.6 4.8-5.6 2.9 0 4.9 2.3 4.9 5.6V596c0 2.4-.1 3.4-.7 4.4-.7 1.4-2.2 2.2-3.9 2.2-2.3 0-3.7-1.1-4.6-3.6-3.1 2.8-5.9 3.9-9.9 3.9-9.7 0-16.8-7.9-16.8-18.7 0-10.6 7.1-18.6 16.6-18.6 3.8 0 6.7 1.1 9.7 3.5v-10.8zM258.7 584c0 4.8 3.6 8.6 8.2 8.6 4.5 0 8.1-3.9 8.1-8.6s-3.7-8.5-8.1-8.5c-4.5.1-8.2 3.9-8.2 8.5zM309.2 565.5c3.4 0 6.2 2.8 6.2 6.2 0 3.3-2.6 6-5.8 6-1.4 0-1.6-.2-3.5-1.3-.7-.5-1.3-.7-1.9-.7-1.5 0-2.1 1.3-2.1 4.6v16.6c-.1 3.4-1.9 5.6-4.8 5.6s-4.9-2.3-4.9-5.6V573c0-3 .1-3.6.7-4.7.9-1.7 2.4-2.7 4.1-2.7 2 0 3.5 1.1 4.4 3 2.3-2 5-3.1 7.6-3.1zM329.7 557.4c0 3.3-2.6 6.1-5.9 6.1-3.1 0-5.8-2.7-5.8-5.9 0-3.5 2.6-6.1 6-6.1 3.2 0 5.7 2.6 5.7 5.9zm-.8 38.4v1.1c-.1 3.4-2 5.6-4.8 5.6-2.9 0-4.9-2.3-4.9-5.6v-25.6c.1-3.4 2-5.6 4.8-5.6 2.9 0 4.9 2.3 4.9 5.6v24.5zM370.4 584.2c0 10.1-8.1 18.5-18 18.5s-18.1-8.3-18.1-18.3c0-10.7 8-19 18.2-19 10 .1 17.9 8.4 17.9 18.8zm-26.2.1c0 4.5 3.7 8.3 8.2 8.3s8.2-3.8 8.2-8.5c0-4.8-3.6-8.6-8.1-8.6-4.7 0-8.3 3.8-8.3 8.8z"
        />
      </g>
    </svg>
  );
}
