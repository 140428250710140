import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Collapse,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import { Add, Remove, ExpandLess, ExpandMore } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { DatePicker } from "@material-ui/pickers";

export default function ViewModal({
  formData,
  setFormData,
  helperTextInfo,
  error,
}) {
  const utils = useSelector((state) => state.utils);
  const users = useSelector((state) => state.users);

  const [expanded, setExpanded] = useState({});
  const [titleTotals, setTitlesTotal] = useState({});

  const handleExpandClick = (id, questionData) => {
    let total = 0;
    if (!expanded[`${id}`]) {
      total = questionData.items.reduce(
        (itemAccum, item) => itemAccum + item.score,
        0
      );
    }

    const expandHandler = {
      ...expanded,
      [`${id}`]: !expanded[`${id}`],
    };

    const totalHandler = {
      ...titleTotals,
      [`${id}`]: total,
    };

    setTitlesTotal(totalHandler);
    setExpanded(expandHandler);
  };

  const calculateTotal = (questionArray) =>
    questionArray.reduce((questionAccum, question) => {
      const itemsTotalScore = question.items.reduce(
        (itemAccum, item) => itemAccum + item.score,
        0
      );
      return questionAccum + itemsTotalScore;
    }, 0);

  const handleInputChange = (event, questionIndex, itemIndex) => {
    const questionArray = formData.questions;
    questionArray[questionIndex].items[itemIndex].score = +event.target.value;
    const total = calculateTotal(questionArray);

    setFormData({
      ...formData,
      total,
      questions: questionArray,
    });
  };

  const handleButton = (questionIndex, itemIndex, type) => {
    const questionArray = formData.questions;

    if (type === "DECREASE") {
      questionArray[questionIndex].items[itemIndex].score--;
    }

    if (type === "INCREASE") {
      questionArray[questionIndex].items[itemIndex].score++;
    }

    const total = calculateTotal(questionArray);

    setFormData({
      ...formData,
      total,
      questions: questionArray,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Autocomplete
          fullWidth
          onChange={(e, k) =>
            setFormData({
              ...formData,
              greenPointID: k._id,
            })
          }
          options={utils.greenPoints}
          value={utils.greenPoints.find(
            (gp) => gp._id === formData.greenPointID
          )}
          disableClearable
          getOptionLabel={(option) =>
            option ? option.name : "Selecciona un Punto GIRA"
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Punto GIRA"
              margin="dense"
              variant="outlined"
              error={error && !formData.greenPointID}
              helperText={!formData.greenPointID && helperTextInfo}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DatePicker
          inputVariant="outlined"
          margin="dense"
          fullWidth
          format="DD/MM/YYYY"
          label="Fecha"
          value={formData.date}
          disableFuture={(date) => {
            setFormData({
              ...formData,
              date: new Date(date),
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Autocomplete
          fullWidth
          onChange={(e, k) =>
            setFormData({
              ...formData,
              userId: k._id,
            })
          }
          options={users.users}
          value={users.users.find((gp) => gp._id === formData.userId)}
          disableClearable
          getOptionLabel={(option) =>
            option
              ? `${option.name} ${option.lastName}`
              : "Selecciona un Monitor"
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Monitor"
              margin="dense"
              variant="outlined"
              error={error && !formData.userId}
              helperText={!formData.userId && helperTextInfo}
            />
          )}
        />
      </Grid>

      {formData.questions.map((question, questionIndex) => (
        <Card key={question.title} style={{ width: "100%" }}>
          <CardActionArea
            onClick={() => handleExpandClick(question.title, question)}
          >
            <CardHeader
              action={
                !expanded[`${question.title}`] ? <ExpandLess /> : <ExpandMore />
              }
              title={question.title}
              subheader={
                !expanded[`${question.title}`]
                  ? `Valor total ${question.totalPoints}`
                  : `Puntuación obtenida: ${
                      titleTotals[`${question.title}`]
                    } / ${question.totalPoints}`
              }
            />
          </CardActionArea>
          <Collapse
            in={!expanded[`${question.title}`]}
            timeout="auto"
            unmountOnExit
          >
            {question.items.map((item, itemIndex) => (
              <CardContent key={item.title}>
                <Grid container>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    sm={9}
                    lg={10}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {`${item.item}) ${item.question}`}
                      </Typography>{" "}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    sm={3}
                    lg={2}
                  >
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        size="small"
                        margin="dense"
                        error={error}
                        value={item.score}
                        onChange={(e) =>
                          handleInputChange(e, questionIndex, itemIndex)
                        }
                        inputProps={{
                          max: item.maxValue,
                          readonly: "true",
                          style: { textAlign: "center" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                variant="contained"
                                color="secondary"
                                size="small"
                                disabled={item.score === 0}
                                onClick={(e) =>
                                  handleButton(
                                    questionIndex,
                                    itemIndex,
                                    "DECREASE"
                                  )
                                }
                              >
                                <Remove />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={item.score >= item.maxValue}
                                onClick={(e) =>
                                  handleButton(
                                    questionIndex,
                                    itemIndex,
                                    "INCREASE"
                                  )
                                }
                              >
                                <Add />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="caption">
                        Puntaje maximo a asignar {item.maxValue}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            ))}
          </Collapse>
        </Card>
      ))}

      <Grid item xs={12}>
        <Typography align="center" style={{ fontWeight: "bold" }}>
          TOTAL {formData.total} / 100
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder=""
          label="Observaciones"
          value={formData.observations}
          onChange={(e) =>
            setFormData({ ...formData, observations: e.target.value })
          }
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
}
