import { createMuiTheme } from "@material-ui/core/styles";
// https://cssgradient.io/
// gradiente a 20°
const primary = "#00A8CA";
const secondary = "#AEB6BF";
const success = "#00C851";
const warning = "#FF8000";
const neutral = "#424242";
const neutral_text = "#ffffff";
const primaryGradient =
  "linear-gradient(20deg, rgba(2,0,36,1) 0%, rgba(0,168,202,1) 35%, rgba(110,214,235,1) 100%)";
const successGradient =
  "linear-gradient(20deg, rgba(2,0,36,1) 0%, rgba(0,200,81,1) 35%, rgba(108,208,148,1) 100%)";
const warningGradient =
  "linear-gradient(20deg, rgba(2,0,36,1) 0%, rgba(255,128,0,1) 35%, rgba(245,183,121,1) 100%)";

export default createMuiTheme({
  palette: {
    type: "dark",
    primaryGradient: {
      main: primaryGradient,
    },
    successGradient: {
      main: successGradient,
    },
    warningGradient: {
      main: warningGradient,
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
    neutral: {
      main: neutral,
      text: neutral_text,
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 350,
    fontWeightBold: 400,
  },

  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: "#303030",
      },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "#303030",
          color: "#fff",
        },
        "&$selected": {
          background: primary,
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
    },
    MuiPaper: {
      root: {
        marginBottom: "4%",
      },
    },
  },
});
