import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

export default function GoalByMonth() {
  const { goal } = useSelector((state) => state.indicators);
  const app = useSelector((state) => state.app);

  return (
    <Box>
      {goal && goal?.type === "TOTAL_MONTH" ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Mes</TableCell>
                <TableCell align="left">
                  Cantidad de material a recuperar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!app.loading &&
                goal &&
                goal.details.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.key}
                    </TableCell>
                    <TableCell align="left">{`${row.quantity.toFixed(
                      2
                    )} Kg`}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "Sin Resultados"
      )}
    </Box>
  );
}
