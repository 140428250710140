const formatCities = (citiesJson) => {
  const cities = [];
  const provinces = [];

  Object.keys(citiesJson).map((keys, i) => {
    return provinces.push({
      city: citiesJson[keys].cantones,
    });
  });
  Object.keys(provinces).map((keys, j) => {
    Object.keys(provinces[keys].city).map((ckey, y) => {
      return cities.push({
        name: provinces[keys].city[ckey].canton,
        code: ckey,
      });
    });
  });

  return cities.sort((x, y) => {
    if (x.name > y.name) {
      return 1;
    }
    if (x.name < y.name) {
      return -1;
    }
    return 0;
  });
};

export default formatCities;
