import React, { useEffect } from "react";
import { Grid, Paper, Card, CardContent, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import reportsActions from "../../../redux/actions/reportsGreenPoint";
import { Description as DescIcon } from "@material-ui/icons/";
import ReportsList from "./ReportsList";

export default function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    const exec = () => {
      dispatch(reportsActions.fetchReports());
    };
    exec();
  }, []);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h4">
                <DescIcon style={{ fontSize: 34, marginBottom: 6 }} /> Reportes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
          <ReportsList />
        </CardContent>
      </Card>
    </Paper>
  );
}
