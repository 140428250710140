import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BarChart from "./BarChart";
import MonthlyChart from "./MonthlyChart";
import AllGreenPoints from "./AllGreenPoints";
import Filters from "./Filters";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 2,
    },
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
}));

export default function Charts() {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.app);
  return (
    <Box>
      <Box>
        <Box p={2} style={{ border: "1px solid gray" }}>
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ marginBottom: "10px" }}
          >
            Filtros
          </Typography>
          <Filters />
        </Box>
      </Box>
      {!loading ? (
        <Box paddingTop="20px">
          <Typography variant="h5" color="textSecondary" align="center">
            Porcentaje de Recuperación de Residuos por Material
          </Typography>
          <BarChart />
        </Box>
      ) : (
        <Box className={classes.root}>Cargando...</Box>
      )}
      {!loading ? (
        <Box>
          <Typography variant="h5" color="textSecondary" align="center">
            Porcentaje de Recuperación de Residuos por Mes
          </Typography>
          <MonthlyChart />
        </Box>
      ) : (
        <Box className={classes.root}>Cargando...</Box>
      )}
      {!loading ? (
        <Box>
          <Typography variant="h5" color="textSecondary" align="center">
            Ranking de recuperación por Punto Gira
          </Typography>
          <AllGreenPoints />
        </Box>
      ) : (
        <Box className={classes.root}>Cargando...</Box>
      )}
    </Box>
  );
}
