import React from "react";
import BarsChart from "./BarsChart";
import DensityChart from "./DensityChart";
import { Grid, Typography } from "@material-ui/core";

export default function Charts() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" color="textSecondary" align="center">
          Kilogramos por material
        </Typography>
        <BarsChart />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h5" color="textSecondary" align="center">
          Densidad de registros por día del mes
        </Typography>
        <DensityChart />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
