import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Buttons({ handleClose, handleDelete, check }) {
  return (
    <ButtonGroup variant="contained" size="small">
      <Button
        color="secondary"
        onClick={() => handleClose()}
        startIcon={<Close />}
      >
        No, salir
      </Button>
      <Button
        color={check ? "primary" : "default"}
        onClick={() => handleDelete()}
        startIcon={<Check />}
      >
        Sí, eliminar
      </Button>
    </ButtonGroup>
  );
}

Buttons.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  check: PropTypes.bool.isRequired,
};
