import React from "react";
import PaperboardIcon from "./icons/Paperboard";
import PetIcon from "./icons/Pet";
import FlexFoamIcon from "./icons/FlexFoam";
import CansIcon from "./icons/Cans";
import PaperIcon from "./icons/Paper";
import FlexPlasticIcon from "./icons/FlexPlastic";
import RigPlasticIcon from "./icons/RigPlastic";
import DrinkCapIcon from "./icons/DrinkCap";
import GlassIcon from "./icons/Glass";
import TetrapackIcon from "./icons/Tetrapack";

export default function Icon({ glyph, size = 80 }) {
  return (
    <>
      {glyph === "paperboard" && <PaperboardIcon size={size} />}
      {glyph === "pet" && <PetIcon size={size} />}
      {glyph === "flex-foam" && <FlexFoamIcon size={size} />}
      {glyph === "cans" && <CansIcon size={size} />}
      {glyph === "paper" && <PaperIcon size={size} />}
      {glyph === "flex-plastic" && <FlexPlasticIcon size={size} />}
      {glyph === "rig-plastic" && <RigPlasticIcon size={size} />}
      {glyph === "drink-cap" && <DrinkCapIcon size={size} />}
      {glyph === "glass" && <GlassIcon size={size} />}
      {glyph === "tetrapack" && <TetrapackIcon size={size} />}
      {glyph === "other" && <TetrapackIcon size={size} />}
    </>
  );
}
