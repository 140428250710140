import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  imgDark: {
    filter: "invert(100%);",
    height: 60,
  },
  imgLight: {
    height: 60,
  },
}));

export default function EcoEq({ data }) {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setHover(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      item
      md={2}
      sm={4}
      xs={6}
      style={{ opacity: hover ? 0.7 : 1 }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      align="center"
    >
      <img
        src={data.image}
        className={app.theme === "LIGHT" ? classes.imgLight : classes.imgDark}
        style={{
          opacity: hover ? 0.8 : 1,
        }}
        alt={data.alt}
      />
      <Typography variant="h6" align="center" color="textSecondary">
        {data.value === -1 ? (
          <PulseLoader color="#303f9f" size={10} loading={true} />
        ) : (
          <NumberFormat
            value={Math.round(data.value)}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />
        )}
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary">
        {data.unity}
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        {data.subtitle}
      </Typography>
    </Grid>
  );
}

EcoEq.propTypes = {
  data: PropTypes.object.isRequired,
};
