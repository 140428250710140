const defaultState = {
  removals: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  search: "",
  createModal: false,
  deleteModal: false,
  viewModal: false,
  editModal: false,
  dataModal: null,
  dataView: "LIST",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "REMOVALS_GREENPOINT_FETCH": {
      return { ...state, removals: payload };
    }
    case "REMOVALS_GREENPOINT_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "REMOVALS_GREENPOINT_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "REMOVALS_GREENPOINT_SET_VIEW_MODAL": {
      return { ...state, viewModal: payload };
    }
    case "REMOVALS_GREENPOINT_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "REMOVALS_GREENPOINT_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "REMOVALS_GREENPOINT_SET_DATAVIEW": {
      return { ...state, dataView: payload };
    }

    case "REMOVALS_GREENPOINT_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "REMOVALS_GREENPOINT_SET_SEARCH": {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}

export default reducer;
