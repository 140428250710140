import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { BorderColor } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import externalUsersActions from "../../../../redux/actions/externalUsers";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import ContentEdit from "./ContentEdit";
import Buttons from "./Buttons";

const initState = {
  newPassword: "",
  newPasswordRpt: "",
  changePassword: false,
  changeGroup: false,
  changeActivate: false,
  newEntity: "EMPTY",
  newgroups: "EMPTY",
  greenPointSelect: [],
  type: "GREENPOINT_EXTERN",
  image: "",
};
export default function ViewModal() {
  const dispatch = useDispatch();
  const externalUsers = useSelector((state) => state.externalUsers);
  const groups = useSelector((state) => state.groups);

  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(initState);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  useEffect(() => {
    if (externalUsers.dataModal && !edit) {
      setFormData({
        ...formData,
        ...externalUsers.dataModal,
        newEntity: externalUsers.dataModal.externalEntityID
          ? externalUsers.dataModal.externalEntityID
          : "EMPTY",
      });
    }
  }, [externalUsers.dataModal, edit]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const handleSuccess = () => {
    if (
      formData.name === "" ||
      (formData.changePassword &&
        (formData.newPassword !== formData.newPasswordRpt ||
          formData.newPassword.length < 6))
    ) {
      setError(true);
    } else {
      let payload = formData;
      if (formData.newPassword) {
        payload = {
          ...payload,
          password: formData.newPassword,
        };
      }
      if (formData.newEntity === "EMPTY") {
        payload = {
          ...payload,
          newEntity: null,
        };
      } else {
        payload = {
          ...payload,
        };
      }
      if (formData.newgroups === "EMPTY") {
        payload = {
          ...payload,
          newEntity: externalUsers.dataModal.externalEntityID
            ? externalUsers.dataModal.externalEntityID._id
            : null,
          newgroups: externalUsers.dataModal.groupID
            ? externalUsers.dataModal.groupID._id
            : null,
        };
      } else {
        payload = {
          ...payload,
        };
      }
      if (formData.changeActivate) {
        payload = {
          ...payload,
          actived: formData.actived === "ACTIVED" ? "DEACTIVED" : "ACTIVED",
        };
      }

      dispatch(externalUsersActions.patchUser(payload));
      handleClose();
    }
  };

  const reset = () => {
    setEdit(false);
    setFormData(initState);
    setImageSrc("");
  };

  const handleClose = () => {
    dispatch(externalUsersActions.setEditModal(false));
    reset();
    dispatch(externalUsersActions.setDataModal(null));
  };

  return (
    <Dialog
      open={externalUsers.editModal}
      onClose={() => handleClose()}
      scroll={"body"}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Header
          title={formData.name + " " + formData.lastName}
          handleClose={handleClose}
          type="VIEW"
        />
        {!edit ? (
          <Content formData={formData} imageSrc={imageSrc} />
        ) : (
          <ContentEdit
            formData={formData}
            setFormData={setFormData}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            setIsUploadingImage={setIsUploadingImage}
            error={error}
          />
        )}
        <hr />
        <Button
          startIcon={<BorderColor />}
          color="primary"
          variant="text"
          onClick={() => setEdit(!edit)}
        >
          {!edit ? "editar" : "descartar cambios"}
        </Button>
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          isUploadingImage={isUploadingImage}
          edit={edit}
        />
      </DialogActions>
    </Dialog>
  );
}
