import React, { Fragment } from "react";
import {
  Grid,
  Button,
  Avatar,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth";

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 256,
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
  },
}));

const roles = {
  ADMIN: "Administrador",
  GREENPOINT: "Monitor GIRA",
  GREENPOINT_EXTERN: "Monitor Externo",
  ADMIN_EXTERN: "Administrador Externo",
};

export default function ProfileCard() {
  const styles = useStyles();
  const [hover, setHover] = React.useState(false);

  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const handleRoute = () => {
    if (auth.userType === "ADMIN") {
      return "/admin/profile";
    }
    if (auth.userType === "GREENPOINT") {
      return "/greenPoint/profile";
    }
    if (auth.userType === "GREENPOINT_EXTERN") {
      return "/greenPoint-external/profile";
    }
    if (auth.userType === "ADMIN_EXTERN") {
      return "/admin-external/profile";
    }
  };

  return (
    <div className={styles.card}>
      <Avatar
        className={styles.avatar}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        src={auth.user?.urlPhoto}
        style={{
          border: hover ? "2px solid #303f9f" : "2px solid #fff",
          opacity: hover ? 0.5 : 1,
        }}
      />
      <Typography variant="h6">{auth.user?.name}</Typography>
      <Typography variant="body2" style={{ marginBottom: 6 }}>
        {auth.user?.email}
      </Typography>
      <Divider light />
      <Typography variant="body2" style={{ margin: 6 }}>
        {roles[auth.userType]}
      </Typography>
      {auth.userType === "GREENPOINT" && (
        <Fragment>
          <Divider light />
          <Typography variant="body2" style={{ margin: 6 }}>
            {profile.user?.entityID?.name}
          </Typography>
        </Fragment>
      )}
      <Divider light />
      <Grid
        container
        style={{
          padding: 10,
        }}
      >
        <Grid item xs={12}>
          <Link to={handleRoute} style={{ textDecoration: "none" }}>
            <Button startIcon={<PersonIcon />} fullWidth>
              Perfil
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<ExitToAppIcon />}
            fullWidth
            onClick={() => dispatch(authActions.logout())}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
