import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import BarChart from "./BarChart";
import MonthlyChart from "./MonthlyChart";
import AllGreenPoints from "./AllGreenPoints";
import Filters from "./Filters";

export default function Charts() {
  const { filters } = useSelector((state) => state.externalsIndicators);
  return (
    <Box>
      <Box>
        <Box p={2} style={{ border: "1px solid gray" }}>
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ marginBottom: "10px" }}
          >
            Filtros
          </Typography>
          <Filters />
        </Box>
      </Box>
      {filters.groupID !== "" ? (
        <Box>
          <br />
          <Box paddingTop="20px">
            <Typography variant="h5" color="textSecondary" align="center">
              Porcentaje de Recuperación de Residuos por Material
            </Typography>
            <BarChart />
          </Box>
          <Box>
            <Typography variant="h5" color="textSecondary" align="center">
              Porcentaje de Recuperación de Residuos por Mes
            </Typography>
            <MonthlyChart />
          </Box>
          <Box>
            <Typography variant="h5" color="textSecondary" align="center">
              Ranking de recuperación por Punto Gira
            </Typography>
            <AllGreenPoints />
          </Box>
        </Box>
      ) : (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingTop={"20px"}
        >
          <Typography>SELECCIONE UN GRUPO</Typography>
        </Box>
      )}
    </Box>
  );
}
