import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Close, Check, Delete } from "@material-ui/icons";
import materialsActions from "../../../../redux/actions/materials";
import formatDate from "../../../../helpers/formatDate";

export default function DeleteModal({ data, open, onClose }) {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);

  const confirmDelete = () => {
    if (check) {
      dispatch(materialsActions.deleteMaterial(data._id));
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              <Delete style={{ fontSize: 26, marginBottom: 5 }} /> Eliminar
              Material de Residuo
            </Typography>
            <hr></hr>
            <Typography variant="body1">
              ¿Seguro que quieres eliminar este Registro?{" "}
              <strong>Esta acción no puede deshacerse.</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Nombre: {data?.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Fecha de creación: {formatDate(data?.createdAt)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Ult. fecha Modificación: {formatDate(data?.updatedAt)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Creado por: {data?.createdBy.name} {data?.createdBy.lastName}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={(e) => setCheck(e.target.checked)}
                  color="primary"
                />
              }
              label="Confirmo que deseo eliminar este Registro"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button color="secondary" onClick={onClose} startIcon={<Close />}>
            No, salir
          </Button>
          <Button
            color={check ? "primary" : "default"}
            disabled={!check}
            onClick={confirmDelete}
            startIcon={<Check />}
          >
            Sí, eliminar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
