import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import indicatorsAction from "../../../../../redux/actions/indicators";
import Header from "../../../../Shared/Modals/Header";
import GreenPointAccordion from "./GreenPointAccordion";

export default function ModalUpdateGoal() {
  const dispatch = useDispatch();
  const { updateGoalModal } = useSelector((state) => state.indicators);
  const app = useSelector((state) => state.app);
  const handleClose = () => {
    dispatch(indicatorsAction.setUpdateModal(false));
  };

  return (
    <Dialog
      open={updateGoalModal}
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
      fullScreen={app.isMobile}
      scroll="body"
    >
      <DialogContent dividers>
        <Header
          handleClose={handleClose}
          title="Actualizar metas"
          type="VIEW"
        />
        <GreenPointAccordion />
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={() => handleClose()}
            variant="contained"
            size="small"
          >
            Salir
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
