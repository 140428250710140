import React from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";

export default function Content({ data, setCheck, check }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2">Grupo: {data.group}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Punto GIRA: {data.greenPoint}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Usuario: {data.user ? data.user : "Eliminado"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Fecha de registro:{" "}
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(
            new Date(data.datetimeRemoval ? data.datetimeRemoval : null)
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Fecha de creación:{" "}
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(data.createdAt ? data.createdAt : null))}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={check}
              onChange={(e) => setCheck(e.target.checked)}
              color="primary"
            />
          }
          label="Confirmo que deseo eliminar este registro"
        />
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  data: PropTypes.object.isRequired,
  setCheck: PropTypes.func.isRequired,
  check: PropTypes.bool.isRequired,
};
