import React, { useEffect } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
  TextField,
} from "@material-ui/core";
import {
  Equalizer as EqualizerIcon,
  GetApp as DownloadIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import externalsgreenPointsActions from "../../../../redux/actions/externalsGreenPoint";
import groupsActions from "../../../../redux/actions/groups";
import Filters from "./Filters";
import UploadExcel from "./UploadExcel";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 2,
    },
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  customTooltip: {
    backgroundColor: "#e68a00",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  customArrow: {
    color: "#ffa31a",
  },
}));

const EditMassiveGoals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { editMassiveGoalsModal } = useSelector(
    (state) => state.externalGreenPoint
  );
  const { user } = useSelector((state) => state.auth);
  const { groupsSelect } = useSelector((state) => state.groups);
  const { loading } = useSelector((state) => state.app);

  const initialValues = {
    greenPoints: [],
    groups: [],
    groupId: "",
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(values);
      const formData = {
        ...values,
        greenPoints: JSON.stringify(values.greenPoints),
      };
      dispatch(externalsgreenPointsActions.getTemplateForGoals({ formData }));
    },
  });

  const handleChangeGroups = (e) => {
    formik.setValues({
      ...formik.values,
      groupId: e.target.value,
    });
  };

  const handleClose = () => {
    dispatch(externalsgreenPointsActions.setEditMassiveGoalsModal(false));
  };

  useEffect(() => {
    if (groupsSelect?.length > 0) {
      formik.setValues({
        ...formik.values,
        groups: groupsSelect || [],
      });
    }
  }, [groupsSelect]);

  useEffect(() => {
    if (editMassiveGoalsModal) {
      if (user?.type === "ADMIN") {
        dispatch(groupsActions.fetchSelectGroups());
      }

      dispatch(
        externalsgreenPointsActions.setMassiveUpdateGoalsResponse({
          isValidData: true,
        })
      );

      formik.setValues(initialValues);
    }
  }, [editMassiveGoalsModal]);

  return (
    <Dialog
      open={editMassiveGoalsModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="md"
      fullWidth
    >
      <>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <EqualizerIcon style={{ fontSize: 26, marginBottom: 5 }} />{" "}
                Carga Masiva de Metas Para Puntos GIRAS
              </Typography>
            </Grid>
            {/* --- */}

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Tooltip
                  title={
                    formik.values?.groups?.length === 0
                      ? "Cargando..."
                      : "Favor seleccionar un Grupo para continuar"
                  }
                  placement={"top-end"}
                  arrow={true}
                  open={Boolean(
                    formik.values?.groupId === "" ||
                      formik.values.groups?.length === 0
                  )}
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                >
                  <TextField
                    label="Grupo"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    select
                    SelectProps={{ native: true }}
                    onChange={handleChangeGroups}
                  >
                    <option value=""></option>

                    {groupsSelect.map((group, i) => (
                      <option key={`op${i}-${group._id}`} value={group._id}>
                        {group.name}
                      </option>
                    ))}
                  </TextField>
                </Tooltip>
              </Box>
            </Grid>
            {formik.values.groupId !== "" ? (
              <>
                <Grid item xs={12}>
                  <Filters formik={formik} />

                  <Box display="flex" justifyContent="flex-end">
                    <Tooltip
                      title={"Debe seleccionar al menos un punto GIRA"}
                      placement="left"
                      arrow={true}
                      open={Boolean(formik.values?.greenPoints?.length === 0)}
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                    >
                      <Button
                        color="primary"
                        startIcon={<DownloadIcon />}
                        disabled={
                          formik.values?.greenPoints?.length === 0 || loading
                        }
                        onClick={formik.handleSubmit}
                        variant="contained"
                        size="small"
                        loading={loading}
                      >
                        Generar Plantilla
                      </Button>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <UploadExcel handleClose={handleClose} formik={formik} />
                </Grid>
              </>
            ) : null}
          </Grid>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default EditMassiveGoals;
