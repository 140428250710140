import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Grid,
  Box,
  Typography,
  makeStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import externalsIndicatorsAction from "../../../../../redux/actions/externalsIndicators";
import GreenPointAccordion from "./GreenPointAccordion";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "greenPoint",
    numeric: false,
    disablePadding: false,
    label: "Punto GIRA",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  accordion_root: {
    marginBottom: 0,
  },
}));

export default function EnhancedTable() {
  const { filtersMissingMaterials, goalMissingByMaterialsList: list } =
    useSelector((state) => state.externalsIndicators);
  const app = useSelector((state) => state.app);
  const utils = useSelector((state) => state.utils);
  const [expanded, setExpanded] = React.useState("");
  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datetimeRemoval");
  const [dense] = useState(false);
  /* ---- */
  const [formData, setFormData] = useState([]);

  const validNumber = (num) => {
    if (isNaN(num)) return 0;
    if (num * 1 < 0) return 0;
    return parseFloat(num);
  };

  const changeMetric = (value, greenPointID, material) => {
    const newFormData = formData.map((item) => {
      let data = item;
      if (item.greenPointID === greenPointID) {
        const metrics = item.metrics_;
        metrics[material] = validNumber(value);
        const total = Object.keys(metrics).reduce((a, b) => a + metrics[b], 0);
        data = { ...item, metrics, quantity: total };
      }
      return data;
    });
    setFormData(newFormData);
  };

  const checkValues = (greenPointID) => {
    const current = formData.find((item) => item.greenPointID === greenPointID);
    return (
      Object.keys(current.metrics_).filter((key) => current.metrics_[key] === 0)
        .length === 0
    );
  };

  const onSave = (greenPointID) => {
    const isValid = checkValues(greenPointID);
    if (isValid) {
      const current = formData.find(
        (item) => item.greenPointID === greenPointID
      );
      dispatch(externalsIndicatorsAction.updateGoal(current));
      dispatch(externalsIndicatorsAction.fetchGreenPointWithoutMaterial());
    }
  };

  useEffect(() => {
    const initialFormData = list.map((item) => {
      const metrics_ = {};
      let oldTotal = 0;
      Object.keys(utils.all_materials_external).map((key) => {
        if (key !== "TOTAL") {
          if (!item.metrics.hasOwnProperty(key)) {
            metrics_[key] = 0;
          } else {
            metrics_[key] = item.metrics[key];
            oldTotal += item.metrics[key];
          }
        }
      });
      const quantity = 0;
      return {
        greenPointID: item.greenPointID,
        name: item.name,
        metrics_,
        oldTotal,
        quantity,
      };
    });
    setFormData(initialFormData);
  }, [list]);

  /* ---- */

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, page) => {
    if (page > filtersMissingMaterials.pagination.page) {
      if (list.length === filtersMissingMaterials.pagination.rows) {
        dispatch(
          externalsIndicatorsAction.setPaginationmissingMaterials({
            ...filtersMissingMaterials.pagination,
            page,
          })
        );
      }
    } else {
      dispatch(
        externalsIndicatorsAction.setPaginationmissingMaterials({
          ...filtersMissingMaterials.pagination,
          page,
        })
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      externalsIndicatorsAction.setPaginationmissingMaterials({
        ...filtersMissingMaterials.pagination,
        rows: parseInt(event.target.value, 10),
      })
    );
  };

  const emptyRows = filtersMissingMaterials.pagination.rows - list.length;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={12}
            />
            <TableBody>
              {!app.loading &&
                stableSort(formData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <StyledTableCell align="left" width="100%">
                          <GreenPointAccordion
                            row={row}
                            index={index}
                            changeMetric={changeMetric}
                            checkValues={checkValues}
                            onSave={onSave}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  }
                )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14]}
          component="div"
          count={-1}
          rowsPerPage={filtersMissingMaterials.pagination.rows}
          page={filtersMissingMaterials.pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
