import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@material-ui/core";
import { ResponsiveBar } from "@nivo/bar";
import { useSelector } from "react-redux";

const ToolTip = ({ value, indexValue }) => {
  return (
    <Box p={2}>
      {indexValue.toUpperCase()} <br />
      <strong>{`${parseInt(value)} Kg`}</strong>
    </Box>
  );
};

export default function Charts() {
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const dashboard = useSelector((state) => state.externalReports);
  const utils = useSelector((state) => state.utils);
  const [materials, setMaterials] = useState({ current: [] });

  const dataZeroValue = (collection) => {
    return collection.filter((item) => item.Actual > 0).length === 0;
  };

  useEffect(() => {
    if (dashboard.data) {
      setMaterials({
        current: Object.keys(dashboard.data.materialsTemp)
          .map((key) => {
            return {
              key,
              color: utils.materials[key]?.color,
              name: utils.materials[key]?.name,
              Actual: parseInt(dashboard.data.materialsTemp[key]),
            };
          })
          .filter((material) => material.key !== "total"),
      });
    }
  }, [dashboard.data]);

  return (
    <>
      <Box height="370px" width="100%">
        <ResponsiveBar
          data={materials.current}
          keys={["Actual"]}
          indexBy="name"
          margin={
            app.isMobile
              ? { top: 50, right: 15, bottom: 80, left: 30 }
              : { top: 50, right: 50, bottom: 80, left: 80 }
          }
          padding={0.25}
          maxValue={dataZeroValue(materials.current) ? 500 : "auto"}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colorBy="index"
          colors={materials.current.map((item) => item.color)}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          label={(d) => `${parseInt(d.value)}`}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -20,
            tickValues: "name",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Kg de material",
            legendPosition: "middle",
            legendOffset: -60,
          }}
          labelSkipWidth={10}
          labelSkipHeight={10}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          tooltip={ToolTip}
          motionStiffness={90}
          motionDamping={15}
        />
      </Box>
    </>
  );
}
