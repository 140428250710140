import axios from "axios";
import notifiesActions from "./notifies";
import utilsActions from "./utils";
import appActions from "./app";
import authActions from "./auth";

const fetchUsers = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().users;
      const response = await axios.get(
        "/users/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search +
          "&userTypes=" +
          state.userTypes
      );
      dispatch({ type: "USERS_FETCH", payload: response.data });
      dispatch(utilsActions.fetchUsers);
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const fetchUsersGreenpoints = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.get("/users/greenPoints");
      dispatch({ type: "USERS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const deleteUser = (userID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/users/admin/" + userID);
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const createUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/users/admin", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const patchUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/users/admin", data);
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const assignUserToEntity = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/users/admin/assignUserToEntity", data);
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_SUCCESS"));
      dispatch(fetchUsers());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_DELETE_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_EDIT_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "USERS_SET_DATA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "USERS_SET_SEARCH", payload: value });
    dispatch(fetchUsers());
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "USERS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchUsers());
  };
};

const setUsertypes = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "USERS_SET_USERTYPES", payload: value });
    dispatch(fetchUsers());
  };
};

export default {
  fetchUsers,
  deleteUser,
  createUser,
  patchUser,
  assignUserToEntity,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setDataModal,
  setSearch,
  setPagination,
  setUsertypes,
  fetchUsersGreenpoints,
};
