import React from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";

export default function Content({ data, check, setCheck }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          ¿Seguro que quieres eliminar el usuario?{" "}
          <strong>Esta acción no se puede deshacer.</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Nombre: {data.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">E-mail: {data.email}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Tipo: {data.type === "GREENPOINT" ? "Monitor GIRA" : data.type}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={check}
              onChange={(e) => setCheck(e.target.checked)}
              color="primary"
            />
          }
          label="Confirmo que deseo eliminar este monitor"
        />
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  data: PropTypes.object.isRequired,
  setCheck: PropTypes.func.isRequired,
  check: PropTypes.bool.isRequired,
};
