import axios from "axios";
import notifiesActions from "./notifies";
import utilsActions from "./utils";
import appActions from "./app";
import authActions from "./auth";

const fetchMaterials = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().materials;

      const response = await axios.get(
        `/materials/admin/${state.filters.pagination.page}/${state.filters.pagination.rows}?search=${state.filters.search}`
      );
      dispatch({ type: "MATERIALS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "MATERIALS_SET_SEARCH", payload: value });
    dispatch(fetchMaterials());
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "MATERIALS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchMaterials());
  };
};

const postMaterial = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/materials/admin", formData);
      dispatch(notifiesActions.enqueueNotify("MATERIAL_CREATE_SUCCESS"));
      dispatch(fetchMaterials());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MATERIAL_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchMaterial = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/materials/admin", formData);
      dispatch(notifiesActions.enqueueNotify("MATERIAL_UPDATE_SUCCESS"));
      dispatch(fetchMaterials());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MATERIAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteMaterial = (id) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete(`/materials/admin/${id}`);
      dispatch(notifiesActions.enqueueNotify("MATERIAL_DELETE_SUCCESS"));
      dispatch(fetchMaterials());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MATERIAL_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

export default {
  fetchMaterials,
  setSearch,
  setPagination,
  postMaterial,
  patchMaterial,
  deleteMaterial,
};
