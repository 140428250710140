import React from "react";
import { makeStyles, Card, CardContent, Typography } from "@material-ui/core";
import { ClockLoader } from "react-spinners";

const useStyles = makeStyles(({ breakpoints }) => ({
  card: {
    width: "100%",
    maxWidth: 500,
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    overflow: "initial",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 8,
    [breakpoints.up("sm")]: {
      textAlign: "start",
      flexDirection: "row",
    },
  },
  overline: {
    lineHeight: 2,
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "0.7rem",
    opacity: 0.7,
    textAlign: "center",
  },
  heading: {
    fontWeight: "450",
    color: "#ffffff",
    letterSpacing: 0.5,
    textAlign: "center",
  },
}));

export default function RewardCard({ value, background, title }) {
  const styles = useStyles();

  return (
    <Card className={styles.card} style={{ opacity: 1, background }}>
      <CardContent className={styles.content} style={{ padding: 0 }}>
        <Typography className={styles.overline} variant={"overline"}>
          {title}
        </Typography>
        <Typography className={styles.heading} variant={"h4"} gutterBottom>
          {value === "..." ? (
            <ClockLoader color="#fff" size={42} loading={true} />
          ) : (
            value
          )}
        </Typography>
      </CardContent>
    </Card>
  );
}
