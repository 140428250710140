import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Buttons({ edit, handleClose, handleSuccess }) {
  return (
    <ButtonGroup variant="contained" size="small">
      <Button
        startIcon={<Close />}
        color="secondary"
        onClick={() => handleClose()}
      >
        Salir {edit && "(sin guardar cambios)"}
      </Button>
      {edit && (
        <Button
          startIcon={<Check />}
          color="primary"
          onClick={() => handleSuccess()}
        >
          Guardar
        </Button>
      )}
    </ButtonGroup>
  );
}

Buttons.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};
