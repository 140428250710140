import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import externalUsersActions from "../../../../redux/actions/externalUsers";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

const formDataInitialState = {
  name: "",
  lastName: "",
  email: "",
  password: "",
  externalEntityID: "EMPTY",
  type: "GREENPOINT_EXTERN",
  phone: "",
  actived: "DEACTIVED",
  ruc: "",
  image: "",
};

export default function CreateModal() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const externalUsers = useSelector((state) => state.externalUsers);

  const [error, setError] = useState(false);
  const [dataSelectEntity, setDataSelectEntity] = useState([]);
  const [formData, setFormData] = useState(formDataInitialState);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [helperTextInfo, setHelperTextInfo] = useState("");
  const [helperTextPass, setHelperTextPass] = useState("");

  useEffect(() => {
    setDataSelectEntity(auth.user.groupID.externalsGreenPoints);
  }, [formData.type, auth.user, externalUsers.createModal]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setHelperTextInfo("");
        setHelperTextPass("");
      }, 1500);
    }
  }, [error]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const reset = () => {
    setFormData(formDataInitialState);
    setDataSelectEntity([]);
    setError(false);
    setImageSrc("");
  };

  const handleSuccess = () => {
    if (
      !validateEmail(formData.email) ||
      formData.name === "" ||
      formData.phone === "" ||
      formData.phone.length < 16 ||
      formData.lastName === "" ||
      formData.ruc === "" ||
      formData.ruc.length < 10
    ) {
      setHelperTextInfo("Este campo es requerido");
      setError(true);
      if (formData.password.length < 8) {
        setHelperTextPass("Tu contraseña debe contener al menos 8");
        setError(true);
      }
    } else {
      if (formData.externalEntityID === "EMPTY") {
        delete formData.externalEntityID;
      }
      dispatch(externalUsersActions.createUserAdminExtern(formData));
      reset();
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(externalUsersActions.setCreateModal(false));
    dispatch(externalUsersActions.setDataModal(null));
    reset();
  };

  return (
    <Dialog
      open={externalUsers.createModal}
      onClose={() => handleClose()}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Header
          handleClose={handleClose}
          type="CREATE"
          title="Crear usuario externo"
        />
        <Content
          validateEmail={validateEmail}
          error={error}
          dataSelectEntity={dataSelectEntity}
          formData={formData}
          setFormData={setFormData}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          setIsUploadingImage={setIsUploadingImage}
          helperTextInfo={helperTextInfo}
          helperTextPass={helperTextPass}
        />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          isUploadingImage={isUploadingImage}
        />
      </DialogActions>
    </Dialog>
  );
}
