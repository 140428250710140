const defaultState = {
  greenPoints: [],
  users: [],
  customers: [],
  locals: [],
  materials: {},
  all_materials: {},
  all_materials_deleted: {},
  materials_external: {},
  all_materials_external: {},
  all_materials_deleted_external: {},
  groups: [],
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "UTILS_FETCH_GREENPOINTS": {
      return { ...state, greenPoints: payload };
    }
    case "UTILS_FETCH_GROUPS": {
      return { ...state, groups: payload };
    }
    case "UTILS_FETCH_USERS": {
      return { ...state, users: payload };
    }
    case "UTILS_FETCH_MATERIALS": {
      return { ...state, materials: payload };
    }
    case "UTILS_FETCH_ALL_MATERIALS": {
      return { ...state, all_materials: payload };
    }
    case "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED": {
      return { ...state, all_materials_deleted: payload };
    }
    case "UTILS_FETCH_MATERIALS_EXTERNALS": {
      return { ...state, materials_external: payload };
    }
    case "UTILS_FETCH_ALL_MATERIALS_EXTERNALS": {
      return { ...state, all_materials_external: payload };
    }
    case "UTILS_FETCH_ALL_MATERIALS_WITH_DETELETED_EXTERNALS": {
      return { ...state, all_materials_deleted_external: payload };
    }
    default:
      return state;
  }
}

export default reducer;
