import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  ButtonGroup,
  withStyles,
  Box,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import materialsActions from "../../../redux/actions/materials";
import formatDate from "../../../helpers/formatDate";
import MaterialsModal from "./MaterialsModal";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "NRO",
    numeric: false,
    disablePadding: false,
    label: "N°",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre Material",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Fecha Creación",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "Creado por",
  },
  { id: "options", numeric: false, disablePadding: false, label: "Opciones" },
];

const TableHeader = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const MaterialsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials);
  const { user } = useSelector((state) => state.auth);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [dense] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [materialSelected, setMaterialSelected] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, page) => {
    if (page > materials.filters.pagination.page) {
      if (rows.length === materials.filters.pagination.rows) {
        dispatch(materialsActions.setPagination({ page }));
      }
    } else {
      dispatch(materialsActions.setPagination({ page }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      materialsActions.setPagination({
        rows: parseInt(event.target.value, 10),
      })
    );
  };

  const emptyRows = materials.filters.pagination.rows - rows.length;

  const options = ({ action, data }) => {
    if (action === "DELETE") {
      setMaterialSelected(data);
      setOpenDelete(true);
    }
    if (action === "EDIT") {
      setMaterialSelected(data);
      setOpenEdit(true);
    }
  };

  useEffect(() => {
    if (materials.materials) {
      setRows(materials.materials);
    }
  }, [materials]);

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={!row?.canBeRemoved ? { opacity: 0.5 } : null}
                  >
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">{row?.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {formatDate(row?.createdAt ? row?.createdAt : null)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.createdBy
                        ? `${row?.createdBy.name} ${row?.createdBy.lastName}`
                        : "ADMINISTRADOR"}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <ButtonGroup
                        size="small"
                        fullWidth
                        variant="contained"
                        disabled={!row?.canBeRemoved}
                      >
                        <Button
                          onClick={() => options({ action: "EDIT", data: row })}
                          color="primary"
                          disabled={!row?.canBeRemoved}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={() =>
                            options({ action: "DELETE", data: row })
                          }
                          color="secondary"
                          disabled={!row?.canBeRemoved}
                        >
                          <DeleteIcon />
                        </Button>
                      </ButtonGroup>
                    </StyledTableCell>
                  </TableRow>
                )
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14, 28]}
          component="div"
          count={-1}
          rowsPerPage={materials.filters.pagination.rows}
          page={materials.filters.pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {materialSelected && openEdit && (
        <MaterialsModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          currentMaterial={materialSelected}
          resetMaterial={() => setMaterialSelected(null)}
          type={"UPDATE"}
        />
      )}

      {materialSelected && openDelete && (
        <DeleteModal
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          data={materialSelected}
          resetMaterial={() => setMaterialSelected(null)}
        />
      )}
    </Box>
  );
};

export default MaterialsList;
