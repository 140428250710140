import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  Typography,
  DialogActions,
  ButtonGroup,
  Button,
  useTheme,
  FormControlLabel,
  Checkbox,
  Box,
} from "@material-ui/core";
import {
  endOfDay,
  endOfMonth,
  startOfMonth,
  startOfDay,
  differenceInMonths as calculateDifferenceInMonths,
} from "date-fns";
import { Check, Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import formats from "../../../../resources/formats.json";
import citiesJson from "../../../../resources/cities.json";
import zones from "../../../../resources/zones.json";
import reportsActions from "../../../../redux/actions/reports";
import formatCities from "../../../../helpers/formatCities";
import Header from "../../../Shared/Modals/Header";
import PropertySelect from "./PropertySelect";
import getDateFormated from "../../../../helpers/FormatHours";

const now = new Date();

const initState = {
  greenPoints: [],
  type: "MONTHLY",
  month: now.getMonth(),
  semester: now.getMonth() < 6 ? "FIRST" : "SECOND",
  year: now.getFullYear(),
  dateInit: new Date(now.getFullYear(), now.getMonth(), 1),
  dateFinish: new Date(now.getFullYear(), now.getMonth() + 1, 1, 0),
  includeGoals: false,
  removals: 0,
  differenceInMonths: 1,
  materials: {},
  download: false,
};

const allGreenPoints = [
  {
    name: "Todos",
    _id: "ALL",
    materials: {},
    download: false,
  },
];

export default function CreateModal() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);
  const utils = useSelector((state) => state.utils);
  const [cities, setCities] = useState([]);

  const [formData, setFormData] = useState(initState);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let dateInit = null;
    let dateFinish = null;
    let differenceInMonths = 1;
    if (formData.type === "MONTHLY") {
      dateInit = new Date(formData.year, formData.month, 1);
      dateFinish = new Date(formData.year, formData.month + 1, 1, 0);
    }
    if (formData.type === "ANNUAL") {
      dateInit = new Date(formData.year, 0, 1);
      dateFinish = new Date(formData.year + 1, 0, 1, 0);
    }
    if (formData.type === "BIANNUAL") {
      if (formData.semester === "FIRST") {
        dateInit = new Date(formData.year, 0, 1);
        dateFinish = new Date(formData.year, 6, 1, 0);
      } else {
        dateInit = new Date(formData.year, 6, 1);
        dateFinish = new Date(formData.year, 12, 1, 0);
      }
    }
    if (formData.type == "DATE_RANGE") {
      dateInit = startOfDay(startOfMonth(new Date()));
      dateFinish = endOfDay(endOfMonth(new Date()));
      differenceInMonths = calculateDifferenceInMonths(dateFinish, dateInit);
      differenceInMonths = differenceInMonths === 0 ? 1 : differenceInMonths;
    }

    setFormData({ ...formData, dateInit, dateFinish, differenceInMonths });
  }, [formData.type, formData.month, formData.semester, formData.year]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(
        reportsActions.getData({
          ...formData,
          dateInit: getDateFormated(formData.dateInit),
          dateFinish: getDateFormated(formData.dateFinish),
          greenPoints: formData.greenPoints.map((gp) => gp._id),
        })
      );
    };
    fetchData();
  }, [formData.dateInit, formData.dateFinish, formData.greenPoints]);

  useEffect(() => {
    if (reports.dataCreate) {
      setFormData({ ...formData, ...reports.dataCreate });
    }
  }, [reports.dataCreate]);

  useEffect(() => {
    if (utils.greenPoints.length > 0) {
      options.splice(options, options.length);
      var optionsAux = options.concat(utils.greenPoints, allGreenPoints);
      setOptions(optionsAux);
    }
  }, [utils.greenPoints]);

  useEffect(() => {
    const { zone, city, format } = reports.createFilters;
    const monthDiff =
      formData.type === "MONTHLY" ? 1 : formData.differenceInMonths;
    if (zone !== "ALL" || city !== "ALL" || format !== "ALL") {
      setFormData({
        ...formData,
        differenceInMonths: monthDiff,
        greenPoints: reports.createGreenPoints,
      });
    } else {
      setFormData({
        ...formData,
        differenceInMonths: monthDiff,
        greenPoints: [
          {
            name: "Todos",
            _id: "ALL",
          },
        ],
      });
    }
  }, [reports.createGreenPoints]);

  useEffect(() => {
    if (reports.createModal) {
      setCities(formatCities(citiesJson));
      dispatch(
        reportsActions.updateFilters({
          city: "ALL",
          zone: "ALL",
          format: "ALL",
        })
      );
      const startDate = startOfMonth(new Date());
      const endDate = endOfDay(endOfMonth(startDate));
      setFormData({
        ...formData,
        startDate,
        endDate,
        greenPoints: [
          {
            name: "Todos",
            _id: "ALL",
          },
        ],
      });
    }
  }, [reports.createModal]);

  const handleClose = () => {
    if (!loading) {
      dispatch(reportsActions.setCreateModal(false));
      dispatch(reportsActions.setDataModal(null));
      setFormData(initState);
    }
  };

  const handleSuccess = async () => {
    if (Boolean(formData.removals)) {
      setLoading(true);
      const { format, city, zone } = reports.createFilters;
      const withFilters = format !== "ALL" && city !== "ALL" && zone !== "ALL";
      const response = await dispatch(
        reportsActions.createReport({
          ...formData,
          dateInit: getDateFormated(formData.dateInit),
          dateFinish: getDateFormated(formData.dateFinish),
          withFilters,
          greenPoints: formData.greenPoints.map((gp) => gp._id),
        })
      );
      if (response && formData.download) {
        window.open(response);
      }
      setLoading(false);
      setFormData(initState);
      handleClose();
    }
  };

  return (
    <Dialog
      open={reports.createModal}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <DialogContent>
        <Box p={2}>
          <Header
            title="Crear reporte"
            type="CREATE"
            handleClose={handleClose}
            loading={loading}
          />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Tipo de reporte"
                variant="outlined"
                margin="dense"
                select
                fullWidth
                value={formData.type}
                onChange={(e) =>
                  setFormData({ ...formData, type: e.target.value })
                }
              >
                <MenuItem value="MONTHLY">Mensual</MenuItem>
                <MenuItem value="DATE_RANGE">Rango de fecha</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                {formData.type === "BIANNUAL" && (
                  <Fragment>
                    <Grid item xs={6}>
                      <TextField
                        label="Semestre"
                        variant="outlined"
                        margin="dense"
                        select
                        fullWidth
                        value={formData.semester}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            semester: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="FIRST">Primer semestre</MenuItem>
                        <MenuItem value="SECOND">Segundo semestre</MenuItem>
                      </TextField>
                    </Grid>
                  </Fragment>
                )}

                <Grid item xs={6}>
                  <TextField
                    label="Mes"
                    variant="outlined"
                    margin="dense"
                    disabled={formData.type !== "MONTHLY"}
                    value={formData.month}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        month: e.target.value,
                      })
                    }
                    select
                    fullWidth
                  >
                    <MenuItem value={0}>Enero</MenuItem>
                    <MenuItem value={1}>Febrero</MenuItem>
                    <MenuItem value={2}>Marzo</MenuItem>
                    <MenuItem value={3}>Abril</MenuItem>
                    <MenuItem value={4}>Mayo</MenuItem>
                    <MenuItem value={5}>Junio</MenuItem>
                    <MenuItem value={6}>Julio</MenuItem>
                    <MenuItem value={7}>Agosto</MenuItem>
                    <MenuItem value={8}>Septiembre</MenuItem>
                    <MenuItem value={9}>Octubre</MenuItem>
                    <MenuItem value={10}>Noviembre</MenuItem>
                    <MenuItem value={11}>Diciembre</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Año"
                    variant="outlined"
                    margin="dense"
                    select
                    fullWidth
                    disabled={formData.type !== "MONTHLY"}
                    value={formData.year}
                    onChange={(e) =>
                      setFormData({ ...formData, year: e.target.value })
                    }
                  >
                    <MenuItem value={2020}>2020</MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                    <MenuItem value={2025}>2025</MenuItem>
                    <MenuItem value={2026}>2026</MenuItem>
                    <MenuItem value={2027}>2027</MenuItem>
                    <MenuItem value={2028}>2028</MenuItem>
                    <MenuItem value={2029}>2029</MenuItem>
                    <MenuItem value={2030}>2030</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                inputVariant="outlined"
                margin="dense"
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha inicial"
                value={formData.dateInit}
                disabled={formData.type !== "DATE_RANGE"}
                disableFuture
                onChange={(date) => {
                  let differenceInMonths = calculateDifferenceInMonths(
                    formData.dateFinish,
                    new Date(date)
                  );
                  differenceInMonths =
                    differenceInMonths === 0 ? 1 : differenceInMonths;
                  setFormData({
                    ...formData,
                    differenceInMonths,
                    dateInit: startOfDay(new Date(date)),
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                inputVariant="outlined"
                margin="dense"
                fullWidth
                label="Fecha final"
                format="DD/MM/YYYY"
                minDate={formData.dateInit}
                value={formData.dateFinish}
                disabled={formData.type !== "DATE_RANGE"}
                onChange={(date) => {
                  let differenceInMonths = calculateDifferenceInMonths(
                    new Date(date),
                    formData.dateInit
                  );
                  differenceInMonths =
                    differenceInMonths === 0 ? 1 : differenceInMonths;
                  setFormData({
                    ...formData,
                    differenceInMonths,
                    dateFinish: endOfDay(new Date(date)),
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <PropertySelect
                label="Formato"
                onChange={(e) => {
                  dispatch(
                    reportsActions.updateFilters({
                      ...reports.createFilters,
                      format: e.target.value,
                    })
                  );
                }}
                options={formats}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <PropertySelect
                label="Cuidad"
                onChange={(e) => {
                  dispatch(
                    reportsActions.updateFilters({
                      ...reports.createFilters,
                      city: e.target.value,
                    })
                  );
                }}
                options={cities}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <PropertySelect
                label="Zona"
                onChange={(e) => {
                  dispatch(
                    reportsActions.updateFilters({
                      ...reports.createFilters,
                      zone: e.target.value,
                    })
                  );
                }}
                options={zones}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={reports.createGreenPoints}
                filterSelectedOptions
                getOptionLabel={(option) => option.name}
                value={formData.greenPoints}
                onChange={(_, k) =>
                  setFormData({ ...formData, greenPoints: [...k] })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    label="Puntos GIRA incluídos en reporte"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.includeGoals}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        includeGoals: e.target.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label="Incluir metas en reporte"
              />
            </Grid>
          </Grid>

          <hr></hr>

          <Typography
            variant="body1"
            style={{
              color: Boolean(formData.removals)
                ? theme.palette.success.main
                : theme.palette.error.main,
            }}
          >
            Registros realizados en rango: {formData.removals}
          </Typography>
          <hr></hr>
          <Typography variant="h6">
            Materiales en kilogramos registrados en el mes
          </Typography>
          <hr />
          <Grid container spacing={2}>
            {formData.materials &&
              Object.keys(utils.materials).map((material) => (
                <Grid item xs={3} key={material + "_material"}>
                  <Typography variant="body2">
                    {utils.materials[material].name}:
                  </Typography>
                  <Typography variant="body2">
                    {formData.materials[material]
                      ? Math.round(formData.materials[material] * 100) / 100
                      : 0}{" "}
                    kg
                  </Typography>
                </Grid>
              ))}
          </Grid>
          <hr />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.download}
                onChange={(e) =>
                  setFormData({ ...formData, download: e.target.checked })
                }
                color="primary"
              />
            }
            label="Descargar reporte al finalizar"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button
            color="secondary"
            startIcon={<Close />}
            disabled={loading}
            onClick={() => handleClose()}
          >
            Salir
          </Button>
          <Button
            color="primary"
            startIcon={<Check />}
            disabled={
              loading ||
              !Boolean(formData.greenPoints.length) ||
              !Boolean(formData.removals)
            }
            onClick={() => handleSuccess()}
          >
            Crear reporte
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
