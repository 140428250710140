const defaultState = {
  reports: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  search: "",
  createModal: false,
  deleteModal: false,
  dataModal: null,
  createFilters: {
    city: "ALL",
    format: "ALL",
    zone: "ALL",
  },
  createGreenPoints: [],
  dataCreate: null,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "REPORTS_FETCH": {
      return { ...state, reports: payload };
    }
    case "REPORTS_ADD": {
      return { ...state, reports: [...state.reports, payload] };
    }
    case "REPORTS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "REPORTS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "REPORTS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "REPORTS_SET_DATA_CREATE": {
      return { ...state, dataCreate: payload };
    }
    case "REPORTS_SET_FILTERS_CREATE_MODAL": {
      return { ...state, createFilters: payload };
    }
    case "REPORTS_SET_FILTERS_GREENPOINTS_MODAL": {
      return {
        ...state,
        createGreenPoints: payload,
      };
    }
    case "REPORTS_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "REPORTS_SET_SEARCH": {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}

export default reducer;
