import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import {
  Save as SaveIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import externalsIndicatorsAction from "../../../../../redux/actions/externalsIndicators";

const useStyles = makeStyles({
  accordion_root: {
    marginBottom: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordion_summary: {
    backgroundColor: "#f8f8f9",
  },
});

export default function GreenPointAccordion() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { goalMissingList } = useSelector((state) => state.externalsIndicators);
  const utils = useSelector((state) => state.utils);
  const [formData, setFormData] = useState([]);
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const validNumber = (num) => {
    if (isNaN(num)) return 0;
    if (num * 1 < 0) return 0;
    return parseFloat(num);
  };

  const changeMetric = (value, greenPointID, material) => {
    const newFormData = formData.map((item) => {
      let data = item;
      if (item.greenPointID === greenPointID) {
        const metrics = item.metrics;
        metrics[material] = validNumber(value);
        const total = Object.keys(metrics).reduce((a, b) => a + metrics[b], 0);
        data = { ...item, metrics, quantity: total };
      }
      return data;
    });
    setFormData(newFormData);
  };
  const checkValues = (greenPointID) => {
    const current = formData.find((item) => item.greenPointID === greenPointID);
    return (
      Object.keys(current.metrics).filter((key) => current.metrics[key] === 0)
        .length === 0
    );
  };
  const onSave = (greenPointID) => {
    const isValid = checkValues(greenPointID);
    if (isValid) {
      const current = formData.find(
        (item) => item.greenPointID === greenPointID
      );
      dispatch(externalsIndicatorsAction.updateGoal(current));
    }
  };
  useEffect(() => {
    const initialFormData = goalMissingList.map((item) => {
      const metrics = {};
      Object.keys(utils.all_materials_external).map((material) => {
        metrics[`${material}`] = 0;
      });
      const quantity = 0;
      return {
        greenPointID: item.greenPointID,
        name: item.name,
        metrics,
        quantity,
      };
    });
    setFormData(initialFormData);
  }, [goalMissingList]);

  return (
    <Box>
      {formData.map((item, index) => (
        <Accordion
          key={`accordion-green-point-${index}`}
          square
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          classes={{ root: classes.accordion_root }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`panel${index}d-header`}
            className={classes.accordion_summary}
          >
            <Typography> {item.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2}>
                {Object.keys(item.metrics).map((key) => (
                  <Grid item xs={6} md={4} lg={3} key={`material_input-${key}`}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      error={item.metrics[key] === 0}
                      value={item.metrics[key].toString()}
                      onChange={(e) =>
                        changeMetric(
                          validNumber(e.target.value),
                          item.greenPointID,
                          key
                        )
                      }
                      label={utils.all_materials_external[key].name}
                      InputProps={{
                        endAdornment: <InputAdornment>KG</InputAdornment>,
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={6} md={4} lg={3}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    type="number"
                    value={item.quantity}
                    label="Total"
                    InputProps={{
                      endAdornment: <InputAdornment>KG</InputAdornment>,
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
          <AccordionActions>
            <Button
              color="primary"
              startIcon={<SaveIcon />}
              disabled={!checkValues(item.greenPointID)}
              onClick={() => onSave(item.greenPointID)}
              variant="contained"
              size="small"
            >
              Actualizar
            </Button>
          </AccordionActions>
        </Accordion>
      ))}
    </Box>
  );
}
