import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

export default function GoalByMonth() {
  const { goal } = useSelector((state) => state.externalsIndicators);
  const app = useSelector((state) => state.app);
  const [goalMonth, setGoalMonth] = useState({});

  useEffect(() => {
    if (goal) {
      setGoalMonth(goal[0]);
    }
  }, [goal]);

  return (
    <Box>
      {goal && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Mes</TableCell>
                <TableCell align="left">
                  Cantidad de material a recuperar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!app.loading &&
                goalMonth !== {} &&
                Object.entries(goalMonth.months).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="left">{`${value} Kg`}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
