import React from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import {
  AddCircleOutline as PlusIcon,
  Eco as EcoIcon,
  Publish as PublishIcon,
} from "@material-ui/icons/";
import externalsGreenPointActions from "../../../redux/actions/externalsGreenPoint";
import { useDispatch } from "react-redux";

export default function ExternalGreenPoints() {
  const dispatch = useDispatch();
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <EcoIcon style={{ fontSize: 34, marginBottom: 6 }} /> Puntos Gira
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" style={{ gap: "4px" }}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<PlusIcon />}
            onClick={() =>
              dispatch(externalsGreenPointActions.setCreateModal(true))
            }
          >
            Crear punto GIRA
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<PublishIcon />}
            size="small"
            onClick={() =>
              dispatch(
                externalsGreenPointActions.setEditMassiveGoalsModal(true)
              )
            }
          >
            Carga Masiva de metas
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
