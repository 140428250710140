import React, { PureComponent } from "react";
import * as d3 from "d3";
import ToolTip from "./ToolTip";

const defaultSvgWidth = 700;
const defaultSvgHeight = 440;
const defaultSvgMargin = { top: 20, right: 35, bottom: 20, left: 70 };
const defaultSvgScrollHeight = 80;
const defaultPadding = 0.2;
const defaultSliceWidth = 22;

class BarChart extends PureComponent {
  constructor(props) {
    super(props);

    const svgWidth = props.width === undefined ? defaultSvgWidth : props.width;
    const svgHeight =
      props.height === undefined ? defaultSvgHeight : props.height;
    const svgMargin =
      props.margin === undefined
        ? {
            ...defaultSvgMargin,
            left: props.isMobile ? 15 : 75,
            right: props.isMobile ? 15 : 35,
          }
        : props.margin;
    const svgScrollHeight =
      props.scrollHeight === undefined
        ? defaultSvgScrollHeight
        : props.scrollHeight;
    const svgScrollMargin = { ...svgMargin, bottom: 0 };
    const padding =
      props.padding === undefined
        ? props.isMobile
          ? 0.2
          : defaultPadding
        : props.padding;
    const sliceWidth =
      props.bars === undefined
        ? props.isMobile
          ? 11
          : defaultSliceWidth
        : props.bars;

    this.state = {
      openToolTip: false,
      anchor: null,
      currentBar: { name: "", total: 0 },
      path: null,
      svgWidth,
      svgHeight,
      svgMargin,
      svgScrollHeight,
      svgScrollMargin,
      padding,
      sliceStart: 0,
      sliceWidth,
      range: svgHeight - svgMargin.bottom - svgScrollHeight,
      xScale: d3
        .scaleBand()
        .range([svgMargin.left, svgWidth - svgMargin.right])
        .padding(padding),
      yScale: d3
        .scaleLinear()
        .range([svgHeight - svgMargin.bottom - svgScrollHeight, svgMargin.top]),
      xAxisRef: null,
      yAxisRef: null,

      xScrollScale: d3
        .scaleBand()
        .range([svgScrollMargin.left, svgWidth - svgScrollMargin.right])
        .padding(padding),
      yScrollScale: d3

        .scaleLinear()
        .range([svgHeight, svgHeight - svgScrollHeight + svgScrollMargin.top]),
      scrollRef: null,

      scrollSelectorWidth: 0,
      scrollSelectorMinX: 0,
      scrollSelectorMaxX: 0,
      scrollBandWidth: 0,
      scrollSelectorX: svgScrollMargin.left,

      bars: [],
      scrollBars: [],
    };

    this.xAxis = d3.axisBottom().scale(this.state.xScale).tickSizeOuter(0);
    this.yAxis = d3
      .axisLeft()
      .scale(this.state.yScale)
      .tickFormat((d) => {
        return `${d}`;
      });
  }

  xAxisRef = (element) => {
    this.setState({ xAxisRef: element });
    d3.select(element).call(this.xAxis);
  };

  yAxisRef = (element) => {
    this.setState({ yAxisRef: element });
    d3.select(element).call(this.yAxis);
  };

  scrollRef = (element) => {
    this.setState({ scrollRef: element });
    d3.select(element).call(
      d3.drag().on("drag", (e) => {
        this.scrollDrag(e);
      })
    );
  };

  scrollDrag = (e) => {
    let newX = this.state.scrollSelectorX + e.dx;
    let newSlice = 0;
    const oldSlice = this.state.sliceStart;

    if (newX > this.state.scrollSelectorMaxX) {
      newX = this.state.scrollSelectorMaxX;
    } else if (newX < this.state.scrollSelectorMinX) {
      newX = this.state.scrollSelectorMinX;
    }

    newSlice = newX - this.state.scrollSelectorMinX;
    newSlice = Math.round(newSlice / this.state.scrollBandWidth);

    if (newSlice !== oldSlice) {
      const bars = this.calculateBars(newSlice);
      this.setState({ scrollSelectorX: newX, sliceStart: newSlice, bars });
    } else {
      this.setState({ scrollSelectorX: newX });
    }
  };

  calculateBars = (newSliceStart) => {
    const { data } = this.props;
    let {
      xScale,
      yScale,
      sliceStart,
      sliceWidth,
      svgHeight,
      svgMargin,
      svgScrollHeight,
    } = this.state;

    if (newSliceStart !== undefined) {
      sliceStart = newSliceStart;
    }

    const activityDomain = data
      .slice(sliceStart, sliceStart + sliceWidth)
      .map((d) => d.name);
    const valueMax = d3.max(data, (d) => d.puntos);

    xScale.domain(activityDomain);
    yScale.domain([0, valueMax + 100]);

    const bars = data
      .slice(sliceStart, sliceStart + sliceWidth)
      .map((d, index) => {
        const x = xScale(d.name);
        const y = yScale(d.puntos);
        const width = xScale.bandwidth();
        const height = svgHeight - svgMargin.bottom - svgScrollHeight - y;
        const fill = d.color;

        return {
          index,
          x,
          y,
          height,
          width,
          fill,
          name: d.name,
          total: d.puntos,
        };
      });
    return bars;
  };

  calculateScrollBars = () => {
    const { data } = this.props;
    const { xScrollScale, yScrollScale, svgHeight } = this.state;

    const scrollActivityDomain = data.map((d) => d.name);
    const valueMax = d3.max(data, (d) => d.puntos);

    xScrollScale.domain(scrollActivityDomain);
    yScrollScale.domain([0, valueMax]);

    const scrollBars = data.map((d, index) => {
      const scrollX = xScrollScale(d.name);
      const scrollY = yScrollScale(d.puntos);
      const scrollWidth = xScrollScale.bandwidth();
      const scrollHeight = svgHeight - scrollY;
      const scrollFill = "#cccccc";

      return {
        index,
        scrollX,
        scrollY,
        scrollWidth,
        scrollHeight,
        scrollFill,
      };
    });

    return scrollBars;
  };

  calculateScrolSellector = (scrollBarsLength) => {
    const { sliceWidth, svgWidth, svgScrollMargin } = this.state;

    const scaleWidth =
      svgWidth - svgScrollMargin.right - svgScrollMargin.left - 70;
    const scrollSelectorWidth = Math.round(
      (sliceWidth / scrollBarsLength) * scaleWidth
    );
    const scrollSelectorMinX = svgScrollMargin.left;
    const scrollSelectorMaxX =
      svgWidth - svgScrollMargin.right - scrollSelectorWidth;
    const scrollBandWidth = Math.round(scaleWidth / scrollBarsLength);

    return {
      scrollSelectorWidth,
      scrollSelectorMinX,
      scrollSelectorMaxX,
      scrollBandWidth,
    };
  };

  calculateChart = () => {
    if (this.props.data === undefined) {
      return;
    }
    const bars = this.calculateBars();
    const scrollBars = this.calculateScrollBars();
    const selector = this.calculateScrolSellector(scrollBars.length);

    const states = { ...selector, bars, scrollBars };
    this.setState(states);
  };

  formatXAxis = (title) => {
    if (title.length < 16) return title;
    const pattern = /([^\s].{0,14}(?=[\s\W]|$))/gm;
    const formatTitle = title.match(pattern);
    const newChildren = `<tspan y="0" x="-10">${
      formatTitle[0]
    }</tspan><tspan y="18" x="-5">${formatTitle[1]} ${formatTitle[2] || ""} ${
      formatTitle[3] || ""
    }</tspan>`;

    return newChildren;
  };
  handleHover = (e, status, details) => {
    const path = {
      x: details.x + details.width / 2,
    };
    this.setState({
      openToolTip: status,
      anchor: e.currentTarget,
      currentBar: details,
      path,
    });
  };

  componentDidMount = () => {
    this.calculateChart();
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateChart();
    }

    d3.select(this.state.xAxisRef).call(this.xAxis);
    d3.select(this.state.yAxisRef).call(this.yAxis);

    const text = document.querySelectorAll(".x-axis .tick text");
    text.forEach((element) => {
      const oldHTML = element.innerHTML;
      element.innerHTML = this.formatXAxis(oldHTML);
    });
  }

  render() {
    return (
      <div id="root-scroll-bar">
        <svg width="100%" height={this.state.svgHeight + 90}>
          {this.state.openToolTip && this.state.path && (
            <path
              stroke="#ccc"
              pointerEvents="none"
              width="399"
              height="319"
              d={`M${this.state.path.x},${this.state.svgMargin.top}L${this.state.path.x},${this.state.range}`}
              style={{ zIndex: "-1" }}
            ></path>
          )}
          {this.state.bars.map((d, i) => (
            <g
              onMouseEnter={(e) => this.handleHover(e, true, d)}
              onMouseLeave={(e) => this.handleHover(e, false, d)}
              key={`bars-${i}`}
            >
              <rect
                x={d.x}
                y={this.state.svgMargin.top}
                width={d.width}
                fill="transparent"
                height={this.state.range - this.state.svgMargin.top}
              ></rect>
              <rect
                x={d.x}
                y={d.y}
                width={d.width}
                height={d.height}
                fill={d.fill}
              ></rect>
            </g>
          ))}

          {this.state.scrollBars.map((d, i) => (
            <rect
              key={i}
              x={d.scrollX}
              y={d.scrollY + 90}
              width={d.scrollWidth}
              height={d.scrollHeight}
              fill={d.scrollFill}
            />
          ))}

          <rect
            ref={this.scrollRef}
            className="scroll-selector"
            x={this.state.scrollSelectorX}
            y={this.state.svgHeight - this.state.svgScrollHeight + 90}
            width={this.state.scrollSelectorWidth}
            height={this.state.svgScrollHeight}
          />

          <g>
            {!this.props.isMobile && (
              <>
                <g
                  ref={this.yAxisRef}
                  transform={`translate(${this.state.svgMargin.left}, 0)`}
                />
                <g
                  transform={`translate(${this.state.svgMargin.left - 50}, ${
                    this.state.svgHeight / 2
                  })`}
                >
                  <text style={{ transform: "rotate(-90deg)" }}>
                    Kg de material
                  </text>
                </g>
              </>
            )}
            <g
              ref={this.xAxisRef}
              className="x-axis"
              transform={`translate(0, ${
                this.state.svgHeight -
                this.state.svgMargin.bottom -
                this.state.svgScrollHeight
              })`}
            />
          </g>
          {this.state.openToolTip && (
            <ToolTip
              open={this.state.openToolTip}
              material={this.state.currentBar.name}
              total={this.state.currentBar.total}
              anchor={this.state.anchor}
            />
          )}
        </svg>
      </div>
    );
  }
}

export default BarChart;
