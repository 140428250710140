import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import greenPointsActions from "../../../redux/actions/greenPoints";
import utilsActions from "../../../redux/actions/utils";
import {
  AddCircleOutline as PlusIcon,
  Eco as EcoIcon,
  Publish as PublishIcon,
} from "@material-ui/icons/";

import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";
import EditGoalModal from "./EditGoalModal";
import EditMassiveGoals from "./EditMassiveGoals";
import ViewModal from "./ViewModal";
import Search from "./Search";
import GreenPointsList from "./GreenPointsList";

export default function Index() {
  const dispatch = useDispatch();
  useEffect(() => {
    const start = async () => {
      dispatch(greenPointsActions.fetchGreenPoints());
      dispatch(utilsActions.fetchMaterials());
    };
    start();
  }, []);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h4">
                <EcoIcon style={{ fontSize: 34, marginBottom: 6 }} /> Puntos
                GIRA
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<PlusIcon />}
                    size="small"
                    onClick={() =>
                      dispatch(greenPointsActions.setCreateModal(true))
                    }
                  >
                    Agregar punto gira
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<PublishIcon />}
                    size="small"
                    onClick={() =>
                      dispatch(
                        greenPointsActions.setEditMassiveGoalsModal(true)
                      )
                    }
                  >
                    Carga Masiva de metas
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
          <CreateModal />
          <DeleteModal />
          <EditGoalModal />
          <EditMassiveGoals />
          <ViewModal />
          <Search />
          <GreenPointsList />
        </CardContent>
      </Card>
    </Paper>
  );
}
