import React from "react";

export default function Paper({ size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={size}
      height={size}
      viewBox="0 0 555.2 731.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1_paper{fill:#4d4129}"}</style>
      <path
        d="M386.7 194l-.2-.2-37.3-45.3c-.8-1.6-2-3.1-3.6-4.2-2.2-1.6-4.7-2.5-7.5-2.5H236.3c-11.6 0-21 9.4-21 21v10.7h-35.9c-7.6 0-13.7 6.2-13.7 13.7v246.6c0 12.7 10.3 23 23 23h127.6c12.7 0 23-10.3 23-23V422h24.4c14.2 0 25.8-11.6 25.8-25.8V200.6c0-2.5-1-4.8-2.8-6.6z"
        fill="#ede9e6"
      />
      <path className="prefix__st1_paper" d="M169.3 409.3h167v13.3h-167z" />
      <path
        className="prefix__st1_paper"
        d="M316.3 456.8H188.7c-12.7 0-23-10.3-23-23V187.2c0-7.6 6.2-13.7 13.7-13.7h136.9c12.7 0 23 10.3 23 23v237.4c0 12.6-10.3 22.9-23 22.9zM179.4 180.7c-3.6 0-6.5 2.9-6.5 6.5v246.6c0 8.7 7.1 15.7 15.7 15.7h127.6c8.7 0 15.7-7.1 15.7-15.7V196.5c0-8.7-7.1-15.7-15.7-15.7H179.4z"
      />
      <path
        d="M363.7 418.4H239.4c-11.3 0-20.5-9.2-20.5-20.5V162.8c0-9.6 7.8-17.4 17.4-17.4h101.9c2 0 3.8.7 5.3 1.8 2.1 1.6 3.5 4.1 3.5 7l.1 21.9c.1 10.5 8.6 19 19.2 18.9l13.8-.1c3.2 0 5.8 2.6 5.8 5.8v195.6c0 12.2-9.9 22.1-22.2 22.1z"
        fill="#40b3c0"
      />
      <path
        d="M363.7 422H239.4c-13.3 0-24-10.8-24-24V162.8c0-11.6 9.4-21 21-21h101.9c2.7 0 5.3.9 7.5 2.5 3.1 2.4 4.9 5.9 4.9 9.8l.1 21.9c0 4.1 1.7 8 4.6 10.9 2.9 2.9 6.8 4.5 11 4.5l13.8-.1h.1c2.5 0 4.8 1 6.6 2.7 1.8 1.8 2.8 4.1 2.8 6.7v195.6c-.2 14.1-11.8 25.7-26 25.7zM236.3 149c-7.6 0-13.8 6.2-13.8 13.8V398c0 9.3 7.6 16.9 16.9 16.9h124.3c10.3 0 18.6-8.4 18.6-18.6V200.6c0-.6-.2-1.1-.6-1.6-.4-.4-1-.6-1.5-.6l-13.8.1h-.2c-6 0-11.6-2.3-15.9-6.5-4.3-4.2-6.7-9.9-6.7-16l-.1-21.9c0-1.6-.8-3.1-2.1-4.1-.9-.7-2-1-3.1-1h-102z"
        fill="#231f20"
      />
      <path
        transform="rotate(50.527 363.62 171.622)"
        className="prefix__st1_paper"
        d="M331.8 168h63.7v7.2h-63.7z"
      />
      <path
        className="prefix__st1_paper"
        d="M218.9 375.3h167v13.3h-167zM310.8 197.4H240c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8h70.8c2.7 0 4.8 2.2 4.8 4.8 0 2.6-2.1 4.8-4.8 4.8zM363.6 232.8H236.7c-2.7 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8h126.9c2.7 0 4.8 2.2 4.8 4.8s-2.1 4.8-4.8 4.8zM360.3 301.9H233.4c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8h126.9c2.7 0 4.8 2.2 4.8 4.8 0 2.7-2.1 4.8-4.8 4.8zM363.6 267.8h-88.2c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8h88.2c2.7 0 4.8 2.2 4.8 4.8.1 2.6-2.1 4.8-4.8 4.8zM262.4 267.8h-27.1c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8h27.1c2.7 0 4.8 2.2 4.8 4.8 0 2.6-2.2 4.8-4.8 4.8zM296.8 338.2h-59.9c-2.7 0-4.8-2.2-4.8-4.8s2.2-4.8 4.8-4.8h59.9c2.7 0 4.8 2.2 4.8 4.8s-2.1 4.8-4.8 4.8zM360.3 336.6h-51.4c-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8h51.4c2.7 0 4.8 2.2 4.8 4.8 0 2.6-2.1 4.8-4.8 4.8z"
      />
      <path
        className="prefix__st1_paper"
        d="M363.7 422H239.4c-13.3 0-24-10.8-24-24V162.8c0-11.6 9.4-21 21-21h101.9c2.7 0 5.3.9 7.5 2.5 3.1 2.4 4.9 5.9 4.9 9.8l.1 21.9c0 4.1 1.7 8 4.6 10.9 2.9 2.9 6.8 4.5 11 4.5l13.8-.1h.1c2.5 0 4.8 1 6.6 2.7 1.8 1.8 2.8 4.1 2.8 6.7v195.6c-.2 14.1-11.8 25.7-26 25.7zM236.3 149c-7.6 0-13.8 6.2-13.8 13.8V398c0 9.3 7.6 16.9 16.9 16.9h124.3c10.3 0 18.6-8.4 18.6-18.6V200.6c0-.6-.2-1.1-.6-1.6-.4-.4-1-.6-1.5-.6l-13.8.1h-.2c-6 0-11.6-2.3-15.9-6.5-4.3-4.2-6.7-9.9-6.7-16l-.1-21.9c0-1.6-.8-3.1-2.1-4.1-.9-.7-2-1-3.1-1h-102z"
      />
      <g>
        <path
          className="prefix__st1_paper"
          d="M209.4 562.3v1.3c-.1 3.9-2.3 6.5-5.6 6.5s-5.5-2.7-5.5-6.5v-37.8c0-4.4 2.3-6.7 6.4-6.7h9.5c1.9 0 4.4.4 6.7.9 4.4 1.1 7.7 3.6 10.2 7.7 1.7 3 2.8 6.6 2.8 10 0 6.9-3.9 13.4-9.9 16.4-2.6 1.3-4.8 1.7-9.2 1.7h-5.4v6.5zm4.3-17.1c3.3 0 4.3-.2 5.6-1 2.3-1.3 3.7-3.9 3.7-6.5 0-2.8-1.7-5.7-4.1-6.9-.9-.5-2.8-.8-5.2-.8h-4.4v15.1h4.4zM246.4 570.2c-6.8 0-12-5.7-12-12.9 0-7.5 5.5-13.2 12.6-13.2 2.4 0 4.6.7 7.2 2.1 0-3.7-1.2-5.1-4.3-5.1-.7 0-1.7.2-2.6.4l-2.3.7c-1.4.5-2.2.6-3 .6-2.3 0-3.8-1.7-3.8-4 0-3.9 4.7-6.5 11.6-6.5 4.7 0 8.9 1.4 11.3 4 2.2 2.3 3.1 5.8 3 11.6v15.5c0 2.6-.2 3.6-1.1 4.8-.9 1.3-2.3 2-3.9 2-2 0-3.4-.9-4.5-3.1-2.8 2.1-5.3 3.1-8.2 3.1zm3.1-18.5c-2.6 0-4.7 2.3-4.7 5.1 0 2.9 2.1 5.1 4.9 5.1 2.7 0 4.7-2.1 4.7-4.9-.1-3-2.2-5.3-4.9-5.3zM281.6 577.4v1.1c-.1 3.5-1.9 5.6-4.8 5.6s-4.9-2.2-4.9-5.6V539.7c0-2.4.1-3.4.7-4.4.7-1.4 2.2-2.1 3.9-2.1 2.3 0 3.8 1.1 4.6 3.6 3.1-2.8 5.9-3.9 9.9-3.9 9.7 0 16.8 8 16.8 18.7 0 10.6-7.1 18.6-16.6 18.6-3.8 0-6.7-1.1-9.7-3.6v10.8zm16.1-25.7c0-4.8-3.6-8.6-8.2-8.6-4.5 0-8.1 3.8-8.1 8.6 0 4.7 3.7 8.5 8.1 8.5 4.5-.1 8.2-3.9 8.2-8.5zM329.1 559.9c2 0 3.6-.6 5.6-2.1 1.2-.9 2-1.2 3.1-1.2 2.8 0 4.9 2.2 4.9 5 0 2.1-1.1 3.8-3.6 5.6-2.7 2-6.4 3.1-9.9 3.1-9.7 0-18-8.6-18-18.8 0-10.1 8.1-18.6 17.7-18.6 7.8 0 15.2 6 15.2 12.6 0 2.2-1.1 3.6-4 4.9l-16.1 7.4c1.4 1.5 3 2.1 5.1 2.1zm0-17.1c-4.2 0-7.7 3.3-8.1 7.6l12.4-5.5c-1-1.5-2.4-2.1-4.3-2.1zM349.1 525.8v-1.1c.1-3.4 2-5.6 4.9-5.6s4.8 2.3 4.8 5.6V564.1c.1 3.8-1.7 6.1-4.8 6.1-2.9 0-4.9-2.3-4.9-5.6V525.8z"
        />
      </g>
    </svg>
  );
}
