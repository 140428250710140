import React from "react";
import { TextField } from "@material-ui/core";

export default function Select({ options, onChange, label }) {
  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      margin="dense"
      select
      SelectProps={{ native: true }}
      onChange={onChange}
    >
      <option value="ALL">TODOS</option>

      {options.map((item, i) => (
        <option key={`op${i}-${item.code}`} value={item.code}>
          {item.name}
        </option>
      ))}
    </TextField>
  );
}
