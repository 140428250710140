import React, { useEffect } from "react";
import { Paper, Card, CardContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import checkListActions from "../../../redux/actions/checkList";
import reportsActions from "../../../redux/actions/checklistReports";
import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";
import ViewModal from "./ViewModal";
import CheckListList from "./CheckListList";
import ReportsList from "./ReportsList";
import CreateReportModal from "./CreateReportModal";
import DeleteReportModal from "./DeleteReportModal";
import Search from "./Search";
import Cards from "./Cards";
import Header from "./Header";

export default function Index() {
  const dispatch = useDispatch();
  const { selector } = useSelector((state) => state.checkLists);

  useEffect(() => {
    const exec = () => {
      dispatch(checkListActions.fetchCheckList());
      dispatch(reportsActions.fetchReports());
    };
    exec();
  }, []);
  return (
    <Paper>
      <Card>
        <CardContent>
          <Header />
          <Cards />
          {selector === "CHECKLIST" && <Search />}
          <CreateModal />
          <ViewModal />
          <DeleteModal />
          {selector === "CHECKLIST" && <CheckListList />}

          <CreateReportModal />
          <DeleteReportModal />
          {selector === "REPORTS" && <ReportsList />}
        </CardContent>
      </Card>
    </Paper>
  );
}
