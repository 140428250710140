import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";

import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

export default function ModalDelete() {
  const removals = useSelector((state) => state.removals);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (removals.dataModal) {
      setData({
        greenPoint: removals.dataModal.greenPointID?.name,
        user:
          removals.dataModal.userID?.name +
          " " +
          removals.dataModal.userID?.lastName +
          " (" +
          removals.dataModal.userID?.email +
          ")",
        createdAt: removals.dataModal.createdAt,
        datetimeRemoval: removals.dataModal.datetimeRemoval,
      });
    }
  }, [removals.dataModal]);

  const handleClose = () => {
    dispatch(removalsActions.setDataModal(null));
    dispatch(removalsActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    if (check) {
      dispatch(removalsActions.deleteRemoval(removals.dataModal._id));
      handleClose();
      setCheck(false);
    }
  };

  return (
    <Dialog open={removals.deleteModal} onClose={handleClose} scroll={"body"}>
      <DialogContent>
        <Header
          handleClose={handleClose}
          type="DELETE"
          title="Eliminar registro"
        />
        <Content data={data} check={check} setCheck={setCheck} />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleDelete={handleDelete}
          check={check}
        />
      </DialogActions>
    </Dialog>
  );
}
