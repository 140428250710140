import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import GreenPointInfo from "./GreenPoints";

export default function CreateModal() {
  const groups = useSelector((state) => state.groups);

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1">Nombre Contacto:</Typography>
          <Typography variant="body2">{groups.dataModal.contact}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Telefono contacto:</Typography>
          <Typography variant="body2">{groups.dataModal.phone}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1">Mail contacto:</Typography>
          <Typography variant="body2">{groups.dataModal.email}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">PG externos:</Typography>
          <Typography variant="body2">
            {groups.dataModal.externalsGreenPoints.length}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">KG Reciclados en el mes:</Typography>
          <Typography variant="body2">0</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            fecha de creación:
          </Typography>
          <Typography variant="body2" align="center">
            {new Date(groups.dataModal.createdAt).toLocaleDateString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <GreenPointInfo data={groups.dataModal.externalsGreenPoints} />
      </Grid>
    </Grid>
  );
}
