import axios from "axios";
import { saveAs } from "file-saver";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";

const fetchRemovals = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().removals;
      const response = await axios.get(
        "/removals/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search +
          "&selector=" +
          state.selector
      );
      dispatch({ type: "REMOVALS_FETCH", payload: response.data });
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postRemoval = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/removals/admin", formData);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_SUCCESS"));
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchRemovals = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/removals/admin", formData);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_SUCCESS"));
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteRemoval = (removalID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/removals/admin/" + removalID);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_SUCCESS"));
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const fetchCards = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/removals/admin/cards");
      dispatch({ type: "REMOVALS_SET_CARDS", payload: response.data });
    } catch (e) {}
  };
};

const setSelector = (selector) => {
  return async (dispatch) => {
    await dispatch({ type: "REMOVALS_SET_SELECTOR", payload: selector });
    await dispatch(fetchRemovals());
    return true;
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_DELETE_MODAL", payload: value });
};

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_VIEW_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_EDIT_MODAL", payload: value });
};

const setAprobeModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_APROBE_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "REMOVALS_SET_DATA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "REMOVALS_SET_SEARCH", payload: value });
    dispatch(fetchRemovals());
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "REMOVALS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchRemovals());
  };
};

const setCreateMassiveRemovalsModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "REMOVALS_SET_CREATE_MASSIVE_REMOVALS_MODAL",
      payload: value,
    });
};

const getTemplateForRemovals = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post(
        "/removals/generate-template-removals",
        formData,
        {
          responseType: "arraybuffer",
        }
      );
      const dirtyFileName = response.headers["content-disposition"];
      const regex =
        /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      const fileName = dirtyFileName.match(regex)[3];

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
      dispatch(
        notifiesActions.enqueueNotify("TEMPLATE_REMOVALS_CREATE_SUCCESS")
      );
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("TEMPLATE_REMOVALS_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postMassiveCreateRemovals = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post(
        "/removals/admin/massive-update-removals",
        formData
      );
      dispatch({
        type: "SET_MASSIVE_CREATE_REMOVALS_RESPONSE",
        payload: response.data,
      });
      dispatch(
        notifiesActions.enqueueNotify("REMOVALS_MASSIVE_CREATE_SUCCESS")
      );
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch({
        type: "SET_MASSIVE_CREATE_REMOVALS_RESPONSE",
        payload: err?.response?.data,
      });
      dispatch(appActions.setLoading(false));
      dispatch(notifiesActions.enqueueNotify("REMOVALS_MASSIVE_CREATE_FAIL"));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setMassiveCreateRemovalsResponse = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MASSIVE_CREATE_REMOVALS_RESPONSE",
      payload: value,
    });
  };
};

export default {
  fetchRemovals,
  postRemoval,
  patchRemovals,
  deleteRemoval,
  fetchCards,
  setSelector,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setAprobeModal,
  setDataModal,
  setViewModal,
  setSearch,
  setPagination,
  setCreateMassiveRemovalsModal,
  getTemplateForRemovals,
  postMassiveCreateRemovals,
  setMassiveCreateRemovalsResponse,
};
