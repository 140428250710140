import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@material-ui/core";
import Card from "./Card";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

export default function Cards() {
  const theme = useTheme();
  const dashboard = useSelector((state) => state.dashboard);

  const [data, setData] = useState({
    removals: 0,
    greenPoints: 0,
    kilograms: 0,
    users: 0,
    externalUsers: 0,
    externalGreenPoints: 0,
  });

  const convertNumber = (quantity) => {
    if (Math.round(quantity).toString().length < 4) {
      return Math.round(quantity) + " Kg";
    } else {
      return Math.round(quantity / 1000) + " Ton";
    }
  };

  useEffect(() => {
    if (dashboard.data) {
      setData({
        ...data,
        removals: dashboard.data.removals?.length,
        greenPoints: dashboard.data?.greenPoints,
        kilograms: dashboard.data?.materialsTemp.total,
        users: dashboard.data?.users,
        externalUsers: dashboard.data?.externalUsers,
        externalGreenPoints: dashboard.data?.externalGreenPoints,
      });
    }
  }, [dashboard.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Card
          title="REGISTROS"
          value={
            <NumberFormat
              value={Math.round(data.removals)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
            />
          }
          background={theme.palette.successGradient.main}
          link="/admin/removals"
        />
      </Grid>
      <Grid item xs={3}>
        <Card
          title="CANTIDAD RECICLADA"
          value={convertNumber(data.kilograms)}
          background={theme.palette.warningGradient.main}
          link="/admin"
        />
      </Grid>
      <Grid item xs={3}>
        <Card
          title="PUNTOS GIRA ACTIVOS"
          value={
            <NumberFormat
              value={Math.round(data.greenPoints)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
            />
          }
          background={theme.palette.successGradient.main}
          link="/admin/greenPoints"
        />
      </Grid>
      <Grid item xs={3}>
        <Card
          title="MONITORES ACTIVOS"
          value={
            <NumberFormat
              value={Math.round(data.users)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
            />
          }
          background={theme.palette.successGradient.main}
          link="/admin/users"
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
