import React, { useEffect, useState } from "react";
import { Typography, useTheme } from "@material-ui/core";
import {
  ComposedChart,
  AreaChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

export default function Charts() {
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const dashboard = useSelector((state) => state.dashboard);
  const [density, setDensity] = useState([]);

  useEffect(() => {
    if (dashboard.data) {
      setDensity(
        dashboard.data.densityPerDay.map((Retiros, i) => {
          return {
            name: i + 1,
            Retiros,
          };
        })
      );
    }
  }, [dashboard.data]);

  return (
    <ResponsiveContainer
      width="100%"
      aspect={app.isMobile ? 4.0 / 4.0 : 4.0 / 3.0}
    >
      <ComposedChart
        data={density}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 40,
        }}
      >
        <XAxis dataKey="name"  textAnchor="end"  interval={2} verticalAnchor="start" />
        {!app.isMobile && (
          <YAxis
            label={{
              value: "Registros",
              angle: -90,
              position: "insideLeft",
            }}
          />
        )}
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
        <Tooltip />
        <Area
          dataKey={"Registros"}
          type="monotone"
          stroke={theme.palette.primary.main}
          strokeWidth={1}
          fillOpacity={1}
          fill={theme.palette.primary.main}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
