import React, { Fragment, useState, useEffect } from "react";
import { Grid, TextField, Button, Typography, Avatar } from "@material-ui/core";
import { Lock, ExpandLess, Check } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { useSelector, useDispatch } from "react-redux";
import Jimp from 'jimp';

import profileActions from "../../../redux/actions/profile";
import functions from "../../../resources/functions";

const initState = {
  newPassword: "",
  newPasswordRpt: "",
  changePassword: false,
  name: "",
  lastName: "",
  email: "",
  phone: "",
  urlPhoto: "",
  image: "",
};

export default function ContentEdit({
  imageSrc,
  setImageSrc,
  isUploadingImage,
  setIsUploadingImage,
}) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const [formData, setFormData] = useState(initState);
  const [error, setError] = useState(false);
  const [helperTextInfo, setHelperTextInfo] = useState("");
  const [helperTextPass, setHelperTextPass] = useState("");

  useEffect(() => {
    if (profile.edit) {
      setFormData({ ...initState, ...profile.user });
    } else {
      setFormData(initState);
    }
  }, [profile.user, profile.edit]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setHelperTextInfo("");
        setHelperTextPass("");
      }, 1500);
    }
  }, [error]);

  useEffect(() => {
    if (formData.urlPhoto) {
      setImageSrc(`${formData.urlPhoto}?20130910043254`);
    }
  }, [formData.urlPhoto]);

  const handleSuccess = async () => {
    if (!formData.name || !formData.phone || !formData.lastName) {
      setHelperTextInfo("Debes ingresar este campo.")
      setError(true);
    } else if (formData.changePassword &&
      (formData.newPassword !== formData.newPasswordRpt)) {
      setHelperTextPass("Tus contraseñas no coinciden.")
      setError(true);
    } else if (formData.changePassword && formData.newPassword.length < 6) {
      setHelperTextPass("Tu contraseña debe tener al menos 6 caracteres")
      setError(true);
    } else {
      let payload = formData;
      if (formData.newPassword) {
        payload = {
          ...payload,
          password: formData.newPassword,
        };
      }
      await dispatch(profileActions.patchUser(payload));
      await dispatch(profileActions.setEditProfile(false));
    }
  };

  const handleSetImages = (event) => {
    setIsUploadingImage(true);
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .resize(1062, 1280)
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
            setIsUploadingImage(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Editar información</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid item container xs={12} justify="center" alignItems="center" direction="column">
          <Avatar
            alt="User photo"
            src={imageSrc}
            style={{ height: '300px', width: '300px' }}
          />
        </Grid>
        <Grid item xs={12} >
          <input
            id="raised-button-file-2"
            type="file"
            accept="image/jpeg,image/jpg"
            name="image"
            style={{ display: "none", width: "100%" }}
            onChange={handleSetImages}
          />
          <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginTop: 8,
                height: 40,
              }}
              fullWidth
              startIcon={<CloudUploadOutlinedIcon />}
              component="span"
            >
              Foto
          </Button>
          </label>
        </Grid>
      </Grid>
      <Grid item xs={6} >
        <TextField
          value={formData.name}
          onChange={(e) =>
            setFormData({
              ...formData,
              name: functions.formatOnlyLetters(e.target.value)
            })
          }
          variant="outlined"
          margin="dense"
          label="Nombre"
          fullWidth
          error={error && !formData.name}
          helperText={!formData.name && helperTextInfo}
        />
        <TextField
          value={formData.lastName}
          onChange={(e) =>
            setFormData({
              ...formData,
              lastName: functions.formatOnlyLetters(e.target.value)
            })
          }
          variant="outlined"
          margin="dense"
          label="Apellido"
          fullWidth
          error={error && !formData.lastName}
          helperText={!formData.lastName && helperTextInfo}
        />
        <TextField
          value={formData.email}
          disabled
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          variant="outlined"
          margin="dense"
          label="Email"
          fullWidth
        />
        <TextField
          value={formData.phone}
          onChange={(e) =>
            setFormData({
              ...formData,
              phone: functions.formatPhone(e.target.value),
            })
          }
          variant="outlined"
          margin="dense"
          label="Teléfono"
          fullWidth
          error={error && !formData.phone}
          helperText={!formData.phone && helperTextInfo}
        />
        <Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<Lock />}
          endIcon={formData.changePassword && <ExpandLess />}
          onClick={() =>
            setFormData({
              ...formData,
              changePassword: !formData.changePassword,
            })
          }
        >
          Cambiar contraseña
        </Button>
        {formData.changePassword && (
          <Fragment>
            <Grid item xs={6} sm={4} md={3}>
              <TextField
                value={formData.newPassword}
                onChange={(e) =>
                  setFormData({ ...formData, newPassword: e.target.value })
                }
                variant="outlined"
                margin="dense"
                label="Nueva contraseña"
                fullWidth
                type="password"
                error={error && (formData.newPassword.length < 6 ||
                  formData.newPassword !== formData.newPasswordRpt)}
                helperText={(formData.newPassword.length < 6 ||
                  formData.newPassword !== formData.newPasswordRpt) && helperTextPass}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <TextField
                value={formData.newPasswordRpt}
                onChange={(e) =>
                  setFormData({ ...formData, newPasswordRpt: e.target.value })
                }
                variant="outlined"
                margin="dense"
                label="Repite nueva contraseña"
                fullWidth
                type="password"
                error={error &&
                  (formData.newPassword.length < 6 ||
                    formData.newPassword !== formData.newPasswordRpt)
                }
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
      <Grid item xs={12} align="right">
        <Button
          startIcon={<Check />}
          color="primary"
          variant="contained"
          size="small"
          disabled={isUploadingImage}
          onClick={() => handleSuccess()}
        >
          Guardar
        </Button>
      </Grid>
    </Grid >
  );
}
