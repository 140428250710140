import React from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import {
  AddCircleOutline as PlusIcon,
  LocalShipping as TruckIcon,
} from "@material-ui/icons/";
import removalsActions from "../../../redux/actions/removalsGreenPoint";
import goalsGreenPointActions from "../../../redux/actions/goalsGreenPoint";
import { useDispatch, useSelector } from "react-redux";

export default function Removals() {
  const dispatch = useDispatch();
  const removalsGreenPoint = useSelector(
    (state) => state.removalsGreenPoint.removals
  );

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4">
          <TruckIcon style={{ fontSize: 34, marginBottom: 6 }} /> Registros en
          mi Punto GIRA
        </Typography>
        {removalsGreenPoint[0] ? (
          <Typography variant="body1">
            {removalsGreenPoint[0].greenPointID.name}
          </Typography>
        ) : (
          <Typography>
            <Box fontStyle="oblique" m={1}>
              {" "}
              Ningún Punto GIRA asignado{" "}
            </Box>
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Button
          style={{ backgroundColor: "#FFC900" }}
          variant="contained"
          size="large"
          startIcon={<PlusIcon />}
          onClick={() => {
            dispatch(goalsGreenPointActions.fetchGoalsProgress());
            dispatch(removalsActions.setCreateModal(true));
          }}
        >
          Ingresar nuevo registro
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
