import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import userActions from "../../../../redux/actions/users";
import utilsActions from "../../../../redux/actions/utils";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

const formDataInitialState = {
  name: "",
  lastName: "",
  email: "",
  password: "",
  entityID: "EMPTY",
  type: "GREENPOINT",
  phone: "",
  actived: "DEACTIVED",
  ruc: "",
  image: "",
};

export default function CreateModal() {
  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const users = useSelector((state) => state.users);

  const [error, setError] = useState(false);
  const [dataSelectEntity, setDataSelectEntity] = useState([]);
  const [formData, setFormData] = useState(formDataInitialState);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [helperTextInfo, setHelperTextInfo] = useState("");
  const [helperTextPass, setHelperTextPass] = useState("");

  useEffect(() => {
    const exec = () => {
      dispatch(utilsActions.fetchGreenPoints());
    };
    exec();
  }, []);

  useEffect(() => {
    setDataSelectEntity(utils.greenPoints);
  }, [formData.type, utils.greenPoints, users.createModal]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setHelperTextInfo("");
        setHelperTextPass("");
      }, 1500);
    }
  }, [error]);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const reset = () => {
    setFormData(formDataInitialState);
    setDataSelectEntity([]);
    setError(false);
    setImageSrc("");
  };

  const handleSuccess = () => {
    if (
      formData.entityID === "EMPTY" ||
      !validateEmail(formData.email) ||
      formData.name === "" ||
      formData.phone === "" ||
      formData.phone.length < 16 ||
      formData.lastName === "" ||
      formData.ruc === "" ||
      formData.ruc.length < 10
    ) {
      setHelperTextInfo("Este campo es requerido")
      setError(true);
      if (formData.password.length < 8) {
        setHelperTextPass("Tu contraseña debe contener al menos 8")
        setError(true);
      }
    }
    else {
      dispatch(userActions.assignUserToEntity(formData));
      reset();
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(userActions.setCreateModal(false));
    dispatch(userActions.setDataModal(null));
    reset();
  };

  return (
    <Dialog
      open={users.createModal}
      onClose={() => handleClose()}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Header handleClose={handleClose} type="CREATE" title="Crear usuario" />
        <Content
          validateEmail={validateEmail}
          error={error}
          dataSelectEntity={dataSelectEntity}
          formData={formData}
          setFormData={setFormData}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          setIsUploadingImage={setIsUploadingImage}
          helperTextInfo={helperTextInfo}
          helperTextPass={helperTextPass}
        />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          isUploadingImage={isUploadingImage}
        />
      </DialogActions>
    </Dialog>
  );
}
