import axios from "axios";
import { saveAs } from "file-saver";
import appActions from "./app";
import notifiesActions from "./notifies";
import formatQuery from "../../helpers/formatQuery";

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNALS_INDICATORS_SET_VIEW_MODAL_GOALS",
      payload: value,
    });
};

const setUpdateModalByMaterial = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNALS_INDICATORS_SET_UPDATE_MODAL_GOAL_BY_MATERIALS",
      payload: value,
    });
};

const setUpdateModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNALS_INDICATORS_SET_UPDATE_MODAL_GOAL",
      payload: value,
    });
};

/* const toggleExportDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_OPEN_EXPORT_DATA_MODAL", payload: value });
}; */

/* const setUpdateData = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_UPDATE_MODAL_GOAL_DATA", payload: value });
}; */

/* const setFilterDate = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_DATE", payload: value });
}; */

/* const setFilterFormat = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_FORMAT", payload: value });
}; */

/* const setFilterCity = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_CITY", payload: value });
}; */

/* const setFilterZone = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_ZONE", payload: value });
}; */

const updateFiltersGreenPoint = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNALS_INDICATORS_UPDATE_FILTERS_GREENPOINTS",
      payload: value,
    });
    dispatch(getDetails());
  };
};

const updateFilters = (value) => {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    await dispatch({
      type: "EXTERNALS_INDICATORS_UPDATE_FILTERS",
      payload: value,
    });
    if (user.type === "ADMIN_EXTERN" || user.type === "ADMIN") {
      dispatch(fetchGreenPoints());
    }
    dispatch(getDetails());
  };
};

/* const updateExportFilters = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "INDICATORS_UPDATE_EXPORT_FILTERS",
      payload: value,
    });
    dispatch(fetchGreenPointsExport());
  };
}; */

/* const fetchGreenPointsExport = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { exportFilters } = getState().indicators;
      if (exportFilters.formats.length > 0 && exportFilters.zones.length > 0) {
        const response = await axios.get(`/indicators/greenpoints/export`, {
          params: {
            formats: formatQuery(exportFilters.formats),
            zones: formatQuery(exportFilters.zones),
          },
        });
        dispatch({
          type: "INDICATORS_UPDATE_EXPORT_GREENPOINTS",
          payload: response.data,
        });
      }

      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
}; */

const fetchGoals = (type = "BY_MATERIAL", search = "") => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { user } = getState().auth;
      const { filters, pagination } = getState().externalsIndicators;
      let URL = "";
      let groupID = "";
      if (user.type === "ADMIN_EXTERN") {
        groupID = user.groupID._id;
      }
      if (user.type === "ADMIN") {
        groupID = filters.groupID;
      }
      if (type === "BY_GREEN_POINT" || type === "BY_MATERIAL_AND_GREEN_POINT") {
        URL = `/external-goals/${pagination.page}/${pagination.rows}?type=${type}&search=${search}&groupID=${groupID}`;
      }
      if (type === "TOTAL_MONTH") {
        URL = `/external-goals/0/0?type=${type}&groupID=${groupID}`;
      }
      if (type === "BY_MATERIAL") {
        URL = `/external-goals/0/0?type=${type}&groupID=${groupID}`;
      }

      const response = await axios.get(URL);

      dispatch({ type: "EXTERNALS_GOALS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const fetchGreenPointWithoutGoal = () => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      const { filters } = getState().externalsIndicators;
      let groupID = "";
      if (user.type === "ADMIN_EXTERN") {
        groupID = user.groupID._id;
      }
      if (user.type === "ADMIN") {
        groupID = filters.groupID;
      }

      dispatch(appActions.setLoading(true));
      const response = await axios.get(
        `/external-goals/missing?groupID=${groupID}`
      );
      dispatch({ type: "EXTERNALS_GOALS_MISSING", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch({ type: "EXTERNALS_GOALS_MISSING", payload: [] });
      dispatch(appActions.setLoading(false));
    }
  };
};

const fetchGreenPointWithoutMaterial = () => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      const { filtersMissingMaterials, filters } =
        getState().externalsIndicators;
      let URL = "";

      if (user.type === "ADMIN_EXTERN") {
        URL = `/external-goals/material-missing/${filtersMissingMaterials.pagination.page}/${filtersMissingMaterials.pagination.rows}?search=${filtersMissingMaterials.search}&group=${user.groupID._id}`;
      }
      if (user.type === "ADMIN") {
        URL = `/external-goals/material-missing/${filtersMissingMaterials.pagination.page}/${filtersMissingMaterials.pagination.rows}?search=${filtersMissingMaterials.search}&group=${filters.groupID}`;
      }

      dispatch(appActions.setLoading(true));
      const response = await axios.get(URL);
      dispatch({
        type: "EXTERNALS_GOALS_MISSING_BY_MATERIALS",
        payload: response.data,
      });

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch({
        type: "EXTERNALS_GOALS_MISSING_BY_MATERIALS",
        payload: {
          pagesInfo: [],
          currentPages: [],
        },
      });
      dispatch(appActions.setLoading(false));
    }
  };
};

const setSearchmissingMaterials = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNALS_GOALS_MISSING_BY_MATERIALS_SET_SEARCH",
      payload: value,
    });
    dispatch(fetchGreenPointWithoutMaterial());
  };
};

const setPaginationmissingMaterials = (value) => {
  console.log(value);
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNALS_GOALS_MISSING_BY_MATERIALS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchGreenPointWithoutMaterial());
  };
};

const fetchGreenPoints = () => {
  return async (dispatch, getState) => {
    try {
      console.log("fetch gp");
      dispatch(appActions.setLoading(true));
      const { filters } = getState().externalsIndicators;

      const response = await axios.get(
        `/external-indicators/externals-greenpoints`,
        {
          params: {
            city: filters.city === "ALL" ? "" : filters.city,
            format: filters.format === "ALL" ? "" : filters.format,
            zone: filters.zone === "ALL" ? "" : filters.zone,
            groupID: filters.groupID,
          },
        }
      );
      dispatch({
        type: "EXTERNALS_INDICATORS_FETCH_GREENPOINTS",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const updateGoal = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));

      const { user } = getState().auth;
      const { filters } = getState().externalsIndicators;

      let finalData = {
        ...data,
      };
      if (user.type === "ADMIN_EXTERN") {
        finalData["groupID"] = user.groupID._id;
      }
      if (user.type === "ADMIN") {
        finalData["groupID"] = filters.groupID;
      }

      const response = await axios.put(`/external-goals`, finalData);
      dispatch({ type: "EXTERNALS_GOALS_UPDATE", payload: response.data });
      dispatch(fetchGreenPointWithoutGoal());
      dispatch(fetchGreenPointWithoutMaterial());
      dispatch(notifiesActions.enqueueNotify("GOAL_UPDATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const setPagination = (value, goalType, search = "") => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNALS_GOALS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchGoals(goalType, search));
  };
};

const getDetails = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { filters } = getState().externalsIndicators;
      const { user } = getState().auth;
      let result = "";

      if (user.type === "GREENPOINT_EXTERN") {
        console.log("asd");
        result = await axios.get(
          `/external-indicators/externals-greenpoints/details`,
          {
            params: {
              differenceMonths:
                filters.differenceMonths === 0 ? 1 : filters.differenceMonths,
              initialDate: filters.initialDate,
              endDate: filters.endDate,
              entityID: user.externalEntityID._id,
              userID: user._id,
              groupID: user.groupID._id,
            },
          }
        );
      }

      if (
        filters.greenPoints.length > 0 &&
        (user.type === "ADMIN_EXTERN" || user.type === "ADMIN")
      ) {
        console.log("fetch details");
        result = await axios.get(`/external-indicators/details`, {
          params: {
            differenceMonths:
              filters.differenceMonths === 0 ? 1 : filters.differenceMonths,
            initialDate: filters.initialDate,
            endDate: filters.endDate,
            greenPointID: formatQuery(filters.greenPoints),
            city: filters.city === "ALL" ? "" : filters.city,
            format: filters.format === "ALL" ? "" : filters.format,
            zone: filters.zone === "ALL" ? "" : filters.zone,
            groupID: filters.groupID,
          },
        });
      }
      const payload = {
        allGreenPointProgress: result.data.allGreenPointProgress || [],
        materialProgress: result.data.materialProgress || [],
        monthlyProgress: result.data.monthlyProgress || [],
      };
      dispatch({
        type: "EXTERNALS_INDICATORS_FETCH_DETAILS",
        payload,
      });

      dispatch(appActions.setLoading(false));
    } catch (err) {
      const payload = {
        allGreenPointProgress: [],
        materialProgress: [],
        monthlyProgress: [],
      };
      dispatch({
        type: "EXTERNALS_INDICATORS_FETCH_DETAILS",
        payload,
      });
      dispatch(appActions.setLoading(false));
    }
  };
};

export default {
  setViewModal,
  updateFilters,
  setUpdateModal,
  setUpdateModalByMaterial,
  fetchGreenPoints,
  updateFiltersGreenPoint,
  fetchGoals,
  setPagination,
  fetchGreenPointWithoutGoal,
  fetchGreenPointWithoutMaterial,
  setSearchmissingMaterials,
  setPaginationmissingMaterials,
  updateGoal,
  getDetails,
};
