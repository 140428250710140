import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Button,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Check, Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import checkListActions from "../../../../redux/actions/checkList";
import usersActions from "../../../../redux/actions/users";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import ContentEdit from "./ContentEdit";

const initState = {
  greenPointID: "",
  date: new Date(),
  userId: "",
  total: 0,
  observations: "",
  questions: [
    {
      totalPoints: 20,
      title: "Presencia / Imagen de Monitor",
      items: [
        {
          item: "a",
          question: "Monitor se encuentra en el Punto de Reciclaje",
          maxValue: 7,
          score: 0,
        },
        {
          item: "b",
          question: "Monitor bien uniformado",
          maxValue: 7,
          score: 0,
        },
        {
          item: "c",
          question: "Monitor usa Equipo de Protección Personal",
          maxValue: 6,
          score: 0,
        },
      ],
    },
    {
      totalPoints: 60,
      title: "Operatividad del Punto Gira",
      items: [
        {
          item: "a",
          question: `Indicadores de Gestión ( Debe mostrar 2 indicadores: 1 Porcentaje de Recuperación 2. Número de clientes gestionados por semana.)`,
          maxValue: 20,
          score: 0,
        },
        {
          item: "b",
          question: `Tiene iniciativa de mostrar resultados y acciones`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "c",
          question: `SOLES (Internamente el Punto de Reciclaje cuenta con: Señalización, orden, informativos, presencia de indicadores actualizados visibles, afiches informativos).`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "d",
          question: `Gestión Monitor (Verificar el procedimiento operativo del monitor, envío de materiales, no material acumulado sin justificación)`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "d",
          question: `Limpieza / Organización dentro del Punto/ Imagen/ Mantenimiento`,
          maxValue: 10,
          score: 0,
        },
      ],
    },
    {
      totalPoints: 20,
      title: "Iniciativa recuperación de materiales",
      items: [
        {
          item: "a",
          question: `Buenas Prácticas`,
          maxValue: 10,
          score: 0,
        },
        {
          item: "b",
          question: `Plan de Acción (Identificar oportunidades mejora)`,
          maxValue: 10,
          score: 0,
        },
      ],
    },
  ],
};

export default function ViewModal() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const checkList = useSelector((state) => state.checkLists);
  const { loading } = useSelector((state) => state.app);

  const [formData, setFormData] = useState(initState);
  const [helperTextInfo, setHelperTextInfo] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(usersActions.fetchUsersGreenpoints());
  }, []);

  useEffect(() => {
    if (checkList.dataModal) {
      setFormData({
        ...checkList.dataModal,
        greenPointID: checkList.dataModal.greenPointID._id,
        userId: checkList.dataModal.userId._id,
      });
    }
  }, [checkList.dataModal, checkList.viewModal]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
        setHelperTextInfo("");
      }, 1500);
    }
  }, [error]);

  const handleClose = () => {
    dispatch(checkListActions.setViewModal(false));
    dispatch(checkListActions.setEditMode(false));
    dispatch(checkListActions.setDataModal(null));
  };

  const handleSuccess = () => {
    if (!formData.greenPointID || !formData.userId) {
      setHelperTextInfo("Este campo es requerido");
      setError(true);
    } else {
      dispatch(checkListActions.patchEvaluation(formData));
      setFormData(initState);
      handleClose();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={checkList.viewModal}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <DialogContent>
        <Box>
          <Header
            title="Ver Evaluación GIRA"
            type="VIEW"
            handleClose={handleClose}
            loading={loading}
          />
          {checkList.editMode ? (
            <ContentEdit
              formData={formData}
              setFormData={setFormData}
              helperTextInfo={helperTextInfo}
              error={error}
            />
          ) : (
            <Content />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {checkList.editMode ? (
          <ButtonGroup variant="contained" size="small">
            <Button
              color="secondary"
              startIcon={<Close />}
              disabled={loading}
              onClick={() => handleClose()}
            >
              Salir (Sin guardar los cambios)
            </Button>
            <Button
              color="primary"
              startIcon={<Check />}
              disabled={loading}
              onClick={() => handleSuccess()}
            >
              Guardar
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup variant="contained" size="small">
            <Button
              color="secondary"
              startIcon={<Close />}
              disabled={loading}
              onClick={() => handleClose()}
            >
              Salir
            </Button>
          </ButtonGroup>
        )}
      </DialogActions>
    </Dialog>
  );
}
