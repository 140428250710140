import axios from "axios";
import appActions from "./app";
import authActions from "./auth";

const fetchReports = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().reportsGreenPoint;
      const response = await axios.get(
        "/reports/greenPoint/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({ type: "REPORTS_GREENPOINT_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "REPORTS_GREENPOINT_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchReports());
  };
};

export default {
  fetchReports,
  setPagination,
};
