const defaultState = {
  materials: [],
  material: null,
  filters: {
    pagination: {
      rows: 7,
      page: 0,
    },
    search: "",
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "MATERIALS_FETCH": {
      return { ...state, materials: payload };
    }
    case "MATERIALS_SET_SEARCH": {
      const newStateFilters = {
        ...state.filters,
        ...payload,
      };
      return { ...state, filters: newStateFilters };
    }
    case "MATERIALS_SET_PAGINATION": {
      const { pagination } = state.filters;
      const newStateFilters = {
        ...state.filters,
        pagination: {
          ...pagination,
          ...payload,
        },
      };
      return { ...state, filters: newStateFilters };
    }
    default:
      return state;
  }
}

export default reducer;
