import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import externalsMaterialsActions from "../../../redux/actions/externalsMaterials";

export default function Search() {
  const [searchApproved, setSearchApproved] = useState("");
  const [searchPending, setSearchPending] = useState("");
  const externalsMaterials = useSelector((state) => state.externalsMaterials);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (externalsMaterials.selector === "APPROVED") {
      dispatch(externalsMaterialsActions.setSearchApproved(searchApproved));
    }
    if (externalsMaterials.selector === "PENDING") {
      dispatch(externalsMaterialsActions.setSearchPending(searchPending));
    }
  };

  useEffect(() => {
    setSearchApproved(externalsMaterials.approvedFilters.search);
    setSearchPending(externalsMaterials.pendingFilters.search);
  }, [
    externalsMaterials.approvedFilters.search,
    externalsMaterials.pendingFilters.search,
  ]);

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      spacing={2}
      style={{ marginBottom: 10 }}
    >
      <Grid item>
        <TextField
          variant="outlined"
          margin="dense"
          placeholder="nombre"
          value={
            externalsMaterials.selector === "APPROVED"
              ? searchApproved
              : searchPending
          }
          onChange={(e) =>
            externalsMaterials.selector === "APPROVED"
              ? setSearchApproved(e.target.value)
              : setSearchPending(e.target.value)
          }
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ height: 40, marginTop: 3 }}
          onClick={() => handleSubmit()}
          startIcon={<SearchIcon />}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}
