const formatPhone = (phone) => {
  phone = String(phone).replace(/\D/g, "");
  let aux = "";
  for (let i = 0; i < phone.length; i++) {
    if (i === 0) {
      aux = "+";
    }
    if (i === 3 || i === 5 || i === 8) {
      aux += " ";
    }
    aux += phone[i];
    if (i === 11) {
      break;
    }
  }
  return aux;
};

const formatOnlyLetters = (letters) => {
  return String(letters).replace(/[0-9]/g, "")
}

const formatRuc = (ruc) => {
  ruc = String(ruc).replace(/\D/g, "");
  let finalRuc = "";
  for(let i = 0; i < ruc.length; i++){
    finalRuc += ruc[i];
    if (i === 9) {
      break;
    }
  }
  return finalRuc;
}
export default { formatPhone, formatOnlyLetters, formatRuc };
