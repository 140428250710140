import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Grid,
  Box,
  Typography,
  makeStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import externalsIndicatorsAction from "../../../../../redux/actions/externalsIndicators";
import Icon from "../../../../Shared/Icon";
import Search from "./Search";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "greenPoint",
    numeric: false,
    disablePadding: false,
    label: "Punto GIRA",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  accordion_root: {
    marginBottom: 0,
  },
}));

export default function EnhancedTable() {
  const { goal, pagination, goalMissingList } = useSelector(
    (state) => state.externalsIndicators
  );
  const app = useSelector((state) => state.app);
  const utils = useSelector((state) => state.utils);
  const [expanded, setExpanded] = React.useState("");
  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datetimeRemoval");
  const [dense] = useState(false);
  const [search, setSearch] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, page) => {
    if (page > pagination.page) {
      if (goal.length === pagination.rows) {
        dispatch(
          externalsIndicatorsAction.setPagination(
            { ...pagination, page },
            "BY_MATERIAL_AND_GREEN_POINT"
          )
        );
      }
    } else {
      dispatch(
        externalsIndicatorsAction.setPagination(
          { ...pagination, page },
          "BY_MATERIAL_AND_GREEN_POINT"
        )
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      externalsIndicatorsAction.setPagination(
        {
          ...pagination,
          rows: parseInt(event.target.value, 10),
        },
        "BY_MATERIAL_AND_GREEN_POINT"
      )
    );
  };

  const emptyRows = pagination.rows - goal.length;

  return (
    <div className={classes.root}>
      <Box>
        <Search
          value={search}
          onChange={setSearch}
          onSubmit={() => {
            dispatch(
              externalsIndicatorsAction.setPagination(
                pagination,
                "BY_MATERIAL_AND_GREEN_POINT",
                search
              )
            );
          }}
        />
      </Box>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={12}
            />
            <TableBody>
              {!app.loading &&
                stableSort(goal, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <StyledTableCell align="left" width="100%">
                          <Accordion
                            square
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            classes={{ root: classes.accordion_root }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              id={`panel${index}d-header`}
                            >
                              <Typography> {row.greenPoint.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                display: "flex",
                              }}
                            >
                              <Grid container spacing={2}>
                                {Object.keys(row.metrics)
                                  .filter((key) => key !== "TOTAL")
                                  .map((key) => (
                                    <Grid
                                      item
                                      xs={3}
                                      key={`${key}--accordion--body`}
                                    >
                                      <Box
                                        width="100%"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          border: "1px solid #FBFBFB",
                                          borderRadius: "5px",
                                          padding: "10px 0px",
                                        }}
                                      >
                                        <Grid container>
                                          <Grid xs={12} item>
                                            <Box
                                              width="100%"
                                              display="flex"
                                              justifyContent="center"
                                            >
                                              <Icon
                                                glyph={
                                                  utils.materials[key]?.iconName
                                                }
                                                size={90}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography align="center">
                                              {utils.materials[key].name}
                                            </Typography>
                                          </Grid>
                                          <Grid xs={12} item>
                                            <Typography
                                              align="center"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {`${row.metrics[key]} Kg`}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </StyledTableCell>
                      </TableRow>
                    );
                  }
                )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[12, 20]}
          component="div"
          count={-1}
          rowsPerPage={pagination.rows}
          page={pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
