import React, { useEffect } from "react";
import { Paper, makeStyles, Card, CardContent } from "@material-ui/core";
import GreenPointsList from "./GreenPointsList";
import Cards from "./Cards";
import CreateModal from "./CreateModal";
import ViewModal from "./ViewModal";
import DeleteModal from "./DeleteModal";
import Search from "./Search";
import Header from "./Header";
import externalsGreenPointActions from "../../../redux/actions/externalsGreenPoint";
import { useDispatch } from "react-redux";
import EditGoalModal from "./EditGoalModal";
import EditMassiveGoals from "./EditMassiveGoals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ExternalGreenPoints() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const exec = () => {
      dispatch(externalsGreenPointActions.fetchGreenPointsAdminExtern());
      dispatch(externalsGreenPointActions.fetchCardsAdminExtern());
    };
    exec();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Cards />
          <Search />
          <CreateModal />
          <ViewModal />
          <DeleteModal />
          <EditGoalModal />
          <EditMassiveGoals />
          <GreenPointsList />
        </CardContent>
      </Card>
    </Paper>
  );
}
