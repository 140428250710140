import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import ScrollBar from "./ScrollBar";

const useStyles = makeStyles({
  root: {
    position: "relative",
    "& .scroll-selector": {
      fill: "lightblue",
      marginTop: "50px",
      opacity: "0.5",
      cursor: "ew-resize",
    },
    "& .x-axis": {
      "& .tick": {
        "& text": {
          textAnchor: "end",
          transform: "rotate(-50deg)",
        },
      },
    },
  },
});

export default function Charts() {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const { data } = useSelector((state) => state.externalsIndicators);
  const [chartData, setChartData] = useState([]);
  const [wd, setWd] = useState(0);

  const getColor = (value) => {
    if (value >= 1) return "#21AD57";
    if (value >= 0.99) return "#DD405A";
    return "#DD405A";
  };

  const validatePercentage = (value) => {
    if (isNaN(value)) {
      return 0;
    }
    if (value > 100) {
      return 1;
    }
    return value;
  };

  useEffect(() => {
    const initialData = data?.allGreenPointProgress
      .map((item) => ({
        name: item.name,
        puntos: validatePercentage(
          item.removals["TOTAL"] / item.goals["TOTAL"]
        ),
        color: getColor(
          validatePercentage(item.removals["TOTAL"] / item.goals["TOTAL"])
        ),
      }))
      .sort((a, b) => b.puntos - a.puntos);
    const chartLength = initialData.length;

    //Formating hidden x-axes to 30 greenpoints by filters
    if (chartLength < 22) {
      for (let index = 0; index < 20 - chartLength; index++) {
        initialData.push({
          name: `GreenPointEmpty__${index}`,
          puntos: 0,
          color: "#ffffff",
        });
      }
    }
    setChartData(initialData);
    setWd(document.getElementById("scroll-bar").offsetWidth);
  }, [data.allGreenPointProgress]);

  return (
    <Box className={classes.root}>
      <div id="scroll-bar" style={{ minWidth: "100%" }}>
        {data.allGreenPointProgress.length > 0 && !app.loading && (
          <ScrollBar data={chartData} width={wd} isMobile={app.isMobile} />
        )}
      </div>
    </Box>
  );
}
