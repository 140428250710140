const defaultState = {
  edit: false,
  user: null,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "PROFILE_FETCH": {
      return { ...state, user: payload };
    }
    case "PROFILE_SET_EDIT": {
      return { ...state, edit: payload };
    }

    default:
      return state;
  }
}

export default reducer;
