import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import groupsActions from "../../../../redux/actions/groups";
import externalGreenPointsActions from "../../../../redux/actions/externalsGreenPoint";
import Content from "./Content";
import GreenPointsForm from "./GreenPointsForm";
import Buttons from "./Buttons";
import Header from "../../../Shared/Modals/Header";

export default function CreateModal() {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups);
  const [error, setError] = useState(false);

  const [userError, setUserError] = useState(false);
  const [helperTextInfo, setHelperTextInfo] = useState("");
  const [helperTextPass, setHelperTextPass] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    contact: "",
  });

  const [userForm, setUserForm] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    groupID: "EMPTY",
    type: "ADMIN_EXTERN",
    phone: "",
    actived: "ACTIVED",
    ruc: "",
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const reset = () => {
    setFormData({
      name: "",
      address: "",
      phone: "",
      email: "",
      contact: "",
    });
    setUserForm({
      name: "",
      lastName: "",
      email: "",
      password: "",
      groupID: "EMPTY",
      type: "ADMIN_EXTERN",
      phone: "",
      actived: "ACTIVED",
      ruc: "",
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClose = () => {
    dispatch(groupsActions.setCreateModal(false));
    dispatch(groupsActions.resetGreenPointList());
    reset();
  };

  const handleSuccess = () => {
    if (
      !formData.name ||
      !formData.phone ||
      !formData.contact ||
      !formData.email
    ) {
      setError(true);
      return;
    }
    if (
      !validateEmail(userForm.email) ||
      userForm.name === "" ||
      userForm.phone === "" ||
      userForm.phone.length < 16 ||
      userForm.lastName === "" ||
      userForm.ruc === "" ||
      userForm.ruc.length < 10
    ) {
      setHelperTextInfo("Este campo es requerido");
      setUserError(true);
      if (userForm.password.length < 8) {
        setHelperTextPass("Tu contraseña debe contener al menos 8");
        setUserError(true);
      }
      return;
    }
    dispatch(
      groupsActions.postGroup({
        groupData: formData,
        userAdminData: userForm,
      })
    );
    dispatch(externalGreenPointsActions.fetchCards());
    handleClose();
  };

  return (
    <Dialog open={groups.createModal} maxWidth="md" fullWidth>
      <DialogContent>
        <Header
          handleClose={handleClose}
          title="Crear Grupo Externo"
          type="CREATE"
        />
        <Content formData={formData} setFormData={setFormData} error={error} />
        <GreenPointsForm
          formData={userForm}
          setFormData={setUserForm}
          error={userError}
          helperTextInfo={helperTextInfo}
          helperTextPass={helperTextPass}
          validateEmail={validateEmail}
        />
      </DialogContent>
      <DialogActions>
        <Buttons handleSuccess={handleSuccess} handleClose={handleClose} />
      </DialogActions>
    </Dialog>
  );
}
