import React, { useEffect, useState } from "react";
import { Typography, useTheme } from "@material-ui/core";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

export default function Charts() {
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const dashboard = useSelector((state) => state.dashboard);
  const utils = useSelector((state) => state.utils);
  const [materials, setMaterials] = useState({ current: [] });

  useEffect(() => {
    if (dashboard.data) {
      setMaterials({
        current: Object.keys(dashboard.data.materialsTemp)
          .map((key) => {
            return {
              key,
              name: utils.materials[key]?.name,
              Actual: dashboard.data.materialsTemp[key],
            };
          })
          .filter((material) => material.key !== "total"),
      });
    }
  }, [dashboard.data]);

  return (
    <ResponsiveContainer
      width="100%"
      aspect={app.isMobile ? 4.0 / 4.0 : 4.0 / 3.0}
    >
      <ComposedChart
        data={materials.current}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 40,
        }}
      >
        <XAxis dataKey="name" angle={-30} textAnchor="end"  interval={0} verticalAnchor="start"/>
        {!app.isMobile && (
          <YAxis
            label={{
              value: "Kg de material",
              angle: -90,
              position: "insideLeft",
            }}
          />
        )}
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
        <Tooltip />
        <Bar dataKey={"Actual"} fill={theme.palette.primary.main} unit=" Kg" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
