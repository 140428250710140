import React, { useState, useEffect } from "react";
import { Grid, useTheme } from "@material-ui/core";
import Card from "./Card";
import { useSelector } from "react-redux";

export default function Cards() {
  const theme = useTheme();
  const dashboard = useSelector((state) => state.dashboard);

  const [data, setData] = useState({
    removals: 0,
    greenPoints: 0,
    kilograms: 0,
    users: 0,
  });

  const convertNumber = (quantity) => {
    if (Math.round(quantity).toString().length < 4) {
      return Math.round(quantity) + " Kg";
    } else {
      return Math.round(quantity / 1000) + " Ton";
    }
  };

  useEffect(() => {
    if (dashboard.data) {
      setData({
        ...data,
        removals: dashboard.data.removals?.length,
        greenPoints: dashboard.data?.greenPoints,
        kilograms: dashboard.data?.materialsTemp.total,
        users: dashboard.data?.users,
      });
    }
  }, [dashboard.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Card
          title="REGISTROS"
          value={data.removals}
          background={theme.palette.primaryGradient.main}
          link="/greenPoint/removals"
        />
      </Grid>
      <Grid item xs={4}>
        <Card
          title="CANTIDAD RECICLADA"
          value={convertNumber(data.kilograms)}
          background={theme.palette.primaryGradient.main}
          link="/greenPoint"
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
