import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core";

export default function Chart({ total }) {
  const theme = useTheme();
  const removals = useSelector((state) => state.removals);
  const utils = useSelector((state) => state.utils);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (removals.dataModal && total) {
      setData(
        removals.dataModal.materials.map((material) => {
          return {
            name: utils.all_materials_deleted[material.material].name,
            color: utils.all_materials_deleted[material.material]?.color,
            Porcentaje: Math.round((material.quantity * 100) / total),
          };
        })
      );
    }
  }, [removals.dataModal, total]);

  return (
    <ResponsiveContainer width="100%" aspect={4.0 / 4.0}>
      <PieChart width={400}>
        <Pie
          data={data}
          innerRadius={50}
          outerRadius={80}
          fill={theme.palette.primary.main}
          paddingAngle={2}
          dataKey="Porcentaje"
        >
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={_entry.color} />
          ))}
        </Pie>
        <Legend verticalAlign="bottom" />
        <Tooltip formatter={(value) => value + " %"} />
      </PieChart>
    </ResponsiveContainer>
  );
}

Chart.propTypes = {
  total: PropTypes.number.isRequired,
};
