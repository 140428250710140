import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import ScrollBar from "./ScrollBar";

const useStyles = makeStyles({
  root: {
    position: "relative",
    "& .scroll-selector": {
      fill: "lightblue",
      marginTop: "50px",
      opacity: "0.5",
      cursor: "ew-resize",
    },
    "& .x-axis": {
      "& .tick": {
        "& text": {
          textAnchor: "end",
          transform: "rotate(-50deg)",
        },
      },
    },
  },
});

export default function Charts() {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const dashboard = useSelector((state) => state.dashboard);
  const [materials, setMaterials] = useState({ current: [] });
  const [wd, setWd] = useState(0);

  useEffect(() => {
    if (dashboard.data) {
      setMaterials({
        current: dashboard.data.removalGreenPoint.map((item) => {
          return {
            name: item.name,
            puntos: Math.round(item.kg * 100) / 100,
            color: "#1F608D",
          };
        }),
      });
      setWd(document.getElementById("scroll-bar").offsetWidth);
    }
  }, [dashboard.data]);

  return (
    <Box className={classes.root}>
      <div id="scroll-bar">
        {materials.current.length > 0 && (
          <>
            <ScrollBar
              data={materials.current}
              width={wd}
              isMobile={app.isMobile}
            />
          </>
        )}
      </div>
    </Box>
  );
}
