import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import indicatorsAction from "../../../../../redux/actions/indicators";
import Search from "./Search";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array.length > 0) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
}

const headCells = [
  {
    id: "greenPoint",
    numeric: false,
    disablePadding: false,
    label: "Punto GIRA",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: false,
    label: "Cantidad de material a recuperar",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const { goal, pagination } = useSelector((state) => state.indicators);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datetimeRemoval");
  const [dense] = useState(false);
  const [search, setSearch] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, page) => {
    if (page > pagination.page) {
      if (goal.length === pagination.rows) {
        dispatch(
          indicatorsAction.setPagination(
            { ...pagination, page },
            "BY_GREEN_POINT"
          )
        );
      }
    } else {
      dispatch(
        indicatorsAction.setPagination(
          { ...pagination, page },
          "BY_GREEN_POINT"
        )
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      indicatorsAction.setPagination(
        {
          ...pagination,
          rows: parseInt(event.target.value, 10),
        },
        "BY_GREEN_POINT"
      )
    );
  };

  const emptyRows = pagination?.rows - goal?.length || 0;

  return (
    <>
      {goal?.length > 0 ? (
        <div className={classes.root}>
          <Search
            value={search}
            onChange={setSearch}
            onSubmit={() => {
              dispatch(
                indicatorsAction.setPagination(
                  pagination,
                  "BY_GREEN_POINT",
                  search
                )
              );
            }}
          />
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={12}
                />
                <TableBody>
                  {!app.loading &&
                    stableSort(goal, getComparator(order, orderBy))?.map(
                      (row, index) => (
                        <TableRow hover tabIndex={-1} key={index}>
                          <StyledTableCell align="left">
                            {row.greenPoint.name}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {Math.round(row.quantity * 100) / 100 + " Kg"}
                          </StyledTableCell>
                        </TableRow>
                      )
                    )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={"Filas por página"}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to === -1 ? count : to} de ${
                  count !== -1 ? count : `más de ${to}`
                }`
              }
              rowsPerPageOptions={[12, 20]}
              component="div"
              count={-1}
              rowsPerPage={pagination.rows}
              page={pagination.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      ) : (
        "Sin Resultados"
      )}
    </>
  );
}
