import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const Content = ({ formData, setFormData, error, type }) => {
  const [addSpecificGoal, setAddSpecificGoal] = useState(false);

  useEffect(() => {
    if (type === "CREATE") {
      setFormData({ ...formData, specificGoal: 0 });
    }
    if (
      (type === "UPDATE" && type === "APPROVE") ||
      formData.specificGoal > 0
    ) {
      setAddSpecificGoal(true);
    }
  }, [addSpecificGoal]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <TextField
          label="Nombre Material"
          variant="outlined"
          margin="dense"
          placeholder="Ej: Tetra Pack"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          fullWidth
          error={error && !formData.name}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={addSpecificGoal}
                color="primary"
                onChange={() => setAddSpecificGoal(!addSpecificGoal)}
              />
            }
            label={"¿Agregar meta especifica para todos los puntos gira?"}
          />
        </Box>
      </Grid>
      {addSpecificGoal ? (
        <Grid item sm={12}>
          <TextField
            label="Meta Especifica"
            variant="outlined"
            margin="dense"
            type={"number"}
            placeholder="Ej: Tetra Pack"
            value={formData.specificGoal}
            disabled={formData.state === "READY" && type !== "APPROVE"}
            onChange={(e) =>
              setFormData({ ...formData, specificGoal: e.target.value })
            }
            fullWidth
            error={error && !formData.specificGoal}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Content;
