import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import {
  AddCircleOutline as PlusIcon,
  LibraryAddCheck,
} from "@material-ui/icons/";
import checkListActions from "../../../redux/actions/checkList";
import checkListReportsActions from "../../../redux/actions/checklistReports";
import { useDispatch, useSelector } from "react-redux";

export default function Removals() {
  const dispatch = useDispatch();
  const { selector } = useSelector((state) => state.checkLists);

  const handleButton = () => {
    if (selector === "CHECKLIST") {
      dispatch(checkListActions.setCreateModal(true));
    }
    if (selector === "REPORTS") {
      dispatch(checkListReportsActions.setCreateModal(true));
    }
  };

  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <LibraryAddCheck style={{ fontSize: 34, marginBottom: 6 }} />{" "}
          {selector === "CHECKLIST" && "Evaluaciones GIRA"}{" "}
          {selector === "REPORTS" && "Reportes de evaluaciones GIRA"}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          size="small"
          startIcon={<PlusIcon />}
          onClick={handleButton}
        >
          {selector === "CHECKLIST"
            ? "Responder evaluaciones GIRA"
            : "Crear Reporte"}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
