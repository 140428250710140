import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  makeStyles,
} from "@material-ui/core";
import {
  Save as SaveIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

const useStyles = makeStyles({
  accordion_root: {
    marginBottom: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordion_summary: {
    backgroundColor: "#f8f8f9",
  },
});

export default function GreenPointAccordion({
  row,
  index,
  changeMetric,
  checkValues,
  onSave,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel0");
  const utils = useSelector((state) => state.utils);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const validNumber = (num) => {
    if (isNaN(num)) return 0;
    if (num * 1 < 0) return 0;
    return parseFloat(num);
  };

  return (
    <Box>
      <Accordion
        key={`accordion-green-point-${index}`}
        square
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
        classes={{ root: classes.accordion_root }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`panel${index}d-header`}
          className={classes.accordion_summary}
        >
          <Typography> {row.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Grid container spacing={2}>
              {Object.keys(row.metrics_).map((key) => (
                <Grid item xs={6} md={4} lg={3} key={`material_input-${key}`}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    type="number"
                    error={row.metrics_[key] === 0}
                    value={row.metrics_[key].toString()}
                    disabled={!utils.all_materials_external[key].canBeRemoved}
                    onChange={(e) =>
                      changeMetric(
                        validNumber(e.target.value),
                        row.greenPointID,
                        key
                      )
                    }
                    label={utils.all_materials_external[key].name}
                    InputProps={{
                      endAdornment: <InputAdornment>KG</InputAdornment>,
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={6} md={4} lg={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="number"
                  value={row.quantity}
                  label="Total"
                  InputProps={{
                    endAdornment: <InputAdornment>KG</InputAdornment>,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
        <AccordionActions>
          <Button
            color="primary"
            startIcon={<SaveIcon />}
            disabled={!checkValues(row.greenPointID)}
            onClick={() => onSave(row.greenPointID)}
            variant="contained"
            size="small"
          >
            Actualizar
          </Button>
        </AccordionActions>
      </Accordion>
    </Box>
  );
}
