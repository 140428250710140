import React, { useEffect, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import StateCard from "./Card";
import { useSelector } from "react-redux";

export default function Cards() {
  const theme = useTheme();
  const externalGreenPoint = useSelector((state) => state.externalGreenPoint);

  const [greenPoints, setGreenPoints] = useState("...");

  useEffect(() => {
    if (externalGreenPoint.cards) {
      setGreenPoints(externalGreenPoint.cards.greenPoints);
    }
  }, [externalGreenPoint.cards]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6}>
        <StateCard
          title="Puntos GIRA"
          value={greenPoints ? greenPoints : "..."}
          background={theme.palette.successGradient.main}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
