import React, { useState, useEffect } from "react";
import { Box, useTheme, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SvgWrapper, ThemeProvider } from "@nivo/core";
import { ResponsivePie } from "@nivo/pie";
import { BoxLegendSvg } from "@nivo/legends";

const PieChartMaterial = () => {
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const dashboard = useSelector((state) => state.externalReports);
  const utils = useSelector((state) => state.utils);
  const [materials, setMaterials] = useState({ current: [] });

  const MATERIAL_ORDERS = {
    PET: 0,
    FLEX_PLASTIC: 1,
    RIG_PLASTIC: 2,
    FLEX_FOAM: 3,
    DRINK_CAP: 4,
    TETRAPAK: 5,
    PAPER: 6,
    PAPERBOARD: 7,
    GLASS: 8,
    CANS: 9,
  };

  useEffect(() => {
    if (dashboard.data) {
      const total = dashboard.data.materialsTemp.total;
      setMaterials({
        current: Object.keys(dashboard.data.materialsTemp)
          .map((key) => {
            const value =
              dashboard.data.materialsTemp[key] === 0
                ? 0
                : Number(
                    (dashboard.data.materialsTemp[key] * 100) / total
                  ).toFixed(2);
            return {
              key,
              id: utils.materials[key]?.name,
              name: utils.materials[key]?.name,
              color: utils.materials[key]?.color,
              kg: Math.round(dashboard.data.materialsTemp[key] * 100) / 100,
              value,
              padding: 16,
              order: MATERIAL_ORDERS[key],
            };
          })
          .sort((a, b) => a.kg > b.kg + a.kg)
          .filter((material) => material.key !== "total"),
      });
    }
  }, [dashboard.data]);

  return (
    <Box style={{ backgroundColor: theme.palette.neutral.main }}>
      <Box p={1}>
        <Typography variant="h5" color="textSecondary" align="center">
          Porcentaje total de recuperación por material
        </Typography>
      </Box>
      <Box position="relative">
        <Box width="100%" height={app.isMobile ? "400px" : "480px"}>
          <ResponsivePie
            data={materials.current}
            margin={{
              top: 40,
              right: 80,
              bottom: app.isMobile ? 20 : 80,
              left: 80,
            }}
            startAngle={-180}
            padAngle={0}
            colorBy="id"
            colors={{ datum: "data.color" }}
            borderColor={{ from: "borderColor" }}
            borderWidth={0}
            enableRadialLabels={!app.isMobile}
            radialLabel={(e) => `${e.data.name} (${e.data.kg} kg)`}
            radialLabelsTextColor={theme.palette.neutral.text}
            radialLabelsLinkDiagonalLength={app.isMobile ? 2 : 15}
            radialLabelsLinkColor={{ from: "color" }}
            sliceLabelsRadiusOffset={0.6}
            sliceLabelsTextColor="#000000"
            sliceLabel={(e) => `${e.data.value}%`}
            sortByValue={true}
            radialLabelsSkipAngle={11}
            sliceLabelsSkipAngle={12}
            animate={{
              duration: 200,
            }}
            motionStiffness={90}
            motionDamping={15}
          />
        </Box>
        <Box
          style={{
            position: app.isMobile ? "relative" : "absolute",
            top: "20px",
            left: "20px",
          }}
        >
          <ThemeProvider>
            <SvgWrapper
              height={250}
              width={400}
              margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
            >
              <BoxLegendSvg
                data={materials.current
                  .sort((a, b) => a.order - b.order)
                  .map((item) => ({
                    ...item,
                    label: `${item.name} (${item.value}%)`,
                  }))}
                anchor={app.isMobile ? "top" : "top-left"}
                direction="column"
                padding={10}
                itemWidth={10}
                itemHeight={20}
                itemsSpacing={0}
                symbolSize={20}
                itemDirection={"left-to-right"}
              />
            </SvgWrapper>
          </ThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PieChartMaterial);
