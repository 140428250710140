import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

export default function ModalRange({
  isOpen,
  onClose,
  filters,
  handleChangeInitialDate,
  handleChangeEndDate,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose} scroll={"body"}>
      <DialogTitle>Rango de Fecha</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              fullWidth
              label="Fecha inicial"
              value={filters.initialDate}
              disableFuture
              onChange={handleChangeInitialDate}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              fullWidth
              label="Fecha final"
              value={filters.endDate}
              onChange={handleChangeEndDate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
