import React from "react";
import { Grid, Typography, Button, LinearProgress } from "@material-ui/core";
import {
  Close,
  Visibility as Vis,
  AddBox,
  Delete,
  Edit,
} from "@material-ui/icons";
import PropTypes from "prop-types";

const style = { fontSize: 26, marginBottom: 5 };
export default function Header({ handleClose, title, type, loading }) {
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h5">
          {type === "VIEW" && <Vis style={style} />}
          {type === "DELETE" && <Delete style={style} />}
          {type === "CREATE" && <AddBox style={style} />}
          {type === "UPDATE" && <Edit style={style} />}
          {" " + title}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          style={{ position: "absolute", top: 17, right: 0 }}
        >
          <Close />
        </Button>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <LinearProgress
            color="primary"
            style={{ height: 1, marginBottom: 16, marginTop: 16 }}
          />
        ) : (
          <hr />
        )}
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
