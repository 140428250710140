import React from "react";

export default function Can({ size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={size}
      height={size}
      viewBox="0 0 555.2 731.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".prefix__st1_cans{fill:#4d4129}.prefix__st2_cans{fill:#40b3c0}"}
      </style>
      <path
        d="M174.5 377.5v50.4c0 19.7 32.7 35.1 74.4 35.1 40.8 0 73-14.8 74.4-33.8 33.9-3.5 57.4-17.2 57.4-33.8V223.2c0-.3 0-.6-.1-.9l-8-33.7c-.6-17.2-29.4-30.6-66-30.6s-65.4 13.4-66 30.6l-8 33.7c-.1.3-.1.6-.1.9v120.1c-33.5 3.4-58 17.2-58 34.2z"
        fill="#ede9e6"
      />
      <path
        className="prefix__st2_cans"
        d="M235.6 345.4V223.2c0 17.3 31.8 31.3 71 31.3s71-14 71-31.3V395.4c0 15.2-24.6 27.9-57.3 30.7v-48.6s7.4-26-84.7-32.1z"
      />
      <path
        className="prefix__st1_cans"
        d="M320.3 429.5h.2c35.4-3.1 60.1-17.1 60.1-34.1V223.2c0-1.9-1.4-3.4-3-3.4-1.7 0-3 1.5-3 3.4 0 13.5-27.3 27.9-68 27.9s-68-14.4-68-27.9c0-1.9-1.4-3.4-3-3.4-1.7 0-3 1.5-3 3.4v122.2c0 1.9 1.4 3.4 3 3.4 1.7 0 3-1.5 3-3.4V237.2c11.3 12.3 37.1 20.7 68 20.7s56.7-8.4 68-20.7v158.2c0 12.9-23.4 24.7-54.5 27.4-1.7.1-2.9 1.8-2.8 3.6.1 1.7 1.5 3.1 3 3.1z"
      />
      <path
        className="prefix__st1_cans"
        d="M320.3 415.4h.2c35.4-3.1 60.1-17.1 60.1-34.1v-15.8c0-1.9-1.4-3.4-3-3.4-1.7 0-3 1.5-3 3.4v15.8c0 12.9-23.4 24.7-54.5 27.4-1.7.1-2.9 1.8-2.8 3.6.1 1.8 1.5 3.1 3 3.1zM174.5 377.5c0-19.7 32.7-35.1 74.4-35.1s74.4 15.4 74.4 35.1-32.7 35.1-74.4 35.1-74.4-15.4-74.4-35.1zm25.5-18.6c-11.8 5.2-18.6 12-18.6 18.6 0 6.6 6.8 13.4 18.6 18.6 13 5.7 30.4 8.9 48.9 8.9s35.9-3.2 48.9-8.9c11.8-5.2 18.6-12 18.6-18.6 0-6.6-6.8-13.4-18.6-18.6-13-5.7-30.4-8.9-48.9-8.9s-35.9 3.2-48.9 8.9z"
      />
      <path
        className="prefix__st1_cans"
        d="M183.7 377.5c0-16.3 33.6-25.1 65.3-25.1s65.3 8.8 65.3 25.1-33.6 25.1-65.3 25.1-65.3-8.7-65.3-25.1zm21.8-12.7c-10.1 3.6-16.4 8.5-16.4 12.8 0 4.3 6.3 9.2 16.4 12.8 11.6 4.1 27 6.3 43.4 6.3s31.9-2.3 43.4-6.3c10.1-3.6 16.4-8.5 16.4-12.8 0-4.3-6.3-9.2-16.4-12.8-11.6-4.1-27-6.3-43.4-6.3-16.4-.1-31.8 2.2-43.4 6.3z"
      />
      <path
        className="prefix__st2_cans"
        d="M320 427.9c0 17.3-31.8 31.3-71 31.3s-71-14-71-31.3v-50.4c0 17.3 31.8 31.3 71 31.3s71-14 71-31.3v50.4z"
      />
      <path
        className="prefix__st1_cans"
        d="M174.5 427.9v-50.4c0-2.1 1.5-3.8 3.4-3.8s3.4 1.7 3.4 3.8c0 6.6 6.8 13.4 18.6 18.6 13 5.7 30.4 8.9 48.9 8.9s35.9-3.2 48.9-8.9c11.8-5.2 18.6-12 18.6-18.6 0-2.1 1.5-3.8 3.4-3.8s3.4 1.7 3.4 3.8v50.4c0 19.7-32.7 35.1-74.4 35.1s-74.2-15.4-74.2-35.1zm74.5-15.3c-30.3 0-55.9-8.1-67.6-20.1v35.4c0 6.6 6.8 13.4 18.6 18.6 13 5.7 30.4 8.9 48.9 8.9s35.9-3.2 48.9-8.9c11.8-5.2 18.6-12 18.6-18.6v-35.4c-11.6 12-37.1 20.1-67.4 20.1z"
      />
      <path
        className="prefix__st1_cans"
        d="M240.2 375.5h15.7c2.2 0 4.3-1.1 5.7-3 1.4-1.9 1.9-4.4 1.4-6.8l-2.2-10.7c-.3-1.6-1.8-2.6-3.2-2.3-1.5.4-2.4 2-2 3.6l2.2 10.7c.2.8-.1 1.4-.4 1.7-.2.3-.7.8-1.4.8h-15.7c-.8 0-1.2-.5-1.4-.8-.2-.3-.5-.9-.4-1.7l2.2-10.7c.3-1.6-.6-3.2-2-3.6-1.5-.4-2.9.6-3.2 2.3l-2.2 10.7c-.5 2.4 0 4.9 1.4 6.8 1.2 1.9 3.3 3 5.5 3z"
      />
      <path
        className="prefix__st1_cans"
        d="M243.7 367.1h8.9c1.5 0 2.7-1.3 2.7-3s-1.2-3-2.7-3h-8.9c-1.5 0-2.7 1.3-2.7 3 0 1.6 1.2 3 2.7 3zM232.6 223.2c0-.3 0-.6.1-.9l8-34c.4-1.6 1.8-2.7 3.3-2.5 1.5.2 2.7 1.6 2.7 3.3 0 5.9 6 11.9 16.5 16.5 11.5 5.1 27 7.9 43.4 7.9s31.9-2.8 43.4-7.9c10.5-4.6 16.5-10.6 16.5-16.5 0-1.7 1.1-3.1 2.7-3.3 1.5-.2 2.9.8 3.3 2.5l8 34c.1.3.1.6.1.9 0 19.4-32.5 34.7-74 34.7s-74-15.3-74-34.7zm136.3-23.5c-8.9 12.1-33.1 20.6-62.3 20.6-29.2 0-53.4-8.5-62.3-20.6l-5.7 23.9c.5 13.4 27.7 27.5 67.9 27.5s67.4-14.2 67.9-27.5l-5.5-23.9z"
      />
      <path
        className="prefix__st1_cans"
        d="M240.6 189.2c0-17.5 29-31.1 66-31.1s66 13.7 66 31.1c0 17.5-29 31.1-66 31.1s-66-13.7-66-31.1zm22.6-16.5c-10.5 4.6-16.5 10.6-16.5 16.5s6 11.9 16.5 16.5c11.5 5.1 27 7.9 43.4 7.9s31.9-2.8 43.4-7.9c10.5-4.6 16.5-10.6 16.5-16.5s-6-11.9-16.5-16.5c-11.5-5.1-27-7.9-43.4-7.9s-31.8 2.8-43.4 7.9z"
      />
      <path
        className="prefix__st1_cans"
        d="M253.9 193c0-14 23.2-24.6 53.9-24.6s53.9 10.6 53.9 24.6c0 14-23.2 24.6-53.9 24.6s-53.9-10.5-53.9-24.6zm4.8 0c0 9.1 20.1 19.2 49 19.2s49-10.1 49-19.2-20.1-19.2-49-19.2c-28.8 0-49 10.1-49 19.2z"
      />
      <path
        className="prefix__st1_cans"
        d="M303.9 218.3c8.4 0 15.3-7.6 15.3-16.9 0-9.3-6.9-16.9-15.3-16.9h-.3l-30 2.9c-6.9.1-12.5 6.3-12.5 14s5.7 14 12.6 14c1.7 0 3-1.5 3-3.4s-1.4-3.4-3-3.4c-3.6 0-6.5-3.2-6.5-7.2s2.9-7.2 6.5-7.2h.3l30-2.9c5 .1 9.1 4.6 9.1 10.2s-4.1 10.2-9.2 10.2c-1.7 0-3 1.5-3 3.4-.1 1.7 1.3 3.2 3 3.2z"
      />
      <path
        className="prefix__st1_cans"
        d="M295.6 209.4c1.7 0 3-1.5 3-3.4v-8.1c0-1.9-1.4-3.4-3-3.4s-3 1.5-3 3.4v8.1c-.1 1.9 1.3 3.4 3 3.4z"
      />
      <g>
        <path
          className="prefix__st1_cans"
          d="M227.2 562.1h1.2c3.4.1 5.6 2.1 5.6 5.3 0 3.2-2.3 5.3-5.6 5.3h-19.1c-4.2-.1-6.5-2.4-6.5-6.7v-37.8c.1-3.9 2.3-6.4 5.6-6.4s5.5 2.6 5.5 6.4v33.9h13.3zM248.2 573.4c-6.8 0-12-5.7-12-12.9 0-7.5 5.5-13.2 12.6-13.2 2.4 0 4.6.7 7.2 2.1 0-3.7-1.2-5.1-4.3-5.1-.7 0-1.7.2-2.6.4l-2.3.7c-1.4.5-2.2.6-3 .6-2.3 0-3.8-1.7-3.8-4 0-3.9 4.7-6.5 11.6-6.5 4.7 0 8.9 1.4 11.3 4 2.2 2.3 3.1 5.8 3 11.6v15.5c0 2.6-.2 3.6-1.1 4.8-.9 1.3-2.3 2-3.9 2-2 0-3.4-.9-4.5-3.1-2.9 2.1-5.3 3.1-8.2 3.1zm3-18.5c-2.6 0-4.7 2.3-4.7 5.1 0 2.9 2.1 5.1 4.9 5.1 2.7 0 4.7-2.1 4.7-4.9-.1-3-2.1-5.3-4.9-5.3zM275.3 531.5v-1.1c.1-3.5 2-5.6 4.9-5.6 2.8 0 4.8 2.3 4.8 5.6v6.5h2.1c2.9.1 4.8 1.8 4.8 4.5s-2 4.5-4.8 4.5H285v21.6c-.1 3.4-2 5.6-4.9 5.6-2.8 0-4.8-2.3-4.8-5.6V546h-.5c-3.1 0-5-1.7-5-4.5 0-3 2-4.6 5.5-4.5v-5.5zM304.8 573.4c-6.8 0-12-5.7-12-12.9 0-7.5 5.5-13.2 12.6-13.2 2.4 0 4.6.7 7.2 2.1 0-3.7-1.2-5.1-4.3-5.1-.7 0-1.7.2-2.6.4l-2.3.7c-1.4.5-2.2.6-3 .6-2.3 0-3.8-1.7-3.8-4 0-3.9 4.7-6.5 11.6-6.5 4.7 0 8.9 1.4 11.3 4 2.2 2.3 3.1 5.8 3 11.6v15.5c0 2.6-.2 3.6-1.1 4.8-.9 1.3-2.3 2-3.9 2-2 0-3.4-.9-4.5-3.1-2.8 2.1-5.2 3.1-8.2 3.1zm3.1-18.5c-2.6 0-4.7 2.3-4.7 5.1 0 2.9 2.1 5.1 4.9 5.1 2.7 0 4.7-2.1 4.7-4.9-.1-3-2.1-5.3-4.9-5.3zM354 543.2c0 2.3-2 4.5-4.4 4.5-1.2 0-2.3-.4-4.1-1.5-2.3-1.4-3-1.7-4-1.7s-1.8.8-1.8 1.7c0 1.7 1.4 2.5 5.8 3.7 3.3.9 4.7 1.4 6.3 2.3 3 1.7 4.7 5 4.7 8.8 0 7.4-5.7 12.6-14 12.6-7.6 0-14.5-4.8-14.5-9.9 0-2.7 2-5.1 4.4-5.1 1.6 0 3.1.8 5.1 2.6 2.8 2.6 3.9 3.1 5.7 3.1s3.3-1.1 3.3-2.7c0-1.3-.9-2-3.2-2.6-2.7-.7-6.3-2-8.4-3-3.2-1.7-5.3-5.2-5.3-8.9 0-4.2 2.7-8.2 6.8-9.9 1.5-.6 3.5-1 5.9-1 6.3-.1 11.7 3.2 11.7 7z"
        />
      </g>
    </svg>
  );
}
