import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, Button, ButtonGroup } from "@material-ui/core";
import {
  BarChart as IndicatorIcon,
  AddCircleOutline as PlusIcon,
  ImportExport as ExportIcon,
} from "@material-ui/icons";
import indicatorsAction from "../../../redux/actions/indicators";

export default function Header() {
  const dispatch = useDispatch();
  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <IndicatorIcon style={{ fontSize: 34, marginBottom: 6 }} />{" "}
          Indicadores de gestión
        </Typography>
      </Grid>
      <Grid item>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<PlusIcon />}
            onClick={() => dispatch(indicatorsAction.setViewModal(true))}
          >
            Ver metas
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<ExportIcon />}
            onClick={() =>
              dispatch(indicatorsAction.toggleExportDataModal(true))
            }
          >
            Exportar a excel
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
