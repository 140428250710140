import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import materialsActions from "../../../redux/actions/materials";

export default function Search() {
  const [search, setSearch] = useState("");
  const materials = useSelector((state) => state.materials);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(materialsActions.setSearch({ search }));
  };

  useEffect(
    () => setSearch(materials.filters.search),
    [materials.filters.search]
  );

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      spacing={2}
      style={{ marginBottom: 10 }}
    >
      <Grid item>
        <TextField
          variant="outlined"
          margin="dense"
          placeholder="nombre"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ height: 40, marginTop: 3 }}
          onClick={() => handleSubmit()}
          startIcon={<SearchIcon />}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}
