import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Close, BorderColor, Check } from "@material-ui/icons";
import Geocode from "react-geocode";

import Content from "./Content";
import ContentEdit from "./ContentEdit";
import Header from "../../../Shared/Modals/Header";
import externalsGreenPointActions from "../../../../redux/actions/externalsGreenPoint";
import cloudupload from "../../../../resources/images/cloudupload.png";
import Cities from "../../../../resources/cities.json";

export default function CreateModal() {
  const externalGreenPoint = useSelector((state) => state.externalGreenPoint);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [cities] = useState([]);
  const [provincias] = useState([]);
  const [imageSrc, setImageSrc] = useState(cloudupload);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [data, setData] = useState({
    name: "",
    address: "",
    addressTemp: "",
    description: "",
    format: "",
    zone: "",
    city: "",
    users: [],
    usersTemp: [],
    coordinates: {
      latitude: 0,
      longitude: 0,
    },
    image: "",
  });

  Geocode.setApiKey(process.env.REACT_APP_PLACES_GOOGLE); // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setLanguage("en"); // set response language. Defaults to english.
  Geocode.setRegion("es"); // set response region. Its optional. // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.enableDebug(); // Enable or disable logs. Its optional.

  useEffect(() => {
    const fetchGeocode = async () => {
      await Geocode.fromAddress(data.address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setData({
            ...data,
            coordinates: {
              latitude: lat,
              longitude: lng,
            },
          });
        },
        (error) => {
          console.error(error);
        }
      );
    };

    if (data.address) {
      fetchGeocode();
    }
  }, [data.address]);

  useEffect(() => {
    if (externalGreenPoint.dataModal && !edit) {
      setData({
        ...externalGreenPoint.dataModal,
        addressTemp: externalGreenPoint.dataModal.address,
        usersTemp: externalGreenPoint.dataModal.users.map((user) => {
          return {
            name: user.name + " " + user.lastName,
            email: user.email,
            eliminated: false,
            _id: user._id,
          };
        }),
      });
    }
  }, [externalGreenPoint.dataModal, edit]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  useEffect(() => {
    Object.keys(Cities).map((keys, i) => {
      return provincias.push({
        city: Cities[keys].cantones,
      });
    });
    Object.keys(provincias).map((keys, j) => {
      Object.keys(provincias[keys].city).map((ckey, y) => {
        return cities.push({
          name: provincias[keys].city[ckey].canton,
          code: ckey,
        });
      });
    });
    cities.sort((x, y) => {
      if (x.name > y.name) {
        return 1;
      }
      if (x.name < y.name) {
        return -1;
      }
      return 0;
    });
  }, []);

  const handleClose = () => {
    dispatch(externalsGreenPointActions.setEditModal(false));
    dispatch(externalsGreenPointActions.setDataModal(null));
    setEdit(false);
    setImageSrc(cloudupload);
    setIsUploadingImage(false);
  };

  const handleSave = () => {
    if (
      (data.coordinates.latitude === 0 &&
        data.coordinates.latitude.longitude === 0) ||
      !data.name ||
      !data.description ||
      !data.format ||
      !data.zone ||
      !data.city
    ) {
      setError(true);
    } else {
      const tempUsers = [];
      const tempEliminateds = [];
      data.usersTemp.forEach((user) => {
        if (user.eliminated) {
          tempEliminateds.push(user._id);
        } else {
          tempUsers.push(user._id);
        }
      });
      data.users = tempUsers;
      data.eliminateds = tempEliminateds;
      dispatch(externalsGreenPointActions.patchGreenPointAdminExtern({ data }));
      dispatch(externalsGreenPointActions.setDataModal(null));
      handleClose();
    }
  };

  return (
    <Dialog open={externalGreenPoint.editModal} maxWidth="md" fullWidth>
      <DialogContent>
        <Header handleClose={handleClose} title={data.name} type="VIEW" />
        {!edit ? (
          <Content data={data} imageSrc={imageSrc} cities={cities} />
        ) : (
          <ContentEdit
            data={data}
            setData={setData}
            error={error}
            cities={cities}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            setIsUploadingImage={setIsUploadingImage}
          />
        )}
        <Button
          startIcon={<BorderColor />}
          color="primary"
          variant="text"
          onClick={() => setEdit(!edit)}
        >
          {!edit ? "editar" : "descartar cambios"}
        </Button>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button
            startIcon={<Close />}
            color="secondary"
            onClick={() => handleClose()}
          >
            Salir {edit && "(sin guardar cambios)"}
          </Button>
          {edit && (
            <Button
              startIcon={<Check />}
              color="primary"
              disabled={isUploadingImage}
              onClick={() => handleSave()}
            >
              Guardar
            </Button>
          )}
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
