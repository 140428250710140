import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { Cell, Label, Pie, PieChart, Tooltip } from "recharts";

const useStyles = makeStyles(() => ({
  bg: {
    background: "rgb(34,195,163)",
    background:
      "linear-gradient(274deg, rgba(34,195,163,0.26404065043986347) 27%, rgba(255,255,255,1) 78%)",
    borderRadius: "20px",
  },
  quantity: {
    fontSize: "20px",
    fontWeight: "bold",
    opacity: 0.4,
  },
  progress: {
    height: "10px",
    borderRadius: "5px",
  },
  percentage: {
    fontSize: "14px",
    fontWeight: "bold",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  root_progress: {
    borderRadius: "50%",
    boxShadow: "inset 0px 0px 0px 4px #E4E6EE",
  },
}));

const TotalProgressCard = ({ goalProgress }) => {
  const classes = useStyles();
  const [totalProgress, setTotalProgress] = useState({
    total: 0,
    goal: 0,
    percentage: 0,
  });

  const renderItemText = (text, value) => {
    return (
      <Grid
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: "10px",
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            color: "#828282",
          }}
        >
          {text}
        </Typography>
        <Typography>{`${parseFloat(value).toFixed(2) || 0} kg`}</Typography>
      </Grid>
    );
  };

  const getColorProgress = (value) => {
    if (value > 99) return "#21AD57";
    if (value <= 99 && value >= 51) return "#FDE498";
    return "#DD405A";
  };

  const data = [
    {
      name: "Material Total Actual",
      value: totalProgress.total,
      color: getColorProgress(totalProgress.percentage),
    },
    {
      name: "Material Total a recuperar",
      value: totalProgress.goal,
      color: "#ccc",
    },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          className="custom-tooltip"
          style={{
            backgroundColor: "#6ae0e98a",
            borderRadius: "5px",
            border: "1px solid #1448a1",
            padding: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          }}
        >{`${payload[0].value} KG`}</Box>
      );
    }

    return null;
  };

  useEffect(() => {
    if (goalProgress) {
      let total = 0;
      let goal = 0;
      Object.keys(goalProgress).forEach((key) => {
        total += goalProgress[key].total;
        goal += goalProgress[key].goal;
      });
      setTotalProgress({
        total: Math.round(total),
        goal: Math.round(goal),
        percentage: isNaN(Math.round((total / goal) * 100))
          ? 0
          : Math.round(Math.round((total / goal) * 100)),
      });
    }
  }, [goalProgress]);

  return (
    <Grid item xs={12} className={classes.bg}>
      <Box justifyContent="center" alignItems="center" width="auto">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          width="auto"
        >
          <Grid
            item
            xs={4}
            style={{
              position: "relative",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <PieChart width={200} height={200}>
              <Tooltip content={<CustomTooltip />} />
              <Pie
                data={data}
                dataKey="value"
                innerRadius={60}
                outerRadius={84}
                cornerRadius={40}
                paddingAngle={2}
                fill="#c4c4c4"
                labelLine={true}
              >
                <Label
                  width={30}
                  position="center"
                  fontSize={26}
                  fontWeight={700}
                >
                  {`${
                    totalProgress.percentage > 100
                      ? 100
                      : totalProgress.percentage
                  }%`}
                </Label>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} display="d" />
                ))}
              </Pie>
            </PieChart>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              position: "relative",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Box paddingY="10px" width="auto">
              {renderItemText("Material Total Actual:", totalProgress.total)}
              {renderItemText(
                "Material Total a recuperar:",
                totalProgress.goal
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default TotalProgressCard;
