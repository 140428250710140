const defaultState = {
  viewModal: false,
  updateGoalModal: false,
  updateGoalByMaterialModal: false,
  updateGoalData: null,
  openFilters: false,
  openExportData: false,
  goal: null,
  goalMissingList: [],
  /* --- */
  totalRecords: 0,
  goalMissingByMaterialsList: [],
  filtersMissingMaterials: {
    pagination: {
      rows: 7,
      page: 0,
    },
    search: "",
  },
  /* --- */
  pagination: {
    rows: 12,
    page: 0,
  },
  filters: {
    differenceMonths: 1,
    initialDate: null,
    endDate: null,
    format: "ALL",
    city: "ALL",
    zone: "ALL",
    greenPoints: [],
  },
  exportFilters: {
    zones: [],
    formats: [],
    greenPoints: [],
    greenPointList: [],
  },
  greenPoints: [],
  data: {
    allGreenPointProgress: [],
    materialProgress: [],
    monthlyProgress: [],
  },
  monthlyProgress: [],
};
function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "INDICATORS_SET_VIEW_MODAL_GOALS": {
      return { ...state, viewModal: payload };
    }
    case "INDICATORS_SET_UPDATE_MODAL_GOAL": {
      return { ...state, updateGoalModal: payload };
    }
    case "INDICATORS_SET_UPDATE_MODAL_GOAL_BY_MATERIALS": {
      return { ...state, updateGoalByMaterialModal: payload };
    }
    case "INDICATORS_OPEN_EXPORT_DATA_MODAL": {
      return { ...state, openExportData: payload };
    }
    case "INDICATORS_UPDATE_FILTERS": {
      return { ...state, filters: payload };
    }
    case "INDICATORS_UPDATE_EXPORT_FILTERS": {
      return { ...state, exportFilters: payload };
    }
    case "INDICATORS_UPDATE_FILTERS_GREENPOINTS": {
      return { ...state, filters: { ...state.filters, greenPoints: payload } };
    }
    case "INDICATORS_UPDATE_EXPORT_GREENPOINTS": {
      return {
        ...state,
        exportFilters: { ...state.exportFilters, greenPointList: payload },
      };
    }
    case "INDICATORS_OPEN_FILTERS_DRAWER": {
      return { ...state, openFilters: payload };
    }
    case "INDICATORS_SET_UPDATE_MODAL_GOAL_DATA": {
      return { ...state, updateGoalData: payload };
    }
    case "INDICATORS_SET_VIEW_MODAL_GOALS": {
      return { ...state, viewModal: payload };
    }
    case "GOALS_FETCH": {
      return { ...state, goal: payload };
    }
    case "GOALS_SET_PAGINATION": {
      return { ...state, pagination: payload };
    }
    case "GOALS_MISSING": {
      return { ...state, goalMissingList: payload };
    }
    case "GOALS_MISSING_BY_MATERIALS": {
      const { pagesInfo, currentPages } = payload;
      let total = 0;
      if (pagesInfo.length > 0) {
        total = pagesInfo[0].totalElement;
      }

      return {
        ...state,
        goalMissingByMaterialsList: currentPages,
        totalRecords: total,
      };
    }
    case "GOALS_MISSING_BY_MATERIALS_SET_SEARCH": {
      const newStateFilters = {
        ...state.filtersMissingMaterials,
        ...payload,
      };
      return {
        ...state,
        filtersMissingMaterials: newStateFilters,
      };
    }
    case "GOALS_MISSING_BY_MATERIALS_SET_PAGINATION": {
      const { pagination } = state.filtersMissingMaterials;
      const { rows, page } = payload;
      const newStateFilters = {
        ...state.filtersMissingMaterials,
        pagination: {
          rows,
          page,
        },
      };
      return { ...state, filtersMissingMaterials: newStateFilters };
    }
    case "INDICATORS_FETCH_DETAILS": {
      return { ...state, data: payload };
    }
    case "INDICATORS_FETCH_GREENPOINTS": {
      return { ...state, greenPoints: payload };
    }
    case "INDICATORS_FETCH_MONTHLY_PROGRESS": {
      return { ...state, monthlyProgress: payload };
    }

    default:
      return state;
  }
}

export default reducer;
