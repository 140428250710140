import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import externalsIndicatorsAction from "../../../../../redux/actions/externalsIndicators";
import Header from "../../../../Shared/Modals/Header";
/* import Search from "./Search"; */
import TableMissingMaterials from "./TableMissingMaterials";

export default function ModalUpdateGoal() {
  const dispatch = useDispatch();
  const { updateGoalByMaterialModal } = useSelector(
    (state) => state.externalsIndicators
  );
  const app = useSelector((state) => state.app);
  const handleClose = () => {
    dispatch(externalsIndicatorsAction.setUpdateModalByMaterial(false));
  };

  return (
    <Dialog
      open={updateGoalByMaterialModal}
      onClose={() => handleClose()}
      maxWidth="md"
      fullWidth
      fullScreen={app.isMobile}
      scroll="body"
    >
      <DialogContent dividers>
        <Header
          handleClose={handleClose}
          title="Actualizar metas"
          type="VIEW"
        />
        {/* <Search /> */}
        <TableMissingMaterials />
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={() => handleClose()}
            variant="contained"
            size="small"
          >
            Salir
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
