import React, { useEffect, Fragment } from "react";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import dashboardActions from "../../../redux/actions/dashboard";
import { DatePicker } from "@material-ui/pickers";

export default function Dates() {
  const dashboard = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    let dates = {
      dateInit: null,
      dateFinish: null,
    };
    const now = new Date();
    if (dashboard.dateRange === "CURRENT") {
      dates = {
        dateInit: new Date(now.getFullYear(), now.getMonth(), 1),
        dateFinish: new Date(now.getFullYear(), now.getMonth() + 1, 1),
      };
      dispatch(dashboardActions.setDates(dates));
    }
    if (dashboard.dateRange === "PREVIUS") {
      dates = {
        dateInit: new Date(now.getFullYear(), now.getMonth() - 1, 1),
        dateFinish: new Date(now.getFullYear(), now.getMonth(), 1),
      };
      dispatch(dashboardActions.setDates(dates));
    }
  }, [dashboard.dateRange]);

  const setDate = ({ date, type }) => {
    dispatch(
      dashboardActions.setDates({
        ...dashboard.dates,
        [type]: new Date(date),
      })
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <ButtonGroup size="small" variant="contained">
            <Button
              onClick={() => dispatch(dashboardActions.setDateRange("RANGE"))}
              color={dashboard.dateRange === "RANGE" ? "primary" : "secondary"}
            >
              seleccionar rango
            </Button>
            <Button
              onClick={() => dispatch(dashboardActions.setDateRange("PREVIUS"))}
              color={
                dashboard.dateRange === "PREVIUS" ? "primary" : "secondary"
              }
            >
              mes anterior
            </Button>
            <Button
              onClick={() => dispatch(dashboardActions.setDateRange("CURRENT"))}
              color={
                dashboard.dateRange === "CURRENT" ? "primary" : "secondary"
              }
            >
              mes actual
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      {dashboard.dateRange === "RANGE" && (
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              label="Fecha inicial"
              value={dashboard.dates.dateInit}
              maxDate={dashboard.dates.dateFinish}
              disableFuture
              onChange={(date) => setDate({ date, type: "dateInit" })}
            />
          </Grid>
          <Grid item>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              label="Fecha final"
              value={dashboard.dates.dateFinish}
              minDate={dashboard.dates.dateInit}
              onChange={(date) => setDate({ date, type: "dateFinish" })}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
