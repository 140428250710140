import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/externalRemovals";
import groupActions from "../../../../redux/actions/groups";

import Header from "../../../Shared/Modals/Header";
import Buttons from "./Buttons";
import Content from "./Contents";

const initialState = {
  description: "",
  greenPointID: "EMPTY",
  groupID: "EMPTY",
  entityIDSelect: [],
  datetimeRemoval: new Date(),
};
export default function AprobeModal() {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups);
  const auth = useSelector((state) => state.auth);
  const removals = useSelector((state) => state.externalRemovals);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(false);
  const [dataSelectEntity, setDataSelectEntity] = useState([]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 1500);
    }
  }, [error]);

  useEffect(() => {
    const exec = () => {
      dispatch(groupActions.fetchSelectGroups());
    };
    exec();
  }, []);

  useEffect(() => {
    setDataSelectEntity(groups.groupsSelect);
  }, [groups.groupsSelect, removals.createModal]);

  useEffect(() => {
    if (removals.aprobeModal && removals.dataModal) {
      let temp = {};
      removals.dataModal.materials.forEach((material) => {
        temp[material.material] = material.quantity;
      });
      setFormData({
        ...formData,
        ...removals.dataModal,
        greenPointID: removals.dataModal.greenPointID._id,
        groupID: removals.dataModal.groupID._id,
        entityIDSelect: groups.groupsSelect.find(
          (gp) => gp._id === removals.dataModal.groupID._id
        ).externalsGreenPoints,
        materials: temp,
      });
    }
  }, [removals.aprobeModal, removals.dataModal]);

  const handleClose = () => {
    dispatch(removalsActions.setAprobeModal(false));
  };

  const checkMaterials = () =>
    Object.keys(formData.materials).reduce(
      (sum, current) => (sum += parseFloat(formData.materials[current])),
      0
    );

  const handleSuccess = () => {
    if (
      formData.greenPointID !== "EMPTY" &&
      formData.description !== "" &&
      formData.groupID !== "EMPTY" &&
      checkMaterials()
    ) {
      const materials = Object.keys(formData.materials).map((material) => {
        return {
          material,
          quantity: formData.materials[material],
        };
      });
      dispatch(
        removalsActions.patchRemovals({
          formData: {
            ...formData,
            materials,
            approver: auth.user._id,
            accepted: true,
          },
        })
      );
      setFormData(initialState);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={removals.aprobeModal}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <DialogContent>
        <Header
          handleClose={handleClose}
          title="Editar y aprobar registro"
          type="VIEW"
        />
        <Content
          error={error}
          formData={formData}
          setFormData={setFormData}
          checkMaterials={checkMaterials}
          dataSelectEntity={dataSelectEntity}
        />
      </DialogContent>
      <DialogActions>
        <Buttons
          edit={true}
          handleClose={handleClose}
          handleSuccess={handleSuccess}
        />
      </DialogActions>
    </Dialog>
  );
}
