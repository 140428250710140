import axios from "axios";
import notifiesActions from "./notifies";
import utilsActions from "./utils";
import appActions from "./app";
import authActions from "./auth";

const fetchApprovedMaterials = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalsMaterials;

      const response = await axios.get(
        `/external-materials/admin/${state.approvedFilters.pagination.page}/${state.approvedFilters.pagination.rows}?search=${state.approvedFilters.search}&group=${state.group}`
      );
      dispatch({
        type: "ADMIN_EXTERNALS_MATERIALS_APPROVED_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchPendingMaterials = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalsMaterials;

      const response = await axios.get(
        `/external-materials/admin/pending/${state.pendingFilters.pagination.page}/${state.pendingFilters.pagination.rows}?search=${state.pendingFilters.search}&group=${state.group}`
      );
      dispatch({
        type: "ADMIN_EXTERNALS_MATERIALS_PENDING_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchAllMaterials = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalsMaterials;
      const auth = getState().auth;

      const response = await axios.get(
        `/external-materials/external-admin/all/${state.externalMaterialFilters.pagination.page}/${state.externalMaterialFilters.pagination.rows}?search=${state.externalMaterialFilters.search}&group=${auth.user.groupID._id}`
      );
      dispatch({
        type: "ADMIN_EXTERNALS_ALL_MATERIALS_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setSearchApproved = (value) => {
  return async (dispatch, getState) => {
    try {
      await dispatch({
        type: "ADMIN_EXTERNALS_MATERIALS_SET_APPROVED_SEARCH",
        payload: value,
      });
      dispatch(fetchApprovedMaterials());
    } catch (error) {
      console.error(error);
    }
  };
};

const setSearchPending = (value) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ADMIN_EXTERNALS_MATERIALS_SET_PENDING_SEARCH",
        payload: value,
      });
      dispatch(fetchPendingMaterials());
    } catch (error) {
      console.error(error);
    }
  };
};

const setSearchAll = (value) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ADMIN_EXTERNALS_ALL_MATERIALS_SET_SEARCH",
        payload: value,
      });
      dispatch(fetchAllMaterials());
    } catch (error) {
      console.error(error);
    }
  };
};

const setApprovedPagination = (value) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ADMIN_EXTERNALS_MATERIALS_SET_APPROVED_PAGINATION",
        payload: value,
      });
      dispatch(fetchApprovedMaterials());
    } catch (error) {
      console.error(error);
    }
  };
};

const setPendingPagination = (value) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ADMIN_EXTERNALS_MATERIALS_SET_PENDING_PAGINATION",
        payload: value,
      });
      dispatch(fetchPendingMaterials());
    } catch (error) {
      console.error(error);
    }
  };
};

const setAllPagination = (value) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "ADMIN_EXTERNALS_ALL_MATERIALS_SET_PAGINATION",
        payload: value,
      });
      dispatch(fetchAllMaterials());
    } catch (error) {
      console.error(error);
    }
  };
};

const setSelector = (selector) => {
  return async (dispatch) => {
    await dispatch({
      type: "ADMIN_EXTERNALS_MATERIALS_SET_SELECTOR",
      payload: selector,
    });
    await dispatch(fetchApprovedMaterials());
    await dispatch(fetchPendingMaterials());
    return true;
  };
};

const setGroup = (group) => {
  return async (dispatch) => {
    await dispatch({
      type: "ADMIN_EXTERNALS_MATERIALS_SET_GROUP",
      payload: group,
    });
    await dispatch(fetchApprovedMaterials());
    await dispatch(fetchPendingMaterials());
    return true;
  };
};

const postMaterial = ({ formData }) => {
  return async (dispatch, getState) => {
    try {
      const auth = getState().auth;
      dispatch(appActions.setLoading(true));
      await axios.post("/external-materials/admin", formData);
      dispatch(notifiesActions.enqueueNotify("MATERIAL_CREATE_SUCCESS"));

      if (auth.user.type === "ADMIN_EXTERN") {
        dispatch(fetchAllMaterials());
      }
      if (auth.user.type === "ADMIN") {
        dispatch(fetchApprovedMaterials());
        dispatch(fetchPendingMaterials());
      }

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MATERIAL_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchMaterial = ({ formData }) => {
  return async (dispatch, getState) => {
    let NOTIFICATIONTYPE = "";
    if (formData) {
      NOTIFICATIONTYPE = formData.state === "READY" ? "APPROVE" : "UPDATE";
    }
    if (!formData) {
      NOTIFICATIONTYPE = "UPDATE";
    }

    try {
      const auth = getState().auth;
      dispatch(appActions.setLoading(true));
      await axios.patch("/external-materials/admin", formData);
      dispatch(
        notifiesActions.enqueueNotify(`MATERIAL_${NOTIFICATIONTYPE}_SUCCESS`)
      );
      if (auth.user.type === "ADMIN_EXTERN") {
        dispatch(fetchAllMaterials());
      }
      if (auth.user.type === "ADMIN") {
        dispatch(fetchApprovedMaterials());
        dispatch(fetchPendingMaterials());
      }
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(
        notifiesActions.enqueueNotify(`MATERIAL_${NOTIFICATIONTYPE}_FAIL`)
      );
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteMaterial = (id) => {
  return async (dispatch, getState) => {
    try {
      const auth = getState().auth;
      dispatch(appActions.setLoading(true));
      await axios.delete(`/external-materials/admin/${id}`);
      dispatch(notifiesActions.enqueueNotify("MATERIAL_DELETE_SUCCESS"));
      if (auth.user.type === "ADMIN_EXTERN") {
        dispatch(fetchAllMaterials());
      }
      if (auth.user.type === "ADMIN") {
        dispatch(fetchApprovedMaterials());
        dispatch(fetchPendingMaterials());
      }

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("MATERIAL_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

export default {
  fetchApprovedMaterials,
  fetchPendingMaterials,
  fetchAllMaterials,
  setSearchApproved,
  setSearchPending,
  setSearchAll,
  setApprovedPagination,
  setPendingPagination,
  setAllPagination,
  setSelector,
  setGroup,
  postMaterial,
  patchMaterial,
  deleteMaterial,
};
