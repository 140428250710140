const defaultState = {
  groups: [],
  groupsSelect: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  search: "",
  createModal: false,
  deleteModal: false,
  viewModal: false,
  editModal: false,
  dataModal: null,
  greenPointsCreated: [],
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "GROUPS_FETCH": {
      return { ...state, groups: payload };
    }
    case "GROUPS_SELECT_FETCH": {
      return { ...state, groupsSelect: payload };
    }
    case "GROUPS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "GROUPS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "GROUPS_SET_VIEW_MODAL": {
      return { ...state, viewModal: payload };
    }
    case "GROUPS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "GROUPS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "GROUPS_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "GROUPS_SET_SEARCH": {
      return { ...state, ...payload };
    }
    case "GROUPS_ADD_GREENPOINT_LIST": {
      const data = [...state.greenPointsCreated, payload];
      return { ...state, greenPointsCreated: data };
    }
    case "GROUPS_REMOVE_GREENPOINT_LIST": {
      const data = [...state.greenPointsCreated];
      data.splice(payload, 1);
      return { ...state, greenPointsCreated: data };
    }
    case "GROUPS_RESET_GREENPOINT_LIST": {
      return { ...state, greenPointsCreated: [] };
    }
    default:
      return state;
  }
}

export default reducer;
