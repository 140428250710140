const defaultState = {
  users: [],
  pagination: {
    rows: 7,
    page: 0,
  },
  search: "",
  userTypes: ["ADMIN_EXTERN", "GREENPOINT_EXTERN"],
  dataModal: null,
  createModal: false,
  deleteModal: false,
  editModal: false,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "EXTERNAL_USERS_FETCH": {
      return { ...state, users: payload };
    }
    case "EXTERNAL_USERS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "EXTERNAL_USERS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "EXTERNAL_USERS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "EXTERNAL_USERS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "EXTERNAL_USERS_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "EXTERNAL_USERS_SET_SEARCH": {
      return { ...state, ...payload };
    }
    case "EXTERNAL_USERS_SET_USERTYPES": {
      return { ...state, userTypes: payload };
    }
    default:
      return state;
  }
}

export default reducer;
