import React, { useEffect, useState } from "react";
import { Paper, makeStyles, Card, CardContent } from "@material-ui/core";
import Header from "./Header";
import Content from "./Content";
import ContentEdit from "./ContentEdit";
import { useDispatch, useSelector } from "react-redux";
import profileActions from "../../../redux/actions/profile";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Profile() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  useEffect(() => {
    const start = () => {
      dispatch(profileActions.fetchProfile());
    };
    start();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          {!profile.edit ?
            <Content imageSrc={imageSrc} />
            :
            <ContentEdit
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}            
              isUploadingImage={isUploadingImage}
              setIsUploadingImage={setIsUploadingImage}
            />
          }
        </CardContent>
      </Card>
    </Paper>
  );
}
