const defaultState = {
  reports: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  search: "",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "REPORTS_GREENPOINT_FETCH": {
      return { ...state, reports: payload };
    }
    case "REPORTS_GREENPOINT_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    default:
      return state;
  }
}

export default reducer;
