import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PeopleIcon from "@material-ui/icons/People";

import { useDispatch } from "react-redux";
import externalUsersActions from "../../../redux/actions/externalUsers";

export default function Header() {
  const dispatch = useDispatch();
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4">
          <PeopleIcon style={{ fontSize: 34, marginBottom: 6 }} /> Usuarios
          Externos
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => dispatch(externalUsersActions.setCreateModal(true))}
          color="primary"
          size="small"
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
        >
          Agregar usuario externo
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
