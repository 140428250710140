import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  makeStyles,
  Grid,
  InputAdornment,
  Typography,
  IconButton,
} from "@material-ui/core";
import colorLogo from "../../resources/logos/logo-color.png";
import colorLogoAC from "../../resources/logos/logo-color-AC.png";
import SecurityIcon from "@material-ui/icons/Security";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import authActions from "../../redux/actions/auth";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    borderRadius: "6px",
  },
  paper: {
    backgroundColor: "white",
    marginTop: spacing(18),
    marginBottom: spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
    borderRadius: "8px",
  },
  typography: {
    padding: "10px",
    color: "#a3a3a3",
  },
}));

export default function Login() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [recoveryForm, setRecoveryForm] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (auth.loginStatus === "ERROR" || auth.loginStatus === "AUTH_FAILED") {
      setTimeout(() => dispatch(authActions.loginStatus("READY")), 1500);
    }
    if (
      auth.loginStatus === "NOT_ACTIVED" ||
      auth.loginStatus === "NOT_ENTITY"
    ) {
      setTimeout(() => dispatch(authActions.loginStatus("READY")), 3000);
    }
  }, [auth.loginStatus]);

  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      dispatch(authActions.login({ ...formData }));
    }
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
      }}
      onKeyPress={handleEnterPress}
    >
      <main className={styles.main}>
        <Paper className={styles.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              <img src={colorLogo} alt="Gira Logo" style={{ height: 100 }} />
            </Grid>
            <Grid item xs={12} align="center">
              <hr />
            </Grid>
            {auth.loginStatus === "NOT_ACTIVED" && (
              <Grid item xs={12}>
                <MuiAlert elevation={6} variant="standard" severity="warning">
                  Debes activar tu cuenta
                </MuiAlert>
              </Grid>
            )}
            {auth.loginStatus === "NOT_ENTITY" && (
              <Grid item xs={12}>
                <MuiAlert elevation={6} variant="standard" severity="warning">
                  No tienes un Punto Gira asignado
                </MuiAlert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                value={formData.email}
                error={
                  auth.loginStatus === "ERROR" ||
                  auth.loginStatus === "AUTH_FAILED"
                }
                name="email"
                label="Correo"
                autoComplete="email"
                autoFocus
                variant="outlined"
                fullWidth
                margin="dense"
                onChange={updateData}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <AccountBoxIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {recoveryForm === false && (
                <TextField
                  value={formData.password}
                  error={
                    auth.loginStatus === "ERROR" ||
                    auth.loginStatus === "AUTH_FAILED"
                  }
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Contraseña"
                  variant="outlined"
                  autoComplete="current-password"
                  onChange={updateData}
                  margin="dense"
                  helperText={
                    auth.loginStatus === "AUTH_FAILED" &&
                    "Datos mal ingresados. Intenta de nuevo"
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant={
                  auth.loginStatus === "READY" ? "contained" : "outlined"
                }
                onClick={() => {
                  !recoveryForm
                    ? dispatch(authActions.login({ ...formData }))
                    : dispatch(authActions.recoveryPassword({ ...formData }));
                }}
                startIcon={<ExitToAppIcon />}
              >
                {!recoveryForm ? "Entrar" : "Solicitar nueva contraseña"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                size="small"
                startIcon={<SecurityIcon />}
                onClick={() => {
                  setRecoveryForm(!recoveryForm);
                }}
              >
                Recuperar contraseña
              </Button>
            </Grid>
            <Grid item xs={12} align="center">
              <hr />
            </Grid>
            <Grid item xs={12} align="center">
              <Grid item xs={12} align="center">
                <Typography className={styles.typography}>
                  POWERED BY
                </Typography>
              </Grid>
              <Grid item xs={12} align="center">
                <img
                  src={colorLogoAC}
                  alt="Powered by Acción Circular"
                  style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </div>
  );
}
