import React, { useState, useEffect, Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import EcoCard from "./EcoCard";
import { useSelector } from "react-redux";

const data = {
  PETROL: {
    /* image: "https://giradata.s3.amazonaws.com/photos/icons/2933894.svg", */
    image:
      "https://s3.amazonaws.com/giradata/resources/images/eco_equivalencias/petroleo.png",
    alt: "litros de petróleo",
    unity: "Litros",
    subtitle: "de petróleo",
    value: -1,
  },
  ENERGY: {
    materialID: "ENERGY",
    /* image: "https://giradata.s3.amazonaws.com/photos/icons/248053.svg", */
    image:
      "https://s3.amazonaws.com/giradata/resources/images/eco_equivalencias/energia.png",
    alt: "kWatt de energía",
    unity: "kWatt",
    subtitle: "de energía",
    value: -1,
  },
  CO2: {
    materialID: "CO2",
    /* image: "https://giradata.s3.amazonaws.com/photos/icons/699404.svg", */
    image:
      "https://s3.amazonaws.com/giradata/resources/images/eco_equivalencias/co2.png",
    alt: "kilos de CO2",
    unity: "Kilos",
    subtitle: "de CO2",
    value: -1,
  },
  WATER: {
    materialID: "WATER",
    /* image: "https://giradata.s3.amazonaws.com/photos/icons/766864.svg", */
    image:
      "https://s3.amazonaws.com/giradata/resources/images/eco_equivalencias/agua.png",
    alt: "litros de agua",
    unity: "Litros",
    subtitle: "de agua",
    value: -1,
  },
  TREE: {
    /* image:
      "https://giradata.s3.amazonaws.com/photos/icons/tree-removebg-preview.svg", */
    image:
      "https://s3.amazonaws.com/giradata/resources/images/eco_equivalencias/arbol.png",
    alt: "árboles",
    unity: "Unidades",
    subtitle: "de árboles",
    value: -1,
  },
  MATERIAL: {
    /* image: "https://giradata.s3.amazonaws.com/photos/icons/1498535.svg", */
    image:
      "https://s3.amazonaws.com/giradata/resources/images/eco_equivalencias/material.png",
    alt: "kilos de material",
    unity: "Kilos",
    subtitle: "de material",
    value: -1,
  },
};
export default function EcoEq() {
  const dashboard = useSelector((state) => state.externalReports);

  const [cards, setCards] = useState(Object.keys(data).map((key) => data[key]));

  useEffect(() => {
    if (dashboard.data) {
      setCards(
        Object.keys(dashboard.data.ecoeqTemp).map((key) => {
          return {
            ...data[key],
            value: dashboard.data.ecoeqTemp[key],
          };
        })
      );
    }
  }, [dashboard.data]);
  return (
    <Fragment>
      <Typography variant="h5" align="center" color="textSecondary">
        Eco equivalencias recuperadas
      </Typography>
      <Grid container spacing={3} style={{ marginTop: 30 }}>
        {cards.map((card, i) => (
          <EcoCard data={card} key={i} />
        ))}
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
    </Fragment>
  );
}
