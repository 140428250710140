import React from "react";
import { Grid, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import PlacesAutocomplete from "./PlacesAutocomplete";
export default function Content({ formData, setFormData, error }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Nombre grupo"
              variant="outlined"
              margin="dense"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              error={error && !formData.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Nombre contacto"
              variant="outlined"
              margin="dense"
              value={formData.contact}
              onChange={(e) =>
                setFormData({ ...formData, contact: e.target.value })
              }
              fullWidth
              error={error && !formData.contact}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Mail contacto"
              variant="outlined"
              margin="dense"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              fullWidth
              error={error && !formData.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Teléfono contacto"
              variant="outlined"
              margin="dense"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              fullWidth
              error={error && !formData.phone}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PlacesAutocomplete formData={formData} setFormData={setFormData} />
        </Grid>
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  error: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
