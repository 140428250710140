import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/externalRemovals";
import groupActions from "../../../../redux/actions/groups";
import utilsActions from "../../../../redux/actions/utils";

const initialState = {
  description: "",
  greenPointID: "EMPTY",
  datetimeRemoval: new Date(),
  groupID: "EMPTY",
  entityIDSelect: [],
};
export default function ModalCreate() {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups);
  const utils = useSelector((state) => state.utils);
  const auth = useSelector((state) => state.auth);
  const removals = useSelector((state) => state.externalRemovals);
  const [dataSelectEntity, setDataSelectEntity] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(false);

  useEffect(() => {
    const exec = () => {
      dispatch(groupActions.fetchSelectGroups());
    };
    const fetchUtils = () => {
      dispatch(utilsActions.fetchMaterials());
    };
    exec();
    fetchUtils();
  }, []);

  useEffect(() => {
    setDataSelectEntity(groups.groupsSelect);
  }, [groups.groupsSelect, removals.createModal]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 1500);
    }
  }, [error]);

  useEffect(() => {
    if (removals.createModal) {
      let temp = {};
      Object.keys(utils.all_materials).forEach((material) => {
        temp[material] = 0;
      });
      setFormData({ ...formData, materials: temp });
    }
  }, [removals.createModal]);

  const handleClose = () => {
    dispatch(removalsActions.setCreateModal(false));
  };

  const checkMaterials = () =>
    Object.keys(formData.materials).reduce(
      (sum, current) => (sum += parseFloat(formData.materials[current])),
      0
    );

  const handleSuccess = () => {
    if (
      formData.greenPointID !== "EMPTY" &&
      formData.description !== "" &&
      formData.groupID !== "EMPTY" &&
      checkMaterials()
    ) {
      const materials = Object.keys(formData.materials).map((material) => {
        return {
          material,
          quantity: formData.materials[material],
        };
      });
      dispatch(
        removalsActions.postRemoval({
          formData: {
            ...formData,
            materials,
            userID: auth.user._id,
            requester: auth.user._id,
            approver: auth.user._id,
          },
        })
      );
      setFormData(initialState);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={removals.createModal}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      scroll="body"
    >
      <DialogContent>
        <Header
          handleClose={handleClose}
          title="Ingresar registro Externo"
          type="VIEW"
        />
        <Content
          error={error}
          formData={formData}
          setFormData={setFormData}
          checkMaterials={checkMaterials}
          dataSelectEntity={dataSelectEntity}
        />
      </DialogContent>
      <DialogActions>
        <Buttons handleSuccess={handleSuccess} handleClose={handleClose} />
      </DialogActions>
    </Dialog>
  );
}
