import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { BorderColor } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import usersActions from "../../../../redux/actions/users";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import ContentEdit from "./ContentEdit";
import Buttons from "./Buttons";

const initState = {
  newPassword: "",
  newPasswordRpt: "",
  changePassword: false,
  changeEntity: false,
  changeActivate: false,
  newEntity: "EMPTY",
  type: "GREENPOINT",
  image:"",
};
export default function ViewModal() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(initState);
  const [imageSrc, setImageSrc] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  useEffect(() => {
    if (users.dataModal && !edit) {
      setFormData({
        ...formData,
        ...users.dataModal,
        newEntity: users.dataModal.entityID
          ? users.dataModal.entityID
          : "EMPTY",
      });
    }
  }, [users.dataModal, edit]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const handleSuccess = () => {
    if (
      formData.name === "" ||
      (formData.changePassword &&
        (formData.newPassword !== formData.newPasswordRpt ||
          formData.newPassword.length < 6))
    ) {
      setError(true);
    } else {
      let payload = formData;
      if (formData.newPassword) {
        payload = {
          ...payload,
          password: formData.newPassword,
        };
      }
      if (formData.newEntity === "EMPTY") {
        payload = {
          ...payload,
          newEntity: null,
        };
      } else {
        payload = {
          ...payload,
          type: "GREENPOINT",
        };
      }
      if (formData.changeActivate) {
        payload = {
          ...payload,
          actived: formData.actived === "ACTIVED" ? "DEACTIVED" : "ACTIVED",
        };
      }

      dispatch(usersActions.patchUser(payload));
      handleClose();
    }
  };

  const reset = () => {
    setEdit(false);
    setFormData(initState);
    setImageSrc("");
  };

  const handleClose = () => {
    dispatch(usersActions.setEditModal(false));
    reset();
    dispatch(usersActions.setDataModal(null));
  };

  return (
    <Dialog
      open={users.editModal}
      onClose={() => handleClose()}
      scroll={"body"}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Header
          title={formData.name + " " + formData.lastName}
          handleClose={handleClose}
          type="VIEW"
        />
        {!edit ? (
          <Content formData={formData} imageSrc={imageSrc}/>
        ) : (
          <ContentEdit
            formData={formData}
            setFormData={setFormData}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            setIsUploadingImage={setIsUploadingImage}
            error={error}
          />
        )}
        <hr />
        <Button
          startIcon={<BorderColor />}
          color="primary"
          variant="text"
          onClick={() => setEdit(!edit)}
        >
          {!edit ? "editar" : "descartar cambios"}
        </Button>
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          isUploadingImage={isUploadingImage}
          edit={edit}
        />
      </DialogActions>
    </Dialog>
  );
}
