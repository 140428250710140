import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../Login";
import MaterialsAdmin from "../Admin/Materials";
import RemovalsAdmin from "../Admin/Removals";
import ExternalsGreenPointsAdmin from "../Admin/ExternalsGreenPoints";
import ExternalsRemovalsAdmin from "../Admin/ExternalsRemovalsAdmin";
import ExternalsIndicatorsAdmin from "../Admin/ExternalsIndicators";
import ExternalsMaterialsAdmin from "../Admin/ExternalsMaterials";
import MaterialsExternalAdmin from "../AdminExternal/Materials";
import ExternalsReportsAdmin from "../Admin/ExternalsReportsAdmin/Dashboard";
import ExternalsUsers from "../Admin/ExternalsUsers";
import ReportsAdmin from "../Admin/Reports";
import CheckList from "../Admin/CheckList";
import ReportsGreenPoint from "../GreenPoint/Reports";
import Users from "../Admin/Users";
import Indicators from "../Admin/Indicators";
import GreenPoints from "../Admin/GreenPoints";
import DashboardAdmin from "../Admin/Dashboard";
import DashboardGreenPoint from "../GreenPoint/Dashboard";
import GreenPointIndicators from "../GreenPoint/GreenPointIndicators";
import ProfileGreenPoint from "../GreenPoint/Profile";
import ProfileAdmin from "../Admin/Profile";
import RemovalsGreenPoint from "../GreenPoint/Removals";
import ExternalsGreenPointsAdminExtern from "../AdminExternal/GreenPoints";
import ExternalsUsersAdminExtern from "../AdminExternal/Users";
import ExternalRemovalsAdminExtern from "../AdminExternal/Removals";
import DashboardAdminExtern from "../AdminExternal/Dashboard";
import IndicatorsAdminExtern from "../AdminExternal/Indicators";
import AuthRoute from "./AuthRoute";
import AuthRedirect from "./AuthRedirect";
import ExternalRemovalsGreenPoints from "../GreenPointExternal/ExternalsRemovals";
import ExternalDashboardGreenPoints from "../GreenPointExternal/Dashboard";
import GreenPointExternalIndicators from "../GreenPointExternal/GreenPointExternalIndicators";

export default function Routes() {
  return (
    <Switch>
      <AuthRoute
        path="/admin/materials"
        roles={["ADMIN"]}
        component={MaterialsAdmin}
      />
      <AuthRoute
        path="/admin/removals"
        roles={["ADMIN"]}
        component={RemovalsAdmin}
      />
      <AuthRoute
        path="/admin/reports"
        roles={["ADMIN"]}
        component={ReportsAdmin}
      />
      <AuthRoute
        path="/admin/check-list"
        roles={["ADMIN"]}
        component={CheckList}
      />
      <AuthRoute
        path="/admin/greenPoints"
        roles={["ADMIN"]}
        component={GreenPoints}
      />
      <AuthRoute path="/admin/users" roles={["ADMIN"]} component={Users} />
      <AuthRoute
        path="/admin/indicators"
        roles={["ADMIN"]}
        component={Indicators}
      />
      <AuthRoute
        path="/admin"
        exact
        roles={["ADMIN"]}
        component={DashboardAdmin}
      />
      <AuthRoute
        path="/admin/external-users"
        roles={["ADMIN"]}
        component={ExternalsUsers}
      />
      <AuthRoute
        path="/admin/external-greenPoints"
        roles={["ADMIN"]}
        component={ExternalsGreenPointsAdmin}
      />
      <AuthRoute
        path="/admin/external-materials"
        roles={["ADMIN"]}
        component={ExternalsMaterialsAdmin}
      />
      <AuthRoute
        path="/admin/external-indicators"
        roles={["ADMIN"]}
        component={ExternalsIndicatorsAdmin}
      />
      <AuthRoute
        path="/admin/external-removals"
        roles={["ADMIN"]}
        component={ExternalsRemovalsAdmin}
      />
      <AuthRoute
        path="/admin/external-reports"
        roles={["ADMIN"]}
        component={ExternalsReportsAdmin}
      />

      <AuthRoute
        path="/admin/profile"
        roles={["ADMIN"]}
        component={ProfileAdmin}
      />
      <AuthRoute
        path="/greenPoint"
        exact
        roles={["GREENPOINT"]}
        component={RemovalsGreenPoint}
      />
      <AuthRoute
        path="/greenPoint/indicators"
        exact
        roles={["GREENPOINT"]}
        component={GreenPointIndicators}
      />
      <AuthRoute
        path="/greenPoint/dashboard"
        exact
        roles={["GREENPOINT"]}
        component={DashboardGreenPoint}
      />
      <AuthRoute
        path="/greenPoint/removals"
        roles={["GREENPOINT"]}
        component={RemovalsGreenPoint}
      />
      <AuthRoute
        path="/greenPoint/reports"
        roles={["GREENPOINT"]}
        component={ReportsGreenPoint}
      />
      <AuthRoute
        path="/greenPoint/profile"
        roles={["GREENPOINT"]}
        component={ProfileGreenPoint}
      />

      <AuthRoute
        path="/admin-external"
        exact
        roles={["ADMIN_EXTERN"]}
        component={DashboardAdminExtern}
      />
      <AuthRoute
        path="/admin-external/materials"
        exact
        roles={["ADMIN_EXTERN"]}
        component={MaterialsExternalAdmin}
      />
      <AuthRoute
        path="/admin-external/indicators"
        exact
        roles={["ADMIN_EXTERN"]}
        component={IndicatorsAdminExtern}
      />
      <AuthRoute
        path="/admin-external/greenPoints"
        roles={["ADMIN_EXTERN"]}
        component={ExternalsGreenPointsAdminExtern}
      />
      <AuthRoute
        path="/admin-external/users"
        roles={["ADMIN_EXTERN"]}
        component={ExternalsUsersAdminExtern}
      />
      <AuthRoute
        path="/admin-external/profile"
        roles={["ADMIN_EXTERN"]}
        component={ProfileGreenPoint}
      />
      <AuthRoute
        path="/admin-external/removals"
        roles={["ADMIN_EXTERN"]}
        component={ExternalRemovalsAdminExtern}
      />

      <AuthRoute
        path="/greenPoint-external/dashboard"
        roles={["GREENPOINT_EXTERN"]}
        component={ExternalDashboardGreenPoints}
      />
      <AuthRoute
        path="/greenPoint-external/indicators"
        exact
        roles={["GREENPOINT_EXTERN"]}
        component={GreenPointExternalIndicators}
      />
      <AuthRoute
        path="/greenPoint-external/removals"
        roles={["GREENPOINT_EXTERN"]}
        component={ExternalRemovalsGreenPoints}
      />
      <AuthRoute
        path="/greenPoint-external/profile"
        roles={["GREENPOINT_EXTERN"]}
        component={ProfileGreenPoint}
      />

      <Route path="/login" component={Login} />
      <AuthRedirect />
    </Switch>
  );
}
