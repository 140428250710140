import React, { useEffect } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
} from "@material-ui/core";
import {
  GetApp as DownloadIcon,
  LocalShipping as TruckIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";
import Filters from "./Filters";
import UploadExcel from "./UploadExcel";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 2,
    },
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  customTooltip: {
    backgroundColor: "#e68a00",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  customArrow: {
    color: "#ffa31a",
  },
}));

const CreateMassiveRemovalsModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { createMassiveRemovalsModal } = useSelector((state) => state.removals);
  const { loading } = useSelector((state) => state.app);

  const initialValues = {
    greenPoints: [],
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(values);
      const formData = {
        greenPoints: JSON.stringify(values.greenPoints),
      };
      dispatch(removalsActions.getTemplateForRemovals({ formData }));
    },
  });

  const handleClose = () => {
    dispatch(removalsActions.setCreateMassiveRemovalsModal(false));
  };

  useEffect(() => {
    if (createMassiveRemovalsModal) {
      dispatch(
        removalsActions.setMassiveCreateRemovalsResponse({
          isValidData: true,
        })
      );
    }
  }, [createMassiveRemovalsModal]);

  return (
    <Dialog
      open={createMassiveRemovalsModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="md"
      fullWidth
    >
      <>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                <TruckIcon style={{ fontSize: 26, marginBottom: 5 }} /> Carga
                Masiva de Residuos
              </Typography>
            </Grid>
            {/* --- */}
            <Grid item xs={12}>
              <Filters formik={formik} />

              <Box display="flex" justifyContent="flex-end">
                <Tooltip
                  title={"Debe seleccionar al menos un punto GIRA"}
                  placement="left"
                  arrow={true}
                  open={Boolean(formik.values?.greenPoints?.length === 0)}
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                >
                  <Button
                    color="primary"
                    startIcon={<DownloadIcon />}
                    disabled={
                      formik.values?.greenPoints?.length === 0 || loading
                    }
                    onClick={formik.handleSubmit}
                    variant="contained"
                    size="small"
                    loading={loading}
                  >
                    Generar Plantilla
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <UploadExcel handleClose={handleClose} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </>
    </Dialog>
  );
};

export default CreateMassiveRemovalsModal;
