const defaultState = {
  externalMaterialList: [],
  externalMaterialFilters: {
    pagination: {
      rows: 7,
      page: 0,
    },
    search: "",
  },
  /* --- */
  approvedList: [],
  approvedFilters: {
    pagination: {
      rows: 7,
      page: 0,
    },
    search: "",
  },
  /* --- */
  pendinglist: [],
  pendingFilters: {
    pagination: {
      rows: 7,
      page: 0,
    },
    search: "",
  },
  /* --- */
  cards: {
    pending: 0,
    approved: 0,
  },
  selector: "APPROVED",
  group: "",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "ADMIN_EXTERNALS_ALL_MATERIALS_FETCH": {
      return { ...state, externalMaterialList: payload };
    }
    case "ADMIN_EXTERNALS_ALL_MATERIALS_SET_SEARCH": {
      const newStateFilters = {
        ...state.externalMaterialFilters,
        search: payload,
      };
      return { ...state, externalMaterialFilters: newStateFilters };
    }
    case "ADMIN_EXTERNALS_ALL_MATERIALS_SET_PAGINATION": {
      const { pagination } = state.externalMaterialFilters;
      const newStateFilters = {
        ...state.externalMaterialFilters,
        pagination: {
          ...pagination,
          ...payload,
        },
      };
      return { ...state, externalMaterialFilters: newStateFilters };
    }
    /* --- */
    case "ADMIN_EXTERNALS_MATERIALS_APPROVED_FETCH": {
      const updateCards = {
        ...state.cards,
        approved: payload.length,
      };

      return { ...state, approvedList: payload, cards: updateCards };
    }
    case "ADMIN_EXTERNALS_MATERIALS_PENDING_FETCH": {
      const updateCards = {
        ...state.cards,
        pending: payload.length,
      };
      return { ...state, pendinglist: payload, cards: updateCards };
    }
    /* --- */
    case "ADMIN_EXTERNALS_MATERIALS_SET_APPROVED_SEARCH": {
      const newStateFilters = {
        ...state.approvedFilters,
        search: payload,
      };
      return { ...state, approvedFilters: newStateFilters };
    }
    case "ADMIN_EXTERNALS_MATERIALS_SET_PENDING_SEARCH": {
      const newStateFilters = {
        ...state.pendingFilters,
        search: payload,
      };
      return { ...state, pendingFilters: newStateFilters };
    }
    /* --- */
    case "ADMIN_EXTERNALS_MATERIALS_SET_APPROVED_PAGINATION": {
      const { pagination } = state.approvedFilters;
      const newStateFilters = {
        ...state.approvedFilters,
        pagination: {
          ...pagination,
          ...payload,
        },
      };
      return { ...state, pendingFilters: newStateFilters };
    }
    case "ADMIN_EXTERNALS_MATERIALS_SET_PENDING_PAGINATION": {
      const { pagination } = state.pendingFilters;
      const newStateFilters = {
        ...state.pendingFilters,
        pagination: {
          ...pagination,
          ...payload,
        },
      };
      return { ...state, pendingFilters: newStateFilters };
    }
    /* --- */
    case "ADMIN_EXTERNALS_MATERIALS_SET_SELECTOR": {
      return { ...state, selector: payload };
    }
    case "ADMIN_EXTERNALS_MATERIALS_SET_GROUP": {
      return { ...state, group: payload };
    }
    default:
      return state;
  }
}

export default reducer;
