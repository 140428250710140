import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Close as CloseIcon } from "@material-ui/icons";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import removalsActions from "../../../../redux/actions/externalRemovals";

export default function View() {
  const removals = useSelector((state) => state.externalRemovals);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(removalsActions.setViewModal(false));
    dispatch(removalsActions.setDataModal(null));
  };
  return (
    <Dialog
      open={removals.viewModal}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <DialogContent dividers>
        <Header handleClose={handleClose} title="Ver registro" type="VIEW" />
        <Content />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          startIcon={<CloseIcon />}
          onClick={() => handleClose()}
          variant="contained"
          size="small"
        >
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
