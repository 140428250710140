import axios from "axios";
import { saveAs } from "file-saver";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";

const fetchGreenPoints = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalGreenPoint;
      const response = await axios.get(
        "/external-greenPoints/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({ type: "EXTERNALS_GREENPOINTS_FETCH", payload: response.data });
      // dispatch(fetchCards())
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postGreenPoint = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/external-greenPoints/admin", formData);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchGreenPoint = ({ data }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/external-greenPoints/admin", data);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteGreenPoint = (removalID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/external-greenPoints/admin/" + removalID);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const fetchCards = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/external-greenPoints/admin/cards");
      dispatch({
        type: "EXTERNALS_GREENPOINTS_SET_CARDS",
        payload: response.data,
      });
    } catch (e) {}
  };
};

const setSelector = (selector) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_SELECTOR",
      payload: selector,
    });
    await dispatch(fetchGreenPoints());
    return true;
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_CREATE_MODAL",
      payload: value,
    });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_DELETE_MODAL",
      payload: value,
    });
};

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNALS_GREENPOINTS_SET_VIEW_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNALS_GREENPOINTS_SET_EDIT_MODAL", payload: value });
};

const setAprobeModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_APROBE_MODAL",
      payload: value,
    });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNALS_GREENPOINTS_SET_DATA_MODAL", payload: value });
};

const fetchCardsAdminExtern = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        "/external-greenPoints/admin-extern/cards"
      );
      dispatch({
        type: "EXTERNALS_GREENPOINTS_SET_CARDS",
        payload: response.data,
      });
    } catch (e) {}
  };
};

const fetchGreenPointsAdminExtern = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalGreenPoint;
      const response = await axios.get(
        "/external-greenPoints/admin-extern/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({ type: "EXTERNALS_GREENPOINTS_FETCH", payload: response.data });
      dispatch(fetchCardsAdminExtern());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postGreenPointAdminExtern = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/external-greenPoints/admin-extern", formData);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_SUCCESS"));
      dispatch(fetchGreenPointsAdminExtern());
      dispatch(fetchCardsAdminExtern());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchGreenPointAdminExtern = ({ data }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/external-greenPoints/admin-extern", data);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_SUCCESS"));
      dispatch(fetchGreenPointsAdminExtern());
      dispatch(fetchCardsAdminExtern());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteGreenPointAdminExtern = (removalID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/external-greenPoints/admin-extern/" + removalID);
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_SUCCESS"));
      dispatch(fetchGreenPointsAdminExtern());
      dispatch(fetchCardsAdminExtern());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REMOVAL_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setSearch = (value) => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    await dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_SEARCH",
      payload: value,
    });
    if (auth.userType === "ADMIN") {
      dispatch(fetchGreenPoints());
    } else {
      dispatch(fetchGreenPointsAdminExtern());
    }
  };
};

const setPagination = (value) => {
  return async (dispatch, getState) => {
    const auth = getState().auth;
    await dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_PAGINATION",
      payload: value,
    });
    if (auth.userType === "ADMIN") {
      dispatch(fetchGreenPoints());
    } else {
      dispatch(fetchGreenPointsAdminExtern());
    }
  };
};

/* -- */

const putUpdateUnitGoal = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.put("/external-goals/update-unit-goal", formData);
      dispatch(notifiesActions.enqueueNotify("GOAL_UPDATE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GOAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchGoalByGreenpoint = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { dataModal } = getState().externalGreenPoint;
      const response = await axios.get(
        `/external-goals/get-one-by-greenpoint/?greenPoint=${dataModal?._id}&groupID=${dataModal?.groupId?._id}`
      );
      dispatch({
        type: "GOAL_BY_EXTERNALS_GREEBPOINT_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setEditGoalModal = (value) => {
  return (dispatch) => {
    if (value) {
      dispatch(fetchGoalByGreenpoint());
    }
    dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_EDIT_GOAL_MODAL",
      payload: value,
    });
  };
};

const setEditMassiveGoalsModal = (value) => {
  return (dispatch) => {
    dispatch({
      type: "EXTERNALS_GREENPOINTS_SET_EDIT_MASSIVE_GOALS_MODAL",
      payload: value,
    });
  };
};

const fetchGreenPointsForFilter = (groupId) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;

      dispatch(appActions.setLoading(true));
      let response = null;

      if (user?.type === "ADMIN") {
        response = await axios.get(
          `/external-greenPoints/get-all-external-greenpoint-for-admin/${groupId}`
        );
      }
      if (user?.type === "ADMIN_EXTERN") {
        response = await axios.get(
          `/external-greenPoints/get-all-external-greenpoint/${user?.groupID?._id}`
        );
      }
      dispatch({
        type: "EXTERNALS_GREENPOINTS_SET_FILTER",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const getTemplateForGoals = ({ formData }) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      dispatch(appActions.setLoading(true));
      let response = null;
      console.log(formData);

      if (user?.type === "ADMIN") {
        response = await axios.post(
          `/external-greenPoints/generate-template-goals-for-admin/${formData.groupId}`,
          formData,
          {
            responseType: "arraybuffer",
          }
        );
      }

      if (user?.type === "ADMIN_EXTERN") {
        response = await axios.post(
          `/external-greenPoints/generate-template-goals/${user?.groupID?._id}`,
          formData,
          {
            responseType: "arraybuffer",
          }
        );
      }
      const dirtyFileName = response.headers["content-disposition"];
      const regex =
        /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      const fileName = dirtyFileName.match(regex)[3];

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
      dispatch(notifiesActions.enqueueNotify("TEMPLATE_GOALS_CREATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("TEMPLATE_GOALS_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postMassiveUpdateGoals = ({ formData, groupId }) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState().auth;
      dispatch(appActions.setLoading(true));
      const response = await axios.post(
        `/external-goals/admin/massive-update-goals/${
          user?.groupID?._id || groupId
        }`,
        formData
      );
      dispatch({
        type: "SET_MASSIVE_UPDATE_EXTERNALS_GOALS_RESPONSE",
        payload: response.data,
      });
      dispatch(notifiesActions.enqueueNotify("GOALS_MASSIVE_UPDATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch({
        type: "SET_MASSIVE_UPDATE_EXTERNALS_GOALS_RESPONSE",
        payload: err?.response?.data,
      });
      dispatch(appActions.setLoading(false));
      dispatch(notifiesActions.enqueueNotify("GOALS_MASSIVE_UPDATE_FAIL"));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setMassiveUpdateGoalsResponse = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MASSIVE_UPDATE_EXTERNALS_GOALS_RESPONSE",
      payload: value,
    });
  };
};

export default {
  fetchGreenPoints,
  postGreenPoint,
  patchGreenPoint,
  deleteGreenPoint,
  fetchCards,
  fetchGreenPointsAdminExtern,
  postGreenPointAdminExtern,
  patchGreenPointAdminExtern,
  deleteGreenPointAdminExtern,
  fetchCardsAdminExtern,
  setSelector,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setAprobeModal,
  setDataModal,
  setViewModal,
  setSearch,
  setPagination,
  /* --- */
  setEditGoalModal,
  putUpdateUnitGoal,
  setEditMassiveGoalsModal,
  fetchGreenPointsForFilter,
  getTemplateForGoals,
  postMassiveUpdateGoals,
  setMassiveUpdateGoalsResponse,
};
