import React from "react";
import { Box, Typography } from "@material-ui/core";
import BarChart from "./BarChart";
import MonthlyChart from "./MonthlyChart";
import Filters from "./Filters";
import AllGreenPoints from "./AllGreenPoints";

export default function Charts() {
  return (
    <Box>
      <Box>
        <Box
          p={2}
          style={{ border: "1px solid gray" }}
          display="flex"
          justifyContent="Flex-end"
          width="auto"
        >
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems="center"
            width="auto"
          >
            <Filters />
          </Box>
        </Box>
      </Box>
      <Box paddingTop="20px">
        <Typography variant="h5" color="textSecondary" align="center">
          Porcentaje de Recuperación de Residuos por Material
        </Typography>
        <BarChart />
      </Box>
      <Box>
        <Typography variant="h5" color="textSecondary" align="center">
          Porcentaje de Recuperación de Residuos por Mes
        </Typography>
        <MonthlyChart />
      </Box>
      <Box>
        <Typography variant="h5" color="textSecondary" align="center">
          Ranking de recuperación por Punto Gira
        </Typography>
        <AllGreenPoints />
      </Box>
    </Box>
  );
}
