import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";

import Content from "./Content";
import Header from "../../../Shared/Modals/Header";
import groupsActions from "../../../../redux/actions/groups";

export default function ViewModal() {
  const groups = useSelector((state) => state.groups);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(groupsActions.setViewModal(false));
    dispatch(groupsActions.setDataModal(null));
  };

  return (
    groups.dataModal && (
      <Dialog open={groups.viewModal} maxWidth="md" fullWidth>
        <DialogContent>
          <Header
            handleClose={handleClose}
            title={groups.dataModal.name}
            type="VIEW"
          />
          <Content />
        </DialogContent>
        <DialogActions>
          <ButtonGroup variant="contained" size="small">
            <Button
              startIcon={<Close />}
              color="secondary"
              onClick={() => handleClose()}
            >
              Salir
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    )
  );
}
