import axios from "axios";
import { saveAs } from "file-saver";
import app from "./app";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";
import formatQuery from "../../helpers/formatQuery";

const fetchDataDashboard = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const dates = await getState().externalReports.dates;
      const response = await axios.get(`/dashboard/admin-report-extern`, {
        params: { ...dates },
      });
      dispatch({
        type: "EXTERNAL_REPORTS_DASHBOARD_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth());
    }
  };
};

const setDates = ({ dateInit, dateFinish, groupId }) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNAL_REPORTS_DASHBOARD_SET_DATES",
      payload: { dateInit, dateFinish, groupId },
    });
    await dispatch(fetchDataDashboard());
  };
};

const setDateRange = (dateRange) => {
  return (dispatch) => {
    dispatch({
      type: "EXTERNAL_REPORTS_DASHBOARD_SET_DATERANGE",
      payload: dateRange,
    });
  };
};

const createReport = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post("/external-reports/admin", formData, {
        responseType: "arraybuffer",
      });
      console.log(response);
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });
      saveAs(blob, "reporte externo.pdf");
      dispatch(notifiesActions.enqueueNotify("REPORT_CREATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("REPORT_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const getData = (value) => {
  return async (dispatch) => {
    try {
      dispatch(app.setLoading(true));
      const response = await axios.post("/external-reports/admin/getData", {
        ...value,
      });
      dispatch({
        type: "EXTERNAL_REPORTS_SET_DATA_CREATE",
        payload: response.data,
      });
      dispatch(app.setLoading(false));
    } catch (err) {
      dispatch(app.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchGreenPoints = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { createFilters: filters } = getState().externalReports;
      const response = await axios.get(`/indicators/greenpoints-extern`, {
        params: {
          city: filters.city === "ALL" ? "" : filters.city,
          format: filters.format === "ALL" ? "" : filters.format,
          zone: filters.zone === "ALL" ? "" : filters.zone,
          groupId: filters.groupId,
        },
      });
      dispatch({
        type: "EXTERNAL_REPORTS_SET_FILTERS_GREENPOINTS_MODAL",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const updateFilters = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNAL_REPORTS_SET_FILTERS_CREATE_MODAL",
      payload: value,
    });
    dispatch(fetchGreenPoints());
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNAL_REPORTS_SET_CREATE_MODAL", payload: value });
};

const setExportToExcelModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNAL_REPORTS_EXCEL_SET_CREATE_MODAL",
      payload: value,
    });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "EXTERNAL_REPORTS_SET_DATA_MODAL", payload: value });
};

const updateExportFilters = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNAL_UPDATE_EXPORT_FILTERS",
      payload: value,
    });
    dispatch(fetchGreenPointsExport());
  };
};

const fetchGreenPointsExport = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { exportFilters } = getState().externalReports;
      if (exportFilters.formats.length > 0 && exportFilters.zones.length > 0) {
        const response = await axios.get(
          `/external-reports/external-greenpoints/export`,
          {
            params: {
              formats: formatQuery(exportFilters.formats),
              zones: formatQuery(exportFilters.zones),
              groupId: exportFilters.groupId,
            },
          }
        );
        dispatch({
          type: "EXTERNAL_UPDATE_EXPORT_GREENPOINTS",
          payload: response.data,
        });
      }

      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const getExcelFile = (
  name,
  zones,
  formats,
  initialDate,
  endDate,
  greenPoints,
  groupID,
  onlyNewMaterials
) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));

      let URL = "";
      if (onlyNewMaterials) {
        URL = `/external-reports/excel/only-new-materials`;
      }
      if (!onlyNewMaterials) {
        URL = `/external-reports/excel`;
      }

      const response = await axios.post(
        URL,
        { name, zones, formats, initialDate, endDate, greenPoints, groupID },
        {
          responseType: "arraybuffer",
        }
      );
      const dirtyFileName = response.headers["content-disposition"];
      const regex =
        /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      const fileName = dirtyFileName.match(regex)[3];

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

export default {
  fetchDataDashboard,
  setDates,
  setDateRange,
  createReport,
  getData,
  setCreateModal,
  setExportToExcelModal,
  setDataModal,
  updateFilters,
  updateExportFilters,
  fetchGreenPointsExport,
  getExcelFile,
};
