import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import reportsActions from "../../../redux/actions/reports";
import {
  AddCircleOutline as PlusIcon,
  Description as DescIcon,
} from "@material-ui/icons/";
import CreateModal from "./CreateModal";
import DeleteModal from "./DeleteModal";
import ReportsList from "./ReportsList";

export default function Index() {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);

  useEffect(() => {
    const exec = () => {
      dispatch(reportsActions.fetchReports());
    };
    exec();
  }, []);
  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h4">
                <DescIcon style={{ fontSize: 34, marginBottom: 6 }} /> Reportes
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                startIcon={<PlusIcon />}
                size="small"
                onClick={() => dispatch(reportsActions.setCreateModal(true))}
              >
                Crear reporte
              </Button>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>
          <CreateModal />
          <DeleteModal />
          <ReportsList />
        </CardContent>
      </Card>
    </Paper>
  );
}
