import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { ResponsiveBar } from "@nivo/bar";
import ToolTip from "./ToolTip";
import getProgressColor from "../../../../helpers/getProgressColor";

export default function BarChart() {
  const utils = useSelector((state) => state.utils);
  const app = useSelector((state) => state.app);
  const { data } = useSelector((state) => state.indicators);
  const [formatData, setFormatData] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (utils) {
      let chartData = [];
      let initialColors = [];
      if (data.materialProgress.length > 0) {
        chartData = Object.keys(utils.materials).map((key) => {
          const removal = data.materialProgress.find(
            (item) => item.material === key
          );

          const quantity = removal.progress / removal.goal;
          initialColors.push(getProgressColor(quantity));

          return {
            material: key,
            name: utils.materials[key].name,
            quantity: removal.progress / removal.goal,
          };
        });

        const total = data.materialProgress.find(
          (item) => item.material === "TOTAL"
        );
        console.log(total, total.progress / total.goal);
        chartData.push({
          material: "TOTAL",
          name: "Total",
          quantity: (total.progress / total.goal).toFixed(2),
        });
        initialColors.push(getProgressColor(total.progress / total.goal));
      } else {
        chartData = Object.keys(utils.materials).map((key) => {
          initialColors.push(0);
          return {
            material: key,
            name: utils.materials[key].name,
            quantity: 0,
          };
        });
      }
      setFormatData(chartData);
      setColors(initialColors);
    }
  }, [utils, data.materialProgress]);

  const dataZeroValue = (collection) => {
    return collection.filter((item) => item.quantity > 0).length === 0;
  };
  return (
    <Box height="400px" width="100%">
      <ResponsiveBar
        data={formatData}
        keys={["quantity"]}
        indexBy="name"
        margin={
          app.isMobile
            ? { top: 50, right: 15, bottom: 80, left: 30 }
            : { top: 50, right: 50, bottom: 80, left: 80 }
        }
        maxValue={dataZeroValue(formatData) ? 1 : "auto"}
        padding={0.25}
        colors={colors}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colorBy="index"
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        label={(d) => `${(d.value * 100).toFixed(1)} %`}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -20,
          tickValues: "name",
          legend: "MATERIALES",
          legendPosition: "middle",
          legendOffset: 60,
        }}
        markers={[
          {
            axis: "y",
            value: 1,
            lineStyle: { stroke: "rgba(255, 192, 43, 0.8)", strokeWidth: 2 },
            legend: "Meta",
            legendOrientation: "vertical",
          },
        ]}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "PORCENTAJE",
          legendPosition: "middle",
          legendOffset: -60,
        }}
        labelSkipWidth={0}
        labelSkipHeight={0}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={ToolTip}
      />
    </Box>
  );
}
