import React, { useEffect, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import StateCard from "./Card";
import { useSelector } from "react-redux";

export default function Cards() {
  const theme = useTheme();
  const users = useSelector((state) => state.users);

  const [usersCount, setUsersCount] = useState("...");

  useEffect(() => {
    if (users.cards) {
      setUsersCount(users.cards.users);
    }
  }, [users.cards]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6}>
        <StateCard
          title="Total"
          value={usersCount ? usersCount : "..."}
          background={theme.palette.primaryGradient.main}
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
