import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Paper,
  Collapse,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  LocalShipping as RemovalsIcon,
  Assignment as ReportsIcon,
  Settings as ProfileIcon,
  Eco as EcoIcon,
  People as PeopleIcon,
  Inbox as InboxIcon,
  LibraryAddCheck as LibraryAddCheckIcon,
  BarChart as IndicatorIcon,
  ExpandLess,
  ExpandMore,
  Queue as QueueIcon,
} from "@material-ui/icons";
import logo from "../../resources/logos/logo-color.png";
import { useSelector, useDispatch } from "react-redux";
import appActions from "../../redux/actions/app";

const useStyles = makeStyles((_theme) => ({
  mobileList: {
    width: "250px",
    height: "100%",
  },
  toolbarLogo: {
    height: "60px",
    margin: "10px",
  },
}));

const items = {
  ADMIN: [
    {
      text: "Home",
      to: "/admin/",
      icon: <HomeIcon />,
    },
    {
      text: "Tipos de Residuos",
      to: "/admin/materials",
      icon: <QueueIcon />,
    },
    {
      text: "Registros",
      to: "/admin/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Puntos GIRA",
      to: "/admin/greenPoints",
      icon: <EcoIcon />,
    },
    {
      text: "Indicadores",
      to: "/admin/indicators",
      icon: <IndicatorIcon />,
    },
    {
      text: "Reportes",
      to: "/admin/reports",
      icon: <ReportsIcon />,
    },
    {
      text: "Evaluaciones GIRA",
      to: "/admin/check-list",
      icon: <LibraryAddCheckIcon />,
    },
    {
      text: "Externos",
      to: "#",
      icon: <InboxIcon />,
      subMenus: [
        {
          text: "Tipos de Residuos Externos",
          to: "/admin/external-materials",
          icon: <QueueIcon />,
        },
        {
          text: "Indicadores Externos",
          to: "/admin/external-indicators",
          icon: <IndicatorIcon />,
        },
        {
          text: "Registros Externos",
          to: "/admin/external-removals",
          icon: <RemovalsIcon />,
        },

        {
          text: "Puntos Gira Externos",
          to: "/admin/external-greenPoints",
          icon: <EcoIcon />,
        },
        {
          text: "Reportes Externos",
          to: "/admin/external-reports",
          icon: <ReportsIcon />,
        },
        {
          text: "Usuarios Externos",
          to: "/admin/external-users",
          icon: <PeopleIcon />,
        },
      ],
    },
    {
      text: "Usuarios",
      to: "/admin/users",
      icon: <PeopleIcon />,
    },
    {
      text: "Perfil",
      to: "/admin/profile",
      icon: <ProfileIcon />,
    },
  ],
  GREENPOINT: [
    {
      text: "Home",
      to: "/greenPoint/",
      icon: <HomeIcon />,
    },
    {
      text: "Dashboard",
      to: "/greenPoint/dashboard",
      icon: <RemovalsIcon />,
    },
    {
      text: "Indicadores",
      to: "/greenPoint/indicators",
      icon: <IndicatorIcon />,
    },
    {
      text: "Reportes",
      to: "/greenPoint/reports",
      icon: <ReportsIcon />,
    },
    {
      text: "Perfil",
      to: "/greenPoint/profile",
      icon: <ProfileIcon />,
    },
    {
      text: "Registro",
      to: "/greenPoint/removals",
      icon: <RemovalsIcon />,
    },
  ],
  ADMIN_EXTERN: [
    {
      text: "Home",
      to: "/admin-external/",
      icon: <HomeIcon />,
    },
    {
      text: "Tipos de Residuos",
      to: "/admin-external/materials",
      icon: <QueueIcon />,
    },
    {
      text: "Indicadores",
      to: "/admin-external/indicators",
      icon: <IndicatorIcon />,
    },
    {
      text: "Registros",
      to: "/admin-external/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Puntos GIRA",
      to: "/admin-external/greenPoints",
      icon: <EcoIcon />,
    },
    {
      text: "Monitores",
      to: "/admin-external/users",
      icon: <PeopleIcon />,
    },
    {
      text: "Perfil",
      to: "/admin-external/profile",
      icon: <ProfileIcon />,
    },
  ],
  GREENPOINT_EXTERN: [
    {
      text: "Dashboard",
      to: "/greenPoint-external/dashboard",
      icon: <HomeIcon />,
    },
    {
      text: "Indicadores",
      to: "/greenPoint-external/indicators",
      icon: <IndicatorIcon />,
    },
    {
      text: "Registros",
      to: "/greenPoint-external/removals",
      icon: <RemovalsIcon />,
    },
    {
      text: "Perfil",
      to: "/greenPoint-external/profile",
      icon: <ProfileIcon />,
    },
  ],
  EMPTY: [],
};

export default function Menu() {
  const [open, setOpen] = React.useState(true);
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Fragment>
      {auth.loged === "LOGED" && app.isMobile && (
        <Mobile open={open} handleClick={handleClick} />
      )}
      {auth.loged === "LOGED" && !app.isMobile && (
        <Desktop open={open} handleClick={handleClick} />
      )}
    </Fragment>
  );
}

function Mobile({ open, handleClick }) {
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const switchMenu = (open) => dispatch(appActions.switchMenu(open));

  return (
    <Fragment>
      {app.isOpenMenu && (
        <Paper
          className={classes.mobileList}
          onClick={() => switchMenu(false)}
          onKeyDown={() => switchMenu(false)}
        >
          <Grid container item xs={12} justify="center">
            <img
              className={classes.toolbarLogo}
              src={logo}
              alt="Acción Circular"
            />
          </Grid>
          <Divider />
          <List>
            {items[auth.userType].map((element) =>
              element.subMenus ? (
                <div key={"menu_item_" + element.text}>
                  <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={element.text} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {element.subMenus.map((element) => (
                    <Collapse
                      key={"menu_item_" + element.text}
                      in={open}
                      timeout="auto"
                      unmountOnExit
                    >
                      <NavLink
                        exact={true}
                        to={element.to}
                        strict
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <ListItem
                          style={{
                            paddingLeft: 40,
                          }}
                          selected={location.pathname === element.to}
                        >
                          <ListItemIcon>{element.icon}</ListItemIcon>
                          <ListItemText>{element.text}</ListItemText>
                        </ListItem>
                      </NavLink>
                    </Collapse>
                  ))}
                </div>
              ) : (
                <NavLink
                  exact={true}
                  to={element.to}
                  key={"menu_item_" + element.text}
                  strict
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <ListItem>
                    <ListItemIcon>{element.icon}</ListItemIcon>
                    <ListItemText>{element.text}</ListItemText>
                  </ListItem>
                </NavLink>
              )
            )}
          </List>
        </Paper>
      )}
    </Fragment>
  );
}

function Desktop({ open, handleClick }) {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  return (
    <div
      className="sidebar-fixed position-fixed"
      style={{
        paddingTop: "2%",
        marginLeft: 12,
        overflowY: "auto",
        maxHeight: "calc(100vh - 64px)",
      }}
    >
      <Paper>
        <List>
          {items[auth.userType].map((element) =>
            element.subMenus ? (
              <div key={"menu_item_" + element.text}>
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={element.text} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {element.subMenus.map((element) => (
                  <Collapse
                    key={"menu_item_" + element.text}
                    in={open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <NavLink
                      exact={true}
                      to={element.to}
                      strict
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <ListItem
                        style={{
                          paddingLeft: 40,
                        }}
                        selected={location.pathname === element.to}
                      >
                        <ListItemIcon>{element.icon}</ListItemIcon>
                        <ListItemText>{element.text}</ListItemText>
                      </ListItem>
                    </NavLink>
                  </Collapse>
                ))}
              </div>
            ) : (
              <NavLink
                exact={true}
                to={element?.to}
                key={"menu_item_" + element.text}
                strict
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItem selected={location.pathname === element.to}>
                  <ListItemIcon>{element.icon}</ListItemIcon>
                  <ListItemText>{element.text}</ListItemText>
                  {element.subMenus && (open ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
              </NavLink>
            )
          )}
        </List>
      </Paper>
    </div>
  );
}
