import React from "react";
import { Box } from "@material-ui/core";

export default function ToolTip({ value, indexValue, units = "%" }) {
  return (
    <Box p={2}>
      {indexValue.toUpperCase()} <br />
      <strong>{`${(value * 100).toFixed(2)} ${units}`}</strong>
    </Box>
  );
}
