import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endOfDay, startOfMonth, endOfMonth } from "date-fns";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import indicatorsActions from "../../../redux/actions/externalsIndicators";

import Header from "./Header";
import Charts from "./Charts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function GreenPointExternalIndicators() {
  const { filters } = useSelector((state) => state.externalsIndicators);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(startDate);
    dispatch(
      indicatorsActions.updateFilters({
        ...filters,
        differenceMonths: 1,
        initialDate: startDate,
        endDate: endOfDay(endDate),
      })
    );
    dispatch(indicatorsActions.getDetails());
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Charts />
        </CardContent>
      </Card>
    </Paper>
  );
}
