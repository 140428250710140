import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";

export default function General({ formData, setFormData }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          label="Descripción"
          value={formData.description}
          variant="outlined"
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          multiline
          rows={10}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
