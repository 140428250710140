import React, { useState, useEffect } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Grid, Card, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

function Map({ data }) {
  const app = useSelector((state) => state.app);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: 400,
    latitude: -33.43749,
    longitude: -70.651024,
    zoom: 14,
  });

  useEffect(() => {
    setViewport((v) => {
      return {
        ...viewport,
        latitude: parseFloat(data.coordinates.latitude)
          ? parseFloat(data.coordinates.latitude)
          : 0,
        longitude: parseFloat(data.coordinates.longitude)
          ? parseFloat(data.coordinates.longitude)
          : 0,
      };
    });
  }, [data.coordinates.longitude, data.coordinates.latitude]);
  const token = process.env.REACT_APP_MAPGL_API_KEY;

  return (
    <ReactMapGL
      mapboxApiAccessToken={token}
      {...viewport}
      width="100%"
      height={400}
      mapStyle={
        app.theme === "LIGHT"
          ? "mapbox://styles/mapbox/light-v10"
          : "mapbox://styles/mapbox/dark-v10"
      }
      onViewportChange={(viewport) => setViewport(viewport)}
      scrollZoom={true}
    >
      <Marker
        key={0}
        latitude={data.coordinates.latitude}
        longitude={data.coordinates.longitude}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LocationOnIcon
              color="primary"
              style={{
                fontSize: 44,
              }}
            />
          </Grid>
          <Grid item align="center">
            <Card>
              <Typography style={{ margin: 10 }} variant="body1">
                {data.name ? data.name : <em>punto verde</em>}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Marker>
    </ReactMapGL>
  );
}

export default Map;
