import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import removalsActions from "../../../redux/actions/externalRemovals";

export default function Search() {
  const [search, setSearch] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const removals = useSelector((state) => state.externalRemovals);
  const groups = useSelector((state) => state.groups);
  const dispatch = useDispatch();

  useEffect(() => setSearch(removals.search), [removals.search]);
  useEffect(() => setGroupFilter(removals.groupID), [removals.groupID]);

  const handleSubmit = () => {
    dispatch(removalsActions.setSearch({ search }));
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      spacing={2}
      style={{ marginBottom: 10 }}
    >
      <Grid item container xs={8} spacing={2} alignItems="center">
        <Grid item>
          <TextField
            variant="outlined"
            margin="dense"
            placeholder="nombre, apellido, email, teléfono"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{ height: 40, marginTop: 3 }}
            onClick={() => handleSubmit()}
            startIcon={<SearchIcon />}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          fullWidth
          onChange={(e, k) => {
            if (!k) {
              setGroupFilter("");
              dispatch(removalsActions.setGroupID({ groupID: "" }));
            } else {
              setGroupFilter(k);
              dispatch(removalsActions.setGroupID({ groupID: k._id }));
            }
          }}
          options={groups.groupsSelect}
          value={groups.groupsSelect.find((gp) => gp._id === groupFilter._id)}
          getOptionLabel={(option) =>
            option ? option.name : "Selecciona un Grupo"
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filtro por Grupo"
              margin="dense"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
