import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import externalUsersActions from "../../../redux/actions/externalUsers";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function Search() {
  const [search, setSearch] = useState("");
  const externalUsers = useSelector((state) => state.externalUsers);
  const dispatch = useDispatch();

  useEffect(() => setSearch(externalUsers.search), [externalUsers.search]);

  const handleSubmit = () => {
    dispatch(externalUsersActions.setSearch({ search }));
  };
  return (
    <Grid
      container
      alignItems="center"
      style={{ marginBottom: 10 }}
      justify="space-between"
      direct="row"
    >
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              variant="outlined"
              margin="dense"
              placeholder="nombre, apellido, email, teléfono"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ height: 40, marginTop: 3 }}
              onClick={() => handleSubmit()}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <ToggleButtonGroup
          value={externalUsers.userTypes}
          onChange={(e, userTypes) =>
            dispatch(externalUsersActions.setUsertypes(userTypes))
          }
          size="small"
        >
          <ToggleButton
            value="GREENPOINT_EXTERN"
            aria-label="GREENPOINT_EXTERN"
          >
            Monitores Externos
          </ToggleButton>
          <ToggleButton value="ADMIN_EXTERN" aria-label="ADMIN_EXTERN">
            Administradores Externos
          </ToggleButton>
          <ToggleButton value="" aria-label="">
            Libres
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
}
