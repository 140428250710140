import React from "react";

function SvgComponent({ size, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={size}
      height={size}
      viewBox="0 0 555.2 731.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1_paperboard{fill:#4d4129}.prefix__st2_paperboard{fill:#40b3c0}"}</style>
      <path
        d="M464.2 203.3c-.3-2.5-1.7-4.6-3.9-5.9L330 122.7c-3-1.7-6.8-1.3-9.3 1.1l-41.5 38.8c-.7.6-1.2 1.4-1.6 2.2-.4-.8-.9-1.5-1.6-2.2l-41.5-38.8c-2.5-2.4-6.3-2.8-9.3-1.1L94.9 197.4c-2.2 1.3-3.6 3.4-3.9 5.9-.3 2.5.6 4.9 2.4 6.6l41.5 38.9c-.2.2-.3.4-.5.6L95.6 308c-1.2 1.8-1.6 4-1.1 6.1.5 2.1 1.9 3.9 3.7 5l37.7 21.6v55.9c0 7.4 4 14.2 10.4 17.9l120.9 69.4c3.2 1.8 6.7 2.7 10.3 2.7s7.1-.9 10.3-2.7l120.9-69.4c6.4-3.7 10.4-10.5 10.4-17.9v-55.9l37.7-21.6c1.9-1.1 3.2-2.9 3.7-5s.1-4.3-1.1-6.1l-38.8-58.5c-.1-.2-.3-.4-.5-.6l41.5-38.9c2-1.7 2.9-4.2 2.6-6.7z"
        fill="#ede9e6"
      />
      <path
        className="prefix__st2_paperboard"
        d="M278.1 327l-.5 155.9s9.7-3 17.8-7.6c8.1-4.7 116.3-68 116.3-68s3.8-7 3.8-19.8 1.9-48.9 1.9-48.9l-94.3 52.8-45-64.4z"
      />
      <path
        d="M277.6 330.9c-.8 0-1.6-.2-2.3-.6l-136-78c-1.4-.8-2.3-2.4-2.3-4 0-1.7.9-3.2 2.3-4l136-78c1.4-.8 3.2-.8 4.6 0l136 78c1.4.8 2.3 2.4 2.3 4 0 1.7-.9 3.2-2.3 4l-136 78c-.7.4-1.5.6-2.3.6zm-130.5-82.7l130.5 74.9 130.5-74.9-130.5-74.8-130.5 74.8zm265.1 2.4z"
        fill="#231f20"
      />
      <path
        className="prefix__st1_paperboard"
        d="M139.1 252.8L93.4 210c-1.8-1.7-2.7-4.1-2.4-6.6.3-2.5 1.7-4.6 3.9-5.9l130.4-74.8c3-1.7 6.8-1.3 9.3 1.1l41.5 38.8c1.8 1.7 2.7 4.1 2.4 6.6s-1.7 4.6-3.9 5.9l-135.5 77.7zm90-123.8c-.1 0-.2 0-.2.1L98.5 203.8c-.1.1-.2.2-.2.4s0 .3.1.4l41.8 39.1 130.7-74.9c.1-.1.2-.2.2-.4s0-.3-.1-.4l-41.5-38.8c-.1-.1-.2-.2-.4-.2z"
      />
      <path
        className="prefix__st1_paperboard"
        d="M416.1 252.8l-135.4-77.7c-2.2-1.3-3.6-3.4-3.9-5.9-.3-2.5.6-4.9 2.4-6.6l41.5-38.8c2.5-2.4 6.3-2.8 9.3-1.1l130.4 74.8c2.2 1.3 3.6 3.4 3.9 5.9.3 2.5-.6 4.9-2.4 6.6l-45.8 42.8zm-90-123.8c-.1 0-.2 0-.3.1L284.2 168c-.1.1-.2.2-.1.4 0 .2.1.3.2.4L415 243.7l41.8-39.1c.1-.1.2-.2.1-.4 0-.2-.1-.3-.2-.4L326.3 129h-.2zM232.9 395.1c-1.3 0-2.7-.3-3.9-1l-130.7-75c-1.9-1.1-3.2-2.9-3.7-5s-.1-4.3 1.1-6.1l38.8-58.5c2.3-3.5 6.9-4.5 10.5-2.5l130.7 75c1.9 1.1 3.2 2.9 3.7 5s.1 4.3-1.1 6.1l-38.8 58.5c-1.5 2.3-4.1 3.5-6.6 3.5zM141 253.3c-.2 0-.3.1-.4.2L101.8 312c-.1.1-.1.2-.1.4 0 .1.1.2.2.3l130.7 75c.2.1.5.1.6-.1l38.8-58.5c.1-.1.1-.2.1-.4 0-.1-.1-.2-.2-.3l-130.7-75c0-.1-.1-.1-.2-.1z"
      />
      <path
        className="prefix__st1_paperboard"
        d="M322.3 395.1c-2.6 0-5.1-1.2-6.6-3.5L277 333.1c-1.2-1.8-1.6-4-1.1-6.1.5-2.1 1.9-3.9 3.7-5l130.7-75c3.6-2.1 8.2-1 10.5 2.5l38.8 58.5c1.2 1.8 1.6 4 1.1 6.1-.5 2.1-1.9 3.9-3.7 5l-130.7 75c-1.3.7-2.6 1-4 1zm91.9-141.8c-.1 0-.2 0-.2.1l-130.7 75c-.1.1-.2.2-.2.3 0 .1 0 .3.1.4l38.8 58.5c.1.2.4.3.6.1l130.7-75c.1-.1.2-.2.2-.3 0-.1 0-.3-.1-.4l-38.8-58.5c-.1-.1-.2-.2-.4-.2z"
      />
      <path
        className="prefix__st1_paperboard"
        d="M277.6 486.6c-3.6 0-7.1-.9-10.3-2.7l-120.9-69.4C140 410.8 136 404 136 396.6v-58h7.4v58c0 4.7 2.6 9.1 6.7 11.5L271 477.5c4.1 2.3 9.2 2.3 13.2 0l120.9-69.4c4.1-2.4 6.7-6.8 6.7-11.5v-58h7.4v58c0 7.4-4 14.2-10.4 17.9l-120.9 69.4c-3.2 1.8-6.7 2.7-10.3 2.7z"
      />
      <path
        className="prefix__st2_paperboard"
        d="M284.1 179.2l118.6 72.4-118.6 68.9zM154.4 252c1.3-.4 116.5-70 116.5-70s2.1 139.4 0 137.2c-2.2-2.1-116.5-67.2-116.5-67.2z"
      />
      <path className="prefix__st1_paperboard" d="M281.3 482.9h-7.4V169.1h7.4v158h.5z" />
      <g>
        <path
          className="prefix__st1_paperboard"
          d="M200.3 538.4c5.9 0 11.1 2 15.8 6.1 2.8 2.4 3.9 4.2 3.9 6.4 0 3.1-2.4 5.6-5.5 5.6-1.5 0-2.7-.5-4.1-1.8l-1.2-1.1c-2.4-2.3-5.8-3.6-9.2-3.6-7.6 0-13.8 6.6-13.8 14.5s6.3 14.5 14 14.5c3.1 0 6.7-1.3 8.6-3.1l2-1.9c1.3-1.3 2.6-1.8 4.1-1.8 3 0 5.5 2.6 5.5 5.7 0 1.7-.8 3.3-2.6 5.1-4.4 4.6-11.1 7.5-17.5 7.5-13.7 0-25.1-11.8-25.1-26 .1-14.4 11.4-26.1 25.1-26.1zM234.6 590.4c-6.8 0-12-5.7-12-12.9 0-7.5 5.5-13.2 12.6-13.2 2.4 0 4.6.7 7.2 2.1 0-3.7-1.2-5.1-4.3-5.1-.7 0-1.7.2-2.6.4l-2.3.7c-1.4.5-2.2.6-3 .6-2.3 0-3.8-1.7-3.8-4 0-3.9 4.7-6.5 11.6-6.5 4.7 0 8.9 1.4 11.3 4 2.2 2.3 3.1 5.8 3 11.6v15.5c0 2.6-.2 3.6-1.1 4.8-.9 1.3-2.3 2-3.9 2-2 0-3.4-.9-4.5-3.1-2.8 2.1-5.3 3.1-8.2 3.1zm3.1-18.5c-2.6 0-4.7 2.3-4.7 5.1 0 2.9 2.1 5.1 4.9 5.1 2.7 0 4.7-2.1 4.7-4.9-.1-3-2.2-5.3-4.9-5.3zM276.8 553.1c3.4 0 6.2 2.8 6.2 6.2 0 3.3-2.6 6-5.8 6-1.4 0-1.6-.2-3.5-1.3-.7-.5-1.3-.7-1.9-.7-1.5 0-2.1 1.3-2.1 4.6v16.6c-.1 3.4-1.9 5.6-4.8 5.6s-4.9-2.3-4.9-5.6V560.6c0-3 .1-3.6.7-4.7.9-1.7 2.4-2.7 4.1-2.7 2 0 3.5 1.1 4.4 3 2.4-1.9 5-3.1 7.6-3.1z"
        />
        <path
          className="prefix__st1_paperboard"
          d="M288.4 548.5v-1.1c.1-3.5 2-5.6 4.9-5.6 2.8 0 4.8 2.3 4.8 5.6v6.5h2.1c2.9.1 4.8 1.8 4.8 4.5 0 2.6-2 4.5-4.8 4.5h-2.1v21.6c-.1 3.4-2 5.6-4.9 5.6-2.8 0-4.8-2.3-4.8-5.6V563h-.5c-3.1 0-5-1.7-5-4.5 0-3 2-4.6 5.5-4.5v-5.5zM342.1 571.9c0 10.1-8.1 18.5-18 18.5s-18.1-8.3-18.1-18.3c0-10.7 8-19 18.2-19 9.9 0 17.9 8.4 17.9 18.8zm-26.2.1c0 4.5 3.7 8.3 8.2 8.3s8.2-3.8 8.2-8.5c0-4.8-3.6-8.6-8.1-8.6-4.8 0-8.3 3.8-8.3 8.8zm10.6-23.3c-.6.7-1.7 1.2-2.8 1.2-2.3 0-4.1-2-4.1-4.3 0-1.2.4-2.2 1.3-3.1l3.6-3.9c1-1.1 1.8-1.5 3.1-1.5 2.3 0 4.2 2 4.2 4.4 0 1.1-.4 2-1.2 2.8l-4.1 4.4zM369 571.9c0-3-.1-3.9-.7-5.2-.9-2-3.1-3.5-5.3-3.5-3.3 0-5.9 2.9-5.9 6.5v14.9c-.1 3.4-2 5.6-4.9 5.6-2.8 0-4.8-2.3-4.8-5.6v-13.9c0-4.9 1-8.3 3.4-11.5 2.9-3.8 7.4-6.1 12.1-6.1 5.4 0 10.7 3.1 13.6 8 1.4 2.3 2.1 5.8 2.1 9.8v13.6c-.1 3.4-2 5.6-4.8 5.6-2.9 0-4.9-2.3-4.9-5.6v-12.6z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
