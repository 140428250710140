import React, { useState } from "react";
import {
  endOfDay,
  startOfMonth,
  endOfMonth,
  differenceInMonths,
} from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import indicatorsAction from "../../../../../redux/actions/indicators";
import ModalRange from "./ModalRange";

export default function Filters() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState("CURRENT_MONTH");
  const { filters } = useSelector((state) => state.indicators);

  const handleChangeInitialDate = (date) => {
    const initialDate = new Date(date);
    const differenceMonths = differenceInMonths(filters.endDate, initialDate);
    dispatch(
      indicatorsAction.updateFilters({
        ...filters,
        differenceMonths,
        initialDate,
      })
    );
  };

  const handleChangeEndDate = (date) => {
    const endDate = new Date(date);
    const differenceMonths = differenceInMonths(endDate, filters.initialDate);
    dispatch(
      indicatorsAction.updateFilters({
        ...filters,
        differenceMonths,
        endDate,
      })
    );
  };

  const selectCurrentMonth = () => {
    setCurrentOption("CURRENT_MONTH");
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(startDate);
    dispatch(
      indicatorsAction.updateFilters({
        ...filters,
        differenceMonths: 1,
        initialDate: startDate,
        endDate: endOfDay(endDate),
      })
    );
  };

  const selectPreviousMonth = () => {
    setCurrentOption("PREVIOUS_MONTH");
    const now = new Date();
    const previousMonth = new Date(now.getFullYear(), now.getMonth(), -1);
    const startDate = startOfMonth(previousMonth);
    const endDate = endOfMonth(startDate);
    dispatch(
      indicatorsAction.updateFilters({
        ...filters,
        differenceMonths: 1,
        initialDate: startDate,
        endDate: endOfDay(endDate),
      })
    );
  };

  const handleOpenModal = () => {
    setCurrentOption("RANGE");
    setOpen(true);
  };

  return (
    <div>
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <ButtonGroup size="small" variant="contained">
            <Button
              color={currentOption === "RANGE" ? "primary" : "secondary"}
              onClick={handleOpenModal}
            >
              seleccionar rango
            </Button>
            <Button
              color={
                currentOption === "PREVIOUS_MONTH" ? "primary" : "secondary"
              }
              onClick={selectPreviousMonth}
            >
              mes anterior
            </Button>
            <Button
              color={
                currentOption === "CURRENT_MONTH" ? "primary" : "secondary"
              }
              onClick={selectCurrentMonth}
            >
              mes actual
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      {open && (
        <ModalRange
          isOpen={open}
          onClose={() => setOpen(false)}
          filters={filters}
          handleChangeInitialDate={handleChangeInitialDate}
          handleChangeEndDate={handleChangeEndDate}
        />
      )}
    </div>
  );
}
