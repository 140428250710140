import React from "react";
import {
  Card,
  Typography,
  Grid,
  ButtonGroup,
  Button,
  TextField,
  Box,
  InputAdornment,
  CardContent,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { useSelector } from "react-redux";
import ProgressCard from "./ProgressCard";
import TotalProgressCard from "./TotalProgressCard";
import Icon from "../../../Shared/Icon";

export default function Materials({ formData, setFormData, error }) {
  const utils = useSelector((state) => state.utils);
  const { goalProgress } = useSelector((state) => state.goalsGreenPoint);
  const handleChangeMaterialsButtons = ({ type, material }) => {
    let materials = formData.materials;
    if (type === "PLUS") {
      materials[material] += 1;
    }
    if (type === "MINUS" && materials[material]) {
      materials[material] -= 1;
    }
    setFormData({ ...formData, materials });
  };

  const handleChangeMaterialsField = ({ e, material }) => {
    let materials = formData.materials;
    let value = parseFloat(e.target.value);
    if (value >= 0) {
      materials[material] = value;
      setFormData({ ...formData, materials });
    } else {
      materials[material] = 0;
      setFormData({ ...formData, materials });
    }
  };

  return (
    <Grid container spacing={3}>
      {goalProgress && (
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Card>
            <CardContent>
              <TotalProgressCard goalProgress={goalProgress} />
            </CardContent>
          </Card>
        </Grid>
      )}
      {formData.materials &&
        Object.keys(utils.all_materials).map((material) => (
          <Grid item xs={6} sm={4} md={3} key={material + "card"}>
            <Card>
              <Grid
                container
                alignItems="center"
                justify="center"
                spacing={1}
                style={{ padding: 10 }}
              >
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Icon
                      glyph={
                        utils.all_materials[material].iconName
                          ? utils.all_materials[material].iconName
                          : "other"
                      }
                      size={110}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ padding: "5px 20px" }}>
                  <Typography variant="h6" align="center">
                    {utils.all_materials[material].name}
                  </Typography>
                  {utils.all_materials[material].name == "Vidrio" ? (
                    <hr
                      style={{
                        backgroundColor: utils.all_materials[material].color,
                        height: 4,
                        border: "2px solid black",
                      }}
                    />
                  ) : utils.all_materials[material].name == "Latas" ? (
                    <hr
                      style={{
                        backgroundColor: utils.all_materials[material].color,
                        height: 4,
                        border: "2px solid gray",
                      }}
                    />
                  ) : (
                    <hr
                      style={{
                        backgroundColor: utils.all_materials[material].color,
                        height: 4,
                      }}
                    />
                  )}
                </Grid>
                <Grid xs={12}>
                  {goalProgress && goalProgress.hasOwnProperty(material) ? (
                    <ProgressCard
                      type="circular"
                      percentage={Number(
                        (goalProgress[material].total * 100) /
                          goalProgress[material].goal
                      ).toFixed(0)}
                      goal={goalProgress[material].goal}
                    />
                  ) : (
                    <Typography align="center" color="secondary">
                      sin meta asiganada
                    </Typography>
                  )}
                </Grid>
                {goalProgress && (
                  <Grid item xs={9}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      value={String(formData.materials[material])}
                      disabled={!goalProgress.hasOwnProperty(material)}
                      onChange={(e) =>
                        handleChangeMaterialsField({ e, material })
                      }
                      error={error}
                      InputProps={{
                        endAdornment: <InputAdornment>KG</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {goalProgress && (
                    <ButtonGroup fullWidth size="small">
                      <Button
                        variant="contained"
                        color={"secondary"}
                        disabled={
                          !Boolean(formData.materials[material]) ||
                          !goalProgress.hasOwnProperty(material)
                        }
                        onClick={() =>
                          handleChangeMaterialsButtons({
                            type: "MINUS",
                            material,
                          })
                        }
                      >
                        <Remove />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleChangeMaterialsButtons({
                            type: "PLUS",
                            material,
                          })
                        }
                        disabled={!goalProgress.hasOwnProperty(material)}
                      >
                        <Add />
                      </Button>
                    </ButtonGroup>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}
