const defaultState = {
  goalProgress: null,
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "GOALS_GREEN_POINT_PROGRESS_FETCH": {
      return { ...state, goalProgress: payload };
    }
    default:
      return state;
  }
}

export default reducer;
