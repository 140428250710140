import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import groupsAction from "../../../redux/actions/groups";
import externalsMaterialsAction from "../../../redux/actions/externalsMaterials";
import { AddCircleOutline as PlusIcon, Queue } from "@material-ui/icons/";
import Cards from "./Cards";
import ListsMaterials from "./ListsMaterials";

const ExternalsMaterials = () => {
  const dispatch = useDispatch();
  const [groupSelected, setGroupSelected] = useState(null);
  const { groupsSelect } = useSelector((state) => state.groups);

  const onChangeGroup = (currentGroup) => {
    setGroupSelected(currentGroup);
  };

  useEffect(() => {
    dispatch(groupsAction.fetchSelectGroups());
  }, []);

  useEffect(() => {
    dispatch(externalsMaterialsAction.setGroup(groupSelected));
  }, [groupSelected]);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h4">
                <Queue style={{ fontSize: 34, marginBottom: 6 }} /> Tipos de
                Residuos Externos
              </Typography>
            </Grid>

            <hr />
            <Grid item xs={12}>
              <TextField
                label="Grupo"
                variant="outlined"
                fullWidth
                margin="dense"
                select
                SelectProps={{ native: true }}
                onChange={(e) => onChangeGroup(e.target.value)}
              >
                <option value=""></option>

                {groupsSelect.map((group, i) => (
                  <option key={`op${i}-${group._id}`} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </TextField>
              {groupSelected && (
                <>
                  <Cards />
                  <ListsMaterials />
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default ExternalsMaterials;
