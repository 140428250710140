import React, { useEffect, useState } from "react";
import { Grid, useTheme } from "@material-ui/core";
import StateCard from "./Card";
import { useSelector, useDispatch } from "react-redux";
import externalsGreenPointActions from "../../../../redux/actions/externalsGreenPoint";
import groupsActions from "../../../../redux/actions/groups";

export default function Cards() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const externalGreenPoint = useSelector((state) => state.externalGreenPoint);

  const [greenPoints, setGreenPoints] = useState("...");
  const [groups, setGroups] = useState("...");
  const [notifyToggle, setNotifyToggle] = useState(false);
  const [cont, setCont] = useState(0);

  useEffect(() => {
    if (externalGreenPoint.cards) {
      setGreenPoints(externalGreenPoint.cards.greenPoints);
      setGroups(externalGreenPoint.cards.groups);
      setCont(0);
    }
  }, [externalGreenPoint.cards]);

  useEffect(() => {
    let interval = null;
    if (cont < 8) {
      interval = setInterval(() => {
        setNotifyToggle(!notifyToggle);
        setCont(cont + 1);
      }, 300);
      return () => clearInterval(interval);
    } else {
      setNotifyToggle(true);
    }
  }, [notifyToggle, cont]);

  const setSelector = (value) => {
    dispatch(externalsGreenPointActions.setSelector(value));
    if (value === "GREENPOINTS") {
      dispatch(externalsGreenPointActions.fetchGreenPoints());
    }
    if (value === "GROUPS") {
      dispatch(groupsActions.fetchGroups());
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6} onClick={() => setSelector("GREENPOINTS")}>
        <StateCard
          title="Puntos gira Externos"
          value={greenPoints ? greenPoints : "..."}
          background={
            externalGreenPoint.selector === "GREENPOINTS"
              ? theme.palette.successGradient.main
              : theme.palette.primaryGradient.main
          }
        />
      </Grid>
      <Grid item sm={3} xs={6} onClick={() => setSelector("GROUPS")}>
        <StateCard
          title="Grupos"
          value={groups ? groups : "..."}
          background={
            externalGreenPoint.selector === "GROUPS"
              ? theme.palette.success.main
              : groups && notifyToggle
              ? theme.palette.warningGradient.main
              : theme.palette.primaryGradient.main
          }
        />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
