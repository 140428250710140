import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../Shared/Modals/Header";
import externalReportsActions from "../../../../redux/actions/externalReports";
import Content from "./Content";

export default function ExportToExcelModal() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const { exportToExcelModal } = useSelector((state) => state.externalReports);
  return (
    <Dialog
      open={exportToExcelModal}
      onClose={() =>
        dispatch(externalReportsActions.setExportToExcelModal(false))
      }
      maxWidth="md"
      fullScreen={app.isMobile}
      scroll="body"
    >
      <DialogContent dividers>
        <Header
          handleClose={() =>
            dispatch(externalReportsActions.setExportToExcelModal(false))
          }
          title="Exportar metas externas"
          type="VIEW"
        />
        <Content />
      </DialogContent>
    </Dialog>
  );
}
