import React from "react";
import Card from "./Card";
import { Grow, Grid, TablePagination } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalActions from "../../../../redux/actions/externalRemovelsGreenpoints";

export default function CardList() {
  const removals = useSelector((state) => state.externalRemovalsGreenpoints);
  const dispatch = useDispatch();

  const handleChangePage = (_event, page) => {
    if (page > removals.pagination.page) {
      if (removals.removals.length === removals.pagination.rows) {
        dispatch(removalActions.setPagination({ page }));
      }
    } else {
      dispatch(removalActions.setPagination({ page }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      removalActions.setPagination({
        rows: parseInt(event.target.value, 10),
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <hr />
      </Grid>
      {removals.removals.map((removal, i) => (
        <Grow key={"card_" + i} in={true} timeout={1000 - 250 * i}>
          <Grid item xs={6} sm={4} md={3} key={i}>
            <Card removal={removal} key={"card_" + i} />
          </Grid>
        </Grow>
      ))}
      <Grid item xs={12}>
        <hr />
        <TablePagination
          labelRowsPerPage={"Retiros por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[4, 8, 24]}
          component="div"
          count={-1}
          rowsPerPage={removals.pagination.rows}
          page={removals.pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}
