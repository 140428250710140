import React, { useEffect, Fragment } from "react";
import { endOfDay } from "date-fns";
import { TextField, Button, ButtonGroup, Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import externalReportsActions from "../../../../redux/actions/externalReports";
import groupActions from "../../../../redux/actions/groups";

export default function Dates() {
  const externalReports = useSelector((state) => state.externalReports);
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups);

  useEffect(() => {
    const exec = () => {
      dispatch(groupActions.fetchSelectGroups());
    };
    exec();
  }, []);

  useEffect(() => {
    let dates = {
      dateInit: null,
      dateFinish: null,
      groupId: "ALL",
    };
    const now = new Date();
    if (externalReports.dateRange === "CURRENT") {
      dates = {
        dateInit: new Date(now.getFullYear(), now.getMonth(), 1),
        dateFinish: new Date(now.getFullYear(), now.getMonth() + 1, 1),
        groupId: "ALL",
      };
      dispatch(externalReportsActions.setDates({ ...dates }));
    }
    if (externalReports.dateRange === "PREVIUS") {
      dates = {
        dateInit: new Date(now.getFullYear(), now.getMonth() - 1, 1),
        dateFinish: new Date(now.getFullYear(), now.getMonth(), 1),
        groupId: "ALL",
      };
      dispatch(externalReportsActions.setDates({ ...dates }));
    }
  }, [externalReports.dateRange]);

  const setDate = ({ date, type }) => {
    let value = date;

    if (type !== "groupId") {
      value = new Date(date);
    }

    dispatch(
      externalReportsActions.setDates({
        ...externalReports.dates,
        [type]: value,
      })
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2} justify="flex-end">
        <Grid item xs={2}>
          <TextField
            label="Grupo"
            variant="outlined"
            select
            inputProps={{
              style: {
                paddingTop: 6,
                paddingBottom: 6,
                paddingRight: 10,
              },
            }}
            SelectProps={{ native: true }}
            onChange={(e) => {
              let date = e.target.value;
              setDate({ date, type: "groupId" });
            }}
          >
            <option value="ALL">TODOS</option>
            {groups.groupsSelect.map((item, i) => (
              <option key={`op${i}-${item._id}`} value={item._id}>
                {item.name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={10}>
          <ButtonGroup size="small" variant="contained">
            <Button
              onClick={() =>
                dispatch(externalReportsActions.setDateRange("RANGE"))
              }
              color={
                externalReports.dateRange === "RANGE" ? "primary" : "secondary"
              }
            >
              seleccionar rango
            </Button>
            <Button
              onClick={() =>
                dispatch(externalReportsActions.setDateRange("PREVIUS"))
              }
              color={
                externalReports.dateRange === "PREVIUS"
                  ? "primary"
                  : "secondary"
              }
            >
              mes anterior
            </Button>
            <Button
              onClick={() =>
                dispatch(externalReportsActions.setDateRange("CURRENT"))
              }
              color={
                externalReports.dateRange === "CURRENT"
                  ? "primary"
                  : "secondary"
              }
            >
              mes actual
            </Button>
            <Button
              onClick={() =>
                dispatch(externalReportsActions.setCreateModal(true))
              }
              color="secondary"
            >
              crear reporte pdf externo
            </Button>
            <Button
              onClick={() =>
                dispatch(externalReportsActions.setExportToExcelModal(true))
              }
              color="secondary"
            >
              crear reporte excel externo
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      {externalReports.dateRange === "RANGE" && (
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              label="Fecha inicial"
              value={externalReports.dates.dateInit}
              maxDate={externalReports.dates.dateFinish}
              disableFuture
              onChange={(date) => setDate({ date, type: "dateInit" })}
            />
          </Grid>
          <Grid item>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              label="Fecha final"
              value={externalReports.dates.dateFinish}
              minDate={externalReports.dates.dateInit}
              onChange={(date) =>
                setDate({
                  date: endOfDay(new Date(date)),
                  type: "dateFinish",
                })
              }
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
