import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMonths,
  startOfMonth,
  subMonths,
  endOfMonth,
  endOfDay,
  startOfDay,
} from "date-fns";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  Input,
  OutlinedInput,
  Menu,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import formats from "../../../../resources/formats.json";
import zones from "../../../../resources/zones.json";
import useMenu from "../../../../hooks/useMenu";
import indicatorsActions from "../../../../redux/actions/indicators";
import getDateFormated from "../../../../helpers/FormatHours";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
}));

export default function Content() {
  const classes = useStyles();
  const [date, setDate] = useState({
    type: "CURRENT",
    initialDate: null,
    endDate: null,
  });
  const dispatch = useDispatch();
  const { exportFilters } = useSelector((state) => state.indicators);

  const { open, anchorEl, handleOpen, handleClose } = useMenu();
  const [fileName, setFileName] = useState("Reporte de metas");
  const [greenPoints, setGreenPoints] = useState([]);
  const [onlyNewMaterials, setOnlyNewMaterials] = useState(false);

  const handleChangeZones = (event) => {
    dispatch(
      indicatorsActions.updateExportFilters({
        ...exportFilters,
        zones: event.target.value,
      })
    );
  };

  const handleChangeFormats = (event) => {
    dispatch(
      indicatorsActions.updateExportFilters({
        ...exportFilters,
        formats: event.target.value,
      })
    );
  };
  const getCheckedGreenPoints = (list) => {
    return list.filter((item) => item.checked).map((item) => item._id);
  };

  const checkGreenPoint = (id) => {
    const newGreenPoints = greenPoints.map((item) => {
      return item._id !== id ? item : { ...item, checked: !item.checked };
    });
    setGreenPoints(newGreenPoints);
  };
  const checkAllGreenPoints = () => {
    const allSelected =
      greenPoints.filter((item) => !item.checked).length === 0;

    const newGreenPoints = greenPoints.map((item) => ({
      ...item,
      checked: !allSelected,
    }));
    setGreenPoints(newGreenPoints);
  };

  const onGenerateFile = () => {
    dispatch(
      indicatorsActions.getExcelFile(
        fileName,
        exportFilters.zones,
        exportFilters.formats,
        getDateFormated(date.initialDate),
        getDateFormated(date.endDate),
        getCheckedGreenPoints(greenPoints),
        onlyNewMaterials
      )
    );
  };

  useEffect(() => {
    let initialDate = null;
    let endDate = null;
    switch (date.type) {
      case "PREVIOUS":
        endDate = startOfMonth(new Date());
        initialDate = subMonths(endDate, 1);
        break;
      case "RANGE":
        initialDate = new Date();
        endDate = addMonths(initialDate, 1);
        break;
      default:
        initialDate = startOfMonth(new Date());
        endDate = endOfMonth(initialDate);
        break;
    }
    setDate({ ...date, initialDate, endDate });
  }, [date.type]);

  useEffect(() => {
    const initialDate = startOfMonth(new Date());
    const endDate = addMonths(initialDate, 1);
    setDate({ ...date, initialDate, endDate });
    dispatch(
      indicatorsActions.updateExportFilters({
        ...exportFilters,
        formats: formats.map((item) => item.code),
        zones: zones.map((item) => item.code),
      })
    );
  }, []);

  useEffect(() => {
    setGreenPoints(
      exportFilters.greenPointList.map((item) => ({ ...item, checked: true }))
    );
  }, [exportFilters.greenPointList]);

  return (
    <Box minWidth="500px" p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Archivo</Typography>
          <TextField
            variant="outlined"
            fullWidth
            margin="dense"
            label="Nombre del archivo"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6">Fecha</Typography>
          </Box>
          <Box paddingTop="10px">
            <ButtonGroup size="small" variant="contained">
              <Button
                color={date.type === "CURRENT" ? "primary" : "secondary"}
                onClick={() => setDate({ ...date, type: "CURRENT" })}
                variant="contained"
                size="small"
              >
                Mes actual
              </Button>
              <Button
                color={date.type === "PREVIOUS" ? "primary" : "secondary"}
                onClick={() => setDate({ ...date, type: "PREVIOUS" })}
                variant="contained"
                size="small"
              >
                Mes anterior
              </Button>
              <Button
                color={date.type === "RANGE" ? "primary" : "secondary"}
                onClick={() => setDate({ ...date, type: "RANGE" })}
                variant="contained"
                size="small"
              >
                Seleccionar rango
              </Button>
            </ButtonGroup>
          </Box>
          {date.type === "RANGE" && (
            <Box paddingTop="10px">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    inputVariant="outlined"
                    margin="dense"
                    fullWidth
                    label="Fecha inicial"
                    value={date.initialDate}
                    onChange={(d) =>
                      setDate({ ...date, initialDate: startOfDay(new Date(d)) })
                    }
                    disableFuture
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    inputVariant="outlined"
                    margin="dense"
                    fullWidth
                    label="Fecha final"
                    value={date.endDate}
                    onChange={(d) =>
                      setDate({ ...date, endDate: endOfDay(new Date(d)) })
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6">Formato</Typography>
            <FormControl style={{ width: "100%" }}>
              <Select
                labelId="formats-checkbox-label"
                id="formats-mutiple-checkbox"
                multiple
                value={exportFilters.formats}
                onChange={handleChangeFormats}
                input={<OutlinedInput margin="dense" />}
                renderValue={(selected) => `${selected.length} seleccionados`}
                MenuProps={{
                  PaperProps: {
                    style: {
                      minWidth: 250,
                      textAlign: "right",
                    },
                  },
                }}
              >
                {formats.map((format) => (
                  <MenuItem key={format.code} value={format.code}>
                    <Checkbox
                      checked={exportFilters.formats.indexOf(format.code) > -1}
                    />
                    <ListItemText primary={format.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6">Zona</Typography>
            <FormControl style={{ width: "100%" }}>
              <Select
                labelId="zone-checkbox-label"
                id="zone-mutiple-checkbox"
                multiple
                value={exportFilters.zones}
                onChange={handleChangeZones}
                input={<OutlinedInput margin="dense" />}
                renderValue={(selected) => `${selected.length} seleccionados`}
                MenuProps={{
                  PaperProps: {
                    style: {
                      minWidth: 250,
                    },
                  },
                }}
              >
                {zones.map((zone) => (
                  <MenuItem key={zone.code} value={zone.code}>
                    <Checkbox
                      checked={exportFilters.zones.indexOf(zone.code) > -1}
                    />
                    <ListItemText primary={zone.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h6">Puntos Gira</Typography>
            <TextField
              label={`${greenPoints.length} Puntos Gira encontrados`}
              variant="outlined"
              fullWidth
              margin="dense"
              onClick={handleOpen}
              value={`${
                greenPoints.filter((item) => item.checked).length
              } seleccionados`}
            ></TextField>

            <Menu
              open={open}
              anchorEl={anchorEl}
              keepMounted
              onClose={handleClose}
              classes={{ paper: classes.paper }}
            >
              <Box p={2}>
                <Box padding="5px 10px">
                  <Typography style={{ fontWeight: "bold" }}>
                    PUNTOS GIRA
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4}>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              greenPoints.filter((item) => !item.checked)
                                .length === 0
                            }
                            color="primary"
                            onChange={checkAllGreenPoints}
                          />
                        }
                        label={"Todos"}
                      />
                    </MenuItem>
                  </Grid>
                  {greenPoints.map((greenPoint) => (
                    <Grid item xs={6} md={4} key={greenPoint._id}>
                      <MenuItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={greenPoint.checked}
                              color="primary"
                              onChange={() => checkGreenPoint(greenPoint._id)}
                            />
                          }
                          label={greenPoint.name}
                        />
                      </MenuItem>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Menu>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyNewMaterials}
                  color="primary"
                  onChange={() => setOnlyNewMaterials(!onlyNewMaterials)}
                />
              }
              label={"Exportar solo materiales Secundarios"}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={onGenerateFile}
              startIcon={<SaveIcon />}
            >
              Descargar archivo
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
