import axios from "axios";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";

const fetchProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.get("/users/greenPoint");
      dispatch({ type: "PROFILE_FETCH", payload: response.data });
      dispatch({ type: "AUTH_SET_USER", payload: response.data });
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const patchUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/users/greenPoint", data);
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_SUCCESS"));
      dispatch(fetchProfile());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("USER_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setEditProfile = (value) => {
  return (dispatch) => {
    dispatch({ type: "PROFILE_SET_EDIT", payload: value });
  };
};

// const getGreenPoint = async () => {
//   return (dispatch) => {
//     try {
//       dispatch(appActions.setLoading(true));
//       const response = await axios.get("/users/greenPoint");
//       dispatch({ type: "PROFILE_FETCH", payload: response.data });
//       dispatch({ type: "AUTH_SET_USER", payload: response.data });
//       dispatch(appActions.setLoading(false));
//     } catch (err) {}
//   };
// };

export default {
  fetchProfile,
  patchUser,
  setEditProfile,
  // getGreenPoint,
};
