import axios from "axios";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";

const fetchCards = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/evaluations/admin/cards");
      dispatch({ type: "CHECKLIST_SET_CARDS", payload: response.data });
    } catch (e) {}
  };
};

const fetchCheckList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().checkLists;
      const response = await axios.get(
        "/evaluations/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search +
          "&selector=" +
          state.selector
      );
      dispatch({ type: "CHECKLIST_FETCH", payload: response.data });
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchEvaluation = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/evaluations/admin", formData);
      dispatch(
        notifiesActions.enqueueNotify("CHECKLIST_UPDATE_SUCCESS")
      );
      dispatch(fetchCheckList());
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const createEvaluation = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post("/evaluations/admin/", formData);
      dispatch(fetchCheckList());
      dispatch(fetchCards());
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_CREATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const deleteEvaluation = (checkListId) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete(`/evaluations/admin/${checkListId}`);
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_DELETE_SUCCESS"));
      dispatch(fetchCheckList());
      dispatch(fetchCards());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("CHECKLIST_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "CHECKLIST_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "CHECKLIST_SET_DELETE_MODAL", payload: value });
};

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "CHECKLIST_SET_VIEW_MODAL", payload: value });
};

const setEditMode = (value) => {
  return (dispatch) =>
    dispatch({ type: "CHECKLIST_SET_EDIT_MODE", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "CHECKLIST_SET_DATA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "CHECKLIST_SET_SEARCH", payload: value });
    dispatch(fetchCheckList());
  };
};

const setSelector = (selector) => {
  return async (dispatch) => {
    await dispatch({ type: "CHECKLIST_SET_SELECTOR", payload: selector });
    await dispatch(fetchCheckList());
    return true;
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "CHECKLIST_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchCheckList());
  };
};

export default {
  fetchCheckList,
  patchEvaluation,
  deleteEvaluation,
  createEvaluation,
  setCreateModal,
  setDeleteModal,
  setDataModal,
  setSearch,
  setPagination,
  fetchCards,
  setSelector,
  setViewModal,
  setEditMode,
};
