import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Check, Close, ContactsOutlined } from "@material-ui/icons";
import materialsActions from "../../../../redux/actions/materials";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";

const MaterialsModal = ({ open, onClose, type, currentMaterial }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const initialState = {
    name: "",
    createdBy: auth.user._id,
  };
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const handleSuccess = () => {
    if (!formData.name) {
      setError(true);
    } else {
      if (type === "CREATE") {
        dispatch(materialsActions.postMaterial({ formData }));
      }
      if (type === "UPDATE") {
        dispatch(materialsActions.patchMaterial({ formData }));
      }
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  useEffect(() => {
    if (type === "CREATE") {
      setFormData(initialState);
    }
    if (type === "UPDATE") {
      setFormData({
        _id: currentMaterial._id,
        name: currentMaterial.name,
        specificGoal: currentMaterial.specificGoal,
        state: currentMaterial.state,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <Header
          handleClose={onClose}
          title={
            type === "CREATE"
              ? `Agregar Tipo de Residuo`
              : `Editar Tipo de Residuo`
          }
          type={type}
        />
        <Content
          formData={formData}
          setFormData={setFormData}
          error={error}
          type={type}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button startIcon={<Close />} color="secondary" onClick={onClose}>
            Salir
          </Button>
          <Button startIcon={<Check />} color="primary" onClick={handleSuccess}>
            {type === "CREATE" ? `Guardar` : `Actualizar`}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default MaterialsModal;
