import React, { useEffect } from "react";
import { Paper, makeStyles, Card, CardContent } from "@material-ui/core";
import RemovalList from "./RemovalList";
import Cards from "./Cards";
import CreateModal from "./CreateModal";
import ViewModal from "./ViewModal";
import DeleteModal from "./DeleteModal";
import EditModal from "./EditModal";
import CreateMassiveRemovalsModal from "./CreateMassiveRemovalsModal";
import AprobeModal from "./AprobeModal";
import Search from "./Search";
import Header from "./Header";
import removalsActions from "../../../redux/actions/removals";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Removals() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const exec = () => {
      dispatch(removalsActions.fetchRemovals());
    };
    exec();
  }, []);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <Cards />
          <Search />
          <CreateModal />
          <ViewModal />
          <DeleteModal />
          <EditModal />
          <CreateMassiveRemovalsModal />
          <AprobeModal />
          <RemovalList />
        </CardContent>
      </Card>
    </Paper>
  );
}
