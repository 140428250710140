import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import reportsActions from "../../../../redux/actions/reports";

import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

export default function ModalDelete() {
  const reports = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    createdAt: null,
    dateInit: null,
    dateFinish: null,
    type: "",
  });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (reports.dataModal) {
      setData({
        ...reports.dataModal,
      });
    }
  }, [reports.dataModal]);

  const handleClose = () => {
    dispatch(reportsActions.setDataModal(null));
    dispatch(reportsActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    if (check) {
      dispatch(reportsActions.deleteReport(reports.dataModal._id));
      handleClose();
      setCheck(false);
    }
  };

  return (
    <Dialog open={reports.deleteModal} onClose={handleClose} scroll={"body"}>
      <DialogContent>
        <Header
          handleClose={handleClose}
          type="DELETE"
          title="Eliminar reporte"
        />
        <Content data={data} check={check} setCheck={setCheck} />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleDelete={handleDelete}
          check={check}
        />
      </DialogActions>
    </Dialog>
  );
}
