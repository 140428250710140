import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Switch, FormControlLabel } from "@material-ui/core";
import { ResponsiveBar } from "@nivo/bar";
import getProgressColor from "../../../../helpers/getProgressColor";
import ToolTip from "./ToolTip";

const ValueModeToolTip = ({ value, indexValue }) => {
  return (
    <Box p={2}>
      {indexValue.toUpperCase()} <br />
      <strong>{`${value} Kg`}</strong>
    </Box>
  );
};

export default function MonthlyChart() {
  const app = useSelector((state) => state.app);
  const { data } = useSelector((state) => state.externalsIndicators);
  const [formatData, setFormatData] = useState([]);
  const [valueMode, setValueMode] = useState(false);
  const [colors, setColors] = useState([]);

  const validatePercentage = (value) => {
    if (isNaN(value)) {
      return 0;
    }
    if (value > 100) {
      return 1;
    }
    return value;
  };

  /* const formaterValue = (progress, goal) => {
    if (progress / goal === Infinity) return 0.0;
    if (progress / goal !== Infinity) return (progress / goal).toFixed(3);
  }; */

  const buildData = (collection) => {
    console.log(collection);
    const dataColors = [];
    const data = collection.map((item) => {
      const percentage = valueMode
        ? item.progress
        : validatePercentage(item.progress / item.goal);
      dataColors.push(
        getProgressColor(validatePercentage(item.progress / item.goal))
      );
      return {
        month: item.monthName,
        name: item.monthName,
        percentage: percentage,
        goal: item.goal,
      };
    });
    setColors(dataColors);
    return data;
  };

  useEffect(() => {
    if (data.monthlyProgress) {
      setFormatData(buildData(data.monthlyProgress));
    }
  }, [data.monthlyProgress, valueMode]);

  return (
    <Box>
      <Box p={2} display="flex" justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={valueMode}
              onChange={(e) => setValueMode(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="Ver valores"
        />
      </Box>
      <Box height="400px" width="100%">
        <ResponsiveBar
          data={formatData}
          keys={["percentage"]}
          indexBy="name"
          margin={
            app.isMobile
              ? { top: 20, right: 15, bottom: 80, left: 30 }
              : { top: 20, right: 30, bottom: 80, left: 80 }
          }
          padding={0.25}
          colors={colors}
          colorBy="index"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          label={(d) =>
            ` ${
              valueMode ? ` ${d.value} kg` : `${(d.value * 100).toFixed(1)} %`
            }`
          }
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -20,
            tickValues: "name",
            legend: "MESES",
            legendPosition: "middle",
            legendOffset: 60,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${valueMode ? "TOTAL KG" : "PORCENTAJE"}`,
            legendPosition: "middle",
            legendOffset: -60,
          }}
          labelSkipWidth={0}
          labelSkipHeight={0}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltip={valueMode ? ValueModeToolTip : ToolTip}
        />
      </Box>
    </Box>
  );
}
