import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import checkLisActions from "../../../../redux/actions/checkList";

import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

export default function ModalDelete() {
  const checkList = useSelector((state) => state.checkLists);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    createdAt: null,
    dateInit: null,
    dateFinish: null,
    type: "",
  });
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (checkList.dataModal) {
      setData({
        ...checkList.dataModal,
      });
    }
  }, [checkList.dataModal]);

  const handleClose = () => {
    dispatch(checkLisActions.setDataModal(null));
    dispatch(checkLisActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    if (check) {
      dispatch(checkLisActions.deleteEvaluation(checkList.dataModal._id));
      handleClose();
      setCheck(false);
    }
  };

  return (
    <Dialog open={checkList.deleteModal} onClose={handleClose} scroll={"body"}>
      <DialogContent>
        <Header
          handleClose={handleClose}
          type="DELETE"
          title="Eliminar evaluación"
        />
        <Content data={data} check={check} setCheck={setCheck} />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleDelete={handleDelete}
          check={check}
        />
      </DialogActions>
    </Dialog>
  );
}
