import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  ButtonGroup,
  makeStyles,
  withStyles,
  Chip,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import externalUsersActions from "../../../redux/actions/externalUsers";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "groupID",
    numeric: false,
    disablePadding: false,
    label: "Grupo",
  },
  {
    id: "entityID",
    numeric: false,
    disablePadding: false,
    label: "Punto GIRA",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Tipo",
  },
  {
    id: "datetimeCreated",
    numeric: false,
    disablePadding: false,
    label: "Activo desde",
  },
  { id: "options", numeric: false, disablePadding: false, label: "Opciones" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const externalUsers = useSelector((state) => state.externalUsers);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [dense] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    if (externalUsers.users) {
      setRows(externalUsers.users);
    }
  }, [externalUsers.users]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event, page) => {
    if (page > externalUsers.pagination.page) {
      if (rows.length === externalUsers.pagination.rows) {
        dispatch(externalUsersActions.setPagination({ page }));
      }
    } else {
      dispatch(externalUsersActions.setPagination({ page }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      externalUsersActions.setPagination({
        rows: parseInt(event.target.value, 10),
      })
    );
  };

  const emptyRows = externalUsers.pagination.rows - rows.length;

  const translateType = (value) => {
    if (value.type === "GREENPOINT_EXTERN") {
      return "Monitor GIRA Externo";
    }
    if (value.type === "ADMIN_EXTERN") {
      return "Administrador Externo";
    }
    if (value.type === "GREENPOINT") {
      return "Monitor GIRA";
    }
    if (value.type === "ADMIN") {
      if (value._id === auth.user._id) {
        return "Administrador (Tú)";
      } else {
        return "Administrador";
      }
    }
    return "unk";
  };

  const options = ({ action, data }) => {
    if (action === "DELETE") {
      dispatch(externalUsersActions.setDataModal(data));
      dispatch(externalUsersActions.setDeleteModal(true));
    }
    if (action === "EDIT") {
      dispatch(externalUsersActions.setDataModal(data));
      dispatch(externalUsersActions.setEditModal(true));
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <StyledTableCell align="left">
                      {row.name + " " + row.lastName}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.email}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.groupID ? row.groupID.name : "Libre"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.type === "ADMIN_EXTERN"
                        ? "---"
                        : row.externalEntityID
                        ? row.externalEntityID.name
                        : "Libre"}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Chip
                        color="secondary"
                        size="small"
                        label={translateType(row)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {new Intl.DateTimeFormat("es-Es", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(row.createdAt ? row.createdAt : null))}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <ButtonGroup size="small" fullWidth variant="contained">
                        <Button
                          onClick={() => options({ action: "EDIT", data: row })}
                          color="primary"
                        >
                          <VisibilityIcon />
                        </Button>
                        <Button
                          disabled={row._id === auth.user._id}
                          onClick={() =>
                            options({ action: "DELETE", data: row })
                          }
                          color="secondary"
                        >
                          <DeleteIcon />
                        </Button>
                      </ButtonGroup>
                    </StyledTableCell>
                  </TableRow>
                )
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14, 28]}
          component="div"
          count={-1}
          rowsPerPage={externalUsers.pagination.rows}
          page={externalUsers.pagination.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
