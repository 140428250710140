import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/externalRemovelsGreenpoints";
import { ArrowForwardIos, ArrowBackIos, Check } from "@material-ui/icons";
import Header from "../../../Shared/Modals/Header";
import Materials from "./Materials";
import General from "./General";

const initialState = {
  materials: null,
  description: "",
  datetimeRemoval: new Date(),
};

export default function CreateModal() {
  const dispatch = useDispatch();
  const removals = useSelector((state) => state.externalRemovalsGreenpoints);
  const app = useSelector((state) => state.app);

  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 1500);
    }
  }, [error]);

  useEffect(() => {
    if (removals.editModal && removals.dataModal) {
      let materials = {};
      removals.dataModal.materials.forEach((material) => {
        materials[material.material] = material.quantity;
      });
      setFormData({ ...removals.dataModal, materials });
    }
  }, [removals.editModal, removals.dataModal]);

  const handleClose = () => {
    dispatch(removalsActions.setEditModal(false));
    setStep(1);
    setFormData(initialState);
  };

  const handleProgress = async (action) => {
    if (action === "NEXT") {
      if (step === 1) {
        let sum = await Object.keys(formData.materials).reduce(
          (total, current) => (total += formData.materials[current] * 1),
          0
        );
        if (sum) {
          setStep(2);
        } else {
          setError(true);
        }
      }
      if (step === 2) {
        const materials = Object.keys(formData.materials).map((material) => {
          return {
            material,
            quantity: formData.materials[material],
          };
        });
        formData.materials = materials;
        dispatch(removalsActions.patchRemovals({ formData }));
        handleClose();
      }
    }
    if (action === "PREVIUS" && step > 1) {
      setStep(step - 1);
    }
  };

  const titles = () => {
    if (step === 1) {
      return "Registrar nuevo retiro - Materiales";
    }
    if (step === 2) {
      return "Registrar nuevo retiro - Descripción";
    }
  };

  return (
    <Dialog
      open={removals.editModal}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      scroll={"body"}
      fullScreen={app.isMobile}
    >
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Header handleClose={handleClose} title={titles()} type="VIEW" />
          </Grid>
          {step === 1 && (
            <Grid item xs={12}>
              <Materials
                formData={formData}
                setFormData={setFormData}
                error={error}
              />
            </Grid>
          )}
          {step === 2 && (
            <Grid item xs={12}>
              <General formData={formData} setFormData={setFormData} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="primary"
            startIcon={<ArrowBackIos />}
            disabled={step === 1}
            onClick={() => handleProgress("PREVIUS")}
          >
            atrás
          </Button>
          <Button
            color="primary"
            endIcon={step === 2 ? <Check /> : <ArrowForwardIos />}
            onClick={() => handleProgress("NEXT")}
          >
            {step === 2 ? "actualizar" : "siguiente"}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
