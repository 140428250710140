import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Description as DescIcon } from "@material-ui/icons/";
import Dates from "./Dates";

export default function Header() {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4">
          <DescIcon style={{ fontSize: 34, marginBottom: 6 }} /> Reportes
          Externos
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "end" }}>
        <Dates />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
