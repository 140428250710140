import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Icon from "../../../../Shared/Icon";

export default function GoalByMaterial() {
  const app = useSelector((state) => state.app);
  const { goal } = useSelector((state) => state.indicators);

  return (
    <Box>
      {goal?.details ? (
        <Grid container spacing={4}>
          {goal &&
            goal.details &&
            !app.loading &&
            goal.details.map((item) => (
              <Grid item xs={6} md={4} lg={3} key={`material-card-${item._id}`}>
                <Card>
                  <Box justifyContent="center" display="flex">
                    <Icon glyph={item.iconName} size={90} />
                  </Box>
                  <Box>
                    <Typography variant="h6" align="center">
                      {item.name}
                    </Typography>
                  </Box>
                  <Box p={2}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="number"
                      value={item.quantity}
                      InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment>KG</InputAdornment>,
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
            ))}
        </Grid>
      ) : (
        "Sin Resultados"
      )}
    </Box>
  );
}
