import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AuthRedirect() {
  const auth = useSelector((state) => state.auth);
  const [pathname, setPathname] = useState("/");

  useEffect(() => {
    if (auth.userType === "ADMIN") {
      setPathname("/admin/");
    }
    if (auth.userType === "GREENPOINT") {
      setPathname("/greenPoint/");
    }
    if (auth.userType === "GREENPOINT_EXTERN") {
      setPathname("/greenPoint-external/removals");
    }
    if (auth.userType === "ADMIN_EXTERN") {
      setPathname("/admin-external");
    }
    if (auth.userType === "") {
      setPathname("/login");
    }
  }, [auth.userType]);

  return <Redirect to={{ pathname }} />;
}
