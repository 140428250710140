import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import externalsIndicatorsAction from "../../../../redux/actions/externalsIndicators";
import ModalUpdateGoal from "./ModalUpdateGoal";

const useStyles = makeStyles({
  button_update: {
    cursor: "pointer",
  },
});

export default function MissingAlertByMaterials() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { totalRecords } = useSelector((state) => state.externalsIndicators);
  const onClickUpdate = () => {
    dispatch(externalsIndicatorsAction.setUpdateModalByMaterial(true));
  };

  useEffect(() => {
    dispatch(externalsIndicatorsAction.fetchGreenPointWithoutMaterial());
  }, []);
  return (
    <>
      {totalRecords > 0 && (
        <Alert severity="warning" style={{ marginBottom: "15px" }}>
          Tienes <strong>{`+${totalRecords}`}</strong>{" "}
          {`Punto${totalRecords === 1 ? "" : "s"}`} Gira a los cuales les falta
          asignar una meta a un Tipo de residuo.{" "}
          <strong className={classes.button_update} onClick={onClickUpdate}>
            Actualizar
          </strong>
          <ModalUpdateGoal />
        </Alert>
      )}
    </>
  );
}
