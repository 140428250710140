const defaultState = {
  loged: "LOADING",
  userType: "EMPTY",
  user: {
    _id: "",
    name: "",
    lastName: "",
    email: "",
    urlPhoto: "",
  },
  loginStatus: "READY",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "AUTH_LOGIN_SUCCESS": {
      return { ...state, ...payload };
    }
    case "AUTH_LOGIN_FAIL": {
      return { ...defaultState, loged: "NOT_LOGED", userType: "" };
    }
    case "AUTH_LOGOUT": {
      return { ...defaultState, loged: "NOT_LOGED", userType: "" };
    }
    case "AUTH_SET_LOGIN_STATUS": {
      return { ...state, loginStatus: payload };
    }
    case "AUTH_SET_USER": {
      return { ...state, user: payload };
    }

    default:
      return state;
  }
}

export default reducer;
