import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import indicatorsAction from "../../../../redux/actions/indicators";
import Content from "./Content";

export default function ViewGoalModal() {
  const dispatch = useDispatch();
  const indicators = useSelector((state) => state.indicators);
  const app = useSelector((state) => state.app);
  const handleClose = () => {
    dispatch(indicatorsAction.setViewModal(false));
  };
  return (
    <Dialog
      open={indicators.viewModal}
      onClose={() => handleClose()}
      maxWidth="md"
      fullScreen={app.isMobile}
      scroll="body"
    >
      <DialogContent dividers>
        <Content />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          startIcon={<CloseIcon />}
          onClick={() => handleClose()}
          variant="contained"
          size="small"
        >
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
