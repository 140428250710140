import axios from "axios";
import { saveAs } from "file-saver";
import appActions from "./app";
import notifiesActions from "./notifies";
import formatQuery from "../../helpers/formatQuery";

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_VIEW_MODAL_GOALS", payload: value });
};

const setUpdateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_UPDATE_MODAL_GOAL", payload: value });
};

const setUpdateModalByMaterial = (value) => {
  return (dispatch) =>
    dispatch({
      type: "INDICATORS_SET_UPDATE_MODAL_GOAL_BY_MATERIALS",
      payload: value,
    });
};

const toggleExportDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_OPEN_EXPORT_DATA_MODAL", payload: value });
};

const setUpdateData = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_UPDATE_MODAL_GOAL_DATA", payload: value });
};

const setFilterDate = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_DATE", payload: value });
};

const setFilterFormat = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_FORMAT", payload: value });
};

const setFilterCity = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_CITY", payload: value });
};

const setFilterZone = (value) => {
  return (dispatch) =>
    dispatch({ type: "INDICATORS_SET_FILTER_ZONE", payload: value });
};

const updateFiltersGreenPoint = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "INDICATORS_UPDATE_FILTERS_GREENPOINTS",
      payload: value,
    });
    dispatch(getDetails());
  };
};

const updateFilters = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "INDICATORS_UPDATE_FILTERS",
      payload: value,
    });
    dispatch(fetchGreenPoints());
    dispatch(getDetails());
  };
};

const updateExportFilters = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "INDICATORS_UPDATE_EXPORT_FILTERS",
      payload: value,
    });
    dispatch(fetchGreenPointsExport());
  };
};

const fetchGreenPointsExport = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { exportFilters } = getState().indicators;
      if (exportFilters.formats.length > 0 && exportFilters.zones.length > 0) {
        const response = await axios.get(`/indicators/greenpoints/export`, {
          params: {
            formats: formatQuery(exportFilters.formats),
            zones: formatQuery(exportFilters.zones),
          },
        });
        dispatch({
          type: "INDICATORS_UPDATE_EXPORT_GREENPOINTS",
          payload: response.data,
        });
      }

      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const fetchGoals = (type = "BY_MATERIAL", search = "") => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      let response = null;
      if (type === "BY_GREEN_POINT" || type === "BY_MATERIAL_AND_GREEN_POINT") {
        const { pagination } = getState().indicators;
        response = await axios.get(
          `/goals/${pagination.page}/${pagination.rows}?type=${type}&search=${search}`
        );
      } else {
        response = await axios.get(`/goals/0/0?type=${type}`);
      }
      dispatch({ type: "GOALS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const fetchGreenPointWithoutGoal = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.get("/goals/missing");
      dispatch({ type: "GOALS_MISSING", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const fetchGreenPointWithoutMaterial = () => {
  return async (dispatch, getState) => {
    try {
      const { filtersMissingMaterials } = getState().indicators;
      dispatch(appActions.setLoading(true));
      const response = await axios.get(
        `/goals/material-missing/${filtersMissingMaterials.pagination.page}/${filtersMissingMaterials.pagination.rows}?search=${filtersMissingMaterials.search}`
      );
      dispatch({
        type: "GOALS_MISSING_BY_MATERIALS",
        payload: response.data,
      });

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const setSearchmissingMaterials = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "GOALS_MISSING_BY_MATERIALS_SET_SEARCH",
      payload: value,
    });
    dispatch(fetchGreenPointWithoutMaterial());
  };
};

const setPaginationmissingMaterials = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "GOALS_MISSING_BY_MATERIALS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchGreenPointWithoutMaterial());
  };
};

const fetchGreenPoints = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { filters } = getState().indicators;
      const response = await axios.get(`/indicators/greenpoints`, {
        params: {
          city: filters.city === "ALL" ? "" : filters.city,
          format: filters.format === "ALL" ? "" : filters.format,
          zone: filters.zone === "ALL" ? "" : filters.zone,
        },
      });
      dispatch({
        type: "INDICATORS_FETCH_GREENPOINTS",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (error) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const updateGoal = (data) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.put(`/goals`, data);
      dispatch({ type: "GOALS_UPDATE", payload: response.data });
      dispatch(fetchGreenPointWithoutGoal());
      dispatch(fetchGreenPointWithoutMaterial());
      dispatch(notifiesActions.enqueueNotify("GOAL_UPDATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const setPagination = (value, goalType, search = "") => {
  return async (dispatch) => {
    await dispatch({
      type: "GOALS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchGoals(goalType, search));
  };
};

const getDetails = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { filters } = getState().indicators;
      const { user } = getState().auth;
      let result = "";
      if (user.type === "GREENPOINT") {
        result = await axios.get(`/indicators/greenpoints/details`, {
          params: {
            differenceMonths:
              filters.differenceMonths === 0 ? 1 : filters.differenceMonths,
            initialDate: filters.initialDate,
            endDate: filters.endDate,
            entityID: user.entityID,
            userID: user._id,
          },
        });
      }
      if (filters.greenPoints.length > 0 && user.type === "ADMIN") {
        result = await axios.get(`/indicators/details`, {
          params: {
            differenceMonths:
              filters.differenceMonths === 0 ? 1 : filters.differenceMonths,
            initialDate: filters.initialDate,
            endDate: filters.endDate,
            greenPointID: formatQuery(filters.greenPoints),
            city: filters.city === "ALL" ? "" : filters.city,
            format: filters.format === "ALL" ? "" : filters.format,
            zone: filters.zone === "ALL" ? "" : filters.zone,
          },
        });
      }
      const payload = {
        allGreenPointProgress: result.data.allGreenPointProgress || [],
        materialProgress: result.data.materialProgress || [],
        monthlyProgress: result.data.monthlyProgress || [],
      };
      dispatch({
        type: "INDICATORS_FETCH_DETAILS",
        payload,
      });

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const getMonthlyProgress = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { user } = getState().auth;

      if (user.type === "ADMIN") {
        const response = await axios.get(`/indicators/get-monthly-progress`, {
          params: {
            initialDate: new Date().toISOString(),
            city: "",
            format: "",
            zone: "",
          },
        });
        dispatch({
          type: "INDICATORS_FETCH_MONTHLY_PROGRESS",
          payload: response.data || [],
        });
      }
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

const getExcelFile = (
  name,
  zones,
  formats,
  initialDate,
  endDate,
  greenPoints,
  onlyNewMaterials
) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));

      let URL = "";
      if (onlyNewMaterials) {
        URL = `/indicators/export/only-new-materials`;
      }
      if (!onlyNewMaterials) {
        URL = `/indicators/export/`;
      }

      const response = await axios.post(
        URL,
        { name, zones, formats, initialDate, endDate, greenPoints },
        {
          responseType: "arraybuffer",
        }
      );
      const dirtyFileName = response.headers["content-disposition"];
      const regex =
        /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      const fileName = dirtyFileName.match(regex)[3];

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);

      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};

export default {
  setViewModal,
  setUpdateModal,
  setUpdateModalByMaterial,
  toggleExportDataModal,
  setFilterDate,
  setFilterFormat,
  setFilterCity,
  setFilterZone,
  updateFilters,
  updateFiltersGreenPoint,
  updateExportFilters,
  setUpdateData,
  fetchGoals,
  fetchGreenPointWithoutGoal,
  fetchGreenPointWithoutMaterial,
  setSearchmissingMaterials,
  setPaginationmissingMaterials,
  setPagination,
  updateGoal,
  getDetails,
  getMonthlyProgress,
  getExcelFile,
};
