const now = new Date();

const defaultState = {
  data: null,
  dates: {
    dateInit: new Date(now.getFullYear(), now.getMonth(), 1),
    dateFinish: new Date(now.getFullYear(), now.getMonth() + 1, 1),
  },
  dateRange: "CURRENT",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "DASHBOARD_FETCH": {
      return { ...state, data: payload };
    }
    case "DASHBOARD_SET_DATES": {
      return { ...state, dates: payload };
    }
    case "DASHBOARD_SET_DATERANGE": {
      return { ...state, dateRange: payload };
    }
    default:
      return state;
  }
}

export default reducer;
