import React from "react";
import { useDispatch } from "react-redux";
import IO from "socket.io-client";
import notifiesActions from "../redux/actions/notifies";

const socket = IO(process.env.REACT_APP_API_URL);

const EventListener = () => {
  const dispatch = useDispatch();
  socket.off("ADMIN").on("ADMIN", (payload) => {
    dispatch(notifiesActions.enqueueNotify("REMOVAL_CREATE_GREENPOINT"));
  });

  return <div />;
};

export default EventListener;
