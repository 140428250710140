import React from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";

export default function Content({ data, setCheck, check }) {
  const translateType = (type) => {
    if (type === "ANNUAL") {
      return "Anual";
    }
    if (type === "BIANNUAL") {
      return "Semestral";
    }
    if (type === "MONTHLY") {
      return "Mensual";
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2">
          Fecha de creación:{" "}
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(data.createdAt ? data.createdAt : null))}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Tipo de reporte: {translateType(data.type)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Fecha inicial:{" "}
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(data.dateInit ? data.dateInit : null))}
          {" - "}
          {new Intl.DateTimeFormat("es-Es", {
            year: "numeric",
            month: "long",
            day: "2-digit",
          }).format(new Date(data.dateFinish ? data.dateFinish : null))}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={check}
              onChange={(e) => setCheck(e.target.checked)}
              color="primary"
            />
          }
          label="Confirmo que deseo eliminar este reporte"
        />
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  data: PropTypes.object.isRequired,
  setCheck: PropTypes.func.isRequired,
  check: PropTypes.bool.isRequired,
};
