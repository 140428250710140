import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Card, CardContent, makeStyles } from "@material-ui/core";
import Header from "./Header";
import ViewGoalModal from "./ViewGoalModal";
import externalsIndicatorsActions from "../../../redux/actions/externalsIndicators";
import utilsActions from "../../../redux/actions/utils";
import MissingAlert from "./MissinAlert";
import MissinAlertByMaterials from "./MissinAlertByMaterials";

import Charts from "./Charts";
import ExportToExcelModal from "./ExportToExcelModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ExternalsIndicators() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.externalsIndicators);

  useEffect(() => {
    dispatch(externalsIndicatorsActions.fetchGoals());
    dispatch(utilsActions.fetchMaterials());
  }, [filters.groupID]);

  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <Header />
          <MissingAlert />
          <MissinAlertByMaterials />
          <Charts />
          <ViewGoalModal />
          <ExportToExcelModal />
        </CardContent>
      </Card>
    </Paper>
  );
}
