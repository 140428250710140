import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Buttons({ handleClose, handleSuccess, isUploadingImage }) {
  return (
    <ButtonGroup variant="contained" size="small">
      <Button
        startIcon={<Close />}
        color="secondary"
        onClick={() => handleClose()}
      >
        Salir
      </Button>
      <Button
        startIcon={<Check />}
        color="primary"
        disabled={isUploadingImage}
        onClick={() => handleSuccess()}
      >
        Guardar
      </Button>
    </ButtonGroup>
  );
}

Buttons.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};
