import React from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import SecurityIcon from "@material-ui/icons/Security";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import Jimp from "jimp";

import functions from "../../../../resources/functions";

export default function Content({
  validateEmail,
  error,
  dataSelectEntity,
  formData,
  setFormData,
  imageSrc,
  setImageSrc,
  setIsUploadingImage,
  helperTextInfo,
  helperTextPass,
}) {
  const updateData = (e) => {
    if (e.target.name === "email") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim().toLowerCase(),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const passwordGen = () => {
    const password = Math.round(Math.random() * 10000000 + 10000000);
    setFormData({ ...formData, password });
  };

  const handleSetImages = (event) => {
    setIsUploadingImage(true);
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .resize(1062, 1280)
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
            setIsUploadingImage(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          name="type"
          value={formData.type}
          onChange={(e) => updateData(e)}
          select
          margin="dense"
          label="Tipo de usuario"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && !formData.type}
          disabled
        >
          <MenuItem value="GREENPOINT_EXTERN">Monitor GIRA Externo</MenuItem>
          
        </TextField>
      </Grid>
      {formData.type !== "ADMIN_EXTERN" && (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            onChange={(e, k) =>
              setFormData({
                ...formData,
                externalEntityID: k._id,
              })
            }
            options={dataSelectEntity}
            value={dataSelectEntity.find(
              (gp) => gp._id === formData.externalEntityID
            )}
            disableClearable
            getOptionLabel={(option) =>
              option ? option.name : "Selecciona un Punto GIRA Externo"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Punto GIRA"
                margin="dense"
                variant="outlined"
                error={error && formData.externalEntityID === "EMPTY"}
                helperText={
                  formData.externalEntityID === "EMPTY" && helperTextInfo
                }
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextField
          name="email"
          value={formData.email}
          onChange={(e) => updateData(e)}
          margin="dense"
          label="Email"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && (!formData.email || !validateEmail(formData.email))}
          helperText={
            (!formData.email || !validateEmail(formData.email)) &&
            helperTextInfo
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="phone"
          value={formData.phone}
          onChange={(e) =>
            setFormData({
              ...formData,
              phone: functions.formatPhone(e.target.value),
            })
          }
          margin="dense"
          label="Teléfono"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && formData.phone.length < 16}
          helperText={formData.phone.length < 16 && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="name"
          value={formData.name}
          onChange={(e) =>
            setFormData({
              ...formData,
              name: functions.formatOnlyLetters(e.target.value),
            })
          }
          margin="dense"
          label="Nombre"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && !formData.name}
          helperText={!formData.name && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="lastName"
          value={formData.lastName}
          onChange={(e) =>
            setFormData({
              ...formData,
              lastName: functions.formatOnlyLetters(e.target.value),
            })
          }
          margin="dense"
          label="Apellido"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && !formData.lastName}
          helperText={!formData.lastName && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="ruc"
          value={formData.ruc}
          onChange={(e) =>
            setFormData({
              ...formData,
              ruc: functions.formatRuc(e.target.value),
            })
          }
          margin="dense"
          label="Ruc"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && formData.ruc.length < 10}
          helperText={formData.ruc.length < 10 && helperTextInfo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="password"
          value={formData.password}
          onChange={(e) => updateData(e)}
          margin="dense"
          label="Contraseña"
          type={"text"}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && formData.password.length < 8}
          helperText={formData.password.length < 8 && helperTextPass}
        />
        <Button
          variant="text"
          color="primary"
          size="small"
          startIcon={<SecurityIcon />}
          onClick={() => passwordGen()}
        >
          generar contraseña
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          id="raised-button-file-2"
          name="image"
          type="file"
          accept="image/jpeg,image/jpg"
          style={{ display: "none", width: "100%" }}
          error={error}
          onChange={handleSetImages}
        />
        <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginTop: 8,
              height: 40,
            }}
            fullWidth
            startIcon={<CloudUploadOutlinedIcon />}
            component="span"
          >
            Foto
          </Button>
        </label>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Avatar
          alt="User photo"
          src={imageSrc}
          style={{ height: "100px", width: "100px" }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.actived === "ACTIVED"}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  actived: e.target.checked ? "ACTIVED" : "DEACTIVED",
                })
              }
              color="primary"
            />
          }
          label="Crear usuario activado"
        />
      </Grid>
    </Grid>
  );
}

Content.propTypes = {
  validateEmail: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  dataSelectEntity: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};
