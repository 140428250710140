const defaultState = {
  checkLists: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  search: "",
  createModal: false,
  deleteModal: false,
  viewModal: false,
  dataModal: null,
  editMode: false,
  cards: {
    evaluations: 0,
    reports: 0,
  },
  selector: "CHECKLIST",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "CHECKLIST_FETCH": {
      return { ...state, checkLists: payload };
    }
    case "CHECKLIST_ADD": {
      return { ...state, checkLists: [...state.checkLists, payload] };
    }
    case "CHECKLIST_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "CHECKLIST_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "CHECKLIST_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "CHECKLIST_SET_VIEW_MODAL": {
      return { ...state, viewModal: payload };
    }
    case "CHECKLIST_SET_EDIT_MODE": {
      return { ...state, editMode: payload };
    }
    case "CHECKLIST_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "CHECKLIST_SET_SEARCH": {
      return { ...state, ...payload };
    }
    case "CHECKLIST_SET_CARDS": {
      return { ...state, cards: payload };
    }
    case "CHECKLIST_SET_SELECTOR": {
      return { ...state, selector: payload };
    }
    default:
      return state;
  }
}

export default reducer;
