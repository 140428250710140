import React, { useEffect, useState } from "react";
import { Grid, Typography, Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function Content({ imageSrc }) {
  const profile = useSelector((state) => state.profile);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (profile.user) {
      setUser(profile.user);
    }
  }, [profile.user]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} >
        <Grid item container xs={12} sm={12} justify="center" alignItems="center" direction="column">
          <Avatar
            alt="User photo"
            src={user.urlPhoto ? `${user.urlPhoto}?20130910043254` : imageSrc}
            style={{ height: '300px', width: '300px' }}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="body2">Nombre</Typography>
          <Typography variant="body1">
            {user.name + " " + user.lastName}
          </Typography>
        </Grid>
        <hr />
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="body2">E-mail</Typography>
          <Typography variant="body1">{user.email}</Typography>
        </Grid>
        <hr />
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="body2">Teléfono</Typography>
          <Typography variant="body1">{user.phone}</Typography>
        </Grid>
        <hr />
        <Grid item xs={6} sm={4} md={4}>
          <Typography variant="body2">Fecha de creación</Typography>
          <Typography variant="body1">
            {new Intl.DateTimeFormat("es-Es", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(new Date(user.createdAt ? user.createdAt : null))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
