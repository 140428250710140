import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import greenPointsActions from "../../../../redux/actions/greenPoints";
import { Close, Check, Delete } from "@material-ui/icons";

export default function ModalDelete() {
  const greenPoints = useSelector((state) => state.greenPoints);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [check, setCheck] = useState(false);
  useEffect(() => {
    if (greenPoints.dataModal) {
      setData({
        name: greenPoints.dataModal.name,
        address: greenPoints.dataModal.address,
        datetimeCreated: greenPoints.dataModal.datetimeCreated,
      });
    }
  }, [greenPoints.dataModal]);

  const handleClose = () => {
    dispatch(greenPointsActions.setDataModal(null));
    dispatch(greenPointsActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    if (check) {
      dispatch(greenPointsActions.deleteGreenPoint(greenPoints.dataModal._id));
      handleClose();
    }
  };

  return (
    <Dialog
      open={greenPoints.deleteModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              <Delete style={{ fontSize: 26, marginBottom: 5 }} /> Eliminar Punto GIRA
            </Typography>
            <hr></hr>
            <Typography variant="body1">
              ¿Seguro que quieres eliminar el Punto GIRA?{" "}
              <strong>Esta acción no puede deshacerse.</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Nombre: {data.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Dirección: {data.address}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Fecha de creación: {data.createdAt}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={(e) => setCheck(e.target.checked)}
                  color="primary"
                />
              }
              label="Confirmo que deseo eliminar este Punto GIRA"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button
            color="secondary"
            onClick={() => handleClose()}
            startIcon={<Close />}
          >
            No, salir
          </Button>
          <Button
            color={check ? "primary" : "default"}
            onClick={() => handleDelete()}
            startIcon={<Check />}
          >
            Sí, eliminar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
