import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import groupsActions from "../../../../redux/actions/groups";
import externalGreenPointsActions from "../../../../redux/actions/externalsGreenPoint";
import Content from "./Content";
import Buttons from "./Buttons";
import Header from "../../../Shared/Modals/Header";

export default function CreateModal() {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups);
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
    contact: "",
  });

  useEffect(() => {
    if (groups.dataModal && groups.editModal) {
      setFormData({
        name: groups.dataModal.name || "",
        address: groups.dataModal.address || "",
        phone: groups.dataModal.phone || "",
        email: groups.dataModal.email || "",
        contact: groups.dataModal.contact || "",
      });
    }
  }, [groups.dataModal, groups.editModal]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const handleClose = () => {
    dispatch(groupsActions.setEditModal(false));
    dispatch(groupsActions.setDataModal(null));
  };

  const handleSuccess = () => {
    if (
      !formData.name ||
      !formData.phone ||
      !formData.contact ||
      !formData.email
    ) {
      setError(true);
    } else {
      dispatch(
        groupsActions.patchGroup(groups.dataModal._id, {
          formData,
        })
      );
      dispatch(externalGreenPointsActions.fetchCards());
      handleClose();
    }
  };

  return (
    <Dialog open={groups.editModal} maxWidth="md" fullWidth>
      <DialogContent>
        <Header
          handleClose={handleClose}
          title="Editar Grupo Externo"
          type="CREATE"
        />
        <Content formData={formData} setFormData={setFormData} error={error} />
      </DialogContent>
      <DialogActions>
        <Buttons handleSuccess={handleSuccess} handleClose={handleClose} />
      </DialogActions>
    </Dialog>
  );
}
