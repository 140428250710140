import Axios from "axios";
import authActions from "./auth";
import appActions from "./app";

const fetchDataDashboard = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const dates = await getState().dashboard.dates;
      const userType = await getState().auth.userType;
      let url = "";
      if (userType === "ADMIN") {
        url = "/dashboard/admin";
      }
      if (userType === "GREENPOINT") {
        url = "/dashboard/greenPoint";
      }

      if (userType === "GREENPOINT_EXTERN") {
        url = "/dashboard/greenPoint-extern";
      }

      if (userType === "ADMIN_EXTERN") {
        url = "/dashboard/admin-extern";
      }

      const response = await Axios.get(url, {
        params: { ...dates },
      });
      dispatch({ type: "DASHBOARD_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth());
    }
  };
};

const setDates = ({ dateInit, dateFinish }) => {
  return async (dispatch) => {
    await dispatch({
      type: "DASHBOARD_SET_DATES",
      payload: { dateInit, dateFinish },
    });
    await dispatch(fetchDataDashboard());
  };
};

const setDateRange = (dateRange) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_SET_DATERANGE",
      payload: dateRange,
    });
  };
};

export default {
  setDates,
  setDateRange,
  fetchDataDashboard,
};
