const defaultState = {
  greenPoints: [],
  greenPointsForFilter: [],
  pagination: {
    page: 0,
    rows: 7,
  },
  cards: {
    greenPoints: 0,
    groups: 0,
  },
  search: "",
  selector: "GREENPOINTS",
  createModal: false,
  deleteModal: false,
  viewModal: false,
  editModal: false,
  editGoalModal: false,
  editMassiveGoalsModal: false,
  aprobeModal: false,
  dataModal: null,
  currentGoals: {},
  responseMassiveUpdateGoals: {
    isValidData: true,
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "EXTERNALS_GREENPOINTS_FETCH": {
      return { ...state, greenPoints: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_SELECTOR": {
      return { ...state, selector: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_VIEW_MODAL": {
      return { ...state, viewModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_APROBE_MODAL": {
      return { ...state, aprobeModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_CARDS": {
      return { ...state, cards: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "EXTERNALS_GREENPOINTS_SET_SEARCH": {
      return { ...state, ...payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_EDIT_GOAL_MODAL": {
      return { ...state, editGoalModal: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_EDIT_MASSIVE_GOALS_MODAL": {
      return { ...state, editMassiveGoalsModal: payload };
    }
    case "GOAL_BY_EXTERNALS_GREEBPOINT_FETCH": {
      return { ...state, currentGoals: payload };
    }
    case "EXTERNALS_GREENPOINTS_SET_FILTER": {
      return { ...state, greenPointsForFilter: payload };
    }
    case "SET_MASSIVE_UPDATE_EXTERNALS_GOALS_RESPONSE": {
      return { ...state, responseMassiveUpdateGoals: payload };
    }
    default:
      return state;
  }
}

export default reducer;
