import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import { Check, Close, ContactsOutlined } from "@material-ui/icons";
import externalsMaterialsActions from "../../../../redux/actions/externalsMaterials";
import Header from "../../../Shared/Modals/Header";
import Content from "./Content";

const ExternalsMaterialsModal = ({ open, onClose, type, currentMaterial }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { group } = useSelector((state) => state.externalsMaterials);
  const initialState = {
    name: "",
    createdBy: auth.user._id,
    group,
    specificGoal: 0,
  };
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const writeAction = () => {
    if (type === "APPROVE") return "Aprobar";
    if (type === "UPDATE") return "Actualizar";
    if (type === "CREATE") return "Guardar";
  };
  const writeActionTittle = () => {
    if (type === "APPROVE") return "Aprobar Tipo de Residuo";
    if (type === "UPDATE") return "Editar Tipo de Residuo";
    if (type === "CREATE") return "Agregar Tipo de Residuo";
  };

  const handleSuccess = () => {
    if (!formData.name) {
      setError(true);
    } else {
      if (type === "CREATE") {
        dispatch(externalsMaterialsActions.postMaterial({ formData }));
      }
      if (type === "UPDATE") {
        dispatch(externalsMaterialsActions.patchMaterial({ formData }));
      }
      if (type === "APPROVE") {
        dispatch(externalsMaterialsActions.patchMaterial({ formData }));
      }
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  useEffect(() => {
    if (type === "CREATE") {
      setFormData(initialState);
    }
    if (type === "UPDATE") {
      setFormData({
        _id: currentMaterial._id,
        name: currentMaterial.name,
        specificGoal: currentMaterial.specificGoal,
        state: currentMaterial.state,
      });
    }
    if (type === "APPROVE") {
      setFormData({
        _id: currentMaterial._id,
        name: currentMaterial.name,
        specificGoal: currentMaterial.specificGoal,
        state: "READY",
      });
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <Header
          handleClose={onClose}
          title={writeActionTittle(type)}
          type={type === "APPROVE" ? "VIEW" : type}
        />
        <Content
          formData={formData}
          setFormData={setFormData}
          error={error}
          type={type}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small">
          <Button startIcon={<Close />} color="secondary" onClick={onClose}>
            Salir
          </Button>
          <Button startIcon={<Check />} color="primary" onClick={handleSuccess}>
            {writeAction(type)}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default ExternalsMaterialsModal;
