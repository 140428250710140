import React, { useEffect } from "react";
import {
  MuiThemeProvider,
  useMediaQuery,
  CssBaseline,
  useTheme,
} from "@material-ui/core";
import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import "./App.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Router from "./components/Router";
import NavBar from "./components/Navigation/NavBar";
import Menu from "./components/Navigation/Menu";
import Notifies from "./components/Notifies";
import { useDispatch, useSelector } from "react-redux";
import appActions from "./redux/actions/app";
import authActions from "./redux/actions/auth";
import utilActions from "./redux/actions/utils";
import profileActions from "./redux/actions/profile";
import removalsActions from "./redux/actions/externalRemovals";
import axios from "axios";
import Socket from "./services/socket";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const token = window.localStorage.getItem("token");
if (token && token !== null) {
  axios.defaults.headers.common.authorization = token;
}

//socket.eventListener();
export default function App() {
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const exec = () => {
      dispatch(appActions.setIsMobile({ isMobile }));
    };
    exec();
  }, [isMobile]);

  useEffect(() => {
    const authVerify = () => {
      dispatch(authActions.verifyToken());
    };
    authVerify();

    if (auth.loged === "LOGED" && auth.userType === "ADMIN") {
      const utilsFetch = () => {
        dispatch(utilActions.fetchMaterials());
        dispatch(utilActions.fetchGreenPoints());
        dispatch(utilActions.fetchUsers());
        dispatch(removalsActions.setGroupID({ groupID: "" }));
      };
      utilsFetch();
    }
    if (auth.loged === "LOGED" && auth.userType === "ADMIN_EXTERN") {
      const utilsFetch = () => {
        dispatch(utilActions.fetchMaterials());
        dispatch(
          removalsActions.setGroupID({ groupID: auth.user.groupID._id })
        );
      };
      utilsFetch();
    }
    if (auth.loged === "LOGED" && auth.userType === "GREENPOINT") {
      const utilsFetch = () => {
        dispatch(utilActions.fetchMaterials());
        dispatch(profileActions.fetchProfile());
      };
      utilsFetch();
    }
    if (auth.loged === "LOGED" && auth.userType === "GREENPOINT_EXTERN") {
      const utilsFetch = () => {
        dispatch(utilActions.fetchMaterials());
        dispatch(profileActions.fetchProfile());
      };
      utilsFetch();
    }
  }, [auth.loged, auth.userType]);

  return (
    <MuiThemeProvider theme={app.theme === "LIGHT" ? lightTheme : darkTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {auth.userType === "ADMIN" && <Socket />} <CssBaseline />
        <Notifies />
        <NavBar />
        <div style={{ marginTop: 60 }}>{!app.isMobile && <Menu />}</div>
        <main
          id="content"
          style={{
            padding: "2%",
          }}
        >
          <Router style={{ zIndex: 1000 }} />
        </main>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}
