import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  useTheme,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import LocalShipping from "@material-ui/icons/LocalShipping";
import PropTypes from "prop-types";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import removalActions from "../../../../redux/actions/removalsGreenPoint";

const days = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

export default function ThisCard({ removal }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const blueBackgorund =
    "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)";

  const options = ({ action }) => {
    if (action === "VIEW") {
      dispatch(removalActions.setDataModal(removal));
      dispatch(removalActions.setViewModal(true));
    }
    if (action === "DELETE") {
      dispatch(removalActions.setDataModal(removal));
      dispatch(removalActions.setDeleteModal(true));
    }
    if (action === "EDIT") {
      dispatch(removalActions.setDataModal(removal));
      dispatch(removalActions.setEditModal(true));
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography component="h2" variant="h6">
              Registro Externo
            </Typography>
          </Grid>
          <Grid item>
            <LocalShipping style={{ color: theme.palette.primary.main }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr style={{ background: blueBackgorund, height: 2 }} />

            <Typography variant="body2" align="center" color="textSecondary">
              Fecha del registro
            </Typography>
            <hr />
            <Typography variant="body2" align="center">
              {days[new Date(removal.datetimeRemoval).getDay()] +
                " " +
                new Date(removal.datetimeRemoval).getDate() +
                " de " +
                months[new Date(removal.datetimeRemoval).getMonth()] +
                " " +
                new Date(removal.datetimeRemoval).getFullYear()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup
              size="small"
              fullWidth
              color="primary"
              variant="outlined"
            >
              <Button onClick={() => options({ action: "VIEW" })}>
                <VisibilityIcon />
              </Button>
              <Button
                onClick={() => options({ action: "EDIT" })}
                disabled={removal.accepted}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={() => options({ action: "DELETE" })}
                disabled={removal.accepted}
              >
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ThisCard.propTypes = {
  removal: PropTypes.object.isRequired,
};
