import axios from "axios";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";

const fetchRemovals = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().externalRemovals;
      const response = await axios.get(
        "/external-removals/greenPoint/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({
        type: "EXTERNAL_REMOVALS_GREENPOINT_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postRemoval = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/external-removals/greenPoint", formData);
      dispatch(
        notifiesActions.enqueueNotify("EXTERNAL_REMOVAL_CREATE_SUCCESS")
      );
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("EXTERNAL_REMOVAL_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchRemovals = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/external-removals/greenPoint", formData);
      dispatch(
        notifiesActions.enqueueNotify("EXTERNAL_REMOVAL_UPDATE_SUCCESS")
      );
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("EXTERNAL_REMOVAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteRemoval = (removalID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/external-removals/greenPoint/" + removalID);
      dispatch(
        notifiesActions.enqueueNotify("EXTERNAL_REMOVAL_DELETE_SUCCESS")
      );
      dispatch(fetchRemovals());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("EXTERNAL_REMOVAL_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_CREATE_MODAL",
      payload: value,
    });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_DELETE_MODAL",
      payload: value,
    });
};

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_VIEW_MODAL",
      payload: value,
    });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_EDIT_MODAL",
      payload: value,
    });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_DATA_MODAL",
      payload: value,
    });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_SEARCH",
      payload: value,
    });
    dispatch(fetchRemovals());
  };
};

const setDataView = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_DATAVIEW",
      payload: value,
    });
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "EXTERNAL_REMOVALS_GREENPOINT_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchRemovals());
  };
};

export default {
  fetchRemovals,
  postRemoval,
  patchRemovals,
  deleteRemoval,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setDataModal,
  setViewModal,
  setSearch,
  setDataView,
  setPagination,
};
