import axios from "axios";
import notifiesActions from "./notifies";
import appActions from "./app";
import authActions from "./auth";

const fetchGroups = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().groups;
      const response = await axios.get(
        "/groups/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({ type: "GROUPS_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchSelectGroups = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.get("/groups/");
      dispatch({ type: "GROUPS_SELECT_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postGroup = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/groups/admin", payload);
      dispatch(notifiesActions.enqueueNotify("GROUP_CREATE_SUCCESS"));
      dispatch(fetchGroups());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GROUP_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchGroup = (id, { formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch(`/groups/admin/${id}`, formData);
      dispatch(notifiesActions.enqueueNotify("GROUP_UPDATE_SUCCESS"));
      dispatch(fetchGroups());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GROUP_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteGroup = (removalID) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/groups/admin/" + removalID);
      dispatch(notifiesActions.enqueueNotify("GROUP_DELETE_SUCCESS"));
      dispatch(fetchGroups());
      dispatch(appActions.setLoading(false));
      return true;
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GROUP_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
      return false;
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GROUPS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GROUPS_SET_DELETE_MODAL", payload: value });
};

const setViewModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GROUPS_SET_VIEW_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GROUPS_SET_EDIT_MODAL", payload: value });
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GROUPS_SET_DATA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "GROUPS_SET_SEARCH", payload: value });
    dispatch(fetchGroups());
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "GROUPS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchGroups());
  };
};

const addGreenPointList = (value) => {
  return (dispatch) => {
    dispatch({
      type: "GROUPS_ADD_GREENPOINT_LIST",
      payload: value,
    });
  };
};

const removeGreenPointList = (value) => {
  return (dispatch) => {
    dispatch({
      type: "GROUPS_REMOVE_GREENPOINT_LIST",
      payload: value,
    });
  };
};

const resetGreenPointList = () => {
  return (dispatch) => {
    dispatch({
      type: "GROUPS_RESET_GREENPOINT_LIST",
    });
  };
};

export default {
  fetchGroups,
  fetchSelectGroups,
  postGroup,
  patchGroup,
  deleteGroup,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setDataModal,
  setViewModal,
  setSearch,
  setPagination,
  addGreenPointList,
  removeGreenPointList,
  resetGreenPointList,
};
