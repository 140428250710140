import React from "react";
import { Grid, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import Dates from "./Dates";
import { useSelector } from "react-redux";

export default function Header() {
  const profile = useSelector((state) => state.profile);
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h4">
          <HomeIcon style={{ fontSize: 34, marginBottom: 6 }} />{" "}
          {profile.user?.externalEntityID?.name}
        </Typography>
      </Grid>
      <Grid item>
        <Dates />
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
