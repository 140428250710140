const defaultState = {
  greenPoints: [],
  greenPointsForFilter: [],
  pagination: {
    rows: 7,
    page: 0,
  },
  search: "",
  createModal: false,
  deleteModal: false,
  editModal: false,
  editGoalModal: false,
  editMassiveGoalsModal: false,
  dataModal: null,
  currentGoals: {},
  responseMassiveUpdateGoals: {
    isValidData: true,
  },
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "GREENPOINTS_FETCH": {
      return { ...state, greenPoints: payload };
    }
    case "GREENPOINTS_ADD": {
      return { ...state, greenPoints: [...state.greenPoints, payload] };
    }
    case "GREENPOINTS_SET_CREATE_MODAL": {
      return { ...state, createModal: payload };
    }
    case "GREENPOINTS_SET_DELETE_MODAL": {
      return { ...state, deleteModal: payload };
    }
    case "GREENPOINTS_SET_EDIT_MODAL": {
      return { ...state, editModal: payload };
    }
    case "GREENPOINTS_SET_EDIT_GOAL_MODAL": {
      return { ...state, editGoalModal: payload };
    }
    case "GREENPOINTS_SET_EDIT_MASSIVE_GOALS_MODAL": {
      return { ...state, editMassiveGoalsModal: payload };
    }
    case "GREENPOINTS_SET_DATA_MODAL": {
      return { ...state, dataModal: payload };
    }
    case "GREENPOINTS_SET_PAGINATION": {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    }
    case "GREENPOINTS_SET_SEARCH": {
      return { ...state, ...payload };
    }
    case "GOAL_BY_GREEBPOINT_FETCH": {
      return { ...state, currentGoals: payload };
    }
    case "GREENPOINTS_SET_FILTER": {
      return { ...state, greenPointsForFilter: payload };
    }
    case "SET_MASSIVE_UPDATE_GOALS_RESPONSE": {
      return { ...state, responseMassiveUpdateGoals: payload };
    }
    default:
      return state;
  }
}

export default reducer;
