import React from "react";
import {
  Grid,
  TextField,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardMedia,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Jimp from "jimp";

import Map from "./Map";
import PlacesAutocomplete from "./PlacesAutocomplete";
import formats from "../../../../resources/formats.json";
import zones from "../../../../resources/zones.json";
import GroupSelect from "../../shared/GroupSelect";

export default function CreateModal({
  data,
  setData,
  error,
  cities,
  imageSrc,
  setImageSrc,
  setIsUploadingImage,
}) {
  React.useEffect(() => {
    if (data.urlPhoto) {
      setImageSrc(`${data.urlPhoto}?20130910043254`);
    }
  }, []);

  const handleSetImages = (event) => {
    setIsUploadingImage(true);
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .resize(1280, 1062)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setData({ ...data, image: res });
              })
              .catch((err) => console.error(err));
            setIsUploadingImage(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={3}>
            <TextField
              label="Nombre"
              variant="outlined"
              margin="dense"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              fullWidth
              error={error && !data.name}
            />
          </Grid>
          <Grid item md={6} sm={3}>
            <TextField
              label="Formato"
              variant="outlined"
              margin="dense"
              value={data.format}
              select
              SelectProps={{ native: true }}
              error={error && !data.format}
              onChange={(e) => setData({ ...data, format: e.target.value })}
              fullWidth
            >
              <option value=""></option>

              {formats.map((format, i) => (
                <option key={`op${i}-${format.code}`} value={format.code}>
                  {format.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PlacesAutocomplete setFormData={setData} formData={data} />
        </Grid>
        <GroupSelect formData={data} setFormData={setData} error={error} />
        <Grid container spacing={2}>
          <Grid item md={6} sm={3}>
            <TextField
              label="Ciudad"
              variant="outlined"
              margin="dense"
              value={data.city}
              select
              SelectProps={{ native: true }}
              error={error && !data.city}
              onChange={(e) => setData({ ...data, city: e.target.value })}
              fullWidth
            >
              <option value=""></option>

              {cities.map((city, i) => (
                <option key={`op${i}-${city.code}`} value={city.code}>
                  {city.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={3}>
            <TextField
              label="Zona"
              variant="outlined"
              margin="dense"
              value={data.zone}
              select
              SelectProps={{ native: true }}
              error={error && !data.zone}
              onChange={(e) => setData({ ...data, zone: e.target.value })}
              fullWidth
            >
              <option value=""></option>

              {zones.map((zone, i) => (
                <option key={`op${i}-${zone.code}`} value={zone.code}>
                  {zone.name}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Card>
          <CardMedia
            style={{
              height: 0,
              paddingTop: "60%",
              backgroundColor: "#d1d1cf",
            }} // 16:9
            image={imageSrc}
            title="Imagen punto Gira"
          />
        </Card>
        <TextField
          label="Descripción"
          variant="outlined"
          margin="dense"
          value={data.description}
          rows={4}
          multiline
          error={error && !data.description}
          onChange={(e) => setData({ ...data, description: e.target.value })}
          fullWidth
        />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.usersTemp.length ? (
              data.usersTemp.map((user, key) => (
                <TableRow key={"fila_" + key}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={user.eliminated}
                          onChange={(e) =>
                            setData({
                              ...data,
                              usersTemp: data.usersTemp.map((user, i) => {
                                if (key === i) {
                                  return {
                                    ...user,
                                    eliminated: e.target.checked,
                                  };
                                } else {
                                  return user;
                                }
                              }),
                            })
                          }
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3}>Sin usuarios Asignados</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={6}>
        <Map data={data} style={{ width: "100%" }} />
        <Grid item sm={12} xs={12}>
          <input
            id="raised-button-file-2"
            name="image"
            type="file"
            accept="image/jpeg,image/jpg"
            style={{ display: "none", width: "100%" }}
            error={error}
            onChange={handleSetImages}
          />
          <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginTop: 8,
                height: 40,
              }}
              fullWidth
              startIcon={<CloudUploadOutlinedIcon />}
              component="span"
            >
              Foto Punto GIRA
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
}
