import React, { useEffect } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
  CloudUpload as CloudUploadIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 2,
    },
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  customTooltip: {
    backgroundColor: "#e68a00",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  customArrow: {
    color: "#ffa31a",
  },
  uploadButton: {
    width: "100%",
  },
  boxFile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    border: "1px dotted #154c79",
    borderRadius: "8px",
    padding: "2px",
    position: "relative",
  },
  deleteButton: {
    position: "absolute",
    top: "0",
    right: "0",
    cursor: "pointer",
    color: "#FF0101",
    padding: 0,
  },
  boxLoading: {
    display: "flex",
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    width: "100%",
    height: "100%",
    color: "#fff",
    fontWeight: "bold",
  },
  boxMsgSuccess: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#00FF00",
    fontWeight: "bold",
    marginRight: "auto",
  },
}));

const UploadExcel = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.app);
  const { responseMassiveCreateRemovals } = useSelector(
    (state) => state.removals
  );

  const initialValues = {
    file: null,
    fileName: "",
  };

  const formikExcel = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append(`excelData`, values.file);

      dispatch(removalsActions.postMassiveCreateRemovals({ formData }));
    },
  });

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    formikExcel.setValues({
      file,
      fileName: file?.name || "",
    });
    e.target.value = null;
  };

  const deleteFile = () => {
    formikExcel.setValues({
      file: null,
      fileName: "",
    });
  };

  return (
    <Box
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignContent="center"
      style={{ gap: "8px" }}
    >
      <Button
        variant="contained"
        color="default"
        startIcon={<CloudUploadIcon />}
        component="label"
        className={classes.uploadButton}
      >
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={onChangeFile}
          hidden
        />
        Buscar archivo
      </Button>
      {formikExcel.values.fileName && (
        <>
          <Tooltip
            title={
              responseMassiveCreateRemovals?.message ||
              "Error al crear registros"
            }
            placement="right"
            arrow={true}
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            open={Boolean(!responseMassiveCreateRemovals.isValidData)}
          >
            <Box className={classes.boxFile}>
              <IconButton
                aria-label="delete"
                className={classes.deleteButton}
                disabled={loading}
                onClick={deleteFile}
              >
                <HighlightOffIcon fontSize="inherit" />
              </IconButton>
              <DescriptionIcon color="secondary" />
              <Typography variant="body2">
                {formikExcel.values.fileName}
              </Typography>
              {loading && formikExcel.isSubmitting && (
                <Box className={classes.boxLoading}>
                  <CircularProgress />
                  <Typography variant="body2">Creando registros...</Typography>
                </Box>
              )}
            </Box>
          </Tooltip>
        </>
      )}
      {/* --- */}

      <Box display="flex" justifyContent="flex-end">
        {responseMassiveCreateRemovals.isValidData &&
        responseMassiveCreateRemovals.message ? (
          <>
            <Box className={classes.boxMsgSuccess}>
              <Typography variant="body2">
                {responseMassiveCreateRemovals.message}
              </Typography>
            </Box>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClose}
            >
              Aceptar
            </Button>
          </>
        ) : (
          <Tooltip
            title={"Debe seleccionar un archivo"}
            placement="left"
            arrow={true}
            open={!Boolean(formikExcel.values?.file)}
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
          >
            <Button
              color="primary"
              startIcon={<CheckCircleOutlineIcon />}
              variant="contained"
              size="small"
              disabled={!Boolean(formikExcel.values?.file) || loading}
              loading={loading}
              onClick={formikExcel.handleSubmit}
            >
              crear registros
            </Button>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default UploadExcel;
