import React, { Fragment } from "react";
import {
  Grid,
  TextField,
  Button,
  useTheme,
  Avatar,
  MenuItem,
} from "@material-ui/core";
import { Lock, Warning, Check, Error, ExpandLess } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Jimp from "jimp";

import functions from "../../../../resources/functions";

export default function ContentEdit({
  formData,
  setFormData,
  imageSrc,
  setImageSrc,
  error,
  setIsUploadingImage,
}) {
  const theme = useTheme();
  const groups = useSelector((state) => state.groups);

  const handleSetImages = (event) => {
    setIsUploadingImage(true);
    setImageSrc(URL.createObjectURL(event.target.files[0]));
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .resize(1062, 1280)
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
            setIsUploadingImage(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  React.useEffect(() => {
    if (formData.urlPhoto) {
      setImageSrc(`${formData.urlPhoto}??20130910043254`);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        xs={12}
        sm={6}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Avatar
          alt="User photo"
          src={imageSrc}
          style={{ height: "100px", width: "100px" }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          id="raised-button-file-2"
          type="file"
          accept="image/jpeg,image/jpg"
          name="image"
          style={{ display: "none", width: "100%" }}
          onChange={handleSetImages}
        />
        <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginTop: 8,
              height: 40,
            }}
            fullWidth
            startIcon={<CloudUploadOutlinedIcon />}
            component="span"
          >
            Foto
          </Button>
        </label>
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          variant="outlined"
          margin="dense"
          label="Nombre"
          fullWidth
          error={error && !formData.name}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={formData.lastName}
          onChange={(e) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
          variant="outlined"
          margin="dense"
          label="Apellido"
          fullWidth
          error={error && !formData.lastName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={formData.ruc}
          onChange={(e) => setFormData({ ...formData, ruc: e.target.value })}
          variant="outlined"
          margin="dense"
          label="Ruc"
          fullWidth
          error={error && !formData.ruc}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={formData.phone}
          onChange={(e) =>
            setFormData({
              ...formData,
              phone: functions.formatPhone(e.target.value),
            })
          }
          variant="outlined"
          margin="dense"
          label="Teléfono"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={formData.email}
          disabled
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          variant="outlined"
          margin="dense"
          label="Email"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="type"
          value={formData.type}
          onChange={(e) => setFormData({ ...formData, type: e.target.value })}
          select
          margin="dense"
          label="Tipo de usuario"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          error={error && !formData.type}
        >
          <MenuItem value="GREENPOINT_EXTERN">Monitor GIRA Externo</MenuItem>
          <MenuItem value="ADMIN_EXTERN">Administrador Externo</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<Lock />}
          endIcon={formData.changePassword && <ExpandLess />}
          onClick={() =>
            setFormData({
              ...formData,
              changePassword: !formData.changePassword,
            })
          }
        >
          Cambiar contraseña
        </Button>
      </Grid>

      {formData.changePassword && (
        <Fragment>
          <Grid item xs={6}>
            <TextField
              value={formData.newPassword}
              onChange={(e) =>
                setFormData({ ...formData, newPassword: e.target.value })
              }
              variant="outlined"
              margin="dense"
              label="Nueva password"
              fullWidth
              type="password"
              error={error && formData.newPassword.length < 6}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formData.newPasswordRpt}
              onChange={(e) =>
                setFormData({ ...formData, newPasswordRpt: e.target.value })
              }
              variant="outlined"
              margin="dense"
              label="Repite nueva password"
              fullWidth
              type="password"
              error={
                error &&
                (formData.newPassword.length < 6 ||
                  formData.newPassword !== formData.newPasswordRpt)
              }
            />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12}>
        <Button
          size="small"
          variant="text"
          color="primary"
          startIcon={<Warning />}
          endIcon={formData.changeGroup && <ExpandLess />}
          disabled={formData.type === "ADMIN"}
          onClick={() =>
            setFormData({ ...formData, changeGroup: !formData.changeGroup })
          }
        >
          Cambiar Grupo o Punto GIRA
        </Button>
      </Grid>
      {formData.changeGroup && (
        <>
          <Grid item xs={6}>
            <Autocomplete
              fullWidth
              onChange={(e, k) =>
                setFormData({
                  ...formData,
                  newgroups: k._id,
                  greenPointSelect: k.externalsGreenPoints,
                  newEntity: "EMPTY",
                })
              }
              options={groups.groupsSelect}
              value={groups.groupsSelect.find(
                (gp) => gp._id === formData.newgroups._id
              )}
              disableClearable
              getOptionLabel={(option) =>
                option ? option.name : "Selecciona un grupo"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupo"
                  margin="dense"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {formData.type !== "ADMIN_EXTERN" && (
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                onChange={(e, k) =>
                  setFormData({
                    ...formData,
                    newEntity: k._id,
                  })
                }
                options={formData.greenPointSelect}
                value={formData.greenPointSelect.find(
                  (gp) => gp._id === formData.newEntity._id
                )}
                disableClearable
                getOptionLabel={(option) =>
                  option ? option.name : "Selecciona un Punto GIRA"
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto GIRA"
                    margin="dense"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
        </>
      )}
      {formData.type !== "ADMIN" && (
        <Grid item xs={12}>
          <Button
            size="small"
            variant="text"
            style={{
              color: !formData.changeActivate
                ? theme.palette.primary.main
                : formData.actived === "ACTIVED"
                ? theme.palette.error.main
                : theme.palette.success.main,
            }}
            startIcon={formData.changeActivate ? <Check /> : <Error />}
            disabled={formData.type === "ADMIN"}
            onClick={() =>
              setFormData({
                ...formData,
                changeActivate: !formData.changeActivate,
              })
            }
          >
            {formData.actived === "ACTIVED" && "Desactivar cuenta"}
            {formData.actived === "DEACTIVED" && "Activar cuenta"}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

ContentEdit.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
