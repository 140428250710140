import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import usersActions from "../../../../redux/actions/users";

import Header from "../../../Shared/Modals/Header";
import Content from "./Content";
import Buttons from "./Buttons";

export default function ModalDelete() {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (users.dataModal) {
      setData({
        name: users.dataModal.name + " " + users.dataModal.lastName,
        email: users.dataModal.email,
        type: users.dataModal.type,
        entityID: users.dataModal.entityID,
      });
    }
  }, [users.dataModal]);

  const handleClose = () => {
    dispatch(usersActions.setDataModal(null));
    dispatch(usersActions.setDeleteModal(false));
  };

  const handleDelete = () => {
    if (check) {
      dispatch(usersActions.deleteUser(users.dataModal._id));
      handleClose();
      setCheck(false);
    }
  };

  return (
    <Dialog open={users.deleteModal} onClose={handleClose} scroll={"body"}>
      <DialogContent>
        <Header
          handleClose={handleClose}
          title="Eliminar usuario"
          type="DELETE"
        />
        <Content data={data} check={check} setCheck={setCheck} />
      </DialogContent>
      <DialogActions>
        <Buttons
          handleClose={handleClose}
          handleDelete={handleDelete}
          check={check}
        />
      </DialogActions>
    </Dialog>
  );
}
