import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardMedia,
} from "@material-ui/core";

import Map from "./Map";
import formats from '../../../../resources/formats.json';
import zones from '../../../../resources/zones.json';

export default function CreateModal({ data, imageSrc, cities }) {

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={3}>
            <Typography variant="body1">Nombre Punto GIRA:</Typography>
            <Typography variant="body2">{data.name}</Typography>
          </Grid>
          <Grid item md={6} sm={3}>
            <Typography variant="body1">Formato:</Typography>
            <Typography variant="body2">
              {
                data.format ?
                  formats.filter(
                    (el) => el.code === data.format
                  )[0].name
                  :
                  "No Registra"
              }
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} sm={6}>
            <Typography variant="body1">Dirección:</Typography>
            <Typography variant="body2">{data.address}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} sm={3}>
            <Typography variant="body1">Zona:</Typography>
            <Typography variant="body2">
              {
                data.zone ?
                  zones.filter(
                    (el) => el.code === data.zone
                  )[0].name
                  :
                  "No Registra"
              }
            </Typography>
          </Grid>
          <Grid item md={6} sm={3}>
            <Typography variant="body1">Ciudad:</Typography>
            <Typography variant="body2">
              {
                data.city ?
                  cities.filter(
                    (el) => el.code === data.city
                  )[0].name
                  :
                  "No Registra"
              }
            </Typography>
          </Grid>
        </Grid>
        <Card>
          <CardMedia
            style={{
              height: 0,
              paddingTop: '60%',
              backgroundColor: '#d1d1cf'
            }} // 16:9
            image={data.urlPhoto ? `${data.urlPhoto}?20130910043254` : imageSrc}
            title="Imagen punto Gira"
          />
        </Card>
        <Grid container spacing={2}>
          <Grid item md={12} sm={6}>
            <Typography variant="body1">Descripción:</Typography>
            <Typography variant="body2">{data.description}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Map data={data} style={{ width: "100%" }} />
        <hr />
        <Typography variant="body1">
          Monitores: {!Boolean(data.users.length) && "No registra monitores"}
        </Typography>
        {data.users.map((user) => (
          <Typography variant="body2">
            - {user.name + " " + user.lastName + " (" + user.email + ")"}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
}
