import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import PropTypes from "prop-types";

export default function Buttons({ handleSuccess, handleClose }) {
  return (
    <ButtonGroup>
      <Button
        color="secondary"
        size="small"
        variant="contained"
        startIcon={<Close />}
        onClick={() => handleClose()}
      >
        Salir
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        startIcon={<Check />}
        onClick={() => handleSuccess()}
      >
        Guardar
      </Button>
    </ButtonGroup>
  );
}

Buttons.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
