import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Grid, Typography, Button, ButtonGroup } from "@material-ui/core";
import {
  BarChart as IndicatorIcon,
  AddCircleOutline as PlusIcon,
  ImportExport as ExportIcon,
} from "@material-ui/icons";
import externalReportsAction from "../../../redux/actions/externalReports";
import externalsIndicatorsAction from "../../../redux/actions/externalsIndicators";

export default function Header() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.externalsIndicators);

  return (
    <Grid container justify="space-between">
      <Grid item>
        <Typography variant="h4">
          <IndicatorIcon style={{ fontSize: 34, marginBottom: 6 }} />{" "}
          {user.type !== "ADMIN_EXTERN"
            ? `Indicadores de gestión externos`
            : `Indicadores de gestión`}
        </Typography>
      </Grid>
      <Grid item>
        <ButtonGroup size="small" variant="contained">
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<PlusIcon />}
            disabled={filters.groupID === ""}
            onClick={() =>
              dispatch(externalsIndicatorsAction.setViewModal(true))
            }
          >
            Ver metas
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            startIcon={<ExportIcon />}
            onClick={() =>
              dispatch(externalReportsAction.setExportToExcelModal(true))
            }
          >
            Exportar a excel
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </Grid>
  );
}
