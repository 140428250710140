import React, { useEffect } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import { Grid, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { schema } from "./Schema";

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#ff0000",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  customArrow: {
    color: "#ff0000",
  },
}));

const MaterialItem = ({ currentMaterial, primaryFormik }) => {
  const classes = useStyles();

  const initialValues = {
    key: "",
    quantity: "",
    hasError: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      primaryFormik.setValues({
        ...primaryFormik.values,
        metrics: primaryFormik.values.metrics.map((metric) => {
          if (metric.key === values.key) {
            return {
              ...metric,
              quantity: values.quantity,
              hasError: false,
            };
          }
          return metric;
        }),
      });
    },
  });

  const handleChange = (e) => {
    formik.setValues({
      ...formik.values,
      quantity: e.target.value,
    });
  };

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values.quantity]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      hasError: Boolean(formik.errors.quantity),
    });
    primaryFormik.setValues({
      ...primaryFormik.values,
      metrics: primaryFormik.values.metrics.map((metric) => {
        if (metric.key === formik.values.key) {
          return {
            ...metric,
            quantity: formik.values.quantity,
            hasError: Boolean(formik.errors.quantity),
          };
        }
        return metric;
      }),
    });
  }, [formik.errors.quantity]);

  useEffect(() => {
    if (currentMaterial) {
      formik.setValues({
        key: currentMaterial.key,
        quantity: currentMaterial.quantity || 0,
        hasError: currentMaterial.hasError || false,
      });
    }
  }, []);

  return (
    <>
      <Grid
        item
        xs={6}
        md={4}
        lg={3}
        key={`material_input-${currentMaterial.key}`}
      >
        <Tooltip
          title={formik.errors.quantity || ""}
          placement="bottom"
          arrow={true}
          open={Boolean(formik.errors.quantity)}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
        >
          <TextField
            variant="outlined"
            fullWidth
            margin="dense"
            type="number"
            error={Boolean(formik.errors.quantity)}
            value={formik.values.quantity}
            label={currentMaterial.name}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: <InputAdornment>KG</InputAdornment>,
            }}
          />
        </Tooltip>
      </Grid>
    </>
  );
};

export default MaterialItem;
