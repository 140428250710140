import React, { useEffect } from "react";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
} from "@material-ui/core";
import {
  Equalizer as EqualizerIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import externalsGreenPointsActions from "../../../../redux/actions/externalsGreenPoint";
import MaterialItem from "./MaterialItem";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 2,
    },
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  customTooltip: {
    backgroundColor: "#e68a00",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  customArrow: {
    color: "#ffa31a",
  },
}));

const EditGoalModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentGoals, dataModal, editGoalModal } = useSelector(
    (state) => state.externalGreenPoint
  );
  const utils = useSelector((state) => state.utils);
  const { loading } = useSelector((state) => state.app);

  const handleClose = () => {
    dispatch(externalsGreenPointsActions.setDataModal(null));
    dispatch(externalsGreenPointsActions.setEditGoalModal(false));
  };

  const initialValues = {
    greenPoint: "",
    group: "",
    metrics: [],
    total: 0,
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log(values);
      const formData = {
        ...values,
        metrics: JSON.stringify(values.metrics),
      };
      dispatch(externalsGreenPointsActions.putUpdateUnitGoal({ formData }));
      handleClose();
    },
  });

  useEffect(() => {
    if (formik.values.metrics?.length > 0) {
      formik.setValues({
        ...formik.values,
        total: formik.values?.metrics.reduce((acc, curr) => {
          return acc + parseFloat(curr.quantity || 0);
        }, 0),
      });
    }
  }, [formik.values.metrics]);

  useEffect(() => {
    if (dataModal && Object.keys(utils?.all_materials)?.length > 0) {
      console.log(currentGoals?.goals);
      formik.setValues({
        ...formik.values,
        greenPoint: dataModal?._id,
        group: dataModal?.groupId?._id,
        metrics: Object.keys(utils?.all_materials).map((key) => ({
          key,
          name: utils?.all_materials[key].name,
          quantity: currentGoals?.goals?.metrics
            ? currentGoals.goals?.metrics[key]
            : 0,
          hasError: false,
        })),
      });
    }
  }, [dataModal, utils?.all_materials, currentGoals]);

  return (
    <Dialog
      open={editGoalModal}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      maxWidth="md"
      fullWidth
    >
      {!loading ? (
        <>
          <DialogContent>
            {formik.values.metrics.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <EqualizerIcon style={{ fontSize: 26, marginBottom: 5 }} />{" "}
                    Actualizar Metas de Punto GIRA
                  </Typography>
                </Grid>
                {/* --- */}
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <Typography variant="h6">
                      TOTAL: {formik?.values?.total} KG
                    </Typography>
                  </Box>
                </Grid>
                {/* --- */}
                {formik.values.metrics.map((item) => (
                  <MaterialItem
                    key={item?.key}
                    currentMaterial={item}
                    primaryFormik={formik}
                  />
                ))}
              </Grid>
            )}
            <Box className={classes.root}>
              <Typography variant="button">
                Nota: Las Metas Corresponden al mes actual
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Tooltip
              title={"Uno de los campos no es valido"}
              placement="right"
              arrow={true}
              open={Boolean(
                formik.values.metrics
                  .map((item) => item.hasError)
                  .includes(true)
              )}
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
            >
              <Button
                color="primary"
                startIcon={<SaveIcon />}
                disabled={
                  formik.values?.metrics
                    .map((item) => item?.hasError)
                    .includes(true) ||
                  formik.values?.metrics?.length === 0 ||
                  formik.values?.greenPoint === ""
                }
                onClick={formik.handleSubmit}
                variant="contained"
                size="small"
              >
                Actualizar
              </Button>
            </Tooltip>
          </DialogActions>
        </>
      ) : (
        <Box className={classes.root}>Cargando...</Box>
      )}
    </Dialog>
  );
};

export default EditGoalModal;
