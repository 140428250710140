import axios from "axios";
import { saveAs } from "file-saver";
import notifiesActions from "./notifies";
import utilsActions from "./utils";
import appActions from "./app";
import authActions from "./auth";

const fetchGreenPoints = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const state = getState().greenPoints;
      const response = await axios.get(
        "/greenPoints/admin/" +
          state.pagination.page +
          "/" +
          state.pagination.rows +
          "?search=" +
          state.search
      );
      dispatch({ type: "GREENPOINTS_FETCH", payload: response.data });
      dispatch(utilsActions.fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postGreenPoint = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.post("/greenPoints/admin", formData);
      dispatch(notifiesActions.enqueueNotify("GREENPOINT_CREATE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GREENPOINT_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const patchGreenPoint = ({ data }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.patch("/greenPoints/admin", data);
      dispatch(notifiesActions.enqueueNotify("GREENPOINT_UPDATE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GREENPOINT_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const deleteGreenPoint = (id) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.delete("/greenPoints/admin/" + id);
      dispatch(notifiesActions.enqueueNotify("GREENPOINT_DELETE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GREENPOINT_DELETE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setCreateModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GREENPOINTS_SET_CREATE_MODAL", payload: value });
};

const setDeleteModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GREENPOINTS_SET_DELETE_MODAL", payload: value });
};

const setEditModal = (value) => {
  return (dispatch) => {
    dispatch({ type: "GREENPOINTS_SET_EDIT_MODAL", payload: value });
  };
};

const setDataModal = (value) => {
  return (dispatch) =>
    dispatch({ type: "GREENPOINTS_SET_DATA_MODAL", payload: value });
};

const setSearch = (value) => {
  return async (dispatch) => {
    await dispatch({ type: "GREENPOINTS_SET_SEARCH", payload: value });
    dispatch(fetchGreenPoints());
  };
};

const setPagination = (value) => {
  return async (dispatch) => {
    await dispatch({
      type: "GREENPOINTS_SET_PAGINATION",
      payload: value,
    });
    dispatch(fetchGreenPoints());
  };
};

const putUpdateUnitGoal = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      await axios.put("/goals/update-unit-goal", formData);
      dispatch(notifiesActions.enqueueNotify("GOAL_UPDATE_SUCCESS"));
      dispatch(fetchGreenPoints());
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("GOAL_UPDATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const fetchGoalByGreenpoint = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const { dataModal } = getState().greenPoints;
      const response = await axios.get(
        `/goals/get-one-by-greenpoint/?greenPoint=${dataModal._id}`
      );
      dispatch({ type: "GOAL_BY_GREEBPOINT_FETCH", payload: response.data });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setEditGoalModal = (value) => {
  return (dispatch) => {
    if (value) {
      dispatch(fetchGoalByGreenpoint());
    }
    dispatch({ type: "GREENPOINTS_SET_EDIT_GOAL_MODAL", payload: value });
  };
};

const setEditMassiveGoalsModal = (value) => {
  return (dispatch) => {
    if (value) {
      dispatch(fetchGoalByGreenpoint());
    }
    dispatch({
      type: "GREENPOINTS_SET_EDIT_MASSIVE_GOALS_MODAL",
      payload: value,
    });
  };
};

const fetchGreenPointsForFilter = () => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.get("/greenPoints/get-all-greenpoint");
      dispatch({
        type: "GREENPOINTS_SET_FILTER",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const getTemplateForGoals = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post(
        "/greenPoints/generate-template-goals",
        formData,
        {
          responseType: "arraybuffer",
        }
      );
      const dirtyFileName = response.headers["content-disposition"];
      const regex =
        /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      const fileName = dirtyFileName.match(regex)[3];

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
      dispatch(notifiesActions.enqueueNotify("TEMPLATE_GOALS_CREATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(notifiesActions.enqueueNotify("TEMPLATE_GOALS_CREATE_FAIL"));
      dispatch(appActions.setLoading(false));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const postMassiveUpdateGoals = ({ formData }) => {
  return async (dispatch) => {
    try {
      dispatch(appActions.setLoading(true));
      const response = await axios.post(
        "/goals/admin/massive-update-goals",
        formData
      );
      dispatch({
        type: "SET_MASSIVE_UPDATE_GOALS_RESPONSE",
        payload: response.data,
      });
      dispatch(notifiesActions.enqueueNotify("GOALS_MASSIVE_UPDATE_SUCCESS"));
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch({
        type: "SET_MASSIVE_UPDATE_GOALS_RESPONSE",
        payload: err?.response?.data,
      });
      dispatch(appActions.setLoading(false));
      dispatch(notifiesActions.enqueueNotify("GOALS_MASSIVE_UPDATE_FAIL"));
      dispatch(authActions.logoutIfNotAuth(err));
    }
  };
};

const setMassiveUpdateGoalsResponse = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MASSIVE_UPDATE_GOALS_RESPONSE",
      payload: value,
    });
  };
};

export default {
  fetchGreenPoints,
  patchGreenPoint,
  postGreenPoint,
  deleteGreenPoint,
  setCreateModal,
  setDeleteModal,
  setEditModal,
  setEditGoalModal,
  setEditMassiveGoalsModal,
  setDataModal,
  setSearch,
  setPagination,
  putUpdateUnitGoal,
  fetchGreenPointsForFilter,
  getTemplateForGoals,
  postMassiveUpdateGoals,
  setMassiveUpdateGoalsResponse,
};
