import axios from "axios";
import appActions from "./app";

const fetchGoalsProgress = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(appActions.setLoading(true));
      const now = new Date();
      const params = {
        date: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      };
      const profile = getState().profile;

      let URL = "";
      if (profile.user.type === "GREENPOINT") {
        URL = `/goals/greenpoint/progress/${profile.user.entityID}`;
      }
      if (profile.user.type === "GREENPOINT_EXTERN") {
        URL = `/goals/externals-greenpoint/progress/${profile.user.externalEntityID._id}`;
      }

      const response = await axios.get(URL, { params });
      dispatch({
        type: "GOALS_GREEN_POINT_PROGRESS_FETCH",
        payload: response.data,
      });
      dispatch(appActions.setLoading(false));
    } catch (err) {
      dispatch(appActions.setLoading(false));
    }
  };
};
export default {
  fetchGoalsProgress,
};
